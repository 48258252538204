import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faSpinner, faExclamationTriangle, faCheckCircle, faChevronRight, faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { fetchMipsCriteriaById } from '../../services/mipsCriteriaService';

/**
 * Component to display and interact with MIPS criteria
 * @param {Object} props - Component props
 * @param {boolean} props.isVisible - Whether the calculator is visible
 * @param {Function} props.onClose - Function to call when closing the calculator
 * @param {string|number} props.mipsCriteriaId - ID of the selected MIPS criteria
 * @param {Array} props.mipsCriteria - Array of all available MIPS criteria
 * @param {Function} props.updateSection - Function to update document sections
 * @param {Function} props.setSaveTriggered - Function to trigger saving
 */
const MipsCriteriaCalculator = ({
    isVisible,
    onClose,
    mipsCriteriaId,
    mipsCriteria = [],
    updateSection,
    setSaveTriggered
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [criteria, setCriteria] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [userInputs, setUserInputs] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [expandedComponents, setExpandedComponents] = useState(new Set());

    // Fetch detailed MIPS criteria data when mipsCriteriaId changes
    useEffect(() => {
        if (isVisible && mipsCriteriaId) {
            fetchMipsCriteriaDetails();
        }
    }, [isVisible, mipsCriteriaId]);

    // Reset state when modal is closed
    useEffect(() => {
        if (!isVisible) {
            setSelectedOption(null);
            setUserInputs({});
            setSuccessMessage('');
            setError(null);
            setExpandedComponents(new Set());
        }
    }, [isVisible]);

    // Fetch the detailed MIPS criteria with all components
    const fetchMipsCriteriaDetails = async () => {
        setLoading(true);
        setError(null);
        
        try {
            // First check if we already have the full criteria data in the props
            const existingCriteria = mipsCriteria.find(c => c.mips_criteria_id === mipsCriteriaId);
            
            if (existingCriteria && existingCriteria.components) {
                setCriteria(existingCriteria);
                setLoading(false);
                return;
            }
            
            // If not already loaded or missing components, fetch from API
            const criteriaData = await fetchMipsCriteriaById(getAccessTokenSilently, mipsCriteriaId);
            
            if (criteriaData) {
                setCriteria(criteriaData);
            } else {
                setError('Failed to load MIPS criteria details');
            }
        } catch (err) {
            console.error('Error fetching MIPS criteria details:', err);
            setError(`Error: ${err.response?.data?.detail || err.message}`);
        } finally {
            setLoading(false);
        }
    };

    // Handle option selection and automatically expand/collapse
    const handleOptionSelect = (componentNumber, componentId) => {
        setSelectedOption(componentNumber);
        
        // Get the selected component
        const selectedComponent = findComponentByNumber(componentNumber, criteria?.components || []);
        
        // Automatically toggle expansion if the component has children
        if (selectedComponent && hasSubcomponents(selectedComponent)) {
            setExpandedComponents(prev => {
                const newExpanded = new Set(prev);
                if (newExpanded.has(componentId)) {
                    newExpanded.delete(componentId);
                } else {
                    newExpanded.add(componentId);
                }
                return newExpanded;
            });
        }
        
        // Clear any previous user inputs that might be needed
        if (selectedComponent && selectedComponent.has_user_input) {
            setUserInputs(prev => {
                const updatedInputs = {...prev};
                delete updatedInputs[selectedComponent.component_id];
                return updatedInputs;
            });
        }
    };

    // Toggle expansion of a component without selecting it
    const toggleComponentExpansion = (componentId, event) => {
        // Stop the event from propagating to prevent selecting the component when expanding/collapsing
        event.stopPropagation();
        
        setExpandedComponents(prev => {
            const newExpanded = new Set(prev);
            if (newExpanded.has(componentId)) {
                newExpanded.delete(componentId);
            } else {
                newExpanded.add(componentId);
            }
            return newExpanded;
        });
    };

    // Helper to find component by its number (in the flattened structure)
    const findComponentByNumber = (componentNumber, components) => {
        for (const component of components) {
            if (component.component_number === componentNumber) {
                return component;
            }
            
            // Check subcomponents if any
            if (component.subcomponents && component.subcomponents.length > 0) {
                const found = findComponentByNumber(componentNumber, component.subcomponents);
                if (found) return found;
            }
        }
        return null;
    };

    // Handle user input for components that require it
    const handleUserInput = (componentId, value) => {
        setUserInputs(prev => ({
            ...prev,
            [componentId]: value
        }));
    };

    // Generate documentation for the note based on selected option
    const handleUpdateDocument = () => {
        if (!criteria || !selectedOption) return;
        
        const selectedComponent = findComponentByNumber(selectedOption, criteria?.components || []);
        if (!selectedComponent) return;
        
        let documentText = '';
        
        // Build the documentation text in a clean format
        documentText += `MIPS Criteria: #${criteria.criteria_number} - ${criteria.criteria_title}\n`;
        
        // Check if the selected component is a third-level subcomponent (e.g., 2f.1, 2k.1, etc.)
        if (selectedComponent.is_subcomponent && selectedComponent.parent_component_id) {
            const parentComponent = findParentComponentById(selectedComponent.parent_component_id, criteria?.components || []);
            
            if (parentComponent) {
                // Check if parent is also a subcomponent (indicating this is a third-level component)
                if (parentComponent.is_subcomponent && parentComponent.parent_component_id) {
                    // This is a third-level component (e.g., 2f.1)
                    // Find the top-level parent (e.g., component 2)
                    const grandparentComponent = findParentComponentById(parentComponent.parent_component_id, criteria?.components || []);
                    
                    if (grandparentComponent) {
                        // Add the top level component text
                        documentText += `${grandparentComponent.component_text}\n`;
                        // Add the second level (parent) component text
                        documentText += `- ${parentComponent.component_text}\n`;
                        // Add the third level (selected) component with an extra indent
                        if (selectedComponent.has_user_input && userInputs[selectedComponent.component_id]) {
                            const contentText = selectedComponent.component_text.replace('[****]', userInputs[selectedComponent.component_id]);
                            documentText += `  - ${contentText}`;
                        } else {
                            documentText += `  - ${selectedComponent.component_text}`;
                        }
                    }
                } else {
                    // This is a second-level component (e.g., 2a)
                    // Add the parent component text
                    documentText += `${parentComponent.component_text}\n`;
                    // Add the selected component text with a dash bullet
                    if (selectedComponent.has_user_input && userInputs[selectedComponent.component_id]) {
                        const contentText = selectedComponent.component_text.replace('[****]', userInputs[selectedComponent.component_id]);
                        documentText += `- ${contentText}`;
                    } else {
                        documentText += `- ${selectedComponent.component_text}`;
                    }
                }
            } else {
                // Fallback if parent not found
                if (selectedComponent.has_user_input && userInputs[selectedComponent.component_id]) {
                    const contentText = selectedComponent.component_text.replace('[****]', userInputs[selectedComponent.component_id]);
                    documentText += contentText;
                } else {
                    documentText += selectedComponent.component_text;
                }
            }
        } else {
            // For top-level components, just add the selected component text
            if (selectedComponent.has_user_input && userInputs[selectedComponent.component_id]) {
                // Replace the placeholder with the actual user input if applicable
                const contentText = selectedComponent.component_text.replace('[****]', userInputs[selectedComponent.component_id]);
                documentText += contentText;
            } else {
                documentText += selectedComponent.component_text;
            }
        }
        
        // Add to the evaluations section - ALWAYS APPEND, NEVER REPLACE
        updateSection('reevaluation', prev => {
            // Always append the new MIPS criteria to the existing content
            return prev ? `${prev}\n\n${documentText}` : documentText;
        });
        
        // Trigger save
        setSaveTriggered(prev => !prev);
        
        // Show success message
        setSuccessMessage('MIPS criteria documentation added to evaluations section');
        
        // Close the modal after a delay
        setTimeout(() => {
            onClose();
        }, 2000);
    };
    
    // Helper to find parent component by ID
    const findParentComponentById = (parentId, components) => {
        for (const component of components) {
            if (component.component_id === parentId) {
                return component;
            }
            
            // Check subcomponents if any
            if (component.subcomponents && component.subcomponents.length > 0) {
                const found = findParentComponentById(parentId, component.subcomponents);
                if (found) return found;
            }
        }
        return null;
    };

    // Check if a component has subcomponents
    const hasSubcomponents = (component) => {
        return component.subcomponents && component.subcomponents.length > 0;
    };

    // Render a component with improved UI
    const renderComponent = (component, level = 0) => {
        const isSelected = selectedOption === component.component_number;
        const isExpanded = expandedComponents.has(component.component_id);
        const hasChildren = hasSubcomponents(component);
        
        return (
            <div key={component.component_id} className="mb-2">
                <div 
                    className={`p-3 rounded-lg cursor-pointer transition-colors duration-200 ${
                        isSelected 
                            ? 'bg-indigo-50 border-l-4 border-indigo-500' 
                            : 'hover:bg-gray-50 border-l-4 border-transparent'
                    }`}
                    onClick={() => handleOptionSelect(component.component_number, component.component_id)}
                >
                    <div className="flex items-start">
                        {/* Expand/Collapse button for components with children */}
                        {hasChildren && (
                            <button
                                className="mr-1 p-1 text-gray-500 hover:text-indigo-600 focus:outline-none"
                                onClick={(e) => toggleComponentExpansion(component.component_id, e)}
                                aria-label={isExpanded ? "Collapse" : "Expand"}
                            >
                                <FontAwesomeIcon 
                                    icon={faChevronDown} 
                                    className={`h-3 w-3 transition-transform duration-200 ${isExpanded ? 'transform rotate-180' : ''}`} 
                                />
                            </button>
                        )}
                        
                        {/* Selection indicator */}
                        <div 
                            className={`flex-shrink-0 flex items-center justify-center w-5 h-5 mt-0.5 mr-3 rounded-full border ${
                                isSelected
                                    ? 'bg-indigo-600 border-indigo-600'
                                    : 'border-gray-300'
                            }`}
                        >
                            {isSelected && (
                                <FontAwesomeIcon icon={faCheck} className="h-3 w-3 text-white" />
                            )}
                        </div>
                        
                        {/* Component text */}
                        <div className={`flex-1 ${isSelected ? 'text-indigo-700 font-medium' : 'text-gray-700'}`}>
                            <span className="font-medium">{component.component_number}.</span> {component.component_text}
                        </div>
                    </div>
                    
                    {/* If this component has user input and is selected, show the input field */}
                    {component.has_user_input && isSelected && (
                        <div className="mt-4 ml-8 pl-4 border-l-2 border-indigo-200">
                            <div className="form-group">
                                <label htmlFor={`input_${component.component_id}`} className="block text-sm font-medium text-gray-700 mb-1">
                                    {component.input_placeholder || "Enter details..."}
                                </label>
                                <input
                                    id={`input_${component.component_id}`}
                                    type="text"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                    placeholder="Enter specific information..."
                                    value={userInputs[component.component_id] || ''}
                                    onChange={(e) => handleUserInput(component.component_id, e.target.value)}
                                    onClick={(e) => e.stopPropagation()} // Prevent the click from affecting the parent
                                />
                            </div>
                            
                            {/* If this component has both user input and subcomponents, show an additional expand button */}
                            {hasChildren && (
                                <div className="mt-2">
                                    <button
                                        className="flex items-center text-sm text-indigo-600 hover:text-indigo-800 focus:outline-none"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleComponentExpansion(component.component_id, e);
                                        }}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faChevronDown} 
                                            className={`h-3 w-3 mr-1 transition-transform duration-200 ${isExpanded ? 'transform rotate-180' : ''}`} 
                                        />
                                        {isExpanded ? "Hide subcomponents" : "Show subcomponents"}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                
                {/* Render subcomponents only if this component is expanded */}
                {hasChildren && isExpanded && (
                    <div className="ml-6 pl-2 border-l border-gray-200 mt-1">
                        {component.subcomponents.map(subcomponent => 
                            renderComponent(subcomponent, level + 1)
                        )}
                    </div>
                )}
            </div>
        );
    };

    // Check if the modal is visible before rendering content
    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
            <div className="relative bg-white rounded-lg shadow-xl w-full max-w-2xl mx-4 md:mx-auto max-h-[90vh] flex flex-col">
                {/* Header */}
                <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center bg-indigo-900 text-white rounded-t-lg">
                    <h2 className="text-xl font-semibold">
                        {criteria ? `MIPS Criteria #${criteria.criteria_number}` : 'MIPS Criteria'}
                    </h2>
                    <button
                        onClick={onClose}
                        className="rounded-full p-1 hover:bg-indigo-800 transition-colors"
                        aria-label="Close"
                    >
                        <FontAwesomeIcon icon={faXmark} className="h-6 w-6" />
                    </button>
                </div>

                {/* Body */}
                <div className="flex-1 overflow-y-auto px-6 py-4">
                    {loading ? (
                        <div className="flex flex-col items-center justify-center py-8">
                            <FontAwesomeIcon icon={faSpinner} spin className="h-8 w-8 text-indigo-600 mb-4" />
                            <p className="text-gray-700">Loading MIPS criteria...</p>
                        </div>
                    ) : error ? (
                        <div className="bg-red-50 border border-red-200 rounded-md p-4 mb-4">
                            <div className="flex">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="h-5 w-5 text-red-500 mr-3" />
                                <p className="text-red-700">{error}</p>
                            </div>
                        </div>
                    ) : successMessage ? (
                        <div className="bg-green-50 border border-green-200 rounded-md p-4 mb-4">
                            <div className="flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="h-5 w-5 text-green-500 mr-3" />
                                <p className="text-green-700">{successMessage}</p>
                            </div>
                        </div>
                    ) : criteria ? (
                        <div>
                            {/* Criteria Title and Description */}
                            <div className="mb-6">
                                <h3 className="text-xl font-semibold text-gray-800 mb-2">{criteria.criteria_title}</h3>
                                <p className="text-gray-600">
                                    Navigate through the options below. Click the arrow icon to expand categories. The documentation will be added to the evaluations section of your note.
                                </p>
                            </div>

                            {/* Components */}
                            <div className="space-y-1 bg-white rounded-lg">
                                {criteria.components?.map((component) => renderComponent(component))}
                            </div>
                        </div>
                    ) : (
                        <div className="text-center py-8 text-gray-500">No MIPS criteria selected</div>
                    )}
                </div>

                {/* Footer */}
                <div className="px-6 py-4 border-t border-gray-200 flex justify-between items-center bg-gray-50 rounded-b-lg">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 text-gray-700 hover:text-gray-900"
                    >
                        Cancel
                    </button>
                    
                    <button
                        onClick={handleUpdateDocument}
                        disabled={!selectedOption || loading}
                        className={`px-4 py-2 rounded-md ${
                            !selectedOption || loading
                                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                : 'bg-indigo-600 text-white hover:bg-indigo-700'
                        } transition-colors`}
                    >
                        {loading ? (
                            <>
                                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                                Processing...
                            </>
                        ) : (
                            'Add to Note'
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MipsCriteriaCalculator; 