import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { invitationsApi } from '../../api/invitationsApi';
import { InvitationsTable } from '../../components/InvitationsTable';
import { callApiWithToken } from '../../api/apiUtils';
import { protectedResources } from '../../authConfig';

const ACTOR_SPECIALTY_OPTIONS = [
    { value: 'EMERGENCY_PHYSICIAN', label: 'Physician' },
    { value: 'ADVANCED_PRACTICE_PROVIDER', label: 'Mid-level' },
    { value: 'EM_RESIDENT', label: 'Resident' },
];

export default function UserInvitationContent({ orgId }) {
    const [activeTab, setActiveTab] = useState('single');
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        actorSpecialty: '',
        group_id: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({ type: '', content: '' });
    const [invitations, setInvitations] = useState([]);
    const { getAccessTokenSilently } = useAuth0();
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePreview, setFilePreview] = useState([]);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        fetchInvitations();
        fetchGroups();
    }, []);

    const fetchInvitations = async () => {
        try {
            const data = await invitationsApi.getOrgInvitations(getAccessTokenSilently, orgId);
            setInvitations(data);
        } catch (error) {
            console.error('Error fetching invitations:', error);
            setMessage({
                type: 'error',
                content: 'Failed to fetch invitations'
            });
        }
    };

    const fetchGroups = useCallback(async () => {
        try {
            const response = await callApiWithToken(
                getAccessTokenSilently,
                `${protectedResources.apiGetGroupHierarchy.endpoint}${orgId}`,
                'GET'
            );

            const flattenGroups = (hierarchy, prefix = '') => {
                let groups = [];
                hierarchy.forEach(group => {
                    // Format group name with subscription type
                    const subscriptionType = group.is_group_paying ?
                        "Group Subscription" :
                        "Individual Subscription";

                    groups.push({
                        id: group.group_id,
                        name: prefix + group.name,
                        displayName: `${prefix}${group.name} (${subscriptionType})`
                    });

                    if (group.children) {
                        groups = [...groups, ...flattenGroups(group.children, `${prefix}${group.name} > `)];
                    }
                });
                return groups;
            };

            setGroups(flattenGroups(response.hierarchy));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching groups:', error);
            setError('Failed to load groups');
            setLoading(false);
        }
    }, [getAccessTokenSilently, orgId]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSingleInvite = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage({ type: '', content: '' });

        const data = {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
            actor_specialty: formData.actorSpecialty,
            org_id: orgId,
            group_id: formData.group_id ? parseInt(formData.group_id) : null
        };

        try {
            await invitationsApi.upsertInvitation(getAccessTokenSilently, data);

            setMessage({
                type: 'success',
                content: 'User invitation sent successfully!'
            });

            // Auto-dismiss success message after 3 seconds
            setTimeout(() => {
                setMessage(prev => prev.type === 'success' ? { type: '', content: '' } : prev);
            }, 3000);

            setFormData({
                email: '',
                firstName: '',
                lastName: '',
                actorSpecialty: '',
                group_id: '',
            });
            await fetchInvitations();
        } catch (error) {
            console.error('Error sending invitation:', error);
            setMessage({
                type: 'error',
                content: error.message || 'Failed to send invitation'
            });
            // Error message will stay until next action
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileSelect = async (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        setSelectedFile(file);

        // Read and preview the file
        const reader = new FileReader();
        reader.onload = (event) => {
            const csv = event.target.result;
            const lines = csv.split('\n');
            const headers = lines[0].split(',');
            const previewData = lines.slice(1, 6).map(line => {  // Preview first 5 rows
                const values = line.split(',');
                return headers.reduce((obj, header, index) => {
                    obj[header.trim()] = values[index]?.trim() || '';
                    return obj;
                }, {});
            }).filter(row => Object.values(row).some(value => value));  // Filter out empty rows

            setFilePreview(previewData);
            setShowPreview(true);
        };

        reader.readAsText(file);
    };

    const handleConfirmUpload = async () => {
        if (!selectedFile) return;

        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            await invitationsApi.bulkCreateInvitations(getAccessTokenSilently, formData, orgId);

            // Refresh invitations table
            await fetchInvitations();

            setMessage({
                type: 'success',
                content: 'Bulk invitations processed'
            });

            // Reset file selection and preview
            setSelectedFile(null);
            setFilePreview([]);
            setShowPreview(false);
        } catch (error) {
            console.error('Error processing bulk invitations:', error);
            setMessage({
                type: 'error',
                content: error.message || 'Failed to process bulk invitations'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const downloadTemplate = () => {
        const headers = 'email,first_name,last_name,actor_specialty,group_id\n';
        const example = 'john.doe@example.com,John,Doe,EMERGENCY_PHYSICIAN,1\n';
        const blob = new Blob([headers, example], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'invite_template.csv';
        a.click();
    };

    if (loading) return <div>Loading groups...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="h-full flex flex-col">
            <div className="flex-1 overflow-auto">
                <div className="p-8">
                    <div className="mx-auto max-w-2xl">
                        <h2 className="text-2xl font-bold mb-6">Invite Users</h2>

                        {/* Tab Navigation */}
                        <div className="border-b border-gray-200 mb-6">
                            <nav className="-mb-px flex space-x-8">
                                <button
                                    onClick={() => setActiveTab('single')}
                                    className={`${activeTab === 'single'
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                >
                                    Single Invite
                                </button>
                                <button
                                    onClick={() => setActiveTab('bulk')}
                                    className={`${activeTab === 'bulk'
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                >
                                    Bulk Upload
                                </button>
                            </nav>
                        </div>

                        {activeTab === 'single' ? (
                            <form onSubmit={handleSingleInvite} className="space-y-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Email Address*
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                    />
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            First Name*
                                        </label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleInputChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Last Name*
                                        </label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleInputChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            required
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Specialty*
                                    </label>
                                    <select
                                        name="actorSpecialty"
                                        value={formData.actorSpecialty}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                    >
                                        <option value="">Select a specialty</option>
                                        {ACTOR_SPECIALTY_OPTIONS.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label
                                        htmlFor="group_id"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Group
                                    </label>
                                    <select
                                        id="group_id"
                                        name="group_id"
                                        value={formData.group_id}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            group_id: e.target.value
                                        })}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm 
                                                 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option value="">Select a group (optional)</option>
                                        {groups.map(group => (
                                            <option key={group.id} value={group.id}>
                                                {group.displayName}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {message.content && (
                                    <div className={`rounded-md p-4 ${message.type === 'success' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'}`}>
                                        {message.content}
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className={`flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                                        }`}
                                >
                                    {isLoading ? 'Sending...' : 'Send Invitation'}
                                </button>
                            </form>
                        ) : (
                            <div className="space-y-6">
                                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                                    <h3 className="font-medium text-gray-900 mb-2">Required CSV Columns</h3>
                                    <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                                        <li>email (e.g., john.doe@example.com)</li>
                                        <li>first_name (e.g., John)</li>
                                        <li>last_name (e.g., Doe)</li>
                                        <li>actor_specialty (EMERGENCY_PHYSICIAN, ADVANCED_PRACTICE_PROVIDER, or EM_RESIDENT)</li>
                                        <li>group_id (optional, e.g., 1)</li>
                                    </ul>

                                    <div className="mt-4">
                                        <h4 className="font-medium text-gray-900 mb-2">Available Groups:</h4>
                                        <ul className="list-disc list-inside text-sm text-gray-600 space-y-1">
                                            {groups.map(group => (
                                                <li key={group.id}>
                                                    {group.displayName} (ID: {group.id})
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-4">
                                    {!showPreview ? (
                                        <div className="flex justify-between">
                                            <button
                                                onClick={downloadTemplate}
                                                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                Download Template
                                            </button>

                                            <label className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer">
                                                Select CSV File
                                                <input
                                                    type="file"
                                                    accept=".csv"
                                                    onChange={handleFileSelect}
                                                    className="hidden"
                                                />
                                            </label>
                                        </div>
                                    ) : (
                                        <div className="space-y-4">
                                            <div className="flex items-center justify-between">
                                                <span className="text-sm font-medium text-gray-700">
                                                    Selected file: {selectedFile?.name}
                                                </span>
                                                <button
                                                    onClick={() => {
                                                        setSelectedFile(null);
                                                        setFilePreview([]);
                                                        setShowPreview(false);
                                                    }}
                                                    className="text-sm text-red-600 hover:text-red-800"
                                                >
                                                    Remove
                                                </button>
                                            </div>

                                            <div className="border rounded-lg">
                                                <div className="overflow-x-auto">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                {Object.keys(filePreview[0] || {}).map((header) => (
                                                                    <th
                                                                        key={header}
                                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                                                                    >
                                                                        {header}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {filePreview.map((row, idx) => (
                                                                <tr key={idx}>
                                                                    {Object.values(row).map((value, valueIdx) => (
                                                                        <td
                                                                            key={valueIdx}
                                                                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                                        >
                                                                            {value}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="flex justify-end space-x-4">
                                                <button
                                                    onClick={() => {
                                                        setSelectedFile(null);
                                                        setFilePreview([]);
                                                        setShowPreview(false);
                                                    }}
                                                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    onClick={handleConfirmUpload}
                                                    disabled={isLoading}
                                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                                                >
                                                    {isLoading ? 'Processing...' : 'Create Invitations'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {message.content && (
                                    <div className={`rounded-md p-4 ${message.type === 'success' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'}`}>
                                        {message.content}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <InvitationsTable
                    invitations={invitations}
                    onInvitationUpdated={fetchInvitations}
                />
            </div>
        </div>
    );
} 