// src/pages/AcceptPolicies.js

import React, { useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../components/catalyst/button";
import { Fieldset, Legend, FieldGroup } from "../components/catalyst/fieldset";
import { useAuth0 } from "@auth0/auth0-react";
import { protectedResources, callProtectedApi } from "../authConfig";

const AcceptPolicies = () => {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);

    const actorInfo = {
        actor_id: 1,
        actor_uuid: "1234-5678-91011-1213",
        email: "test@example.com",
        full_name: "Test User",
        accepted_most_recent_terms: false,
        accepted_most_recent_privacy_policy: false,
    };

    const handleAcceptPolicies = useCallback(async () => {
        try {
            const result = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiAcceptPolicies.endpoint,
                {
                    method: "POST",
                    body: JSON.stringify({})
                }
            );

            if (result.success) {
                navigate("/er-dashboard", { state: { actorInfo: actorInfo } });
            } else {
                console.error("Failed to update actor info");
            }
        } catch (error) {
            console.error("Error updating actor info:", error);
        }
    }, [getAccessTokenSilently, agreedToTerms, agreedToPrivacyPolicy, actorInfo, navigate]);

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="p-8 w-full max-w-lg bg-white rounded-lg shadow-lg">
                <Fieldset>
                    <Legend className="pb-4 text-2xl text-black">Policy Refresh</Legend>
                    <p className="mb-8 text-gray-600 text-leading">
                        We've refreshed our policies! Please acknowledge them below to continue using Cleo.
                    </p>
                    <FieldGroup className="flex flex-col items-start">
                        <div className="mb-4">
                            <input
                                type="checkbox"
                                id="terms"
                                checked={agreedToTerms}
                                onChange={(e) => setAgreedToTerms(e.target.checked)}
                                className="mr-2"
                            />
                            <label htmlFor="terms" className="text-lg">
                                I accept the{" "}
                                <a
                                    className="text-indigo-900"
                                    href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Terms_of_Use.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Terms of Use
                                </a>
                            </label>
                        </div>
                        <div className="mb-8">
                            <input
                                type="checkbox"
                                id="privacy"
                                checked={agreedToPrivacyPolicy}
                                onChange={(e) => setAgreedToPrivacyPolicy(e.target.checked)}
                                className="mr-2"
                            />
                            <label htmlFor="privacy" className="text-lg">
                                I accept the{" "}
                                <a
                                    className="text-indigo-900"
                                    href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Privacy_Policy.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </label>
                        </div>
                        <Button
                            onClick={handleAcceptPolicies}
                            color="indigo"
                            className="self-center w-full"
                            disabled={!agreedToTerms || !agreedToPrivacyPolicy}
                        >
                            Accept and Continue
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </div>
        </div>
    );
};

export default AcceptPolicies;
