import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faMeh, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { callProtectedApi, protectedResources } from '../../authConfig';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * NoteFeedbackButton component for collecting user feedback on notes
 * @param {Object} props - Component props
 * @param {boolean} props.isFeedbackVisible - Whether feedback component is visible
 * @param {Function} props.setIsFeedbackVisible - Function to update feedback visibility
 * @param {Object} props.documentID - The document ID of the current chart
 */
const NoteFeedbackButton = ({
    isFeedbackVisible,
    setIsFeedbackVisible,
    documentID
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
    const [noteFeedback, setNoteFeedback] = useState('');
    const [hasProvidedRating, setHasProvidedRating] = useState(false);
    /**
     * Submit feedback to the server
     */
    const submitFeedback = async () => {
        try {
            setIsFeedbackSubmitted(true);

            await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiUpdateDocument.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        document_id: documentID,
                        field_to_update: 'note_feedback',
                        new_value: noteFeedback
                    })
                }
            );

            setIsFeedbackSubmitted(true);
            setHasProvidedRating(true);

            // Hide the feedback component after a delay  
            setTimeout(() => {
                setIsFeedbackVisible(false);
            }, 2000);
        } catch (error) {
            console.error('Error submitting feedback:', error);
            // Optionally handle error state  
        }
    };

    /**
     * Update the note rating
     * @param {string} rating - The rating value ('good', 'medium', or 'bad')
     */
    const handleRatingUpdate = async (rating) => {
        try {
            setHasProvidedRating(true);

            await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiUpdateDocument.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        document_id: documentID,
                        field_to_update: 'note_rating',
                        new_value: rating
                    })
                }
            );
        } catch (error) {
            console.error('Error updating note rating:', error);
        }
    };

    /**
     * Skip providing additional feedback
     */
    const handleSkipFeedback = () => {
        setIsFeedbackSubmitted(true);
        setNoteFeedback('');
        setTimeout(() => {
            setIsFeedbackVisible(false);
        }, 3000);
    };

    if (!isFeedbackVisible) {
        return null;
    }

    return (
        <div className="fixed bottom-48 right-10 z-40">
            {!hasProvidedRating ? (
                <div className="w-full md:w-20 border border-gray-300 rounded-xl overflow-hidden flex flex-col items-center bg-white py-2">
                    <div className="text-xs font-bold text-gray-700 mb-2 text-center">
                        How did Cleo do?
                    </div>

                    <button
                        onClick={() => handleRatingUpdate('good')}
                        className="mb-2 focus:outline-none"
                        aria-label="Rate Good"
                    >
                        <FontAwesomeIcon icon={faThumbsUp} className="h-6 w-6 text-green-500" />
                    </button>

                    <button
                        onClick={() => handleRatingUpdate('medium')}
                        className="mb-2 focus:outline-none"
                        aria-label="Rate Neutral"
                    >
                        <FontAwesomeIcon icon={faMeh} className="h-6 w-6 text-yellow-500" />
                    </button>

                    <button
                        onClick={() => handleRatingUpdate('bad')}
                        className="focus:outline-none"
                        aria-label="Rate Poor"
                    >
                        <FontAwesomeIcon icon={faThumbsDown} className="h-6 w-6 text-red-500" />
                    </button>
                </div>
            ) : (
                !isFeedbackSubmitted ? (
                    <div className="w-full md:w-64 border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white p-4">
                        <p className="text-base font-semibold text-gray-700">
                            Would you like to provide additional feedback?
                        </p>
                        <textarea
                            className="w-full border border-gray-300 rounded-md p-2 mt-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            value={noteFeedback}
                            onChange={(e) => setNoteFeedback(e.target.value)}
                            rows={4}
                            placeholder="Optional feedback..."
                        />
                        <div className="flex justify-end space-x-2 mt-2">
                            <button
                                onClick={handleSkipFeedback}
                                className="text-gray-600 hover:text-gray-800 focus:outline-none"
                            >
                                Skip
                            </button>
                            <button
                                onClick={submitFeedback}
                                className="bg-indigo-900 text-white py-1 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-200 focus:outline-none"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="w-full md:w-32 border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col items-center bg-white py-4">
                        <p className="text-sm text-green-600 font-semibold text-center">
                            Thank you for the feedback!
                        </p>
                    </div>
                )
            )}
        </div>
    );
};

export default NoteFeedbackButton; 