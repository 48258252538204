import { useEffect, useState } from 'react';
import SSO from "./components/sso";
import * as Sentry from '@sentry/react';
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { callProtectedApi } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { Home } from "./pages/Home";
import { useLocation, useNavigate } from "react-router-dom";

import AcceptPolicies from "./pages/AcceptPolicies";
import { useFeatureFlagEnabled } from 'posthog-js/react'

import ERDashboard from "./pages/ERDashboard";
import PatientInfoCarepoint from "./pages/PatientInfoCarepointPOC";
import CarepointLocationSurvey from "./pages/CarepointLocationSurvey";
import HospitalistNoteDashboard from "./pages/HospitalistNoteDashboard";
import HospitalistChart from "./pages/HospitalistChart";
import PhoneCalls from "./pages/PhoneCalls";
import PatientDashboard from "./pages/PatientDashboard";

import AdminDashboard from "./pages/AdminDashboard";

import Settings from "./pages/Settings";

import GlobalAdminDashboard from "./pages/GlobalAdminDashboard";
import OrganizationAdminDashboard from "./pages/OrganizationAdminDashboard";

import OrganizationPortal from "./pages/OrganizationPortal";

import SignUp from "./pages/SignUp";
import Subscribe from "./pages/Subscribe";
import ProcessSignUp from "./pages/ProcessSignUp";
import ProcessSignUpD2C from "./pages/ProcessSignUpD2C";

import GroupMacros from "./pages/macros/GroupMacros";

import { protectedResources } from "./authConfig";
import { AuthProvider } from './contexts/AuthProvider';
import { SettingsProvider } from './contexts/SettingsProvider';
import RecordingCleanupProvider from './contexts/RecordingCleanupProvider';

import "./styles/App.css";

import { ContactUsForm } from './components/ContactUsForm';
import { Fab, Stack, Box, CircularProgress, Typography } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import posthog from "posthog-js";
import ErrorPage from "./pages/ErrorPage";

posthog.init("phc_Ixe2VTs6tgoDI3WWt26mowQzBhCEXsCEUhYXMmhHJmH", {
  api_host: "https://app.posthog.com",
});

Sentry.init({
  dsn: "https://f3a793673660bb4367a89f3d6aba2866@o4506684392407040.ingest.sentry.io/4506684598648832",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/cleohealth\.io\/api/],
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const hasRequiredRole = async (requiredRoles, getAccessTokenSilently) => {
  try {
    // Add caching to prevent frequent API calls
    if (hasRequiredRole.cachedResult) {
      return hasRequiredRole.cachedResult;
    }

    const actorInfo = await callProtectedApi(
      getAccessTokenSilently,
      protectedResources.apiGetMyInfo.endpoint,
      {
        method: 'POST'
      }
    );

    const hasAccess = requiredRoles.includes(actorInfo.admin_status);
    // Cache the result
    hasRequiredRole.cachedResult = hasAccess;

    if (hasAccess) {
      console.log(`Access granted for User ID: ${actorInfo.actor_uuid}`);
    } else {
      console.log(`Access denied for User ID: ${actorInfo.actor_uuid}`);
    }

    return hasAccess;
  } catch (error) {
    console.error("Error checking admin status:", error);
    return false;
  }
};

const ProtectedRoute = ({ children, requiredRoles = [] }) => {
  const { isAuthenticated, isLoading: auth0Loading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isChecking, setIsChecking] = useState(true);
  const [lastCheckedPath, setLastCheckedPath] = useState('');
  const [lastAuthState, setLastAuthState] = useState(null);

  useEffect(() => {
    // Skip re-checking if auth state and path haven't changed
    if (
      lastCheckedPath === location.pathname &&
      lastAuthState === isAuthenticated &&
      !isChecking
    ) {
      return;
    }

    const checkAuthorization = async () => {
      // Wait for Auth0 to finish its initial loading
      if (auth0Loading) {
        // Don't leave in checking state during auth0 loading
        setIsChecking(false);
        return;
      }

      setIsChecking(true);

      try {
        // If not authenticated, redirect to login
        if (!isAuthenticated) {
          loginWithRedirect({
            appState: { returnTo: location.pathname }
          });
          setIsChecking(false);
          return;
        }

        // If authenticated and no roles required, grant access immediately
        if (requiredRoles.length === 0) {
          setIsAuthorized(true);
          setIsChecking(false);
          setLastCheckedPath(location.pathname);
          setLastAuthState(isAuthenticated);
          return;
        }

        // Only check roles if authenticated and roles are required
        try {
          const hasRole = await hasRequiredRole(requiredRoles, getAccessTokenSilently);
          setIsAuthorized(hasRole);
        } catch (error) {
          console.error("Role check error:", error);
          setIsAuthorized(false);
        }
      } catch (error) {
        console.error("Auth error:", error);
        // If we get a login error, redirect
        if (error.message?.includes('Login required')) {
          loginWithRedirect({
            appState: { returnTo: location.pathname }
          });
        }
      } finally {
        setIsChecking(false);
        setLastCheckedPath(location.pathname);
        setLastAuthState(isAuthenticated);
      }
    };

    checkAuthorization();
  }, [
    isAuthenticated,
    auth0Loading,
    // Only include location.pathname in dependencies, not the entire location object
    location.pathname,
    // Include requiredRoles to re-check if they change
    requiredRoles.toString()
  ]);

  // Show nothing while Auth0 is initializing
  if (auth0Loading) {
    return null;
  }

  // Show nothing while checking authorization or if not authenticated
  if (isChecking || !isAuthenticated) {
    return null;
  }

  // If roles were required but user isn't authorized, redirect to home
  if (requiredRoles.length > 0 && !isAuthorized) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

const App = () => {
  const { isLoading, error } = useAuth0();
  const [contactFormOpen, setContactFormOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isCleoOutboundEnabled = useFeatureFlagEnabled("cleo_outbound");
  const isPatientManagementEnabled = useFeatureFlagEnabled('patient_management');
  // Check for error parameters in the URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');

    console.log('App - Checking for error parameters:', {
      error,
      path: location.pathname,
      fullUrl: window.location.href
    });

    // If there's an error parameter in the URL and we're not already on the error page,
    // redirect to the error page while preserving the query parameters
    if (error && location.pathname !== '/error') {
      console.log('App - Redirecting to error page with params:', location.search);
      // Use navigate instead of directly modifying window.location to maintain React Router state
      navigate(`/error${location.search}`, { replace: true });
    }
  }, [location, navigate]);

  // Handle Auth0 initialization errors
  useEffect(() => {
    if (error) {
      console.error('Auth0 error:', error);
      // Create error parameters for our error page
      const errorParams = new URLSearchParams({
        error: 'Authentication Error',
        error_description: error.message || 'An error occurred during authentication initialization.'
      });

      // Navigate to the error page with the error parameters
      navigate(`/error?${errorParams.toString()}`, { replace: true });
    }
  }, [error, navigate]);

  // If there's an Auth0 error, don't render anything as we're redirecting to the error page
  if (error) {
    return null;
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  // Check if we're on the error page
  const isErrorPage = location.pathname === '/error';

  // If we're on the error page, don't render anything as it's handled in index.js
  if (isErrorPage) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SettingsProvider>
          <RecordingCleanupProvider>
            <PageLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/sso" element={<SSO />} />
                <Route path="/sso/*" element={<SSO />} />
                <Route path="/settings/*" element={
                  <ProtectedRoute requiredRoles={[]}>
                    <Settings />
                  </ProtectedRoute>
                } />
                {isCleoOutboundEnabled && (
                  <Route path="/phone-calls" element={
                    <ProtectedRoute requiredRoles={[]}>
                      <PhoneCalls />
                    </ProtectedRoute>
                  } />
                )}
                {isPatientManagementEnabled && (
                  <Route path="/patient-dashboard" element={
                    <ProtectedRoute requiredRoles={[]}>
                      <PatientDashboard />
                    </ProtectedRoute>
                  } />
                )}
                <Route path="/group-macros" element={
                  <ProtectedRoute requiredRoles={["GROUP_ADMIN"]}>
                    <GroupMacros />
                  </ProtectedRoute>
                } />
                <Route path="/global-admin-dashboard" element={
                  <ProtectedRoute requiredRoles={["GLOBAL_ADMIN"]}>
                    <GlobalAdminDashboard />
                  </ProtectedRoute>
                } />
                <Route path="/organization-admin-dashboard" element={
                  <ProtectedRoute requiredRoles={["GLOBAL_ADMIN"]}>
                    <OrganizationAdminDashboard />
                  </ProtectedRoute>
                } />
                <Route path="/er-dashboard" element={
                  <ProtectedRoute requiredRoles={[]}>
                    <ERDashboard />
                  </ProtectedRoute>
                } />
                <Route path="/view-er-patient" element={
                  <ProtectedRoute requiredRoles={[]}>
                    <PatientInfoCarepoint />
                  </ProtectedRoute>
                } />
                <Route path="/carepoint-location-survey" element={
                  <ProtectedRoute requiredRoles={[]}>
                    <CarepointLocationSurvey />
                  </ProtectedRoute>
                } />
                <Route path="/hospitalist-inpatient-dashboard" element={
                  <ProtectedRoute requiredRoles={[]}>
                    <HospitalistNoteDashboard />
                  </ProtectedRoute>
                } />
                <Route path="/hospitalist-chart" element={
                  <ProtectedRoute requiredRoles={[]}>
                    <HospitalistChart />
                  </ProtectedRoute>
                } />
                <Route path="/accept-policies" element={<AcceptPolicies />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/subscribe" element={<Subscribe />} />
                <Route path="/process-sign-up" element={<ProcessSignUp />} />
                <Route path="/process-signup" element={<ProcessSignUpD2C />} />
                <Route
                  path="/admin"
                  element={
                    <ProtectedRoute requiredRoles={["GLOBAL_ADMIN"]}>
                      <AdminDashboard />
                    </ProtectedRoute>
                  }
                />
                <Route path="/organization-portal/*" element={
                  <ProtectedRoute requiredRoles={["GLOBAL_ADMIN", "ORGANIZATION_ADMIN"]}>
                    <OrganizationPortal />
                  </ProtectedRoute>
                } />
              </Routes>
              <ContactUsForm
                open={contactFormOpen}
                onClose={() => setContactFormOpen(false)}
              />
            </PageLayout>
          </RecordingCleanupProvider>
        </SettingsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;




