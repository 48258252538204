import { protectedResources } from '../authConfig';
import { callApiWithToken } from './apiUtils';

export const documentApi = {
    deleteDocument: async (getAccessTokenSilently, documentId) => {
        return callApiWithToken(
            getAccessTokenSilently,
            protectedResources.apiDeleteDocument.endpoint,
            'DELETE',
            { document_id: documentId }
        );
    }
}; 