// apiUtils.jsx

export const callApiWithToken = async (getAccessTokenSilently, endpoint, method, body = null) => {
    try {
        const token = await getAccessTokenSilently();

        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        const options = {
            method: method,
            headers: headers
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const fetchResponse = await fetch(endpoint, options);

        if (!fetchResponse.ok) {
            const errorText = await fetchResponse.text();
            throw new Error(`HTTP error! status: ${fetchResponse.status}, message: ${errorText}`);
        }

        const data = await fetchResponse.json();
        return data;
    } catch (error) {
        console.error('callApiWithToken error:', error);
        throw error;
    }
};

export const callFileUploadWithToken = async (getAccessTokenSilently, endpoint, formData) => {
    try {
        const token = await getAccessTokenSilently();

        const options = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                // Don't set Content-Type - browser will set it with correct boundary
            },
            body: formData
        };

        const fetchResponse = await fetch(endpoint, options);

        if (!fetchResponse.ok) {
            const errorText = await fetchResponse.text();
            throw new Error(`HTTP error! status: ${fetchResponse.status}, message: ${errorText}`);
        }

        const data = await fetchResponse.json();
        return data;
    } catch (error) {
        console.error('callFileUploadWithToken error:', error);
        throw error;
    }
};  