import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#312E81', // Indigo-900
      light: '#3730A3', // Indigo-800
      dark: '#312E81',  // Indigo-900
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          transition: 'background-color 0.2s ease-in-out',
          '&:hover': {
            backgroundColor: '#3730A3', // Indigo-800
          },
        },
      },
    },
  },
});

export default theme; 