import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources, callProtectedApi } from '../authConfig';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
    const { 
        isAuthenticated, 
        getAccessTokenSilently
    } = useAuth0();
    const [actorInfo, setActorInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchActorInfo = useCallback(async () => {
        if (!isAuthenticated) {
            setIsLoading(false);
            return;
        }

        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiGetMyInfo.endpoint,
                {
                    method: 'POST'
                }
            );
            setActorInfo(data);
        } catch (error) {
            console.error('Error fetching actor info:', error);
        } finally {
            setIsLoading(false);
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        fetchActorInfo();
    }, [isAuthenticated, fetchActorInfo]);

    const updateActorInfo = (field, value) => {
        setActorInfo(prevInfo => ({
            ...prevInfo,
            [field]: value
        }));
    };

    return (
        <AuthContext.Provider value={{ actorInfo, isLoading, updateActorInfo, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}