import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/catalyst/button";
import {
  Fieldset,
  Legend,
  FieldGroup,
} from "../components/catalyst/fieldset";

export const SignUp = () => {
  const navigate = useNavigate();
  const [agreed, setAgreed] = useState(false);

  const handleCheckboxChange = () => {
    setAgreed(!agreed);
  };

  const handleSignUp = () => {
    const callbackUrl = 'https://app.cleohealth.io';

    window.location.href = `https://auth.cleohealth.io/authorize?` +
      `response_type=token&` +
      `client_id=jKgCqJfwoHfqpr01zzXzotey1pD68Z31&` +
      `redirect_uri=${encodeURIComponent(callbackUrl)}&` +
      `screen_hint=signup`;
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-screen">
        <div className="max-w-2xl w-full bg-white shadow-lg rounded-lg p-12">
          <div className="mb-12">
            <img
              src="/cleo-logo.png"
              alt="Cleo Logo"
              style={{ maxWidth: "50px", marginBottom: "30px" }}
            />
            <Fieldset>
              <Legend
                className="text-black pb-4"
                style={{ fontSize: "28px" }}
              >
                Try Cleo Health For Free
              </Legend>
              <p className="text-black text-left">
                No credit card required!
              </p>
              <FieldGroup className="flex flex-col items-center">
                <button
                  onClick={handleSignUp}
                  className={`self-center w-3/4 flex items-center justify-center transition-all rounded-md duration-200 ${agreed
                    ? 'hover:bg-indigo-800 bg-indigo-900 text-white cursor-pointer'
                    : 'bg-gray-200 text-gray-500 cursor-not-allowed'
                    }`}
                  disabled={!agreed}
                  style={{ fontSize: "18px", padding: "10px 20px" }}
                >
                  Sign Up
                </button>
              </FieldGroup>
            </Fieldset>
          </div>
          <div className="text-left text-black mb-8 flex items-start">
            <input
              type="checkbox"
              id="terms"
              checked={agreed}
              onChange={handleCheckboxChange}
              className="h-6 w-6 rounded border-gray-300 text-indigo-900 focus:ring-indigo-900 mr-2"
            />
            <label htmlFor="terms" className="text-lg">
              I agree to Cleo Health's{" "}
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Terms_of_Use.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>
              ,{" "}
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/Cleo_Technologies_Privacy_Policy.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              , and{" "}
              <a
                className="text-indigo-900"
                href="https://cleopublic.blob.core.windows.net/website-public/CLEO_Technologies_Business_Associate_Agreement.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                BAA
              </a>
              .
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
