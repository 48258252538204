import React, { useState, useEffect, useMemo, useRef } from 'react'

import { Dialog, Switch } from '@headlessui/react'
import { ArrowLeftIcon, TrashIcon, ChartBarIcon, PlusIcon, XMarkIcon, GlobeAltIcon, HeartIcon, MagnifyingGlassIcon, ChatBubbleLeftIcon, ShareIcon, CheckIcon, HandThumbUpIcon } from '@heroicons/react/20/solid'
import { ShieldCheckIcon } from '@heroicons/react/24/outline'
import { useAuth } from '../../contexts/AuthProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useSettings } from '../../contexts/SettingsProvider';
import { protectedResources, callProtectedApi } from '../../authConfig';
import {
    UserCircleIcon,
    PencilSquareIcon,
    UserIcon, ExclamationTriangleIcon, DocumentTextIcon, ClipboardIcon, PencilIcon,
    BuildingOfficeIcon
} from '@heroicons/react/24/outline'


import { useFeatureFlagEnabled } from 'posthog-js/react'


import customInstructionsService from '../../services/customInstructionsService';
import MilitaryTimeToggle from './MilitaryTimeToggle';
import AutoGenerateMDMToggle from './AutoGenerateMDMToggle';
import HideROSToggle from './HideROSToggle';


const titleToFieldMap = {
    "HPI": "custom_instructions_hpi",
    "ROS": "custom_instructions_ros",
    "PHYSICAL_EXAM": "custom_instructions_physical_exam",
    "EVALUATION": "custom_instructions_reevaluation"
};

const titleToSectionMap = {
    "History of Present Illness": "HPI",
    "Review of Systems": "ROS",
    "Physical Exam": "PHYSICAL_EXAM",
    "Evaluation and Plan": "EVALUATION"
};

const sectionToTitleMap = {
    "HPI": "History of Present Illness",
    "ROS": "Review of Systems",
    "PHYSICAL_EXAM": "Physical Exam",
    "EVALUATION": "Evaluation and Plan"
};

// Function to get a friendly display name for sharing status
const getShareStatusDisplay = (status) => {
    // Normalize status to lowercase for case-insensitive comparison
    const normalized = (status || '').toLowerCase();

    if (normalized === 'private' || normalized === '') {
        return 'Private';
    } else if (normalized === 'org' || normalized === 'organization') {
        return 'Organization';
    } else if (normalized === 'global' || normalized === 'all') {
        return 'Global';
    }

    // Fallback to the capitalized original value if none of our known statuses
    return status ? status.charAt(0).toUpperCase() + status.slice(1).toLowerCase() : 'Private';
};

// Map backend sharing status to frontend value
const getShareStatus = (backendStatus) => {
    // Default to PRIVATE if no status is provided
    if (!backendStatus) return 'PRIVATE';

    // Normalize to lowercase for case-insensitive comparison
    const normalized = backendStatus.toLowerCase();

    // Map to internal representation
    if (normalized === 'private') {
        return 'PRIVATE';
    } else if (normalized === 'org' || normalized === 'organization') {
        return 'ORGANIZATION';
    } else if (normalized === 'global' || normalized === 'all') {
        return 'GLOBAL';
    }

    // If we get an unexpected value, return it in uppercase
    return backendStatus.toUpperCase();
};

// Function to check if the current user is the creator of an instruction
const isInstructionCreator = (instruction, actorId) => {
    return instruction.creator_actor_id === actorId;
};

function CustomInstructions({ onSuccessfulSave }) {
    const { actorInfo, isLoading } = useAuth();
    const { getAccessTokenSilently } = useAuth0();
    const [isPoolOpen, setIsPoolOpen] = useState(false);
    const [localInstructions, setLocalInstructions] = useState({
        custom_instructions_hpi: [],
        custom_instructions_ros: [],
        custom_instructions_physical_exam: [],
        custom_instructions_reevaluation: []
    });
    const [isRemoveConfirmOpen, setIsRemoveConfirmOpen] = useState(false);
    const [instructionToRemove, setInstructionToRemove] = useState(null);
    const isGlobalInstructionsEnabled = useFeatureFlagEnabled("custom_instructions_sharing");
    // const isGlobalInstructionsEnabled = true;

    // Create a function to load instructions from the backend
    const loadInstructions = async () => {
        try {
            console.log('Loading instructions from backend...');
            const data = await customInstructionsService.getMyInstructions(getAccessTokenSilently);
            console.log('Instructions loaded:', data);

            // Debug each instruction's sharing status
            data.forEach(instruction => {
                console.log(`Instruction ${instruction.instruction_id} - ${instruction.name} - sharing status: ${instruction.sharing_status}`);
            });

            // Clear any cached data
            const groupedInstructions = {
                custom_instructions_hpi: [],
                custom_instructions_ros: [],
                custom_instructions_physical_exam: [],
                custom_instructions_reevaluation: []
            };

            // Group instructions by section
            data.forEach(instruction => {
                const fieldName = titleToFieldMap[instruction.section];
                if (fieldName) {
                    if (!groupedInstructions[fieldName]) {
                        groupedInstructions[fieldName] = [];
                    }
                    groupedInstructions[fieldName].push(instruction);
                }
            });

            setLocalInstructions(groupedInstructions);
        } catch (error) {
            console.error('Failed to load your instructions:', error);
        }
    };

    // Load initial instructions
    useEffect(() => {
        if (!isLoading && actorInfo) {
            loadInstructions();
        }
    }, [isLoading, actorInfo, getAccessTokenSilently]);

    const handleAddInstruction = async (instruction) => {
        const fieldName = titleToFieldMap[instruction.section];
        if (!fieldName) return;

        // After adding the instruction, reload all instructions from the backend
        await loadInstructions();

        onSuccessfulSave(`Added "${instruction.name}" to ${sectionToTitleMap[instruction.section]}`);
    };

    const confirmRemoveInstruction = (instruction) => {
        // If user is not the creator, remove immediately without confirmation
        if (!isInstructionCreator(instruction, actorInfo?.actor_id)) {
            handleRemoveInstruction(instruction.instruction_id);
            return;
        }

        // Otherwise, show confirmation dialog
        setInstructionToRemove(instruction);
        setIsRemoveConfirmOpen(true);
    };

    const handleRemoveInstruction = async (instructionId) => {
        try {
            await customInstructionsService.removeInstruction(instructionId, getAccessTokenSilently);

            // After removing the instruction, reload all instructions from the backend
            await loadInstructions();

            onSuccessfulSave("Instruction removed successfully");
            setIsRemoveConfirmOpen(false);
            setInstructionToRemove(null);
        } catch (error) {
            console.error('Failed to remove instruction:', error);
        }
    };

    const handleUpdateInstruction = async (updatedInstruction) => {
        try {
            // Check if the instruction is shared (not private)
            const sharingStatus = (updatedInstruction.sharing_status || '').toLowerCase();
            const isShared = sharingStatus === 'org' ||
                sharingStatus === 'organization' ||
                sharingStatus === 'global' ||
                sharingStatus === 'all';

            // Don't allow updates if the instruction is shared
            if (isShared) {
                console.error('Cannot update a shared instruction');
                onSuccessfulSave('Shared instructions cannot be modified', 'error');
                return;
            }

            await customInstructionsService.updateInstruction(
                updatedInstruction.instruction_id,
                {
                    name: updatedInstruction.name,
                    content: updatedInstruction.content,
                    section: updatedInstruction.section
                },
                getAccessTokenSilently
            );

            // After updating the instruction, reload all instructions from the backend
            await loadInstructions();

            onSuccessfulSave(`Updated "${updatedInstruction.name}" instruction`);
        } catch (error) {
            console.error('Failed to update instruction:', error);
        }
    };

    const handleShareInstruction = async (instructionId, shareType) => {
        try {
            // Map frontend share types to backend share types
            // Backend expects 'all' for global and 'org' for organization
            const shareTypeMappings = {
                'PRIVATE': 'private',
                'ORGANIZATION': 'org',
                'GLOBAL': 'all'  // Backend expects 'all' for global sharing
            };

            // Use the mapped value
            const apiShareType = shareTypeMappings[shareType];

            console.log('Sharing instruction', instructionId, 'with type', apiShareType);

            // Debug the request
            const token = await getAccessTokenSilently();
            const endpoint = protectedResources.apiShareInstruction.endpoint.replace('{instruction_id}', instructionId);

            console.log('Making request to:', endpoint);
            console.log('With payload:', { share_type: apiShareType });

            // Make the request directly rather than through the service layer
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ share_type: apiShareType })
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', response.status, errorText);
                throw new Error(`API error: ${response.status} ${errorText}`);
            }

            const data = await response.json();
            console.log('Share response:', data);

            // Reload instructions to reflect the new sharing status
            await loadInstructions();

            const shareTypeDisplay = {
                'PRIVATE': 'private',
                'ORGANIZATION': 'organization-wide',
                'GLOBAL': 'global'
            };

            onSuccessfulSave(`Instruction sharing changed to ${shareTypeDisplay[shareType]}`);
        } catch (error) {
            console.error('Failed to share instruction:', error);
            alert(`Error sharing instruction: ${error.message || 'Unknown error'}`);
        }
    };

    // Check if instruction is shared (not private)
    const isInstructionShared = (instruction) => {
        const sharingStatus = (instruction.sharing_status || '').toLowerCase();
        return sharingStatus === 'org' ||
            sharingStatus === 'organization' ||
            sharingStatus === 'global' ||
            sharingStatus === 'all';
    };

    const handleToggleOptOut = async (instruction) => {
        const updatedInstructions = { ...localInstructions };
        const section = titleToFieldMap[instruction.section];

        if (section) {
            updatedInstructions[section] = updatedInstructions[section].map((inst) => {
                if (inst.instruction_id === instruction.instruction_id) {
                    return { ...inst, has_opted_out: !inst.has_opted_out };
                }
                return inst;
            });

            // Optimistically update the UI
            setLocalInstructions(updatedInstructions);

            try {
                const token = await getAccessTokenSilently();
                if (instruction.has_opted_out) {
                    await customInstructionsService.optInInstruction(getAccessTokenSilently, instruction.instruction_id);
                } else {
                    await customInstructionsService.optOutInstruction(getAccessTokenSilently, instruction.instruction_id);
                }
            } catch (error) {
                console.error('Error toggling opt-out status:', error);

                // Revert the change if the request fails
                updatedInstructions[section] = updatedInstructions[section].map((inst) => {
                    if (inst.instruction_id === instruction.instruction_id) {
                        return { ...inst, has_opted_out: instruction.has_opted_out };
                    }
                    return inst;
                });
                setLocalInstructions(updatedInstructions);
            }
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-6xl mx-auto">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center">
                        <PencilSquareIcon className="h-8 w-8 text-indigo-600 mr-3" />
                        <div className="text-left">
                            <h1 className="text-3xl font-bold text-gray-900">Custom Instructions</h1>
                            <p className="mt-2 text-lg text-gray-600">
                                Customize how Cleo generates each section of your notes
                            </p>
                        </div>
                    </div>
                    {isGlobalInstructionsEnabled && <button
                        onClick={() => setIsPoolOpen(true)}
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-indigo-600 bg-white border border-indigo-200 rounded-md hover:bg-indigo-50 hover:border-indigo-300 transition-colors"
                    >
                        <GlobeAltIcon className="h-5 w-5 mr-2" />
                        Global Instructions Pool
                    </button>
                    }
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* HPI Card */}
                    <div className="bg-white rounded-xl overflow-hidden shadow-sm border border-indigo-100 hover:border-indigo-200 transition-all">
                        <div className="p-5">
                            <div className="flex items-center">
                                <DocumentTextIcon className="h-5 w-5 text-indigo-600" />
                                <h2 className="ml-2 text-lg font-semibold text-gray-900">History of Present Illness</h2>
                            </div>
                            <p className="mt-1 text-sm text-gray-600">
                                Structure and format your patient's history
                            </p>
                            <div className="mt-3">
                                <CustomInstructionSection
                                    title="History of Present Illness"
                                    instructions={localInstructions.custom_instructions_hpi}
                                    onRemove={confirmRemoveInstruction}
                                    onAdd={handleAddInstruction}
                                    onEdit={handleUpdateInstruction}
                                    onShare={handleShareInstruction}
                                    onToggleOptOut={handleToggleOptOut}
                                />
                            </div>
                        </div>
                    </div>

                    {/* ROS Card */}
                    <div className="bg-white rounded-xl overflow-hidden shadow-sm border border-indigo-100 hover:border-indigo-200 transition-all">
                        <div className="p-5">
                            <div className="flex items-center">
                                <ClipboardIcon className="h-5 w-5 text-indigo-600" />
                                <h2 className="ml-2 text-lg font-semibold text-gray-900">Review of Systems</h2>
                            </div>
                            <p className="mt-1 text-sm text-gray-600">
                                Organize the review of systems
                            </p>
                            <div className="mt-3">
                                <CustomInstructionSection
                                    title="Review of Systems"
                                    instructions={localInstructions.custom_instructions_ros}
                                    onRemove={confirmRemoveInstruction}
                                    onAdd={handleAddInstruction}
                                    onEdit={handleUpdateInstruction}
                                    onShare={handleShareInstruction}
                                    onToggleOptOut={handleToggleOptOut}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Physical Exam Card */}
                    <div className="bg-white rounded-xl overflow-hidden shadow-sm border border-indigo-100 hover:border-indigo-200 transition-all">
                        <div className="p-5">
                            <div className="flex items-center">
                                <UserIcon className="h-5 w-5 text-indigo-600" />
                                <h2 className="ml-2 text-lg font-semibold text-gray-900">Physical Exam</h2>
                            </div>
                            <p className="mt-1 text-sm text-gray-600">
                                Present physical examination findings
                            </p>
                            <div className="mt-3">
                                <CustomInstructionSection
                                    title="Physical Exam"
                                    instructions={localInstructions.custom_instructions_physical_exam}
                                    onRemove={confirmRemoveInstruction}
                                    onAdd={handleAddInstruction}
                                    onEdit={handleUpdateInstruction}
                                    onShare={handleShareInstruction}
                                    onToggleOptOut={handleToggleOptOut}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Evaluation Card */}
                    <div className="bg-white rounded-xl overflow-hidden shadow-sm border border-indigo-100 hover:border-indigo-200 transition-all">
                        <div className="p-5">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <ChartBarIcon className="h-5 w-5 text-indigo-600" />
                                    <h2 className="ml-2 text-lg font-semibold text-gray-900">Evaluation and Plan</h2>
                                </div>
                            </div>
                            <p className="mt-1 text-sm text-gray-600">
                                Structure your assessment and plan
                            </p>
                            <div className="mt-3">
                                <CustomInstructionSection
                                    title="Evaluation and Plan"
                                    instructions={localInstructions.custom_instructions_reevaluation}
                                    onRemove={confirmRemoveInstruction}
                                    onAdd={handleAddInstruction}
                                    onEdit={handleUpdateInstruction}
                                    onShare={handleShareInstruction}
                                    onToggleOptOut={handleToggleOptOut}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* MDM Auto Generation Setting */}
                <div className="mt-6 bg-white rounded-xl overflow-hidden shadow-sm border border-indigo-100 hover:border-indigo-200 transition-all p-5">
                    <div className="flex items-center justify-between">
                        <div>
                            <div className="flex items-center">
                                <DocumentTextIcon className="h-5 w-5 text-indigo-600" />
                                <h2 className="ml-2 text-lg font-semibold text-gray-900">Medical Decision Making</h2>
                            </div>
                            <p className="mt-1 text-sm text-gray-600">
                                Configure automatic MDM generation
                            </p>
                        </div>
                        <div className="flex items-center">
                            <span className="mr-2 text-sm text-gray-600">Auto Generate MDM</span>
                            <AutoGenerateMDMToggle />
                        </div>
                    </div>
                </div>

                {actorInfo?.actor_specialty === 'HOSPITALIST' && (
                    <div className="mt-6 bg-amber-50 border-l-4 border-amber-400 p-4 rounded-r-lg">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-5 w-5 text-amber-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-amber-700">
                                    Custom Instructions are currently only configured for Admission Notes Sections.
                                </p>
                            </div>
                        </div>
                    </div>
                )}

                <GlobalInstructionsPool
                    isOpen={isPoolOpen}
                    setIsOpen={setIsPoolOpen}
                    onAddInstruction={handleAddInstruction}
                />
            </div>

            {/* Add the confirmation dialog at the end of the component */}
            <Dialog
                open={isRemoveConfirmOpen}
                onClose={() => setIsRemoveConfirmOpen(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="w-full max-w-md rounded-xl bg-white p-6 shadow-2xl">
                        <Dialog.Title className="text-xl font-semibold text-gray-900">
                            Remove Instruction
                        </Dialog.Title>

                        <div className="mt-4">
                            {instructionToRemove && isInstructionShared(instructionToRemove) && (
                                <div className="bg-amber-50 rounded-lg p-4 mb-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <ExclamationTriangleIcon className="h-5 w-5 text-amber-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-amber-800">Important information</h3>
                                            <div className="mt-2 text-sm text-amber-700">
                                                <p>
                                                    This instruction is currently shared with {
                                                        (instructionToRemove?.sharing_status || '').toLowerCase() === 'global' ||
                                                            (instructionToRemove?.sharing_status || '').toLowerCase() === 'all'
                                                            ? 'all Cleo users'
                                                            : 'your organization'
                                                    }.
                                                </p>
                                                <p className="mt-2">When you remove this instruction:</p>
                                                <ul className="list-disc pl-5 mt-1 space-y-1">
                                                    <li>It will no longer appear in the global pool</li>
                                                    <li>Users who have already added it to their collection will still be able to use it</li>
                                                    <li>It will be marked as "Previously Shared" for those users</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <p className="text-gray-600">
                                Are you sure you want to remove <strong>"{instructionToRemove?.name}"</strong> from your collection?
                            </p>

                            <div className="mt-6 flex justify-end space-x-3">
                                <button
                                    onClick={() => setIsRemoveConfirmOpen(false)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => handleRemoveInstruction(instructionToRemove?.instruction_id)}
                                    className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700"
                                >
                                    Remove
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </div>
    );
}

function InstructionDiscussion({ instruction, onClose, onAddInstruction }) {
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddingComment, setIsAddingComment] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    // Load comments when component mounts
    useEffect(() => {
        const fetchComments = async () => {
            try {
                setIsLoading(true);
                const fetchedComments = await customInstructionsService.getComments(
                    instruction.instruction_id,
                    getAccessTokenSilently
                );
                setComments(fetchedComments);
            } catch (error) {
                console.error('Failed to fetch comments:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchComments();
    }, [instruction.instruction_id, getAccessTokenSilently]);

    const handleAddComment = async (content) => {
        if (!content.trim()) return;

        try {
            setIsAddingComment(true);
            const newComment = await customInstructionsService.addComment(
                instruction.instruction_id,
                content,
                getAccessTokenSilently
            );
            setComments(prev => [...prev, newComment]);
        } catch (error) {
            console.error('Failed to add comment:', error);
            alert('Failed to add comment. Please try again.');
        } finally {
            setIsAddingComment(false);
        }
    };

    // Handle adding reactions to a comment
    const handleAddReaction = async (commentId, reactionType) => {
        try {
            await customInstructionsService.addCommentReaction(
                instruction.instruction_id,
                commentId,
                reactionType,
                getAccessTokenSilently
            );
            // We would ideally update the UI here, but we need to implement
            // the comment reaction endpoint first
        } catch (error) {
            console.error('Failed to add reaction:', error);
        }
    };

    return (
        <Dialog.Panel className="w-[900px] h-[800px] bg-white rounded-xl shadow-2xl">
            <div className="flex flex-col h-full">
                <div className="p-6 border-b border-gray-200">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <button
                                onClick={onClose}
                                className="inline-flex items-center text-indigo-600 hover:text-indigo-700 transition-colors"
                            >
                                <ArrowLeftIcon className="h-5 w-5" />
                                <span className="ml-2">Back to Library</span>
                            </button>
                            <div className="h-6 w-px bg-gray-200" />
                            <div className="flex items-center space-x-2">
                                <ChatBubbleLeftIcon className="h-6 w-6 text-indigo-600" />
                                <Dialog.Title className="text-2xl font-semibold text-gray-900">
                                    Discussion: {instruction.name}
                                </Dialog.Title>
                            </div>
                        </div>
                        <button
                            onClick={() => onAddInstruction(instruction)}
                            className="flex items-center px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700 bg-white border border-indigo-200 rounded-md hover:bg-indigo-50 transition-colors"
                        >
                            <PlusIcon className="h-4 w-4 mr-2" />
                            Add
                        </button>
                    </div>
                </div>

                <div className="flex-1 overflow-y-auto p-6">
                    <div className="mb-6">
                        <div className="bg-gray-50 rounded-lg p-4">
                            <h3 className="font-medium text-gray-900">{instruction.name}</h3>
                            <p className="mt-1 text-sm text-gray-600 whitespace-pre-wrap">{instruction.content}</p>
                            <div className="mt-2 flex items-center space-x-4 text-sm text-gray-500">
                                <span className="flex items-center">
                                    <UserCircleIcon className="h-4 w-4 mr-1 text-indigo-600" />
                                    {instruction.creator_name}
                                    {instruction.creator_group && (
                                        <span className="ml-1 text-gray-500">
                                            • {instruction.creator_group}
                                        </span>
                                    )}
                                </span>
                                <span className="flex items-center text-indigo-600">
                                    <HeartIcon className="h-4 w-4 mr-1" />
                                    {instruction.usage_count} {instruction.usage_count === 1 ? 'use' : 'uses'}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="space-y-4">
                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                            </div>
                        ) : comments.length > 0 ? (
                            comments.map((comment) => (
                                <div key={comment.comment_id} className="bg-white rounded-lg border border-gray-200 p-4">
                                    <div className="flex items-start">
                                        <div className="flex-1">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center space-x-2">
                                                    <span className="font-medium text-gray-900">
                                                        {comment.commenter_name || 'Unknown User'}
                                                        {comment.commenter_group && (
                                                            <span className="ml-1 text-gray-500">
                                                                • {comment.commenter_group}
                                                            </span>
                                                        )}
                                                    </span>
                                                    <span className="text-sm text-gray-500">
                                                        {new Date(comment.created_at).toLocaleDateString()}
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="mt-2 text-gray-600">{comment.content}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-center py-6 text-gray-500">
                                No comments yet. Be the first to comment!
                            </div>
                        )}
                    </div>

                    <div className="mt-6">
                        <div className="flex items-start space-x-4">
                            <div className="min-w-0 flex-1">
                                <div className="relative">
                                    <textarea
                                        id="comment-textarea"
                                        rows={3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="Add your comment..."
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                e.preventDefault();
                                                const content = e.target.value.trim();
                                                if (content) {
                                                    handleAddComment(content);
                                                    e.target.value = '';
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className="mt-2 flex justify-end">
                                    <button
                                        type="button"
                                        className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-70"
                                        onClick={() => {
                                            const textarea = document.getElementById('comment-textarea');
                                            const content = textarea.value.trim();
                                            if (content) {
                                                handleAddComment(content);
                                                textarea.value = '';
                                            }
                                        }}
                                        disabled={isAddingComment}
                                    >
                                        {isAddingComment ? (
                                            <>
                                                <div className="mr-2 h-4 w-4 rounded-full border-2 border-transparent border-t-white animate-spin"></div>
                                                Posting...
                                            </>
                                        ) : (
                                            'Post Comment'
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog.Panel>
    );
}

function AddInstructionModal({ isOpen, setIsOpen, section, onAdd }) {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const handleCreate = async () => {
        try {
            setIsLoading(true);
            const instruction = await customInstructionsService.createInstruction(
                {
                    name: title,
                    content,
                    section: titleToSectionMap[section]
                },
                getAccessTokenSilently
            );

            // Instead of setting createdInstruction state and showing sharing UI,
            // immediately add the instruction and close the modal
            onAdd(instruction);
            setIsOpen(false);
            setTitle('');
            setContent('');
        } catch (error) {
            console.error('Failed to create instruction:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
        setTitle('');
        setContent('');
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} className="relative z-50">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="w-[600px] bg-white rounded-xl shadow-2xl">
                    <div className="p-6 border-b border-gray-200">
                        <div className="flex items-center justify-between">
                            <Dialog.Title className="text-xl font-semibold text-gray-900">
                                Add {section} Instruction
                            </Dialog.Title>
                            <button
                                onClick={handleClose}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                    </div>

                    <div className="p-6 space-y-4">
                        <div>
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                Title
                            </label>
                            <input
                                type="text"
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Enter instruction title..."
                            />
                        </div>
                        <div>
                            <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                                Content
                            </label>
                            <textarea
                                id="content"
                                rows={4}
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Enter instruction content..."
                            />
                        </div>
                    </div>

                    <div className="px-6 py-4 bg-gray-50 rounded-b-xl flex justify-end space-x-3">
                        <button
                            onClick={handleCreate}
                            disabled={!title || !content || isLoading}
                            className={`inline-flex items-center px-4 py-2 text-sm font-medium text-white rounded-md transition-colors ${!title || !content || isLoading
                                ? 'bg-gray-300 cursor-not-allowed'
                                : 'bg-indigo-600 hover:bg-indigo-700'
                                }`}
                        >
                            {isLoading ? (
                                <span className="flex items-center">
                                    <span className="mr-2 h-4 w-4 rounded-full border-2 border-transparent animate-spin"></span>
                                    Creating...
                                </span>
                            ) : (
                                <>
                                    <PlusIcon className="h-4 w-4 mr-2" />
                                    Create
                                </>
                            )}
                        </button>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}

function EditInstructionModal({ isOpen, setIsOpen, instruction, onSave }) {
    const [name, setName] = useState(instruction?.name || '');
    const [content, setContent] = useState(instruction?.content || '');
    const [section, setSection] = useState(instruction?.section || '');
    const [isSaving, setIsSaving] = useState(false);

    // Check if instruction is shared
    const isShared = useMemo(() => {
        if (!instruction) return false;
        const sharingStatus = (instruction.sharing_status || '').toLowerCase();
        return sharingStatus === 'org' ||
            sharingStatus === 'organization' ||
            sharingStatus === 'global' ||
            sharingStatus === 'all';
    }, [instruction]);

    useEffect(() => {
        if (instruction) {
            setName(instruction.name);
            setContent(instruction.content);
            setSection(instruction.section);
        }
    }, [instruction]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Additional check to prevent editing shared instructions
        if (isShared) {
            alert('Shared instructions cannot be modified.');
            setIsOpen(false);
            return;
        }

        setIsSaving(true);

        try {
            await onSave({
                instruction_id: instruction.instruction_id,
                name,
                content,
                section,
                sharing_status: instruction.sharing_status // Preserve the sharing status
            });
            setIsOpen(false);
        } catch (error) {
            console.error('Failed to update instruction:', error);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="w-[600px] bg-white rounded-xl shadow-2xl">
                    <div className="p-6 border-b border-gray-200">
                        <div className="flex items-center justify-between">
                            <Dialog.Title className="text-xl font-semibold text-gray-900">
                                {isShared ? 'View Custom Instruction' : 'Edit Custom Instruction'}
                            </Dialog.Title>
                            <button
                                onClick={() => setIsOpen(false)}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                    </div>

                    {isShared && (
                        <div className="p-4 bg-amber-50 border-l-4 border-amber-400">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-amber-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-amber-700">
                                        This instruction has been shared and cannot be modified. You must unshare it first.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    <form onSubmit={handleSubmit}>
                        <div className="p-6 space-y-4">
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                    disabled={isShared}
                                />
                            </div>

                            <div>
                                <label htmlFor="content" className="block text-sm font-medium text-gray-700">Content</label>
                                <textarea
                                    id="content"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    rows={6}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                    disabled={isShared}
                                />
                            </div>
                        </div>

                        <div className="px-6 py-4 bg-gray-50 rounded-b-xl flex justify-end space-x-3">
                            <button
                                type="button"
                                onClick={() => setIsOpen(false)}
                                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                            >
                                {isShared ? 'Close' : 'Cancel'}
                            </button>
                            {!isShared && (
                                <button
                                    type="submit"
                                    disabled={isSaving}
                                    className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                                >
                                    {isSaving ? 'Saving...' : 'Save Changes'}
                                </button>
                            )}
                        </div>
                    </form>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
}

function CustomInstructionSection({ title, instructions = [], onRemove, onAdd, onEdit, onShare, onToggleOptOut }) {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingInstruction, setEditingInstruction] = useState(null);
    const { actorInfo } = useAuth();
    const { getSetting } = useSettings();
    const isGlobalInstructionsEnabled = useFeatureFlagEnabled("custom_instructions_sharing");

    // Separate admin instructions from personal ones
    const adminInstructions = instructions.filter(instruction => instruction.is_admin_instruction === true);
    const personalInstructions = instructions.filter(instruction => instruction.is_admin_instruction !== true);

    // Use a memoized value instead of state to prevent unnecessary re-renders
    const transferROSToHPI = useMemo(() => {
        if (!actorInfo) return false;
        const actorHideROS = actorInfo.transfer_ros_to_hpi || false;
        const settingHideROS = getSetting('hide_ros') === true;
        return actorHideROS || settingHideROS;
    }, [actorInfo, getSetting]);

    const handleEdit = (instruction) => {
        setEditingInstruction(instruction);
        setIsEditModalOpen(true);
    };

    // Helper function to check if an instruction is shared (not private)
    const isInstructionShared = (instruction) => {
        const sharingStatus = (instruction.sharing_status || '').toLowerCase();
        return sharingStatus === 'org' ||
            sharingStatus === 'organization' ||
            sharingStatus === 'global' ||
            sharingStatus === 'all';
    };

    // Helper function to check if an instruction was created by someone else 
    // but is no longer shared (previously added from global pool)
    const isPreviouslySharedInstruction = (instruction) => {
        // If it's not the user's own instruction and not currently shared
        return instruction.creator_actor_id !== actorInfo?.actor_id &&
            !isInstructionShared(instruction);
    };

    // Determine if we should show the Add Instruction button
    const showAddButton = !(title === "Review of Systems" && transferROSToHPI);

    // Determine if we should show the Military Time toggle
    const showMilitaryTimeToggle = title === "Evaluation and Plan";

    // Use the global helper function
    const isCreator = (instruction) => {
        return isInstructionCreator(instruction, actorInfo?.actor_id);
    };

    return (
        <div>
            <div className="flex items-center justify-between mb-3">

                {showAddButton && (
                    <button
                        type="button"
                        onClick={() => setIsAddModalOpen(true)}
                        className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-indigo-600 hover:text-indigo-700 bg-white border border-indigo-200 rounded-md hover:bg-indigo-50 transition-colors"
                    >
                        <PlusIcon className="h-4 w-4 mr-1" />
                        Add Instruction
                    </button>
                )}

                {title === "Review of Systems" && (
                    <div className="flex items-center">
                        <span className="mr-2 text-sm text-gray-600">Hide ROS</span>
                        <HideROSToggle />
                    </div>
                )}

                {showMilitaryTimeToggle && (
                    <div className="flex items-center">
                        <span className="mr-2 text-sm text-gray-600">Use Military Time for Re-evals</span>
                        <MilitaryTimeToggle />
                    </div>
                )}
            </div>

            {(title !== "Review of Systems" || !transferROSToHPI) ? (
                <>
                    {/* Admin Instructions Section */}
                    {adminInstructions.length > 0 && (
                        <div className="mb-4">
                            <div className="flex items-center mb-2">
                                <ShieldCheckIcon className="h-5 w-5 text-blue-500 mr-2" />
                                <h3 className="text-sm font-medium text-blue-700">Group Instructions</h3>
                            </div>
                            <div className="space-y-2">
                                {adminInstructions.map((instruction) => (
                                    <div
                                        key={instruction.instruction_id}
                                        className={`p-3 rounded-lg border ${instruction.has_opted_out ? 'bg-gray-100 text-gray-500' : 'bg-blue-50'
                                            } hover:border-blue-300 transition-colors`}
                                    >
                                        <div className="flex items-start justify-between">
                                            <div className="flex-1">
                                                <h3 className="text-sm font-medium text-gray-900">
                                                    {instruction.name}
                                                </h3>
                                                <p className="mt-1 text-sm text-gray-600 whitespace-pre-wrap">
                                                    {instruction.content}
                                                </p>
                                            </div>
                                            {instruction.is_opt_outable && (
                                                <div className="flex items-center ml-4">
                                                    <Switch
                                                        checked={!instruction.has_opted_out}
                                                        onChange={() => onToggleOptOut(instruction)}
                                                        className={`${!instruction.has_opted_out ? 'bg-indigo-600' : 'bg-gray-200'
                                                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                    >
                                                        <span className="sr-only">Toggle Opt-Out</span>
                                                        <span
                                                            className={`${!instruction.has_opted_out ? 'translate-x-6' : 'translate-x-1'
                                                                } inline-block h-4 w-4 transform bg-white rounded-full`}
                                                        />
                                                    </Switch>
                                                    <span className="ml-3 text-sm">
                                                        {instruction.has_opted_out ? 'Opted Out' : 'Opted In'}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Personal Instructions Section */}
                    {personalInstructions.length > 0 ? (
                        <div className="space-y-2">
                            {personalInstructions.length > 0 && adminInstructions.length > 0 && (
                                <h3 className="text-sm font-medium text-gray-700 mb-2">Your Personal Instructions</h3>
                            )}
                            {personalInstructions.map((instruction) => (
                                <div
                                    key={instruction.instruction_id}
                                    className={`bg-white rounded-lg border border-gray-200 p-3 hover:border-indigo-200 transition-colors ${isPreviouslySharedInstruction(instruction) ? 'bg-gray-50' : ''
                                        }`}
                                >
                                    <div className="flex items-start justify-between">
                                        <div className="flex-1">
                                            <div className="flex items-center justify-between">
                                                <h3 className="text-sm font-medium text-gray-900">
                                                    {instruction.name}
                                                </h3>
                                                {isGlobalInstructionsEnabled && (
                                                    <div className="flex items-center space-x-1">
                                                        {isPreviouslySharedInstruction(instruction) ? (
                                                            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-700">
                                                                <UserCircleIcon className="h-3 w-3 mr-1" />
                                                                Previously Shared
                                                            </span>
                                                        ) : (
                                                            <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${instruction.sharing_status && instruction.sharing_status.toLowerCase() === 'all' ||
                                                                instruction.sharing_status && instruction.sharing_status.toLowerCase() === 'global'
                                                                ? 'bg-green-50 text-green-700'
                                                                : instruction.sharing_status && (instruction.sharing_status.toLowerCase() === 'org' ||
                                                                    instruction.sharing_status.toLowerCase() === 'organization')
                                                                    ? 'bg-sky-50 text-sky-700'
                                                                    : 'bg-indigo-50 text-indigo-700'
                                                                }`}>
                                                                {instruction.sharing_status && instruction.sharing_status.toLowerCase() === 'all' || instruction.sharing_status && instruction.sharing_status.toLowerCase() === 'global' ? (
                                                                    <GlobeAltIcon className="h-3 w-3 mr-1" />
                                                                ) : instruction.sharing_status && (instruction.sharing_status.toLowerCase() === 'org' || instruction.sharing_status.toLowerCase() === 'organization') ? (
                                                                    <BuildingOfficeIcon className="h-3 w-3 mr-1" />
                                                                ) : (
                                                                    <UserCircleIcon className="h-3 w-3 mr-1" />
                                                                )}
                                                                {getShareStatusDisplay(instruction.sharing_status)}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <p className="mt-1 text-sm text-gray-600 whitespace-pre-wrap">
                                                {instruction.content}
                                            </p>

                                            {isPreviouslySharedInstruction(instruction) && (
                                                <div className="mt-2 text-xs text-gray-500 bg-gray-50 p-2 rounded-md">
                                                    <p>This instruction was previously shared by its creator and added to your collection.
                                                        The creator has since made it private, but you can still use it.</p>
                                                </div>
                                            )}

                                            <div className="mt-2 flex items-center justify-between">
                                                <div className="flex items-center space-x-4 text-xs text-gray-500">
                                                    <span className="flex items-center">
                                                        <UserCircleIcon className="h-4 w-4 mr-1 text-gray-400" />
                                                        {instruction.creator_name}
                                                        {instruction.creator_group && (
                                                            <span className="ml-1">
                                                                • {instruction.creator_group}
                                                            </span>
                                                        )}
                                                    </span>
                                                    {instruction.usage_count > 0 && isGlobalInstructionsEnabled && (
                                                        <span className="flex items-center">
                                                            <HeartIcon className="h-4 w-4 mr-1 text-indigo-500" />
                                                            {instruction.usage_count} {instruction.usage_count === 1 ? 'use' : 'uses'}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="flex space-x-2">
                                                    {isCreator(instruction) && (
                                                        <>
                                                            {isGlobalInstructionsEnabled && (
                                                                <ShareButton
                                                                    instruction={instruction}
                                                                    onShare={onShare}
                                                                />
                                                            )}
                                                            {!isInstructionShared(instruction) ? (
                                                                <button
                                                                    onClick={() => handleEdit(instruction)}
                                                                    className="flex items-center px-2 py-1 text-sm text-gray-600 hover:text-indigo-600 hover:bg-gray-50 rounded transition-colors"
                                                                    title="Edit"
                                                                >
                                                                    <PencilIcon className="h-4 w-4 mr-1" />
                                                                    <span className="text-xs">Edit</span>
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    disabled
                                                                    className="flex items-center px-2 py-1 text-sm text-gray-400 cursor-not-allowed rounded"
                                                                    title="Shared instructions cannot be edited"
                                                                >
                                                                    <PencilIcon className="h-4 w-4 mr-1" />
                                                                    <span className="text-xs">Edit</span>
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    <button
                                                        onClick={() => onRemove(instruction)}
                                                        className="flex items-center px-2 py-1 text-sm text-red-500 hover:text-red-700 hover:bg-red-50 rounded transition-colors"
                                                        title="Remove"
                                                    >
                                                        <TrashIcon className="h-4 w-4 mr-1" />
                                                        <span className="text-xs">Remove</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center text-gray-500 py-4">
                            {adminInstructions.length > 0 ?
                                "No personal instructions added yet" :
                                "No instructions added yet"}
                        </div>
                    )}
                </>
            ) : (
                <div className="bg-gray-50 rounded-lg p-4 text-gray-500">
                    <p>Pertinent positives and negatives will be in the HPI. Instructions disabled.</p>
                </div>
            )}

            <AddInstructionModal
                isOpen={isAddModalOpen}
                setIsOpen={setIsAddModalOpen}
                section={title}
                onAdd={onAdd}
            />

            {editingInstruction && (
                <EditInstructionModal
                    isOpen={isEditModalOpen}
                    setIsOpen={setIsEditModalOpen}
                    instruction={editingInstruction}
                    onSave={onEdit}
                />
            )}
        </div>
    );
}

function GlobalInstructionsPool({ isOpen, setIsOpen, onAddInstruction }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSection, setSelectedSection] = useState('all');
    const [selectedSharingStatus, setSelectedSharingStatus] = useState('all');
    const [sortBy, setSortBy] = useState('recent'); // 'recent', 'popular', 'alphabetical'
    const [selectedInstruction, setSelectedInstruction] = useState(null);
    const [instructions, setInstructions] = useState([]);
    const [userInstructions, setUserInstructions] = useState([]);
    const [addingInstruction, setAddingInstruction] = useState(null); // Track currently adding instruction
    const [isLoading, setIsLoading] = useState(true);
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo } = useAuth();

    // Check if an instruction is already in the user's collection
    const isInstructionAlreadyAdded = (instructionId) => {
        return userInstructions.some(instr => instr.instruction_id === instructionId);
    };

    // Check if an instruction was once shared but is now private
    const isPreviouslyShared = (instruction) => {
        // Is it already in the user's collection?
        const inUserCollection = isInstructionAlreadyAdded(instruction.instruction_id);

        // Is it not the user's own instruction?
        const notOwnedByUser = instruction.creator_actor_id !== actorInfo?.actor_id;

        // Is it currently private?
        const isPrivate = instruction.sharing_status && instruction.sharing_status.toLowerCase() === 'private';

        // If it's in the user's collection, not created by the user, and is private,
        // then it must have been previously shared and added by the user
        return inUserCollection && notOwnedByUser && isPrivate;
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isOpen) return;

            try {
                setIsLoading(true);

                // Fetch both shared instructions and user's own instructions in parallel
                const [sharedData, userData] = await Promise.all([
                    customInstructionsService.getSharedInstructions(getAccessTokenSilently),
                    customInstructionsService.getMyInstructions(getAccessTokenSilently)
                ]);

                console.log('Fetched shared instructions:', sharedData);
                console.log('Fetched user instructions:', userData);

                // Filter out any instructions with a deleted_at timestamp
                const nonDeletedSharedInstructions = sharedData.filter(instruction => !instruction.deleted_at);
                console.log(`Filtered out ${sharedData.length - nonDeletedSharedInstructions.length} deleted shared instructions`);

                setInstructions(nonDeletedSharedInstructions);
                setUserInstructions(userData.filter(instruction => !instruction.deleted_at));
            } catch (error) {
                console.error('Failed to load instructions:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [isOpen, getAccessTokenSilently]);

    const handleAddInstruction = async (instruction) => {
        try {
            // Don't add if it's the user's own instruction or already added
            if (instruction.creator_actor_id === actorInfo?.actor_id || isInstructionAlreadyAdded(instruction.instruction_id)) {
                return;
            }

            // Set the adding state to show loading
            setAddingInstruction(instruction.instruction_id);

            await customInstructionsService.addInstruction(
                instruction.instruction_id,
                getAccessTokenSilently
            );

            // Update parent component
            onAddInstruction(instruction);

            // Add to user instructions so the UI updates immediately
            setUserInstructions(prevInstructions => [...prevInstructions, instruction]);

            // Clear the adding state
            setAddingInstruction(null);
        } catch (error) {
            console.error('Failed to add instruction:', error);
            setAddingInstruction(null);
        }
    };

    const filteredInstructions = instructions.filter(instruction => {
        // Filter by search query
        const matchesSearch = searchQuery.toLowerCase() === '' ||
            instruction.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            instruction.content.toLowerCase().includes(searchQuery.toLowerCase());

        // Filter by section
        const matchesSection = selectedSection === 'all' ||
            instruction.section.toLowerCase() === selectedSection.toLowerCase();

        // Filter by sharing status
        const instructionSharingStatus = instruction.sharing_status?.toLowerCase() || '';
        const isGlobal = instructionSharingStatus === 'global' || instructionSharingStatus === 'all';
        const isOrg = instructionSharingStatus === 'organization' || instructionSharingStatus === 'org';

        const matchesSharing = selectedSharingStatus === 'all' ||
            (selectedSharingStatus === 'org' && isOrg) ||
            (selectedSharingStatus === 'global' && isGlobal);

        return matchesSearch && matchesSection && matchesSharing;
    });

    // Sort the filtered instructions based on the selected sort option
    const sortedInstructions = [...filteredInstructions].sort((a, b) => {
        if (sortBy === 'alphabetical') {
            return a.name.localeCompare(b.name);
        } else if (sortBy === 'popular') {
            return (b.usage_count || 0) - (a.usage_count || 0);
        } else {
            // By default sort by recent (assuming instruction_id is sequential)
            return b.instruction_id - a.instruction_id;
        }
    });

    // Log filtered results for debugging
    console.log(`Filtered to ${filteredInstructions.length} of ${instructions.length} instructions`);

    const getActionButton = (instruction) => {
        const isAdded = isInstructionAlreadyAdded(instruction.instruction_id);
        const isAdding = addingInstruction === instruction.instruction_id;
        const wasPreviouslyShared = isPreviouslyShared(instruction);

        if (instruction.creator_actor_id === actorInfo?.actor_id) {
            return (
                <span className="inline-flex items-center px-3 py-1 text-sm text-gray-500 bg-gray-100 rounded-md">
                    Your Instruction
                </span>
            );
        }

        if (isAdded) {
            return (
                <span className={`inline-flex items-center px-3 py-1.5 text-sm ${wasPreviouslyShared ? 'text-gray-700 bg-gray-100' : 'text-green-700 bg-green-50'} rounded-md`}>
                    <CheckIcon className="h-5 w-5 mr-1.5" />
                    {wasPreviouslyShared ? 'Preserved in Collection' : 'Added to Collection'}
                </span>
            );
        }

        return (
            <button
                onClick={() => handleAddInstruction(instruction)}
                disabled={isAdding}
                className="flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
                {isAdding ? (
                    <>
                        <div className="mr-2 h-4 w-4 rounded-full border-2 border-transparent border-t-white animate-spin"></div>
                        Adding...
                    </>
                ) : (
                    <>
                        <PlusIcon className="h-4 w-4 mr-2" />
                        Add to Collection
                    </>
                )}
            </button>
        );
    };

    const getSharingStatusBadge = (instruction) => {
        const sharingStatus = instruction.sharing_status?.toLowerCase();

        if (sharingStatus === 'global' || sharingStatus === 'all') {
            return (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-50 text-green-700">
                    <GlobeAltIcon className="h-3 w-3 mr-1" />
                    Global
                </span>
            );
        } else if (sharingStatus === 'organization' || sharingStatus === 'org') {
            return (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-sky-50 text-sky-700">
                    <BuildingOfficeIcon className="h-3 w-3 mr-1" />
                    Organization
                </span>
            );
        }

        return null;
    };

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <Dialog.Panel className="w-[950px] h-[800px] bg-white rounded-xl shadow-2xl">
                    <div className="flex flex-col h-full">
                        <div className="p-6 border-b border-gray-200">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <GlobeAltIcon className="h-6 w-6 text-indigo-600" />
                                    <Dialog.Title className="text-2xl font-semibold text-gray-900">
                                        Global Instructions Pool
                                    </Dialog.Title>
                                </div>
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="text-gray-400 hover:text-gray-500"
                                >
                                    <XMarkIcon className="h-6 w-6" />
                                </button>
                            </div>

                            {/* Enhanced filtering UI */}
                            <div className="mt-6 grid grid-cols-12 gap-4">
                                <div className="col-span-5">
                                    <div className="relative">
                                        <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                                        <input
                                            type="text"
                                            placeholder="Search instructions..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                        />
                                    </div>
                                </div>

                                <div className="col-span-2">
                                    <select
                                        value={selectedSection}
                                        onChange={(e) => setSelectedSection(e.target.value)}
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500"
                                    >
                                        <option value="all">All Sections</option>
                                        <option value="hpi">HPI</option>
                                        <option value="ros">ROS</option>
                                        <option value="physical_exam">Physical Exam</option>
                                        <option value="evaluation">Evaluation</option>
                                    </select>
                                </div>

                                <div className="col-span-2">
                                    <select
                                        value={selectedSharingStatus}
                                        onChange={(e) => setSelectedSharingStatus(e.target.value)}
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500"
                                    >
                                        <option value="all">All Sharing</option>
                                        <option value="global">Global</option>
                                        <option value="org">Organization</option>
                                    </select>
                                </div>

                                <div className="col-span-3">
                                    <select
                                        value={sortBy}
                                        onChange={(e) => setSortBy(e.target.value)}
                                        className="w-full border border-gray-300 rounded-md px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500"
                                    >
                                        <option value="recent">Most Recent</option>
                                        <option value="popular">Most Popular</option>
                                        <option value="alphabetical">Alphabetical</option>
                                    </select>
                                </div>
                            </div>

                            <div className="mt-2 text-sm text-gray-500">
                                Showing {sortedInstructions.length} of {instructions.length} instructions
                            </div>
                        </div>

                        <div className="flex-1 overflow-y-auto p-6">
                            {isLoading ? (
                                <div className="flex items-center justify-center h-full">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    {sortedInstructions.map((instruction) => (
                                        <div
                                            key={instruction.instruction_id}
                                            className={`bg-white rounded-lg border ${instruction.creator_actor_id === actorInfo?.actor_id
                                                ? 'border-indigo-200 bg-indigo-50'
                                                : 'border-gray-200'
                                                } p-6 hover:border-indigo-300 transition-colors shadow-sm`}
                                        >
                                            <div className="flex items-start justify-between">
                                                <div className="flex-1 mr-4">
                                                    <div className="flex items-center justify-between mb-2">
                                                        <h3 className="text-lg font-medium text-gray-900 flex items-center">
                                                            {instruction.name}
                                                            {instruction.creator_actor_id === actorInfo?.actor_id && (
                                                                <span className="inline-flex ml-1.5 -mt-3 h-2 w-2 flex-shrink-0 rounded-full bg-green-400" title="Your instruction"></span>
                                                            )}
                                                        </h3>
                                                        <div className="flex items-center space-x-2">
                                                            {getSharingStatusBadge(instruction)}
                                                            {isPreviouslyShared(instruction) && (
                                                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-700">
                                                                    <UserCircleIcon className="h-3 w-3 mr-1" />
                                                                    Previously Shared
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 flex items-center space-x-4 text-sm">
                                                        <span className="flex items-center text-gray-600">
                                                            <UserCircleIcon className="h-4 w-4 mr-1" />
                                                            {instruction.creator_name}
                                                            {instruction.creator_group && (
                                                                <span className="ml-1 text-gray-500">
                                                                    • {instruction.creator_group}
                                                                </span>
                                                            )}
                                                        </span>
                                                        <span className="flex items-center text-gray-600">
                                                            <DocumentTextIcon className="h-4 w-4 mr-1" />
                                                            {sectionToTitleMap[instruction.section]}
                                                        </span>
                                                        <span className="flex items-center text-indigo-500">
                                                            <HeartIcon className="h-4 w-4 mr-1" />
                                                            {instruction.usage_count || 0} {instruction.usage_count === 1 ? 'use' : 'uses'}
                                                        </span>
                                                        <button
                                                            onClick={() => setSelectedInstruction(instruction)}
                                                            className="flex items-center text-indigo-600 hover:text-indigo-700"
                                                        >
                                                            <ChatBubbleLeftIcon className="h-4 w-4 mr-1" />
                                                            View Discussion
                                                        </button>
                                                    </div>
                                                    <p className="mt-3 text-sm text-gray-600 whitespace-pre-wrap line-clamp-3 hover:line-clamp-none transition-all duration-300">
                                                        {instruction.content}
                                                    </p>
                                                </div>
                                                <div className="ml-4 flex-shrink-0">
                                                    {getActionButton(instruction)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {sortedInstructions.length === 0 && !isLoading && (
                                        <div className="flex flex-col items-center justify-center p-12 text-center">
                                            <DocumentTextIcon className="h-12 w-12 text-gray-300 mb-4" />
                                            <h3 className="text-lg font-medium text-gray-900 mb-1">No instructions found</h3>
                                            <p className="text-gray-500">Try adjusting your filters or search terms</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Dialog.Panel>
            </div>

            {/* Render the Discussion dialog when an instruction is selected */}
            {selectedInstruction && (
                <Dialog
                    open={!!selectedInstruction}
                    onClose={() => setSelectedInstruction(null)}
                    className="relative z-50"
                >
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <InstructionDiscussion
                            instruction={selectedInstruction}
                            onClose={() => setSelectedInstruction(null)}
                            onAddInstruction={onAddInstruction}
                        />
                    </div>
                </Dialog>
            )}
        </Dialog>
    );
}

function ShareButton({ instruction, onShare }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isConfirmingUnshare, setIsConfirmingUnshare] = useState(false);
    // Use the hook directly in this component
    const isGlobalInstructionsEnabled = useFeatureFlagEnabled("custom_instructions_sharing");
    // const isGlobalInstructionsEnabled = true;

    // Get the current sharing status
    const currentStatus = getShareStatus(instruction.sharing_status);
    console.log(`ShareButton: instruction ${instruction.instruction_id} has status ${instruction.sharing_status} mapped to ${currentStatus}`);

    // Check if instruction is currently shared (not private)
    const isShared = currentStatus !== 'PRIVATE' && currentStatus !== '';

    const handleShareChange = (shareType) => {
        // If trying to unshare and is currently shared, show confirmation
        if (shareType === 'PRIVATE' && isShared) {
            setIsConfirmingUnshare(true);
        } else {
            // Otherwise just share normally
            onShare(instruction.instruction_id, shareType);
            setIsOpen(false);
        }
    };

    const handleConfirmUnshare = () => {
        onShare(instruction.instruction_id, 'PRIVATE');
        setIsConfirmingUnshare(false);
        setIsOpen(false);
    };

    return (
        <>
            {isGlobalInstructionsEnabled && (
                <button
                    onClick={() => setIsOpen(true)}
                    className={`flex items-center px-2 py-1 text-sm ${isShared
                        ? 'text-green-600 hover:text-green-800 hover:bg-green-50'
                        : 'text-indigo-600 hover:text-indigo-800 hover:bg-indigo-50'
                        } rounded transition-colors`}
                    title={isShared ? "Manage Sharing" : "Share"}
                >
                    <ShareIcon className="h-4 w-4 mr-1" />
                    <span className="text-xs">{isShared ? "Shared" : "Share"}</span>
                </button>
            )}

            {/* Main Share Dialog */}
            <Dialog open={isOpen && !isConfirmingUnshare} onClose={() => setIsOpen(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="w-full max-w-md rounded-xl bg-white p-6 shadow-2xl">
                        <Dialog.Title className="text-xl font-semibold text-gray-900">
                            {isShared ? "Manage Sharing" : "Share"} "{instruction.name}"
                        </Dialog.Title>

                        {isShared && (
                            <div className="mt-3 bg-blue-50 rounded-lg p-4 text-sm text-blue-700">
                                <p>
                                    <strong>Note:</strong> This instruction is currently shared. To edit it, you must first unshare it by selecting "Keep private".
                                </p>
                            </div>
                        )}

                        <div className="mt-4">
                            <p className="text-gray-600">
                                Who do you want to share this instruction with?
                            </p>

                            <div className="mt-6 space-y-4">
                                <button
                                    onClick={() => handleShareChange('PRIVATE')}
                                    className={`w-full flex items-center justify-between px-4 py-3 text-left rounded-lg border ${currentStatus === 'PRIVATE' || !currentStatus || currentStatus === ''
                                        ? 'border-indigo-600 bg-indigo-50 text-indigo-700'
                                        : 'border-gray-200 hover:border-indigo-300 hover:bg-indigo-50'
                                        }`}
                                >
                                    <div className="flex items-center">
                                        <UserCircleIcon className="h-5 w-5 mr-3 text-indigo-600" />
                                        <div>
                                            <span className="block font-medium">Keep private</span>
                                            <span className="block text-sm text-gray-500">Only you can access this instruction</span>
                                            {isShared && (
                                                <span className="block text-sm text-blue-600 font-medium">
                                                    Select to enable editing
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {(currentStatus === 'PRIVATE' || !currentStatus || currentStatus === '') && (
                                        <CheckIcon className="h-5 w-5 text-indigo-600" />
                                    )}
                                </button>

                                <button
                                    onClick={() => handleShareChange('ORGANIZATION')}
                                    className={`w-full flex items-center justify-between px-4 py-3 text-left rounded-lg border ${currentStatus === 'ORGANIZATION'
                                        ? 'border-indigo-600 bg-indigo-50 text-indigo-700'
                                        : 'border-gray-200 hover:border-indigo-300 hover:bg-indigo-50'
                                        }`}
                                >
                                    <div className="flex items-center">
                                        <BuildingOfficeIcon className="h-5 w-5 mr-3 text-indigo-600" />
                                        <div>
                                            <span className="block font-medium">Share with organization</span>
                                            <span className="block text-sm text-gray-500">All colleagues in your organization</span>
                                        </div>
                                    </div>
                                    {currentStatus === 'ORGANIZATION' && (
                                        <CheckIcon className="h-5 w-5 text-indigo-600" />
                                    )}
                                </button>

                                <button
                                    onClick={() => handleShareChange('GLOBAL')}
                                    className={`w-full flex items-center justify-between px-4 py-3 text-left rounded-lg border ${currentStatus === 'GLOBAL'
                                        ? 'border-indigo-600 bg-indigo-50 text-indigo-700'
                                        : 'border-gray-200 hover:border-indigo-300 hover:bg-indigo-50'
                                        }`}
                                >
                                    <div className="flex items-center">
                                        <GlobeAltIcon className="h-5 w-5 mr-3 text-indigo-600" />
                                        <div>
                                            <span className="block font-medium">Share with Cleo Community</span>
                                            <span className="block text-sm text-gray-500">All Cleo users can view and use this instruction</span>
                                        </div>
                                    </div>
                                    {currentStatus === 'GLOBAL' && (
                                        <CheckIcon className="h-5 w-5 text-indigo-600" />
                                    )}
                                </button>
                            </div>

                            <div className="mt-6 flex justify-end">
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>

            {/* Unshare Confirmation Dialog */}
            <Dialog
                open={isConfirmingUnshare}
                onClose={() => setIsConfirmingUnshare(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="w-full max-w-md rounded-xl bg-white p-6 shadow-2xl">
                        <Dialog.Title className="text-xl font-semibold text-gray-900">
                            Unshare Instruction
                        </Dialog.Title>

                        <div className="mt-4">
                            <div className="bg-amber-50 rounded-lg p-4 mb-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <ExclamationTriangleIcon className="h-5 w-5 text-amber-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-amber-800">Important information</h3>
                                        <div className="mt-2 text-sm text-amber-700">
                                            <p>
                                                When you unshare this instruction:
                                            </p>
                                            <ul className="list-disc pl-5 mt-1 space-y-1">
                                                <li>Users who have already added this instruction to their collection will still be able to use it</li>
                                                <li>The instruction will no longer appear in the global pool</li>
                                                <li>It will be marked as "Previously Shared" for users who already have it</li>
                                                <li>You will be able to edit it once it's unshared</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <p className="text-gray-600">
                                Are you sure you want to make <strong>"{instruction.name}"</strong> private?
                            </p>

                            <div className="mt-6 flex justify-end space-x-3">
                                <button
                                    onClick={() => setIsConfirmingUnshare(false)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleConfirmUnshare}
                                    className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
                                >
                                    Unshare
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>
        </>
    );
}

export default CustomInstructions;