import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CheckCircleIcon,
    TrashIcon,
    ExclamationTriangleIcon
} from '@heroicons/react/20/solid';
import {
    UserIcon,
    UserGroupIcon,
    EyeIcon,
    EyeSlashIcon
} from '@heroicons/react/24/outline';
import { useAuth } from '../../contexts/AuthProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { getMacros, toggleMacroOptOut, upsertMacro as upsertMacroApi, deleteMacro as deleteMacroApi } from '../../api/macroApi';
import { useSettings } from '../../contexts/SettingsProvider';
export default function MacroSettings({ onSuccessfulSave }) {
    const { getAccessTokenSilently, user, isLoading: isAuth0Loading } = useAuth0();
    const [macroTitle, setMacroTitle] = useState('');
    const [macroCommand, setMacroCommand] = useState('');
    const [macroContent, setMacroContent] = useState('');
    const [macroSection, setMacroSection] = useState('reevaluation');
    const [specialInstructions, setSpecialInstructions] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [macroList, setMacroList] = useState([]);
    const [isEditable, setIsEditable] = useState(true);
    const [lastUpsertedMacroId, setLastUpsertedMacroId] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedMacroId, setSelectedMacroId] = useState(null);
    const [macroId, setMacroId] = useState(null);
    const [isShareable, setIsShareable] = useState(true);
    const { actorInfo } = useAuth();
    const [willAffectAllUsers, setWillAffectAllUsers] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedMacroType, setSelectedMacroType] = useState('personal');
    const macroSpecialInstructionsEnabled = useFeatureFlagEnabled('macro_special_instructions')
    const { getSetting, isLoading: isSettingsLoading } = useSettings();
    const isProceduresEnabled = useFeatureFlagEnabled('procedures') || getSetting('procedures_enabled');
    const [isOptingOut, setIsOptingOut] = useState(false);


    const macroTypeDetails = {
        'personal': {
            name: 'My Macros',
            icon: UserIcon,
            color: 'text-blue-600',
        },
        'group': {
            name: 'Group Macros',
            icon: UserGroupIcon,
            color: 'text-purple-600',
        },
    };

    const determineMacroType = (macro) => {
        if (macro.group_id) {
            return 'group';
        }
        return 'personal';
    };


    const handleTokenExpiration = useCallback((error) => {
        if (error.message.includes('invalid_token')) {
            // Handle token expiration - Auth0 will automatically refresh
            console.error('Token error:', error);
        } else {
            console.error('Error:', error);
        }
    }, []);

    const handleUpsertMacro = async () => {
        setIsLoading(true);
        try {
            const macroData = {
                macro_id: macroId,
                title: macroTitle,
                command: macroCommand,
                content: macroContent,
                section: macroSection,
                is_shareable_within_org: isShareable,
                special_instructions: specialInstructions
            };

            const data = await upsertMacroApi(getAccessTokenSilently, macroData);

            if (data.success) {
                if (!macroId && data.macro_id) {
                    setMacroId(data.macro_id);
                }
                setLastUpsertedMacroId(macroId || data.macro_id);
                await fetchMacros(macroId || data.macro_id);
                onSuccessfulSave(`Macro "${macroTitle}" saved successfully`);
            } else {
                throw new Error(data.error || 'Failed to save macro');
            }
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
        }
        setIsLoading(false);
    };

    const deleteMacro = async () => {
        setIsLoading(true);
        try {
            await deleteMacroApi(getAccessTokenSilently, macroId);

            setMacroTitle('');
            setMacroCommand('');
            setMacroContent('');
            setMacroSection('reevaluation');
            setSelectedMacroId(null);
            setMacroId(null);

            await fetchMacros();
            onSuccessfulSave(`Macro deleted successfully`);
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error deleting macro:', error);
        }
        setIsLoading(false);
    };

    const handleMacroClick = (macro) => {
        setMacroId(macro.macro_id);
        setMacroTitle(macro.title);
        setMacroCommand(macro.command);
        setMacroContent(macro.content);
        setMacroSection(macro.section || 'reevaluation');
        setSelectedMacroId(macro.macro_id);
        setIsShareable(macro.is_shareable_within_org);
        setSpecialInstructions(macro.special_instructions || '');

        // Determine editability based on macro type
        const macro_type = determineMacroType(macro);
        setSelectedMacroType(macro_type);

        // Group macros are only editable by group admins or global admins
        const isGroupMacro = macro_type === 'group';

        setIsEditable(!isGroupMacro);
        setWillAffectAllUsers(false);
    };
    const fetchMacros = useCallback(async (lastUpsertedMacroId = '') => {
        setIsLoading(true);
        try {
            const data = await getMacros(getAccessTokenSilently);

            if (!data || !data.macros) {
                console.error('Invalid response format from getMacros:', data);
                setMacroList([]);
                return;
            }

            // Convert the macros object to an array
            const macros = Object.entries(data.macros).map(([macro_id, macro]) => ({
                macro_id: Number(macro_id),
                ...macro,
                macro_type: determineMacroType(macro),
            }));

            setMacroList(macros);

            if (lastUpsertedMacroId) {
                const foundIndex = macros.findIndex(macro => macro.macro_id === lastUpsertedMacroId);
                setSelectedMacroId(lastUpsertedMacroId);
                if (foundIndex !== -1) {
                    const foundMacro = macros[foundIndex];
                    setMacroId(foundMacro.macro_id);
                    setMacroTitle(foundMacro.title);
                    setMacroCommand(foundMacro.command);
                    setMacroContent(foundMacro.content);
                    setMacroSection(foundMacro.section || 'reevaluation');
                    setSpecialInstructions(foundMacro.special_instructions || '');
                }
            }
            setLastUpsertedMacroId(null);
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error fetching macros:', error);
            setMacroList([]); // Set empty array on error
        }
        setIsLoading(false);
    }, [getAccessTokenSilently]);

    const isDuplicateTitle = (title) => {
        return macroList.some(macro => macro.title.toLowerCase() === title.toLowerCase());
    };

    const confirmSave = () => {
        setShowConfirmationModal(false);
        handleUpsertMacro();
        setIsEditable(false);
        onSuccessfulSave(`Macro "${macroTitle}" saved successfully`);
    };


    const handleSaveClick = () => {
        if (!areFieldsFilled()) {
            alert("Please fill in all the required fields.");
            return;
        }

        if (selectedMacroId === null && isDuplicateTitle(macroTitle)) {
            alert("A macro with this title already exists. Please use a different title.");
            return;
        }

        if (willAffectAllUsers) {
            setShowConfirmationModal(true);
        } else {
            handleUpsertMacro();
            setIsEditable(false);
            onSuccessfulSave(`Macro "${macroTitle}" saved successfully`);
        }
    };

    const areFieldsFilled = () => {
        if (macroSpecialInstructionsEnabled) {
            // When special instructions are enabled, only title and content are required
            return macroTitle.trim() !== '' && macroContent.trim() !== '';
        }

        return macroTitle.trim() !== '' && macroCommand.trim() !== '' && macroContent.trim() !== '';
    };

    const handleDeleteClick = () => {
        if (willAffectAllUsers) {
            // Show confirmation modal for delete
            setShowConfirmationModal(true);
        } else {
            deleteMacro();
        }
    };

    const handleNewMacro = () => {
        setMacroTitle('');
        setMacroCommand('');
        setMacroContent('');
        setMacroId(null);
        setMacroSection('reevaluation');
        setSelectedMacroId(null);
        setIsEditable(true);
        setSpecialInstructions('');
    };


    useEffect(() => {
        // Replace MSAL loading check with Auth0
        const loading = isAuth0Loading;

        setIsLoading(loading);
        const timeoutId = setTimeout(() => {
            fetchMacros();
        }, 250);

        return () => clearTimeout(timeoutId);
    }, [fetchMacros, isAuth0Loading]);

    // Group macros by macro_type
    const groupedMacros = macroList.reduce((groups, macro) => {
        const type = determineMacroType(macro);

        if (!groups[type]) {
            groups[type] = [];
        }
        groups[type].push(macro);
        return groups;
    }, {});

    const handleOptOutToggle = async (macro) => {
        setIsOptingOut(true);

        // Optimistically update the UI
        const updatedMacros = macroList.map(m => {
            if (m.macro_id === macro.macro_id) {
                return {
                    ...m,
                    is_opted_out: !m.is_opted_out
                };
            }
            return m;
        });
        setMacroList(updatedMacros);

        try {
            const data = await toggleMacroOptOut(getAccessTokenSilently, macro.macro_id);
            onSuccessfulSave(`Macro "${macro.title}" ${data.is_opted_out ? 'opted out' : 'opted in'} successfully`);
        } catch (error) {
            // Revert the optimistic update if the request fails
            setMacroList(macroList);
            handleTokenExpiration(error);
            console.error('Error toggling macro opt-out:', error);
        } finally {
            setIsOptingOut(false);
        }
    };

    // Modify the macro list item render to include the opt-out toggle
    const renderMacroListItem = (macro) => {
        const macroType = determineMacroType(macro);
        const isPersonalMacro = macroType === 'personal';

        return (
            <li
                key={macro.macro_id}
                className={`
                    relative
                    cursor-pointer 
                    text-zinc-700 
                    hover:bg-gray-50 
                    p-2 
                    ${macro.macro_id === selectedMacroId ? 'bg-gray-100' : ''} 
                    border-b border-gray-200
                    ${macro.is_opted_out ? 'opacity-75' : ''}
                `}
            >
                <div className="flex items-center justify-between group">
                    {/* Left side: Macro title */}
                    <div
                        className="flex-1 mr-2"
                        onClick={() => handleMacroClick(macro)}
                    >
                        <div className="flex items-center space-x-2">
                            <span className={macro.is_opted_out ? 'text-gray-400' : ''}>
                                {macro.title}
                            </span>
                            {/* Move the opted-out badge next to the title */}
                            {macro.is_opted_out && (
                                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-500">
                                    Opted Out
                                </span>
                            )}
                        </div>
                    </div>

                    {/* Right side: Opt-out toggle for non-personal macros */}
                    {!isPersonalMacro && (
                        <div className="flex items-center opacity-100 hover:opacity-100 transition-opacity ml-2">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleOptOutToggle(macro);
                                }}
                                disabled={isOptingOut}
                                className={`
                                    flex items-center px-2 py-1 rounded-md text-sm
                                    transition-colors duration-200
                                    ${isOptingOut ? 'opacity-50 cursor-not-allowed' : ''}
                                    ${macro.is_opted_out
                                        ? 'text-blue-600 hover:bg-blue-50'
                                        : 'text-gray-500 hover:bg-gray-50'}
                                    border border-current
                                    whitespace-nowrap
                                `}
                            >
                                {macro.is_opted_out ? (
                                    <>
                                        <EyeIcon className="w-4 h-4 mr-1" />
                                        <span>Opt In</span>
                                    </>
                                ) : (
                                    <>
                                        <EyeSlashIcon className="w-4 h-4 mr-1" />
                                        <span>Opt Out</span>
                                    </>
                                )}
                            </button>
                        </div>
                    )}
                </div>
            </li>
        );
    };

    return (
        <>
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Macro Settings</h1>
            {/* Add this conditional rendering for HOSPITALIST users */}
            {actorInfo && actorInfo.actor_specialty === 'HOSPITALIST' && (
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2 mb-2" role="alert">
                    <p className="font-bold">Note for Hospitalists:</p>
                    <p>Macros are currently only configured for Admission Notes Sections.</p>
                </div>
            )}
            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                    <div className="flex">
                        {/* Left Column */}
                        <div className="w-1/3 pr-4">
                            <button
                                className="inline-flex items-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4"
                                onClick={handleNewMacro}>
                                + New Empty Macro
                            </button>
                            <input
                                type="text"
                                placeholder="Search macros"
                                className="mt-2 mb-4 p-2 border border-gray-300 rounded w-full"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
                                {isLoading ? (
                                    <div className="flex justify-center items-center h-full">
                                        <div className="spinner"></div>
                                    </div>
                                ) : Object.keys(groupedMacros).length > 0 ? (
                                    Object.entries(groupedMacros).map(([type, macros]) => {
                                        const IconComponent = macroTypeDetails[type]?.icon;
                                        const colorClass = macroTypeDetails[type]?.color || 'text-gray-600';
                                        const typeName = macroTypeDetails[type]?.name || 'Unknown Macros';

                                        return (
                                            <div key={type}>
                                                <div className="flex items-center mt-4 mb-2">
                                                    {IconComponent && (
                                                        <IconComponent className={`h-5 w-5 mr-2 ${colorClass}`} />
                                                    )}
                                                    <h2 className={`text-lg font-semibold ${colorClass}`}>
                                                        {typeName}
                                                    </h2>
                                                </div>
                                                <ul className="space-y-0">
                                                    {macros
                                                        .filter((macro) =>
                                                            macro.title.toLowerCase().includes(searchQuery.toLowerCase())
                                                        )
                                                        .map(renderMacroListItem)}
                                                </ul>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p className="text-center text-gray-500 py-4">No macros found</p>
                                )}
                            </div>

                        </div>

                        {/* Middle Column */}
                        <div className="w-2/3 px-4">
                            <div className="space-y-6">
                                {/* Title and Shareable Toggle */}
                                <div>
                                    <div className="flex items-center justify-between mb-2">
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Title</label>
                                    </div>
                                    <input
                                        type="text"
                                        name="macroTitle"
                                        className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
                                            ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                                        value={macroTitle}
                                        onChange={(e) => setMacroTitle(e.target.value)}
                                        disabled={!isEditable}
                                        required
                                    />
                                </div>

                                {/* Command */}
                                {!macroSpecialInstructionsEnabled && (
                                    <div>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">When I say this during my patient encounter...</label>
                                        <textarea
                                            name="macroCommand"
                                            className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
                                                ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                                            rows="2"
                                            placeholder="Enter command here"
                                            value={macroCommand}
                                            onChange={(e) => setMacroCommand(e.target.value)}
                                            disabled={!isEditable}
                                            required
                                        />
                                    </div>
                                )}


                                {/* Content */}
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">Content:</label>
                                    <textarea
                                        name="macroContent"
                                        className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
                                            ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                                        rows="8"
                                        placeholder="Enter content here"
                                        value={macroContent}
                                        onChange={(e) => setMacroContent(e.target.value)}
                                        disabled={!isEditable}
                                        required
                                    />
                                </div>


                                {/* Section */}
                                <div>
                                    <label className="block text-sm font-medium leading-6 text-gray-900">Note Section:</label>
                                    <select
                                        name="macroSection"
                                        className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
                                            ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                                        value={macroSection || 'reevaluation'}
                                        onChange={(e) => setMacroSection(e.target.value)}
                                        disabled={!isEditable}
                                    >
                                        <option value="hpi">HPI</option>
                                        <option value="ros">Review of Systems</option>
                                        <option value="physical_exam">Physical Exam</option>
                                        <option value="reevaluation">Evaluations</option>
                                        {isProceduresEnabled && (
                                            <option value="procedures">Procedures</option>
                                        )}
                                        <option value="mdm">MDM</option>
                                    </select>
                                </div>

                                {/* Special Instructions */}
                                {macroSpecialInstructionsEnabled && (
                                    <div>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">Special Instruction:</label>
                                        <textarea
                                            name="specialInstructions"
                                            className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
                                                ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                                            rows="2"
                                            placeholder="Enter special instructions here"
                                            value={specialInstructions}
                                            onChange={(e) => setSpecialInstructions(e.target.value)}
                                            disabled={!isEditable}
                                        />
                                    </div>
                                )}

                                {/* Only show delete/save buttons if the macro is editable */}
                                {isEditable && (
                                    <div className="flex justify-between mt-4">
                                        <button
                                            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-50 ring-1 ring-inset ring-red-600 focus:outline-none focus:ring-2 focus:ring-red-600"
                                            onClick={handleDeleteClick}>
                                            <TrashIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                            Delete
                                        </button>
                                        <button
                                            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 ring-1 ring-inset ring-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                            onClick={handleSaveClick}
                                            disabled={!areFieldsFilled()}>
                                            <CheckCircleIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                            Save
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmationModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg sm:w-full"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                        >
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                            Confirm Update
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                This macro is used by all users in your {selectedMacroType}. Saving changes will update the macro for all users. Do you want to proceed?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-transparent px-4 py-1.5 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 sm:ml-3"
                                    onClick={confirmSave}
                                >
                                    Yes, Save Changes
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center rounded-md border border-gray-300 px-4 py-1.5 bg-white text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                                    onClick={() => setShowConfirmationModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}