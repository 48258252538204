import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../contexts/AuthProvider';
import { useLocation } from 'react-router-dom';
import { getGroupHierarchy, getGroupSetting, updateGroupSetting } from '../api/organizationPortalApi';
import { PlusIcon, TrashIcon, ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline';

const OrganizationPortalConsentAttestations = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo } = useAuth();
    const location = useLocation();

    const [groups, setGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [isLoadingGroups, setIsLoadingGroups] = useState(true);
    const [isLoadingSettings, setIsLoadingSettings] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [hasExistingSettings, setHasExistingSettings] = useState(false);

    // Consent attestation settings state
    const [attestationType, setAttestationType] = useState('interactive');
    const [automaticText, setAutomaticText] = useState('');
    const [modalTitle, setModalTitle] = useState('Consent Attestation');
    const [modalDescription, setModalDescription] = useState('Please select the most relevant attestation.');
    const [attestations, setAttestations] = useState([]);

    // Get organization ID from location state if provided (for global admin viewing other orgs)
    const isGlobalAdminView = location.state?.isGlobalAdminView || false;
    const orgId = isGlobalAdminView ? location.state?.orgId : actorInfo?.org_id;

    // Fetch groups for the organization
    useEffect(() => {
        const fetchGroups = async () => {
            if (!orgId) return;

            try {
                setIsLoadingGroups(true);
                const hierarchy = await getGroupHierarchy(getAccessTokenSilently, orgId);

                // Process the hierarchy to create a flattened list with proper indentation
                const flattenedGroups = [];
                const processHierarchy = (items, level = 0) => {
                    items.forEach(item => {
                        flattenedGroups.push({
                            ...item,
                            displayName: '  '.repeat(level) + item.name
                        });
                        if (item.children && item.children.length > 0) {
                            processHierarchy(item.children, level + 1);
                        }
                    });
                };

                processHierarchy(hierarchy);

                // Filter groups based on organization ID
                const processedGroups = flattenedGroups.filter(group => {
                    // For org_id 51, only show specific groups
                    if (Number(orgId) === 51) {
                        // Filter out top-level groups (level === 1)
                        return group.level !== 1;
                    }

                    // For other organizations, show all groups
                    return true;
                });

                setGroups(processedGroups);

                // Select the first group by default if none is selected
                if (!selectedGroupId && processedGroups.length > 0) {
                    setSelectedGroupId(processedGroups[0].group_id);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
            } finally {
                setIsLoadingGroups(false);
            }
        };

        fetchGroups();
    }, [getAccessTokenSilently, orgId, selectedGroupId]);

    // Fetch consent attestation settings for the selected group
    const fetchConsentSettings = useCallback(async () => {
        if (!selectedGroupId) return;

        try {
            setIsLoadingSettings(true);
            setErrorMessage('');

            const response = await getGroupSetting(
                getAccessTokenSilently,
                selectedGroupId,
                'consent_attestations'
            );

            const settings = response?.Setting?.value;

            if (settings) {
                // Load settings into state
                setHasExistingSettings(true);
                setAttestationType(settings.type || 'interactive');

                if (settings.type === 'automatic' && settings.content) {
                    setAutomaticText(settings.content.text || '');
                } else if (settings.type === 'interactive' && settings.content) {
                    setModalTitle(settings.content.modal_title || 'Consent Attestation');
                    setModalDescription(settings.content.modal_description || 'Please select the most relevant attestation.');

                    if (settings.content.attestations) {
                        // Convert attestations object to array for easier handling in UI
                        const attestationsArray = Object.entries(settings.content.attestations).map(([id, att]) => ({
                            id,
                            label: att.label || id,
                            text: att.text || '',
                            order: att.order || 999
                        }));

                        // Sort by order
                        attestationsArray.sort((a, b) => a.order - b.order);
                        setAttestations(attestationsArray);
                    } else {
                        setAttestations([]);
                    }
                }
            } else {
                // Reset to empty state if no settings found
                resetToEmpty();
            }
        } catch (error) {
            console.error('Error fetching consent attestation settings:', error);
            setErrorMessage('Failed to load consent attestation settings. Please try again.');
            resetToEmpty();
        } finally {
            setIsLoadingSettings(false);
        }
    }, [getAccessTokenSilently, selectedGroupId]);

    // Reset to empty state
    const resetToEmpty = () => {
        setHasExistingSettings(false);
        setAttestationType('interactive');
        setAutomaticText('');
        setModalTitle('Consent Attestation');
        setModalDescription('Please select the most relevant attestation.');
        setAttestations([]);
    };

    // Load settings when group changes
    useEffect(() => {
        if (selectedGroupId) {
            fetchConsentSettings();
        }
    }, [selectedGroupId, fetchConsentSettings]);

    // Handle group selection change
    const handleGroupChange = (e) => {
        setSelectedGroupId(e.target.value);
    };

    // Handle attestation type change
    const handleAttestationTypeChange = (e) => {
        setAttestationType(e.target.value);
    };

    // Add a new attestation option
    const addAttestationOption = () => {
        const newId = `option_${Date.now()}`;
        setAttestations([
            ...attestations,
            {
                id: newId,
                label: 'New Attestation Option',
                text: '',
                order: attestations.length + 1
            }
        ]);
    };

    // Remove an attestation option
    const removeAttestationOption = (id) => {
        setAttestations(attestations.filter(att => att.id !== id));
    };

    // Update attestation field
    const updateAttestationField = (id, field, value) => {
        setAttestations(attestations.map(att =>
            att.id === id ? { ...att, [field]: value } : att
        ));
    };

    // Move attestation up in the list
    const moveAttestationUp = (index) => {
        if (index === 0) return;

        const newAttestations = [...attestations];
        const temp = newAttestations[index];
        newAttestations[index] = newAttestations[index - 1];
        newAttestations[index - 1] = temp;

        // Update order values
        newAttestations.forEach((att, i) => {
            att.order = i + 1;
        });

        setAttestations(newAttestations);
    };

    // Move attestation down in the list
    const moveAttestationDown = (index) => {
        if (index === attestations.length - 1) return;

        const newAttestations = [...attestations];
        const temp = newAttestations[index];
        newAttestations[index] = newAttestations[index + 1];
        newAttestations[index + 1] = temp;

        // Update order values
        newAttestations.forEach((att, i) => {
            att.order = i + 1;
        });

        setAttestations(newAttestations);
    };

    // Save settings
    const saveSettings = async () => {
        if (!selectedGroupId) return;

        try {
            setIsSaving(true);
            setErrorMessage('');
            setSuccessMessage('');

            // Prepare settings object based on attestation type
            let settingsValue = {
                type: attestationType
            };

            if (attestationType === 'automatic') {
                settingsValue.content = {
                    text: automaticText
                };
            } else {
                // Convert attestations array back to object format
                const attestationsObject = {};
                attestations.forEach(att => {
                    attestationsObject[att.id] = {
                        label: att.label,
                        text: att.text,
                        order: att.order
                    };
                });

                settingsValue.content = {
                    modal_title: modalTitle,
                    modal_description: modalDescription,
                    attestations: attestationsObject
                };
            }

            // Call API to update the setting
            await updateGroupSetting(
                getAccessTokenSilently,
                selectedGroupId,
                'consent_attestations',
                settingsValue
            );

            setHasExistingSettings(true);
            setSuccessMessage('Consent attestation settings saved successfully.');

            // Clear success message after a delay
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        } catch (error) {
            console.error('Error saving consent attestation settings:', error);
            setErrorMessage(`Failed to save settings: ${error.message || 'Unknown error'}`);
        } finally {
            setIsSaving(false);
        }
    };

    // Delete settings
    const deleteSettings = async () => {
        if (!selectedGroupId) return;

        if (!window.confirm('Are you sure you want to delete these consent attestation settings? This action cannot be undone.')) {
            return;
        }

        try {
            setIsSaving(true);
            setErrorMessage('');
            setSuccessMessage('');

            // Call API to update the setting with null value
            await updateGroupSetting(
                getAccessTokenSilently,
                selectedGroupId,
                'consent_attestations',
                null
            );

            resetToEmpty();
            setSuccessMessage('Consent attestation settings deleted successfully.');

            // Clear success message after a delay
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        } catch (error) {
            console.error('Error deleting consent attestation settings:', error);
            setErrorMessage(`Failed to delete settings: ${error.message || 'Unknown error'}`);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900">Consent Attestation Management</h1>
                    <p className="mt-2 text-sm text-gray-600">
                        Configure consent attestation settings for specific groups in your organization.
                    </p>
                </div>

                {/* Success and Error Messages */}
                {successMessage && (
                    <div className="mb-4 p-4 bg-green-50 border border-green-200 rounded-lg">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-green-800">{successMessage}</p>
                            </div>
                        </div>
                    </div>
                )}

                {errorMessage && (
                    <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-red-800">{errorMessage}</p>
                            </div>
                        </div>
                    </div>
                )}

                {/* Group Selection */}
                <div className="bg-white rounded-lg shadow mb-8">
                    <div className="p-6">
                        <div className="mb-6">
                            <label htmlFor="group-select" className="block text-sm font-medium text-gray-700 mb-1">
                                Select Group
                            </label>
                            <div className="relative">
                                <select
                                    id="group-select"
                                    value={selectedGroupId || ''}
                                    onChange={handleGroupChange}
                                    disabled={isLoadingGroups || isLoadingSettings}
                                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                >
                                    <option value="" disabled>Select a group</option>
                                    {groups.map((group) => (
                                        <option key={group.group_id} value={group.group_id}>
                                            {group.displayName}
                                        </option>
                                    ))}
                                </select>
                                {isLoadingSettings && (
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-indigo-600"></div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Settings */}
                        {isLoadingGroups ? (
                            <div className="flex justify-center items-center py-8">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                            </div>
                        ) : groups.length === 0 ? (
                            <div className="text-center py-8 text-gray-500">
                                No groups found for this organization.
                            </div>
                        ) : selectedGroupId ? (
                            <div>
                                <h3 className="text-lg font-medium text-gray-900 mb-4">Consent Attestation Settings</h3>
                                {isLoadingSettings ? (
                                    <div className="flex justify-center items-center py-8">
                                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                                    </div>
                                ) : (
                                    <div>
                                        {!hasExistingSettings ? (
                                            <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                                                <p className="text-gray-700 mb-4">No consent attestation settings configured for this group.</p>
                                                <p className="text-gray-600 mb-4">Configure settings below to enable consent attestations.</p>
                                            </div>
                                        ) : null}

                                        {/* Attestation Type Selection */}
                                        <div className="mb-6">
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Attestation Type
                                            </label>
                                            <div className="space-y-2">
                                                <label className="flex items-center">
                                                    <input
                                                        type="radio"
                                                        name="attestationType"
                                                        value="automatic"
                                                        checked={attestationType === 'automatic'}
                                                        onChange={handleAttestationTypeChange}
                                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                                                    />
                                                    <span className="ml-2 text-gray-700">Automatic Attestation</span>
                                                    <span className="ml-2 text-xs text-gray-500">(Automatically adds text to notes)</span>
                                                </label>
                                                <label className="flex items-center">
                                                    <input
                                                        type="radio"
                                                        name="attestationType"
                                                        value="interactive"
                                                        checked={attestationType === 'interactive'}
                                                        onChange={handleAttestationTypeChange}
                                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
                                                    />
                                                    <span className="ml-2 text-gray-700">Interactive Attestation</span>
                                                    <span className="ml-2 text-xs text-gray-500">(Shows a modal with options)</span>
                                                </label>
                                            </div>
                                        </div>

                                        {/* Automatic Attestation Settings */}
                                        {attestationType === 'automatic' && (
                                            <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                                    Automatic Attestation Text
                                                </label>
                                                <textarea
                                                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                    rows="4"
                                                    value={automaticText}
                                                    onChange={(e) => setAutomaticText(e.target.value)}
                                                    placeholder="Enter the attestation text that will be automatically added to notes..."
                                                ></textarea>
                                            </div>
                                        )}

                                        {/* Interactive Attestation Settings */}
                                        {attestationType === 'interactive' && (
                                            <div className="mb-6">
                                                {/* Modal Settings */}
                                                <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                                                    <h4 className="text-md font-medium text-gray-900 mb-4">Modal Settings</h4>

                                                    <div className="mb-4">
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            Modal Title
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                            value={modalTitle}
                                                            onChange={(e) => setModalTitle(e.target.value)}
                                                            placeholder="Enter modal title..."
                                                        />
                                                    </div>

                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                            Modal Description
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                            value={modalDescription}
                                                            onChange={(e) => setModalDescription(e.target.value)}
                                                            placeholder="Enter modal description..."
                                                        />
                                                    </div>
                                                </div>

                                                {/* Attestation Options */}
                                                <div className="mb-4">
                                                    <div className="flex justify-between items-center mb-4">
                                                        <h4 className="text-md font-medium text-gray-900">Attestation Options</h4>
                                                        <button
                                                            type="button"
                                                            onClick={addAttestationOption}
                                                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        >
                                                            <PlusIcon className="h-4 w-4 mr-1" />
                                                            Add Option
                                                        </button>
                                                    </div>

                                                    {attestations.length === 0 ? (
                                                        <div className="text-center py-6 bg-gray-50 rounded-lg">
                                                            <p className="text-gray-500">No attestation options added yet.</p>
                                                            <p className="text-gray-500 text-sm mt-1">Click "Add Option" to create your first attestation option.</p>
                                                        </div>
                                                    ) : (
                                                        <div className="space-y-4">
                                                            {attestations.map((attestation, index) => (
                                                                <div
                                                                    key={attestation.id}
                                                                    className="border border-gray-200 rounded-md p-4 bg-white"
                                                                >
                                                                    <div className="flex justify-between mb-3">
                                                                        <div className="flex items-center space-x-2">
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => moveAttestationUp(index)}
                                                                                disabled={index === 0}
                                                                                className={`p-1 rounded ${index === 0 ? 'text-gray-300' : 'text-gray-500 hover:bg-gray-100'}`}
                                                                            >
                                                                                <ArrowUpIcon className="h-4 w-4" />
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => moveAttestationDown(index)}
                                                                                disabled={index === attestations.length - 1}
                                                                                className={`p-1 rounded ${index === attestations.length - 1 ? 'text-gray-300' : 'text-gray-500 hover:bg-gray-100'}`}
                                                                            >
                                                                                <ArrowDownIcon className="h-4 w-4" />
                                                                            </button>
                                                                            <span className="text-sm text-gray-500">Position: {index + 1}</span>
                                                                        </div>
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeAttestationOption(attestation.id)}
                                                                            className="text-red-600 hover:text-red-800"
                                                                        >
                                                                            <TrashIcon className="h-5 w-5" />
                                                                        </button>
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                            Label
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                                            value={attestation.label}
                                                                            onChange={(e) => updateAttestationField(attestation.id, 'label', e.target.value)}
                                                                            placeholder="Enter label..."
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                                                            Attestation Text
                                                                        </label>
                                                                        <textarea
                                                                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                                            rows="3"
                                                                            value={attestation.text}
                                                                            onChange={(e) => updateAttestationField(attestation.id, 'text', e.target.value)}
                                                                            placeholder="Enter attestation text..."
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {/* Action Buttons */}
                                        <div className="flex justify-end space-x-3">
                                            {hasExistingSettings && (
                                                <button
                                                    type="button"
                                                    onClick={deleteSettings}
                                                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                    disabled={isSaving}
                                                >
                                                    Delete Settings
                                                </button>
                                            )}
                                            <button
                                                type="button"
                                                onClick={saveSettings}
                                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                disabled={isSaving}
                                            >
                                                {isSaving ? (
                                                    <>
                                                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                                                        Saving...
                                                    </>
                                                ) : 'Save Settings'}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="text-center py-8 text-gray-500">
                                Please select a group to manage consent attestation settings.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizationPortalConsentAttestations; 