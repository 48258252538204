import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../contexts/AuthProvider';
import { format } from 'date-fns';
import {
    DocumentArrowDownIcon,
    CalendarIcon,
    ChartBarIcon,
    UserGroupIcon
} from '@heroicons/react/24/outline';
import { organizationPortalApi } from '../api/organizationPortalApi';
import { useLocation } from 'react-router-dom';

const OrganizationPortalUsageReporting = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo } = useAuth();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: format(new Date(new Date().setDate(new Date().getDate() - 30)), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd')
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [allTime, setAllTime] = useState(false);
    const [loadingReportId, setLoadingReportId] = useState(null);

    // Get organization ID from location state if provided (for global admin viewing other orgs)
    const isGlobalAdminView = location.state?.isGlobalAdminView || false;
    const orgId = isGlobalAdminView ? location.state?.orgId : actorInfo?.org_id;

    // Report types with descriptions
    const reportTypes = [
        {
            id: 'user-activity',
            name: 'User Activity Report',
            description: 'Shows document creation activity for each user, including email, full name, creation date, last modification date, and whether any part of the document was copied',
            icon: <ChartBarIcon className="h-8 w-8 text-blue-500" />,
            requiresDateRange: true
        },
        {
            id: 'users-list',
            name: 'Users List Report',
            description: 'Comprehensive list of all users with their details including specialty, status, first and last patient seen times',
            icon: <UserGroupIcon className="h-8 w-8 text-green-500" />,
            requiresDateRange: false
        }
    ];

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDateRange(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const toggleAllTime = () => {
        setAllTime(!allTime);
    };

    const downloadReport = async (report) => {
        setLoadingReportId(report.id);
        setSuccessMessage('');
        setErrorMessage('');

        try {
            if (report.id === 'user-activity') {
                // Call the API to download the user activity report
                await organizationPortalApi.getUsageData(
                    getAccessTokenSilently,
                    orgId,
                    dateRange.startDate,
                    dateRange.endDate,
                    allTime
                );
            } else if (report.id === 'users-list') {
                // Call the API to download the users list report
                await organizationPortalApi.downloadUsersListReport(
                    getAccessTokenSilently,
                    orgId
                );
            }

            // Show success message
            setSuccessMessage(`${report.name} successfully downloaded`);

            // Clear success message after 3 seconds
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        } catch (error) {
            console.error(`Error downloading ${report.name}:`, error);
            setErrorMessage(`Failed to download ${report.name}. Please try again.`);

            // Clear error message after 5 seconds
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        } finally {
            setLoadingReportId(null);
        }
    };

    if (!actorInfo) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900">Usage Reporting</h1>
                    <p className="mt-2 text-sm text-gray-600">
                        Download reports about user activity and document usage within your organization.
                    </p>
                </div>

                {/* Date Range Selector - Only shown for reports that need it */}
                {reportTypes.some(report => report.requiresDateRange) && (
                    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl mb-8">
                        <div className="px-4 py-6 sm:p-8">
                            <h3 className="text-lg font-medium text-gray-900 mb-4">Date Range Options</h3>
                            <p className="text-sm text-gray-600 mb-4">
                                Select a date range for reports that require it. This applies only to the User Activity Report.
                            </p>
                            <div className="flex flex-col sm:flex-row gap-4 items-end">
                                <div className="w-full sm:w-1/3">
                                    <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                                        Start Date
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <CalendarIcon className="h-5 w-5 text-gray-400" />
                                        </div>
                                        <input
                                            type="date"
                                            id="startDate"
                                            name="startDate"
                                            className={`block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${allTime ? 'opacity-50' : ''}`}
                                            value={dateRange.startDate}
                                            onChange={handleDateChange}
                                            disabled={allTime}
                                        />
                                    </div>
                                </div>
                                <div className="w-full sm:w-1/3">
                                    <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                                        End Date
                                    </label>
                                    <div className="relative">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <CalendarIcon className="h-5 w-5 text-gray-400" />
                                        </div>
                                        <input
                                            type="date"
                                            id="endDate"
                                            name="endDate"
                                            className={`block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${allTime ? 'opacity-50' : ''}`}
                                            value={dateRange.endDate}
                                            onChange={handleDateChange}
                                            min={dateRange.startDate}
                                            disabled={allTime}
                                        />
                                    </div>
                                </div>
                                <div className="w-full sm:w-1/3 flex items-center pt-6">
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="sr-only peer"
                                            checked={allTime}
                                            onChange={toggleAllTime}
                                        />
                                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">All time</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Success/Error Messages */}
                {successMessage && (
                    <div className="mb-6 bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded relative">
                        {successMessage}
                    </div>
                )}

                {errorMessage && (
                    <div className="mb-6 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
                        {errorMessage}
                    </div>
                )}

                {/* Report Types */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {reportTypes.map((report) => (
                        <div
                            key={report.id}
                            className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl overflow-hidden hover:shadow-md transition-shadow"
                        >
                            <div className="p-6">
                                <div className="flex items-center mb-4">
                                    {report.icon}
                                    <h3 className="ml-3 text-lg font-semibold text-gray-900">{report.name}</h3>
                                </div>
                                <p className="text-sm text-gray-600 mb-6">{report.description}</p>
                                <button
                                    onClick={() => downloadReport(report)}
                                    disabled={loadingReportId !== null}
                                    className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    {loadingReportId === report.id ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Processing...
                                        </>
                                    ) : (
                                        <>
                                            <DocumentArrowDownIcon className="h-5 w-5 mr-2" />
                                            Download Report
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default OrganizationPortalUsageReporting; 