import { protectedResources, callProtectedApi } from '../authConfig';

export const saveCustomMdmTemplate = async (getAccessTokenSilently, selectedGroupId, templateContent) => {
  try {
    return await callProtectedApi(
      getAccessTokenSilently,
      protectedResources.apiSetCustomMdmTemplate.endpoint,
      {
        method: 'POST',
        body: JSON.stringify({
          group_id: parseInt(selectedGroupId, 10),
          template_content: templateContent,
        })
      }
    );
  } catch (error) {
    console.error('saveCustomMdmTemplate error:', error);
    throw error;
  }
};
