import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../contexts/AuthProvider';
import { protectedResources, callProtectedApi } from '../authConfig';
import { Switch } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function GenericSettings({ 
    settingName,
    title,
    description,
    type = 'text',
    options = [],
    onSuccessfulSave,
    validate = (value) => ({ isValid: true, message: '' }),
    inputProps = {},
    convertFrom, // Function to convert from API value to display value
    convertTo,   // Function to convert from display value to API value
    lockedMessage = "This setting has been locked by your organization."
}) {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo, isLoading: isActorLoading } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [value, setValue] = useState('');
    const [userCanEdit, setUserCanEdit] = useState(false);
    const [error, setError] = useState(null);
    const [settingSource, setSettingSource] = useState(null);

    useEffect(() => {
        fetchSetting();
    }, [settingName]);

    const fetchSetting = async () => {
        try {
            const response = await callProtectedApi(
                getAccessTokenSilently,
                `${protectedResources.apiSettings.endpoint}${settingName}`,
                { method: 'GET' }
            );
            
            if (response.Setting) {
                // Convert the value if a conversion function is provided
                const displayValue = convertFrom ? convertFrom(response.Setting.value) : response.Setting.value;
                setValue(displayValue);
                setUserCanEdit(response.actor_can_edit);
                setSettingSource(response.Setting.source || 'Default');
            }
        } catch (error) {
            console.error('Error fetching setting:', error);
            setError('Failed to load setting');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        const validation = validate(value);
        if (!validation.isValid) {
            setError(validation.message);
            return;
        }

        setIsSaving(true);
        setError(null);

        try {
            // Convert the value back if a conversion function is provided
            const apiValue = convertTo ? convertTo(value) : value;
            
            await callProtectedApi(
                getAccessTokenSilently,
                `${protectedResources.apiSettings.endpoint}${settingName}`,
                {
                    method: 'POST',
                    body: JSON.stringify({ value: apiValue }),
                }
            );
            onSuccessfulSave && onSuccessfulSave(`${title} saved successfully`);
        } catch (error) {
            console.error('Error saving setting:', error);
            setError('Failed to save setting');
        } finally {
            setIsSaving(false);
        }
    };

    const renderInput = () => {
        const commonProps = {
            disabled: !userCanEdit,
            ...inputProps
        };

        switch (type) {
            case 'boolean':
                return (
                    <Switch
                        checked={value === true}
                        onChange={(checked) => setValue(checked)}
                        disabled={!userCanEdit}
                        className={classNames(
                            value ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 items-center rounded-full'
                        )}
                    >
                        <span
                            className={classNames(
                                value ? 'translate-x-6' : 'translate-x-1',
                                'inline-block h-4 w-4 transform rounded-full bg-white transition-transform'
                            )}
                        />
                    </Switch>
                );
            case 'select':
                return (
                    <select
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className={classNames(
                            "mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                            !userCanEdit && 'bg-gray-50 cursor-not-allowed',
                            inputProps.className
                        )}
                        {...commonProps}
                    >
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                );
            case 'number':
                return (
                    <div className="mt-2 flex items-center gap-x-3">
                        <input
                            type="number"
                            value={value}
                            onChange={(e) => setValue(Number(e.target.value))}
                            className={classNames(
                                "block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                !userCanEdit && 'bg-gray-50 cursor-not-allowed',
                                inputProps.className || 'w-full'
                            )}
                            {...commonProps}
                        />
                        {!userCanEdit && (
                            <span className="text-sm text-gray-400">
                                {lockedMessage}
                            </span>
                        )}
                    </div>
                );
            case 'textarea':
                return (
                    <textarea
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        rows={4}
                        className={classNames(
                            "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                            !userCanEdit && 'bg-gray-50 cursor-not-allowed',
                            inputProps.className
                        )}
                        {...commonProps}
                    />
                );
            default:
                return (
                    <input
                        type="text"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className={classNames(
                            "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                            !userCanEdit && 'bg-gray-50 cursor-not-allowed',
                            inputProps.className
                        )}
                        {...commonProps}
                    />
                );
        }
    };

    if (isLoading || isActorLoading) {
        return (
            <div className="flex justify-center items-center h-24">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
            </div>
        );
    }

    return (
        <div className="bg-white shadow-sm sm:rounded-xl p-6">
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div>
                        <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
                        {description && (
                            <p className="text-sm text-gray-500 mt-1">{description}</p>
                        )}
                    </div>
                    {error && (
                        <div className="text-sm text-red-600 flex items-center">
                            <InformationCircleIcon className="h-5 w-5 mr-1" />
                            {error}
                        </div>
                    )}
                </div>

                <div className="space-y-4">
                    {renderInput()}
                    
                    {userCanEdit && (
                        <div className="flex justify-end">
                            <button
                                onClick={handleSave}
                                disabled={isSaving}
                                className={classNames(
                                    'inline-flex items-center rounded-md px-4 py-2 text-sm font-semibold shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600',
                                    isSaving ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-indigo-800 text-white hover:bg-indigo-900'
                                )}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
} 