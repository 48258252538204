import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave, faEdit, faChartLine, faCopy, faCheck,
    faArrowCircleLeft, faQuestionCircle, faFileCode,
    faCalculator, faChevronLeft, faChevronRight,
    faInfoCircle, faExclamationTriangle, faExclamationCircle, faArchive,
    faSpinner, faChevronUp, faChevronDown, faSync, faUndo, faMicrophone,
    faThumbsUp, faThumbsDown, faMeh
} from '@fortawesome/free-solid-svg-icons'

import { Fieldset } from '../components/catalyst/fieldset';
import { Text, Strong } from '../components/catalyst/text';
//import { Textarea } from '../components/catalyst/textarea';
import { Button } from '../components/catalyst/button';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/catalyst/dialog';
import '../styles/ViewReports.css';

import {
    CheckCircleIcon, XMarkIcon, XCircleIcon, ExclamationCircleIcon,
    ShieldCheckIcon, InformationCircleIcon, ExclamationTriangleIcon, ChatBubbleLeftIcon, CalculatorIcon
} from '@heroicons/react/20/solid';

import { useFeatureFlagEnabled } from 'posthog-js/react'

import TextareaAutosize from 'react-textarea-autosize';
import { PlusIcon } from '@heroicons/react/20/solid';

import ProgressBar from '../components/PatientViewer/ProgressBars';

import recordingManager from '../contexts/RecordingManager';

import TipTapEditor from '../components/TipTap';

import { htmlToText } from 'html-to-text';

import { protectedResources, callProtectedApi } from '../authConfig';

function HospitalistChart() {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [hpi, setHPI] = useState('');
    const [ros, setROS] = useState('');
    const [exam, setExam] = useState('');
    const [reeval, setReeval] = useState('');
    const [mdm, setMDM] = useState('');
    const [riskAnalysis, setRiskAnalysis] = useState('');
    const [riskContent, setRiskContent] = useState([]);
    const [copa, setCopa] = useState(-1);
    const [complexityOfData, setComplexityOfData] = useState(-1);
    const [billingCode, setBillingCode] = useState('Not available');
    const [riskOfComplications, setRiskOfComplications] = useState(-1);
    const [riskScoreLabel, setRiskScoreLabel] = useState('');
    const [currentRiskScoreKey, setCurrentRiskScoreKey] = useState("");
    const [showRiskScoreDropdown, setShowRiskScoreDropdown] = useState(false);
    const [showMacroDropdown, setShowMacroDropdown] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading Notes...');
    const [patientName, setPatientName] = useState('');
    const location = useLocation();
    const chart = location.state?.document;
    const [documentStatus, setDocumentStatus] = useState(chart.document_status);
    const [mdmStatus, setMDMStatus] = useState(chart.mdm_status);
    const [procedureDescription, setProcedureDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMDM, setIsLoadingMDM] = useState(false);
    const [copiedHPI, setCopiedHPI] = useState(false);
    const [copiedROS, setCopiedROS] = useState(false);
    const [copiedExam, setCopiedExam] = useState(false);
    const [copiedReeval, setCopiedReeval] = useState(false);
    const [copiedMDM, setCopiedMDM] = useState(false);
    const [copiedAll, setCopiedAll] = useState(false);
    const [isEditingPatientName, setIsEditingPatientName] = useState(false);
    const [isEditingProcedureDescription, setIsEditingProcedureDescription] = useState(false);
    const [riskScoreNotificationVisible, setRiskScoreNotificationVisible] = useState(false);
    const [riskAnalysisNotificationVisible, setRiskAnalysisNotificationVisible] = useState(false);
    const [canShowRiskAnalysisNotification, setCanShowRiskAnalysisNotification] = useState(false);
    const [mdmCodingNotificationVisible, setMDMCodingNotificationVisible] = useState(false);
    const [riskScoreText, setRiskScoreText] = useState('');
    const providedDate = location.state?.providedDate;
    const navigate = useNavigate();
    const [editedDocument, setEditedDocument] = useState(chart);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
    const [showNotification, setShowNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [riskScore, setRiskScore] = useState(false);
    const [saveTriggered, setSaveTriggered] = useState(false);
    const [macro_list, setMacroList] = useState({});
    const riskScoreDropdownRef = useRef(null);
    const macroDropdownRef = useRef(null);
    const [noteFeedbackNotificationVisible, setNoteFeedbackNotificationVisible] = useState(!chart.note_rating);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const isRiskAnalystEnabled = useFeatureFlagEnabled('risk_analyst');
    const isMDMRecodeEnabled = useFeatureFlagEnabled('mdm_recode');
    const [isFeedbackVisible, setIsFeedbackVisible] = useState(chart?.note_rating === null ? true : false);
    const [noteRating, setNoteRating] = useState(null);
    const [noteFeedback, setNoteFeedback] = useState('');
    const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
    const [hasProvidedRating, setHasProvidedRating] = useState(false);
    const [macroSearchQuery, setMacroSearchQuery] = useState('');
    const [originalMDM, setOriginalMDM] = useState(mdm);
    const [cleanedMDM, setCleanedMDM] = useState(mdm);
    const [isRemoved, setIsRemoved] = useState(false);
    const [icd10Codes, setICD10Codes] = useState('');
    const [copiedICD10, setCopiedICD10] = useState(false);
    const [isLoadingICD10, setIsLoadingICD10] = useState(false);
    const [subjective, setSubjective] = useState('');
    const [objective, setObjective] = useState('');
    const [assessmentAndPlan, setAssessmentAndPlan] = useState('');
    const [dischargeSummary, setDischargeSummary] = useState('');
    const [externalNote, setExternalNote] = useState('');
    const [copiedSubjective, setCopiedSubjective] = useState(false);
    const [copiedObjective, setCopiedObjective] = useState(false);
    const [copiedAssessmentAndPlan, setCopiedAssessmentAndPlan] = useState(false);
    const [copiedDischargeSummary, setCopiedDischargeSummary] = useState(false);
    const [copiedExternalNote, setCopiedExternalNote] = useState(false);
    const [currentRiskScoreId, setCurrentRiskScoreId] = useState(null);
    const [riskScores, setRiskScores] = useState([]);

    // State variables for macro dropdown visibility
    const [showHpiMacroDropdown, setShowHpiMacroDropdown] = useState(false);
    const [showRosMacroDropdown, setShowRosMacroDropdown] = useState(false);
    const [showExamMacroDropdown, setShowExamMacroDropdown] = useState(false);
    const [showReevalMacroDropdown, setShowReevalMacroDropdown] = useState(false);
    const [showMDMMacrosDropdown, setShowMDMMacrosDropdown] = useState(false);

    const hpiMacroDropdownRef = useRef(null);
    const rosMacroDropdownRef = useRef(null);
    const examMacroDropdownRef = useRef(null);
    const reevalMacroDropdownRef = useRef(null);
    const mdmMacrosDropdownRef = useRef(null);

    const isCustomMDMEnabled = useFeatureFlagEnabled('custom_hospitalist_mdm');
    const isUSACSAttestationEnabled = useFeatureFlagEnabled('usacs_attestation');
    const USACS_ATTESTATION = "This note was generated using Cleo (ambient AI) software. If any parties were recorded to generate this note outside of the software user, their consent was obtained prior to recording their voices. All portions of this note were verified by the clinician prior to inclusion in the medical record.";

    const [sectionLoadingStates, setSectionLoadingStates] = useState({
        hpi: false,
        ros: false,
        physical_exam: false,
        reevaluation: false,
        mdm: false,
        subjective: false,
        objective: false,
        assessment_and_plan: false,
        hospitalist_discharge_summary: false,
        hospitalist_external_note: false,
    });

    const [isRecording, setIsRecording] = useState({
        hpi: false,
        ros: false,
        mdm: false,
        physical_exam: false,
        reevaluation: false,
        subjective: false,
        objective: false,
        assessment_and_plan: false,
        hospitalist_discharge_summary: false,
        hospitalist_external_note: false,
    });

    const [currentRecordingSection, setCurrentRecordingSection] = useState(null);

    const startRecording = (section) => {

        const startTime = Date.now();
        const onTranscriptionComplete = async (section, transcription) => {

            const endTime = Date.now();
            const transcriptionTime = Math.floor((endTime - startTime) / 1000);
            await sendSmartChartTranscription(chart.document_id, section, transcription, transcriptionTime);
        };

        recordingManager.startRecording(
            section,
            process.env.REACT_APP_AZURE_SPEECH_KEY,
            process.env.REACT_APP_AZURE_SPEECH_REGION,
            onTranscriptionComplete
        );

        // Update local state to reflect recording status if needed
        setIsRecording((prevState) => ({ ...prevState, [section]: true }));
        setCurrentRecordingSection(section);
    };

    const stopRecording = (section) => {
        recordingManager.stopRecording(section);

        // Update local state to reflect recording status if needed
        setIsRecording((prevState) => ({ ...prevState, [section]: false }));
        setCurrentRecordingSection(null);
    };

    const sendSmartChartTranscription = async (documentId, section, transcriptionToSend, transcriptionTime) => {
        if (!transcriptionToSend) {
            console.warn('No transcription to send.');
            setSectionLoadingStates((prevState) => ({ ...prevState, [section]: false }));
            return;
        }

        setSectionLoadingStates((prevState) => ({ ...prevState, [section]: true }));

        const timeZoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const payload = {
            document_id: documentId,
            section_name: section,
            transcript: transcriptionToSend,
            transcription_time: transcriptionTime,
            time_zone: timeZoneIdentifier
        };

        try {
            const token = await getAccessTokenSilently();

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            };

            const fetchResponse = await fetch(protectedResources.apiSendSmartEditTranscriptionHospitalist.endpoint, requestOptions);
            const data = await fetchResponse.json();

            if (data.updated_section) {
                updateSection(section, data.updated_section);
            } else {
                console.error('No updated section found in the response');
            }
        } catch (error) {
            console.error('Error sending transcription:', error);
            // Optionally, inform the user of the error
        } finally {
            setSectionLoadingStates((prevState) => ({ ...prevState, [section]: false }));
        }
    };


    const getMacrosForSection = (section) => {
        // Add null check and ensure macro_list is an array
        if (!macro_list || !Array.isArray(macro_list)) {
            console.warn('macro_list is not an array:', macro_list);
            return [];
        }
        return macro_list.filter((macro) => macro.section === section);
    };



    const updateSubjective = (value) => {
        setSubjective(value);
        // Additional logic if needed
    };
    const updateObjective = (value) => {
        setObjective(value);
        // Additional logic if needed
    };
    const updateAssessmentAndPlan = (value) => {
        setAssessmentAndPlan(value);
        // Additional logic if needed
    };
    const updateDischargeSummary = (value) => {
        setDischargeSummary(value);
        // Additional logic if needed
    };
    const updateExternalNote = (value) => {
        setExternalNote(value);
        // Additional logic if needed
    };


    const generateICD10Codes = async () => {
        setIsLoadingICD10(true);
        try {
            const token = await getAccessTokenSilently();

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiGenerateICD10.endpoint, requestOptions);

            const data = await fetchResponse.json();
            updateICD10Codes(data.code_suggestions);

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error generating ICD-10 codes:', error);
            // Handle any errors here
        }
        setIsLoadingICD10(false);
    };

    const generateMDM = async () => {
        setLoadingMessage("Generating MDM...");
        setMDMStatus("IN_PROGRESS");
        try {

            const token = await getAccessTokenSilently();

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ "document_id": chart.document_id })
            };

            const fetchResponse = await fetch(protectedResources.apiCalculateMDM.endpoint, requestOptions);

            const data = await fetchResponse.json();
            setMDM(data.mdm_content);
            parseMDMCoding(data.mdm_coding);

            // Consider fetching differente stuff
            setMDMStatus("GENERATED");
            setIsRemoved(false);
            setMDMCodingNotificationVisible(true);

            // await fetchDocument();
            // await fetchAuditChartMessages();
            // await fetchRiskMessages();


        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error calculating MDM:', error);
            setMDMStatus("ERROR");
            // Handle any errors here
        }
    };

    const fetchRiskScores = async () => {
        try {
            const token = await getAccessTokenSilently();

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            };

            const fetchResponse = await fetch(protectedResources.apiFetchRiskScores.endpoint, requestOptions);
            const data = await fetchResponse.json();
            setRiskScores(data.risk_scores);
        } catch (error) {
            console.error('Error fetching risk scores:', error);
        }
    };

    useEffect(() => {
        try {
            const riskAnalysisObj = JSON.parse(riskAnalysis);
            const contentArray = Object.keys(riskAnalysisObj).map(key => riskAnalysisObj[key]);
            setRiskContent(contentArray);
        } catch (error) {
            console.error('Invalid JSON string:', error);
        }
    }, [riskAnalysis]);

    const handleRiskNotificationClose = () => {
        setRiskAnalysisNotificationVisible(false);
    };

    const handleMDMNotificationClose = () => {
        setMDMCodingNotificationVisible(false);
    };

    const renderRiskContent = () => {
        if (!Array.isArray(riskContent)) return null;

        return (
            <>
                {riskContent.map((item, index) => (
                    <div key={index} className="py-4 px-2 rounded-lg shadow-md mb-4">
                        <div className="flex items-center">
                            {getClassStyle(item.class)}
                            <p className={`text-sm ml-2 ${item.class === 'warning' ? 'font-bold' : ''}`}>{item.msg}</p>
                        </div>
                    </div>
                ))}
            </>
        );
    };

    const getClassStyle = (type) => {
        switch (type) {
            case 'info':
                return <FontAwesomeIcon icon={faInfoCircle} className="ml-0 p-0 h-6 w-6 text-blue-800" />;
            case 'caution':
                return <FontAwesomeIcon icon={faExclamationTriangle} className="ml-0 p-0 h-6 w-6 text-yellow-800" />;
            case 'warning':
                return <FontAwesomeIcon icon={faExclamationCircle} className="ml-0 p-0 h-6 w-6 text-red-800" />;
            default:
                return null;
        }
    };


    const handleRiskScoreChange = (field, value) => {
        setRiskScore((prevScores) => {
            // Check if the field is being unselected (the new value is the same as the current value)
            const isUnselecting = prevScores[field] === value;

            return {
                ...prevScores,
                // If unselecting, set the field's value to null, otherwise, update it with the new value
                [field]: isUnselecting ? null : value,
            };
        });
    };

    const insertSelectedMacro = async (macro) => {
        const macroSection = macro.section;
        console.log("macro: ", macro);
        const sectionMapping = {
            "hpi": hpi,
            "ros": ros,
            "physical_exam": exam,
            "reevaluation": reeval
        }
        console.log(macroSection);
        const sectionToUpdate = sectionMapping[macroSection];
        const updatedSection = sectionToUpdate ? sectionToUpdate + "\n\n" + macro.content : macro.content;
        console.log("macro section", macroSection);
        const setterMapping = {
            "hpi": setHPI,
            "ros": setROS,
            "physical_exam": setExam,
            "reevaluation": setReeval
        };
        // Update the section with the new macro content
        setterMapping[macroSection](updatedSection);
        // Save the updated section to the database
        saveDocument(macroSection, updatedSection);

        // Update macro_manually_inserted flag in backend
        try {
            const token = await getAccessTokenSilently();

            // Prepare the API call
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: chart.document_id,
                    field_to_update: 'macro_manually_inserted',
                    new_value: true
                })
            };

            // Make the API call to update the macro_manually_inserted flag
            await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);

        } catch (error) {
            console.error('Error updating macro_manually_inserted flag:', error);
            // Handle any errors here
        }
    };


    const handleTokenExpiration = useCallback((error) => {
        if (error?.error === 'login_required' || error?.error === 'consent_required') {
            // Redirect to login if token is expired
        } else {
            console.error('Error:', error);
        }
    }, []);

    const updateSection = (sectionName, sectionContent) => {
        switch (sectionName) {
            case 'hpi':
                setHPI(sectionContent);
                setEditedDocument(prev => ({ ...prev, hpi: sectionContent }));
                break;
            case 'ros':
                setROS(sectionContent);
                setEditedDocument(prev => ({ ...prev, ros: sectionContent }));
                break;
            case 'physical_exam':
                setExam(sectionContent);
                setEditedDocument(prev => ({ ...prev, physical_exam: sectionContent }));
                break;
            case 'reevaluation':
                setReeval(sectionContent);
                setEditedDocument(prev => ({ ...prev, reevaluation: sectionContent }));
                break;
            case 'mdm':
                setMDM(sectionContent);
                setEditedDocument(prev => ({ ...prev, mdm: sectionContent }));
                break;
            case 'subjective':
                setSubjective(sectionContent);
                setEditedDocument(prev => ({ ...prev, subjective: sectionContent }));
                break;
            case 'objective':
                setObjective(sectionContent);
                setEditedDocument(prev => ({ ...prev, objective: sectionContent }));
                break;
            case 'assessment_and_plan':
                setAssessmentAndPlan(sectionContent);
                setEditedDocument(prev => ({ ...prev, assessment_and_plan: sectionContent }));
                break;
            case 'hospitalist_discharge_summary':
                setDischargeSummary(sectionContent);
                setEditedDocument(prev => ({ ...prev, hospitalist_discharge_summary: sectionContent }));
                break;
            case 'hospitalist_external_note':
                setExternalNote(sectionContent);
                setEditedDocument(prev => ({ ...prev, hospitalist_external_note: sectionContent }));
                break;
        }
    };

    const updateHPI = (newHPI) => {
        setHPI(newHPI);
        setEditedDocument(prev => ({ ...prev, hpi: newHPI }));
    };

    const updateROS = (newROS) => {
        setROS(newROS);
        setEditedDocument(prev => ({ ...prev, ros: newROS }));
    };

    const updateExam = (newExam) => {
        setExam(newExam);
        setEditedDocument(prev => ({ ...prev, physical_exam: newExam }));
    };

    const updateReeval = (newReeval) => {
        setReeval(newReeval);
        setEditedDocument(prev => ({ ...prev, reevaluation: newReeval }));
    };

    const updateMDM = (sectionName,newMDM) => {
        setMDM(newMDM);
        setEditedDocument(prev => ({ ...prev, mdm: newMDM }));
    };

    const updateProcedureDescription = (newProcedureDescription) => {
        setProcedureDescription(newProcedureDescription);
        setEditedDocument(prev => ({ ...prev, procedure_description: newProcedureDescription }));
    };

    const updatePatientName = (newPatientName) => {
        setPatientName(newPatientName);
        setEditedDocument(prev => ({ ...prev, patient_name: newPatientName }));
    };

    const updateICD10Codes = (newICD10Codes) => {
        setICD10Codes(newICD10Codes);
        setEditedDocument(prev => ({ ...prev, icd10_codes: newICD10Codes }));
    };

    const saveDocument = async (fieldToSave, newValue) => {
        let hasChanges = false;

        if (newValue === undefined) {
            switch (fieldToSave) {
                case 'hpi':
                    hasChanges = chart.hpi !== hpi;
                    newValue = hpi;
                    break;
                case 'ros':
                    hasChanges = chart.ros !== ros;
                    newValue = ros;
                    break;
                case 'physical_exam':
                    hasChanges = chart.physical_exam !== exam;
                    newValue = exam;
                    break;
                case 'reeval':
                    hasChanges = chart.reevaluation !== reeval;
                    newValue = reeval;
                    break;
                case 'mdm':
                    hasChanges = chart.mdm !== mdm;
                    newValue = mdm;
                    break;
                case 'procedure_description':
                    hasChanges = chart.procedure_description !== procedureDescription;
                    newValue = procedureDescription;
                    break;
                case 'patient_name':
                    hasChanges = chart.patient_name !== patientName;
                    newValue = patientName;
                    break;
                case 'note_feedback':
                    hasChanges = chart.note_feedback !== noteFeedback;
                    newValue = noteFeedback;
                    break;
                case 'code':
                    hasChanges = chart.icd10_codes !== icd10Codes;
                    newValue = icd10Codes;
                    break;
                case 'hospitalist_discharge_summary':
                    hasChanges = chart.hospitalist_discharge_summary !== dischargeSummary;
                    newValue = dischargeSummary;
                    break;
                case 'hospitalist_external_note':
                    hasChanges = chart.hospitalist_external_note !== externalNote;
                    newValue = externalNote;
                    break;
                case 'subjective':
                    hasChanges = chart.subjective !== subjective;
                    newValue = subjective;
                    break;
                case 'objective':
                    hasChanges = chart.objective !== objective;
                    newValue = objective;
                    break;
                case 'assessment_and_plan':
                    hasChanges = chart.assessment_and_plan !== assessmentAndPlan;
                    newValue = assessmentAndPlan;
                    break;
                default:
                    console.log('Invalid field to save');
                    return;
            }
        } else {
            // Directly check changes against the document's existing value
            hasChanges = chart[fieldToSave] !== newValue;
        }

        if (!hasChanges) {
            console.log("No changes to save.");
            return;
        }

        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiUpdateDocument.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        document_id: chart.document_id,
                        field_to_update: fieldToSave,
                        new_value: newValue
                    })
                }
            );

            if (data.updated_document) {
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 3000);
            }
        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            setErrorMessage("An error occurred while updating the document.");
            setShowErrorNotification(true);
            setTimeout(() => setShowErrorNotification(false), 5000);
        }
    };

    const parseMDMCoding = (mdmCoding) => {
        // Initialize default values  
        let copa = -1;
        let complexityOfData = -1;
        let riskOfComplications = -1;
        let billingCode = 'Not available';

        // Split the mdmCoding string by commas to get individual parts  
        const parts = mdmCoding.split(', ');

        // Parse each part to extract the values  
        parts.forEach(part => {
            const [key, value] = part.split(': ');
            if (key && value) {
                const trimmedKey = key.trim();
                const trimmedValue = value.trim();
                if (trimmedKey === 'copa') {
                    copa = parseInt(trimmedValue);
                } else if (trimmedKey === 'complexity_of_data') {
                    complexityOfData = parseInt(trimmedValue);
                } else if (trimmedKey === 'risk_of_complications') {
                    riskOfComplications = parseInt(trimmedValue);
                } else if (trimmedKey === 'billing_code') {
                    billingCode = trimmedValue;
                }
            }
        });

        // Update state variables  
        setCopa(copa);
        setComplexityOfData(complexityOfData);
        setRiskOfComplications(riskOfComplications);
        setBillingCode(billingCode);
    };


    const toggleArchiveStatus = async () => {
        try {
            const token = await getAccessTokenSilently();

            const newStatus = chart.document_status === "FINALIZED" ? "ENCOUNTER_COMPLETE" : "FINALIZED";

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    "document_id": chart.document_id,
                    "field_to_update": "document_status",
                    "new_value": newStatus
                })
            };

            const fetchResponse = await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);

            if (fetchResponse.ok) {
                // Update the local state to reflect the change
                setDocumentStatus(newStatus);

                // If we're archiving (setting to FINALIZED), navigate back to ER dashboard
                if (newStatus === "FINALIZED") {
                    navigate('/hospitalist-inpatient-dashboard', { state: { providedDate: providedDate } });
                } else {
                    // If we're unarchiving, show a success message (optional)
                    console.log('Document unarchived successfully');
                    // You could also set a state to show a success message in the UI
                }
            } else {
                // Handle error
                console.error('Failed to update document status');
                // You might want to show an error message here
            }

        } catch (error) {
            handleTokenExpiration(error);
            console.error('Error updating document:', error);
            // Handle any errors here
        }
    };

    // RISK SCORE NOTIFICATION
    const RenderRiskScoreNotification = ({ riskScoreId }) => {
        const [currentRiskScoreState, setCurrentRiskScoreState] = useState([]);

        // Get the selected risk score based on the ID
        const currentRiskScore = riskScores.find(score => score.risk_score_id === riskScoreId);

        useEffect(() => {
            if (currentRiskScore) {
                // Initialize the risk score state with component names and initial null values
                const initialState = currentRiskScore.components.map(component => ({
                    component_name: component.component_name,
                    component_value: null // Set to null initially to indicate no selection
                }));
                setCurrentRiskScoreState(initialState);
            }
        }, [currentRiskScoreId, currentRiskScore]);

        // Handle changes in risk score options
        const handleRiskScoreChange = (componentName, value) => {
            setCurrentRiskScoreState(prevState =>
                prevState.map(item =>
                    item.component_name === componentName ? { ...item, component_value: value } : item
                )
            );
        };

        // Evaluate whether the score is complete and calculate the total score
        const evaluateRiskScore = () => {
            const scoreValues = currentRiskScoreState.map(item => item.component_value);
            const totalScore = scoreValues.reduce((total, current) => (current !== null ? total + current : total), 0);
            const isComplete = currentRiskScoreState.every(item => item.component_value !== null);

            return { totalScore, isComplete };
        };

        // Update the reevaluation summary and close the notification
        const updateReevaluationWithSummary = () => {
            // Calculate total score and completion status
            var totalScore = 0;

            // Generate the inputs summary
            const inputsSummary = currentRiskScore.components.map(component => {
                // Find the selected option by comparing component_value with risk_score_component_option_id
                const selectedValue = currentRiskScoreState.find(item => item.component_name === component.component_name)?.component_value;

                // Find the selected option and extract its actual option_value
                const selectedOption = component.options.find(option => option.risk_score_component_option_id === selectedValue);
                const optionValue = selectedOption ? selectedOption.option_value : 'Not selected';  // Get the value or 'Not selected'
                totalScore += optionValue;

                return `${component.component_label} (${optionValue}): ${selectedOption ? selectedOption.option_name : 'Not selected'}`;
            }).join('\n');

            // Include the total score at the top of the summary
            const summary = `${currentRiskScore.score_name} Calculation (Total Score = ${totalScore})\n\n${inputsSummary}`;

            // Update the reevaluation with the new summary
            const newReeval = `${reeval}\n\n${summary}`;

            updateReeval(newReeval);
            setSaveTriggered(true);
            setRiskScoreNotificationVisible(false);
        };




        return (
            currentRiskScore && (
                <Fieldset className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="border border-gray-300 shadow-lg rounded-lg overflow-hidden h-[85vh] w-[95vw] md:h-[75vh] md:w-[900px] flex flex-col bg-white">
                        <div className="flex justify-between items-center bg-indigo-900 text-white py-4 px-6">
                            <div className="font-bold text-xl text-center flex-grow">
                                {currentRiskScore.score_name}
                            </div>
                            <button onClick={() => setRiskScoreNotificationVisible(false)} className="ml-auto">
                                <XMarkIcon className="h-6 w-6" />
                            </button>
                        </div>
                        <div className="p-4 flex-grow overflow-auto max-h-[calc(100%-8rem)] space-y-4">
                            {currentRiskScore.components.map(component => (
                                <div className="w-full bg-gray-100 p-4 rounded-lg flex flex-col">
                                    <RenderRiskScoreOptions
                                        key={component.component_name}
                                        label={component.component_label}
                                        options={component.options}
                                        selectedValue={currentRiskScoreState.find(item => item.component_name === component.component_name)?.component_value}
                                        onChange={(value) => handleRiskScoreChange(component.component_name, value)}
                                        className="break-words whitespace-normal w-full" // Added class to handle long text wrapping
                                    />
                                </div>
                            ))}
                            <div className="bg-white p-4 flex flex-col">
                                {evaluateRiskScore().isComplete ? (
                                    <p className="mt-2">Score complete.</p>
                                ) : (
                                    <p className="flex items-center text-yellow-500 mt-2">
                                        <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                                        Complete items above to calculate {currentRiskScore.score_name} score
                                    </p>
                                )}
                                <div className="flex justify-center items-center mt-4">
                                    <button
                                        onClick={updateReevaluationWithSummary}
                                        className={`bg-indigo-900 hover:bg-indigo-900 text-white font-bold py-3 px-4 rounded ${!evaluateRiskScore().isComplete ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        disabled={!evaluateRiskScore().isComplete}
                                    >
                                        Copy and Save to A&P
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>
            )
        );

    };

    const RenderRiskScoreOptions = ({ label, options, selectedValue, onChange }) => {
        return (
            <div className="flex justify-between items-center w-full my-4 border-b border-gray-200 pb-3 px-3">
                <div className="text-sm font-semibold flex items-center">
                    {selectedValue === null && (
                        <span className="mr-2 h-3 w-3 bg-red-500 rounded-full inline-block"></span>
                    )}
                    {label}:
                </div>
                <div className="flex flex-col items-end w-[75%] space-y-2">
                    {options.map((option, index) => {
                        let borderRadiusClass = '';
                        if (index === 0) {
                            borderRadiusClass = 'rounded-t-lg';
                        } else if (index === options.length - 1) {
                            borderRadiusClass = 'rounded-b-lg';
                        }

                        return (
                            <button
                                key={option.risk_score_component_option_id}
                                type="button"
                                className={`relative inline-flex justify-between items-center w-full px-5 py-3 text-sm font-medium ${selectedValue === option.risk_score_component_option_id ? 'bg-indigo-600 text-white' : 'bg-white text-gray-800 hover:bg-indigo-50'} shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none transition-colors duration-150 ease-in-out ${borderRadiusClass}`}
                                onClick={() => onChange(option.risk_score_component_option_id)}
                            >
                                <span>{option.option_name}</span>
                                <span>{option.option_value}</span>
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    };


    const fetchDocument = useCallback(async () => {
        setLoadingMessage("Fetching Document...");
        setIsLoading(true);
        if (isAuthenticated) {
            try {
                const token = await getAccessTokenSilently();

                // Fetch document and macros in parallel
                const documentFetchOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ document_id: chart.document_id })
                };

                const macrosFetchOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };

                const [documentResponse, macrosResponse] = await Promise.all([
                    fetch(protectedResources.apiGetDocument.endpoint, documentFetchOptions),
                    fetch(protectedResources.apiGetMacros.endpoint, macrosFetchOptions)
                ]);

                const documentData = await documentResponse.json();
                const macrosData = await macrosResponse.json();

                if (documentData.document) {
                    setHPI(documentData.document.hpi);
                    setROS(documentData.document.ros);
                    setExam(documentData.document.physical_exam);
                    setReeval(documentData.document.reevaluation);
                    setMDM(documentData.document.mdm);
                    setSubjective(documentData.document.subjective);
                    setObjective(documentData.document.objective);
                    setAssessmentAndPlan(documentData.document.assessment_and_plan);
                    setDischargeSummary(documentData.document.hospitalist_discharge_summary);
                    setExternalNote(documentData.document.hospitalist_external_note);
                    setPatientName(documentData.document.patient_name);
                    setDocumentStatus(documentData.document.document_status);
                    setMDMStatus(documentData.document.mdm_status);
                    setProcedureDescription(documentData.document.procedure_description);
                    if (documentData.document.mdm_coding) {
                        parseMDMCoding(documentData.document.mdm_coding);
                    }
                    setICD10Codes(documentData.document.code_suggestions);
                } else {
                    setHPI("");
                    setROS("");
                    setExam("");
                    setReeval("");
                    setMDM('');
                    setMDMStatus('');
                    setPatientName("");
                    setExternalNote("");
                    setProcedureDescription("");
                    setICD10Codes("");
                }

                if (macrosData.macros && typeof macrosData.macros === 'object') {
                    const newMacros = Object.values(macrosData.macros).map(macro => ({
                        macro_id: macro.macro_id,
                        title: macro.title,
                        command: macro.command,
                        content: macro.content,
                        section: macro.section,
                        is_shareable_within_org: macro.is_shareable_within_org,
                        created_at: macro.created_at,
                        updated_at: macro.updated_at
                    }));
                    setMacroList(newMacros);
                } else {
                    console.error('Unexpected macros data structure:', macrosData);
                    setMacroList([]);
                }
                setIsLoading(false);

                await fetchRiskScores();

            } catch (error) {
                handleTokenExpiration(error);
                console.error('Error fetching reports:', error);
            }
        }
        setIsLoading(false);
    }, [isAuthenticated, chart, handleTokenExpiration]);

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text);
        switch (type) {
            case 'hpi':
                setCopiedHPI(true);
                updateDocument(chart.document_id, 'copied_hpi', true);
                setTimeout(() => setCopiedHPI(false), 3000);
                break;
            case 'ros':
                setCopiedROS(true);
                updateDocument(chart.document_id, 'copied_ros', true);
                setTimeout(() => setCopiedROS(false), 3000);
                break;
            case 'physical_exam':
                setCopiedExam(true);
                updateDocument(chart.document_id, 'copied_physical_exam', true);
                setTimeout(() => setCopiedExam(false), 3000);
                break;
            case 'reeval':
                setCopiedReeval(true);
                updateDocument(chart.document_id, 'copied_reevaluation', true);
                setTimeout(() => setCopiedReeval(false), 3000);
                break;
            case 'mdm':
                setCopiedMDM(true);
                updateDocument(chart.document_id, 'copied_mdm', true);
                setTimeout(() => setCopiedMDM(false), 3000);
                break;
            case 'icd10_codes':
                setCopiedICD10(true);
                updateDocument(chart.document_id, 'copied_icd10_codes', true);
                setTimeout(() => setCopiedICD10(false), 3000);
                break;
            default:
                console.error('Invalid copy type:', type);
                break;
        }
    };

    const updateDocument = async (documentId, fieldToUpdate, newValue) => {
        try {
            const token = await getAccessTokenSilently();

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    document_id: documentId,
                    field_to_update: fieldToUpdate,
                    new_value: newValue
                })
            };

            await fetch(protectedResources.apiUpdateDocument.endpoint, requestOptions);
            console.log('Updated', fieldToUpdate, 'to', newValue, 'for document', documentId);
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };



    useEffect(() => {
        const loading = false;
        setIsLoading(loading);
        const timeoutId = setTimeout(() => {
            fetchDocument();
        }, 250);

        return () => clearTimeout(timeoutId);
    }, [fetchDocument]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (riskScoreDropdownRef.current && !riskScoreDropdownRef.current.contains(event.target)) {
                setShowRiskScoreDropdown(false);
            }
            if (macroDropdownRef.current && !macroDropdownRef.current.contains(event.target)) {
                setShowMacroDropdown(false);
            }
        };

        // Attach the listener if either dropdown is visible
        if (showRiskScoreDropdown || showMacroDropdown) {
            window.addEventListener('click', handleClickOutside);
        }

        // Cleanup the listener
        return () => window.removeEventListener('click', handleClickOutside);
    }, [showRiskScoreDropdown, showMacroDropdown]);


    const goBack = () => {
        navigate('/hospitalist-inpatient-dashboard', { state: { providedDate: providedDate, sortKey: location.state?.sortKey } });
    };




    const formatTime = (utcString) => {

        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const utcDate = new Date(utcString);
        const formatter = new Intl.DateTimeFormat(undefined, options);
        return formatter.format(utcDate);
    };

    const formatDate = (utcString) => {
        const date = new Date(utcString);

        const options = { month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat(undefined, options).format(date);
    };


    function RiskScoreOptions({ label, options, selectedValue, onChange }) {
        return (
            <div className="flex justify-between items-center w-full my-2 border-b border-gray-300 pb-2 px-2">
                <div className="text-sm font-semibold mr-8 flex items-center">
                    {selectedValue === null ? (
                        <span className="mr-2 h-2 w-2 bg-red-500 rounded-full inline-block"></span>
                    ) : null}
                    {label}:
                </div>
                <div className="flex flex-col items-end">
                    {options.map((option, index) => {
                        let borderRadiusClass = '';
                        if (index === 0) {
                            borderRadiusClass = 'rounded-t-md';
                        } else if (index === options.length - 1) {
                            borderRadiusClass = 'rounded-b-md';
                        } else {
                            borderRadiusClass = '';
                        }

                        return (
                            <button
                                key={index}
                                type="button"
                                className={`relative inline-flex justify-between items-center w-64 px-3 py-2 text-sm font-semibold text-gray-900 ${selectedValue === option.value ? 'bg-indigo-500 text-white' : 'bg-gray-100'} ring-1 ring-inset ring-gray-300 focus:z-10 ${borderRadiusClass}`}
                                onClick={() => onChange(option.value)}
                            >
                                <span>{option.label}</span> <span>{option.value}</span>
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    }

    const RecordingButton = ({ section, label }) => {
        const isDisabled = currentRecordingSection && currentRecordingSection !== section;
        const isSectionRecording = isRecording[section];

        const toggleRecording = () => {
            if (isSectionRecording) {
                stopRecording(section);
            } else {
                startRecording(section);
            }
        };

        const buttonClasses = `rounded-full p-2 focus:outline-none relative ${isRecording[section]
            ? 'bg-red-500'
            : isDisabled
                ? 'bg-gray-200 cursor-not-allowed'
                : ' hover:bg-gray-200'
            }`;

        const iconClasses = `text-indigo-900 ${isRecording[section]
            ? 'animate-pulse'
            : isDisabled
                ? 'opacity-50'
                : ''
            }`;

        const buttonTitle = isRecording[section]
            ? 'Stop Recording'
            : isDisabled
                ? `Cannot record ${label} while ${currentRecordingSection.replace('_', ' ').toUpperCase()} is recording`
                : 'Start Recording';

        return (
            <button
                className={buttonClasses}
                onClick={() => toggleRecording(section)}
                title={buttonTitle}
                disabled={isDisabled}
            >

                <span className="flex items-center">
                    <FontAwesomeIcon
                        icon={faMicrophone}
                        className={iconClasses}
                    />
                    <span className={`ml-2 ${isDisabled ? 'text-gray-500' : 'text-indigo-900'}`}>
                        Smart Edit
                    </span>
                </span>
            </button>
        );
    };

    useEffect(() => {
        if (saveTriggered) {
            // Now inside this effect, you know reeval has been updated
            saveDocument('reeval');
            setSaveTriggered(false); // Reset trigger to avoid unintended saves
        }
    }, [reeval, saveTriggered]); // Reacts to changes in reeval and the save trigger

    const filteredMacros = Object.values(macro_list).filter(macro =>
        macro.title.toLowerCase().includes(macroSearchQuery.toLowerCase())
    );

    const sectionMapping = {
        hpi: 'HPI',
        ros: 'ROS',
        physical_exam: 'Physical Exam',
        reevaluation: 'Evaluations'
    };

    const [showHpiMacros, setShowHpiMacros] = useState(false);

    const toggleHpiMacros = () => {
        setShowHpiMacros(!showHpiMacros);
    };

    const [showExamMacros, setShowExamMacros] = useState(false);

    const toggleExamMacros = () => {
        setShowExamMacros(!showExamMacros);
    };

    const [showRosMacros, setShowRosMacros] = useState(false);

    const toggleRosMacros = () => {
        setShowRosMacros(!showRosMacros);
    };

    const [showEvaluationsMacros, setShowEvaluationsMacros] = useState(false);

    const toggleEvaluationsMacros = () => {
        setShowEvaluationsMacros(!showEvaluationsMacros);
    };



    const toggleBracketedText = () => {
        if (!isRemoved) {
            const bracketedPattern = /^[^\S\r\n]*.*?\[\*\*\*.*?\*\*\*\].*$(\r?\n|\r)?/gm;
            const cleanedMDM = mdm.replace(bracketedPattern, '').trim();
            setOriginalMDM(mdm); // Save the current state before changing
            setCleanedMDM(cleanedMDM);
            setMDM(cleanedMDM);
            setIsRemoved(true); // Update flag to indicate the text is removed
        } else if (mdm === cleanedMDM) {
            setMDM(originalMDM);
            setIsRemoved(false);
        } else {
            alert("MDM has been edited since bracketremoval; undo is not available.");
        }
    };





    const MacroList = ({ macros, onSelectMacro }) => {
        const sectionMapping = {
            hpi: "HPI",
            ros: "ROS",
            physical_exam: "Physical Exam",
            reevaluation: "Evaluations"
        };

        return (
            <div className="bg-gray-100 p-4 rounded shadow-lg">
                <h2 className="text-lg font-bold mb-4">Macros</h2>
                <ul>
                    {macros.map((macro, index) => (
                        <li key={index}>
                            <button
                                className="text-left w-full px-2 py-1 mb-2 border border-gray-300 rounded hover:bg-indigo-200"
                                onClick={() => onSelectMacro(macro)}
                            >
                                {macro.title} ({sectionMapping[macro.section]})
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const [showRatingButtons, setShowRatingButtons] = useState(true);

    const submitFeedback = async () => {
        try {

            setIsFeedbackSubmitted(true);
            // Update document with note feedback  
            await updateDocument(chart.document_id, 'note_feedback', noteFeedback);

            setIsFeedbackSubmitted(true);

            setHasProvidedRating(true);

            // Hide the feedback component after a delay  
            setTimeout(() => {
                setIsFeedbackVisible(false);
            }, 2000);
        } catch (error) {
            console.error('Error submitting feedback:', error);
            // Optionally handle error state  
        }
    };

    const handleRatingUpdate = async (rating) => {
        try {

            setNoteRating(rating);
            setHasProvidedRating(true);
            // Update the note rating in the backend  
            await updateDocument(chart.document_id, 'note_rating', rating);



            // If you want to automatically close the feedback component after rating  
            // setIsFeedbackVisible(false);  

            // Optionally, you can proceed to ask for additional feedback  
        } catch (error) {
            console.error('Error updating note rating:', error);
            // Handle error if needed  
        }
    };

    const handleCreateMacro = () => {
        navigate('/settings/macros');
    };

    function convertHtmlToPlainText(html) {
        return htmlToText(html, {
            wordwrap: false,
            selectors: [
                // Use 'heading' formatter for headings
                { selector: 'h1', format: 'heading' },
                { selector: 'h2', format: 'heading' },
                { selector: 'h3', format: 'heading' },
                { selector: 'h4', format: 'heading' },
                { selector: 'h5', format: 'heading' },
                { selector: 'h6', format: 'heading' },

                // Use 'lineBreak' for <br> tags
                { selector: 'br', format: 'lineBreak' },

                // Use 'unorderedList' and 'orderedList' for lists
                { selector: 'ul', format: 'unorderedList' },
                { selector: 'ol', format: 'orderedList' },

                // Use 'anchor' formatter for links
                { selector: 'a', format: 'anchor' },

                // Use a custom formatter for bold elements
                { selector: 'strong', format: 'uppercaseInline' },
                { selector: 'b', format: 'uppercaseInline' },
            ],
            formatters: {
                uppercaseInline: function (elem, walk, builder, formatOptions) {
                    // Create a temporary builder to collect text
                    let textContent = '';

                    const tempBuilder = {
                        addInline(text) {
                            textContent += text;
                        },
                        addLineBreak() {
                            textContent += '\n';
                        },
                        options: builder.options,
                    };

                    // Process child nodes
                    walk(elem.children, tempBuilder);

                    // Add the uppercase text to the main builder
                    builder.addInline(textContent.toUpperCase());
                },
            },
        });
    }


    const copyAllToClipboardHtml = () => {
        // Prepare HTML content based on document type
        let htmlContent = '';
        let plainTextContent = '';

        const getContent = (content) => content || '';

        switch (chart.hospitalist_document_type) {
            case 'ADMISSION_NOTE':
                htmlContent = `<h4>HISTORY OF PRESENT ILLNESS:</h4><div>${getContent(hpi).replace(/\n/g, '<br>')}</div><br>`;
                htmlContent += `<h4>REVIEW OF SYSTEMS:</h4><div>${getContent(ros).replace(/\n/g, '<br>')}</div><br>`;
                htmlContent += `<h4>PHYSICAL EXAM:</h4><div>${getContent(exam).replace(/\n/g, '<br>')}</div><br>`;
                htmlContent += `<h4>ASSESSMENT AND PLAN:</h4><div>${getContent(reeval).replace(/\n/g, '<br>')}</div><br>`;
                if (mdm) {
                    htmlContent += `<h4>MEDICAL DECISION-MAKING:</h4><div>${getContent(mdm).replace(/\n/g, '<br>')}</div>`;
                }

                // Plain text version
                plainTextContent = `HISTORY OF PRESENT ILLNESS:\n${getContent(hpi)}\n\n`;
                plainTextContent += `REVIEW OF SYSTEMS:\n${getContent(ros)}\n\n`;
                plainTextContent += `PHYSICAL EXAM:\n${getContent(exam)}\n\n`;
                plainTextContent += `EVALUATIONS:\n${getContent(reeval)}\n\n`;
                if (mdm) {
                    plainTextContent += `MEDICAL DECISION-MAKING:\n${convertHtmlToPlainText(getContent(mdm))}`;
                }
                break;

            case 'PROGRESS_NOTE':
                htmlContent = `<h4>SUBJECTIVE:</h4><div>${getContent(subjective).replace(/\n/g, '<br>')}</div><br>` +
                    `<h4>OBJECTIVE:</h4><div>${getContent(objective).replace(/\n/g, '<br>')}</div><br>` +
                    `<h4>ASSESSMENT AND PLAN:</h4><div>${getContent(assessmentAndPlan).replace(/\n/g, '<br>')}</div>`;
                if (mdm) {
                    htmlContent += `<h4>MEDICAL DECISION-MAKING:</h4><div>${getContent(mdm).replace(/\n/g, '<br>')}</div>`;
                }

                plainTextContent = `SUBJECTIVE:\n${getContent(subjective)}\n\n` +
                    `OBJECTIVE:\n${getContent(objective)}\n\n` +
                    `ASSESSMENT AND PLAN:\n${getContent(assessmentAndPlan)}`;
                if (mdm) {
                    plainTextContent += `MEDICAL DECISION-MAKING:\n${convertHtmlToPlainText(getContent(mdm))}`;
                }
                break;

            case 'DISCHARGE_SUMMARY':
                htmlContent = `<div>${getContent(dischargeSummary).replace(/\n/g, '<br>')}</div>`;
                plainTextContent = getContent(dischargeSummary);
                break;

            case 'EXTERNAL_NOTE':
                htmlContent = `<div>${getContent(externalNote).replace(/\n/g, '<br>')}</div>`;
                plainTextContent = getContent(externalNote);
                break;

            default:
                console.error('Unknown document type');
                return;
        }

        // Add attestation if enabled
        if (isUSACSAttestationEnabled) {
            htmlContent += `<div>${USACS_ATTESTATION}</div>`;
            plainTextContent += `\n\n${USACS_ATTESTATION}`;
        }

        // Replace \n with \r\n for Windows compatibility
        plainTextContent = plainTextContent.replace(/\n/g, '\r\n');

        setCopiedAll(true);
        updateDocument(chart.document_id, 'copied_all', true);

        if (navigator.clipboard && window.isSecureContext) {
            const htmlType = 'text/html';
            const textType = 'text/plain';

            const htmlBlob = new Blob([htmlContent], { type: htmlType });
            const textBlob = new Blob([plainTextContent], { type: textType });

            const data = new ClipboardItem({
                [htmlType]: htmlBlob,
                [textType]: textBlob,
            });

            navigator.clipboard.write([data]).then(
                () => {
                    console.log('Copied to clipboard successfully!');
                },
                (err) => {
                    console.error('Could not copy text: ', err);
                    fallbackCopyToClipboard(plainTextContent);
                }
            );
        } else {
            fallbackCopyToClipboard(plainTextContent);
        }

        setTimeout(() => {
            setCopiedAll(false);
        }, 3000);
    };

    const fallbackCopyToClipboard = (content) => {
        const textarea = document.createElement('textarea');
        textarea.value = content; // Use plain text content
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page
        textarea.style.left = '-9999px';
        textarea.style.top = '0';

        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                console.log('Fallback: Copied to clipboard successfully!');
            } else {
                console.error('Fallback: Could not copy text');
            }
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textarea);
    };

    const copyAllToClipboardPlain = () => {
        let allText = '';

        const getContent = (content) => content || '';

        switch (chart.hospitalist_document_type) {
            case 'ADMISSION_NOTE':
                allText = `HISTORY OF PRESENT ILLNESS: \n${getContent(hpi)}`;
                allText += `\n\nREVIEW OF SYSTEMS: \n${getContent(ros)}`;
                allText += `\n\nPHYSICAL EXAM: \n${getContent(exam)}`;
                allText += `\n\nASSESSMENT AND PLAN: \n${getContent(reeval)}`;
                if (mdm) {
                    allText += `\n\nMEDICAL DECISION-MAKING: \n${getContent(mdm)}`;
                }
                break;

            case 'PROGRESS_NOTE':
                allText = `SUBJECTIVE: \n${getContent(subjective)}\n\n` +
                    `OBJECTIVE: \n${getContent(objective)}\n\n` +
                    `ASSESSMENT AND PLAN: \n${getContent(assessmentAndPlan)}`;
                if (mdm) {
                    allText += `\n\nMEDICAL DECISION-MAKING: \n${getContent(mdm)}`;
                }
                break;

            case 'DISCHARGE_SUMMARY':
                allText = getContent(dischargeSummary);
                break;

            case 'EXTERNAL_NOTE':
                allText = getContent(externalNote);
                break;

            default:
                console.error('Unknown document type');
                return;
        }

        // Add attestation if enabled
        if (isUSACSAttestationEnabled) {
            allText += `\n\n${USACS_ATTESTATION}`;
        }

        navigator.clipboard.writeText(allText).then(() => {
            setCopiedAll(true);
            updateDocument(chart.document_id, 'copied_all', true);
            setTimeout(() => {
                setCopiedAll(false);
            }, 3000);
        });
    };

    const copyMDMToClipboard = (content) => {
        setCopiedMDM(true);
        updateDocument(chart.document_id, 'copied_mdm', true);
        setTimeout(() => setCopiedMDM(false), 3000);

        // Prepare HTML content
        const htmlContent = `<div>${content}</div>`;

        // Convert HTML content to plain text
        const mdmPlainText = convertHtmlToPlainText(content);

        // Replace \n with \r\n for Windows compatibility
        const plainTextContent = mdmPlainText.replace(/\n/g, '\r\n');

        if (navigator.clipboard && window.isSecureContext) {
            // Use the Async Clipboard API for both HTML and plain text content
            const htmlType = 'text/html';
            const textType = 'text/plain';

            const htmlBlob = new Blob([htmlContent], { type: htmlType });
            const textBlob = new Blob([plainTextContent], { type: textType });

            const data = new ClipboardItem({
                [htmlType]: htmlBlob,
                [textType]: textBlob,
            });

            navigator.clipboard.write([data]).then(
                () => {
                    console.log('Copied to clipboard successfully!');
                },
                (err) => {
                    console.error('Could not copy text: ', err);
                    // Fallback to legacy method
                    fallbackCopyToClipboard(plainTextContent);
                }
            );
        } else {
            // Fallback for insecure contexts or unsupported browsers
            fallbackCopyToClipboard(plainTextContent);
        }
    };

    const renderDocumentContent = () => {
        switch (chart.hospitalist_document_type) {
            case 'ADMISSION_NOTE':
                return (
                    <>
                        {/* History of Present Illness Section */}
                        <div className="flex flex-col">
                            <div className="flex items-center">
                                <div className="font-bold text-slate-700 text-md">History of Present Illness</div>
                                <div className="flex items-center space-x-2 pl-4">
                                    <button
                                        className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => saveDocument('hpi')}
                                    >
                                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                    </button>
                                    {copiedHPI ? (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(hpi, 'hpi')}
                                        >
                                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                        </button>
                                    ) : (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(hpi, 'hpi')}
                                        >
                                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                        </button>
                                    )}
                                    {/* Macro Dropdown Button */}
                                    <div className="relative" ref={hpiMacroDropdownRef}>
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() =>
                                                setShowHpiMacroDropdown(!showHpiMacroDropdown)
                                            }
                                        >
                                            Macros{' '}
                                            <FontAwesomeIcon
                                                icon={showHpiMacroDropdown ? faChevronUp : faChevronDown}
                                                className="ml-2"
                                            />
                                        </button>
                                        {showHpiMacroDropdown && (
                                            <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                <div className="py-1">
                                                    {getMacrosForSection('hpi').map((macro) => (
                                                        <button
                                                            key={macro.macro_id}
                                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            onClick={() => {
                                                                insertSelectedMacro(macro, 'hpi');
                                                                setShowHpiMacroDropdown(false);
                                                            }}
                                                        >
                                                            {macro.title}
                                                        </button>
                                                    ))}
                                                    {getMacrosForSection('hpi').length === 0 && (
                                                        <div className="text-center">
                                                            <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                            <div className="mt-6">
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                    onClick={handleCreateMacro}
                                                                >
                                                                    + New Macro
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <RecordingButton section="hpi" label="HPI" />
                                </div>
                            </div>
                            {sectionLoadingStates.hpi ? (
                                <div className="flex justify-center items-center h-full">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                </div>
                            ) : (
                                <TextareaAutosize
                                    className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                    value={hpi || ''}
                                    onChange={(e) => updateHPI(e.target.value)}
                                    onBlur={() => saveDocument('hpi', hpi)}
                                    rows={10}
                                    placeholder="Enter History of Present Illness...">
                                </TextareaAutosize>
                            )}
                        </div>

                        {/* Review of Systems Section */}
                        <div className="flex flex-col border-t border-gray-300 mt-2">
                            <div className="flex items-center mt-2">
                                <div className="font-bold text-slate-700 text-md">Review of Systems</div>
                                <div className="flex items-center space-x-2 pl-4">
                                    <button
                                        className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => saveDocument('ros')}
                                    >
                                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                    </button>
                                    {copiedROS ? (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(ros, 'ros')}
                                        >
                                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                        </button>
                                    ) : (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(ros, 'ros')}
                                        >
                                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                        </button>
                                    )}
                                    {/* Macro Dropdown Button */}
                                    <div className="relative" ref={rosMacroDropdownRef}>
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() =>
                                                setShowRosMacroDropdown(!showRosMacroDropdown)
                                            }
                                        >
                                            Macros{' '}
                                            <FontAwesomeIcon
                                                icon={showRosMacroDropdown ? faChevronUp : faChevronDown}
                                                className="ml-2"
                                            />
                                        </button>

                                        {showRosMacroDropdown && (
                                            <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                <div className="py-1">
                                                    {getMacrosForSection('ros').map((macro) => (
                                                        <button
                                                            key={macro.macro_id}
                                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                            onClick={() => {
                                                                insertSelectedMacro(macro, 'ros');
                                                                setShowRosMacroDropdown(false);
                                                            }}
                                                        >
                                                            {macro.title}
                                                        </button>
                                                    ))}
                                                    {getMacrosForSection('ros').length === 0 && (
                                                        <div className="text-center">
                                                            <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                            <div className="mt-6">
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                    onClick={handleCreateMacro}
                                                                >
                                                                    + New Macro
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <RecordingButton section="ros" label="ROS" />
                                </div>
                            </div>
                            {sectionLoadingStates.ros ? (
                                <div className="flex justify-center items-center h-full">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                </div>
                            ) : (
                                <TextareaAutosize
                                    className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                    value={ros || ''}
                                    onChange={(e) => updateROS(e.target.value)}
                                    onBlur={() => saveDocument('ros', ros)}
                                    rows={10}
                                    placeholder="Enter Review of Systems...">
                                </TextareaAutosize>
                            )}
                        </div>

                        {/* Physical Exam Section */}
                        <div className="flex flex-col border-t border-gray-300 mt-2">
                            <div className="flex items-center mt-2">
                                <div className="font-bold text-slate-700 text-md">Physical Exam</div>
                                <div className="flex items-center space-x-2 pl-4">
                                    <button
                                        className="rounded  text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => saveDocument('physical_exam')}
                                    >
                                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                    </button>
                                    {copiedExam ? (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(exam, 'physical_exam')}
                                        >
                                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                        </button>
                                    ) : (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(exam, 'physical_exam')}
                                        >
                                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                        </button>
                                    )}
                                    {/* Macro Dropdown Button */}
                                    <div className="relative" ref={examMacroDropdownRef}>
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() =>
                                                setShowExamMacroDropdown(!showExamMacroDropdown)
                                            }
                                        >
                                            Macros{' '}
                                            <FontAwesomeIcon
                                                icon={showExamMacroDropdown ? faChevronUp : faChevronDown}
                                                className="ml-2"
                                            />
                                        </button>
                                        {showExamMacroDropdown && (
                                            <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                <div className="py-1">
                                                    {getMacrosForSection('physical_exam').map(
                                                        (macro) => (
                                                            <button
                                                                key={macro.macro_id}
                                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                onClick={() => {
                                                                    insertSelectedMacro(
                                                                        macro,
                                                                        'physical_exam'
                                                                    );
                                                                    setShowExamMacroDropdown(false);
                                                                }}
                                                            >
                                                                {macro.title}
                                                            </button>
                                                        )
                                                    )}
                                                    {getMacrosForSection('physical_exam').length ===
                                                        0 && (
                                                            <div className="text-center">
                                                                <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                <div className="mt-6">
                                                                    <button
                                                                        type="button"
                                                                        className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                        onClick={handleCreateMacro}
                                                                    >
                                                                        + New Macro
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <RecordingButton section="physical_exam" label="Physical Exam" />
                                </div>
                            </div>
                            {sectionLoadingStates.physical_exam ? (
                                <div className="flex justify-center items-center h-full">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                </div>
                            ) : (
                                <TextareaAutosize
                                    className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                    value={exam || ''}
                                    onChange={(e) => updateExam(e.target.value)}
                                    onBlur={() => saveDocument('physical_exam', exam)}
                                    rows={10}
                                    placeholder="Enter Physical Exam...">
                                </TextareaAutosize>
                            )}
                        </div>

                        {/* Assessment and Plan Section */}
                        <div className="flex flex-col border-t border-gray-300 mt-2">
                            <div className="flex items-center mt-2">
                                <div className="font-bold text-slate-700 text-md">Assessment and Plan</div>
                                <div className="flex items-center space-x-2 pl-4">
                                    <button
                                        className="rounded  text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => saveDocument('reeval')}
                                    >
                                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                    </button>
                                    {copiedReeval ? (
                                        <button
                                            className="rounded  px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(reeval, 'reeval')}
                                        >
                                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                        </button>
                                    ) : (
                                        <button
                                            className="rounded  px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(reeval, 'reeval')}
                                        >
                                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                        </button>
                                    )}
                                    {/* Macro Dropdown Button */}
                                    <div className="relative" ref={reevalMacroDropdownRef}>
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() =>
                                                setShowReevalMacroDropdown(!showReevalMacroDropdown)
                                            }
                                        >
                                            Macros{' '}
                                            <FontAwesomeIcon
                                                icon={showReevalMacroDropdown ? faChevronUp : faChevronDown}
                                                className="ml-2"
                                            />
                                        </button>
                                        {showReevalMacroDropdown && (
                                            <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                <div className="py-1">
                                                    {getMacrosForSection('reevaluation').map(
                                                        (macro) => (
                                                            <button
                                                                key={macro.macro_id}
                                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                onClick={() => {
                                                                    insertSelectedMacro(
                                                                        macro,
                                                                        'reeval'
                                                                    );
                                                                    setShowReevalMacroDropdown(false);
                                                                }}
                                                            >
                                                                {macro.title}
                                                            </button>
                                                        )
                                                    )}
                                                    {getMacrosForSection('reevaluation').length ===
                                                        0 && (
                                                            <div className="text-center">
                                                                <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                <div className="mt-6">
                                                                    <button
                                                                        type="button"
                                                                        className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                        onClick={handleCreateMacro}
                                                                    >
                                                                        + New Macro
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <RecordingButton section="reevaluation" label="Evaluations" />

                                </div>
                            </div>
                            {sectionLoadingStates.reevaluation ? (
                                <div className="flex justify-center items-center h-full">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                </div>
                            ) : (
                                <TextareaAutosize
                                    className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                    value={reeval || ''}
                                    onChange={(e) => updateReeval(e.target.value)}
                                    onBlur={() => saveDocument('reeval', reeval)}
                                    rows={10}
                                    placeholder="Enter Assessment and Plan...">
                                </TextareaAutosize>
                            )}
                        </div>

                        {/* Medical Decision-Making Documentation (MDM) Section */}
                        <div className="flex flex-col border-t border-gray-300 mt-2">
                            <div className="flex items-center mt-2 justify-start space-x-2">
                                <div className="font-bold text-slate-700 text-md">Medical Decision-Making</div>
                                <div className="flex items-center space-x-2 pl-4">
                                    {mdmStatus !== "IN_PROGRESS" && (
                                        <button
                                            onClick={generateMDM}
                                            className={`flex items-center space-x-2 rounded-lg px-3 py-2 cursor-pointer ${mdm === null || mdm === ''
                                                ? "bg-indigo-900 text-white"
                                                : "border border-[#DB104E] bg-transparent text-[#DB104E] hover:bg-[#DB104E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#DB104E] transition duration-150 ease-in-out"
                                                }`}
                                            title={mdm === null || mdm === '' ? "Generate MDM" : "Regenerate MDM"}
                                        >
                                            <FontAwesomeIcon icon={mdm === null || mdm === '' ? faCalculator : faSync} className="h-5 w-5" />
                                            <span>{mdm === null || mdm === '' ? "Generate MDM" : "Regenerate MDM"}</span>
                                        </button>
                                    )}
                                    {mdmStatus === "IN_PROGRESS" && (
                                        <div className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2">
                                            <FontAwesomeIcon icon={faSpinner} spin className="h-5 w-5" />
                                            <span>Generating MDM...</span>
                                        </div>
                                    )}

                                    {mdm && (
                                        <>
                                            {!isCustomMDMEnabled && (
                                                <button
                                                    className={`rounded-md text-indigo-900 px-3 text-sm hover:bg-gray-200 focus:outline-none ${isRemoved && mdm !== cleanedMDM ? 'cursor-not-allowed opacity-50' : ''}`}
                                                    onClick={toggleBracketedText}
                                                    disabled={isRemoved && mdm !== cleanedMDM}
                                                >
                                                    {isRemoved ? 'Undo Remove' : 'Remove Bracketed Lines'}
                                                </button>
                                            )}
                                            <button
                                                className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                onClick={() => saveDocument('mdm')}
                                            >
                                                Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                            </button>
                                            {copiedMDM ? (
                                                <button
                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                    onClick={() => {
                                                        if (isCustomMDMEnabled) {
                                                            copyMDMToClipboard(mdm, 'mdm');
                                                        } else {
                                                            copyToClipboard(mdm, 'mdm');
                                                        }
                                                    }}
                                                >
                                                    Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                </button>
                                            ) : (
                                                <button
                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                    onClick={() => {
                                                        if (isCustomMDMEnabled) {
                                                            copyMDMToClipboard(mdm, 'mdm');
                                                        } else {
                                                            copyToClipboard(mdm, 'mdm');
                                                        }
                                                    }}
                                                >
                                                    Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                                </button>
                                            )}

                                            {/* Macro Dropdown Button */}
                                            <div className="relative" ref={mdmMacrosDropdownRef}>
                                                <button
                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                    onClick={() =>
                                                        setShowMDMMacrosDropdown(!showMDMMacrosDropdown)
                                                    }
                                                >
                                                    Macros{' '}
                                                    <FontAwesomeIcon
                                                        icon={showMDMMacrosDropdown ? faChevronUp : faChevronDown}
                                                        className="ml-2"
                                                    />
                                                </button>
                                                {showMDMMacrosDropdown && (
                                                    <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                        <div className="py-1">
                                                            {getMacrosForSection('mdm').map(
                                                                (macro) => (
                                                                    <button
                                                                        key={macro.macro_id}
                                                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                        onClick={() => {
                                                                            insertSelectedMacro(
                                                                                macro,
                                                                                'mdm'
                                                                            );
                                                                            setShowMDMMacrosDropdown(false);
                                                                        }}
                                                                    >
                                                                        {macro.title}
                                                                    </button>
                                                                )
                                                            )}
                                                            {getMacrosForSection('mdm').length ===
                                                                0 && (
                                                                    <div className="text-center">
                                                                        <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                        <div className="mt-6">
                                                                            <button
                                                                                type="button"
                                                                                className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                                onClick={handleCreateMacro}
                                                                            >
                                                                                + New Macro
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <RecordingButton section="mdm" label="MDM" />
                                        </>
                                    )}

                                </div>
                            </div>
                            {sectionLoadingStates.mdm ? (
                                <div className="flex justify-center items-center h-full">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                </div>
                            ) : mdmStatus === "IN_PROGRESS" ? (
                                <div className="w-full rounded p-2 text-slate-400 italic">
                                    Generating MDM...
                                </div>
                            ) : mdm ? (
                                isCustomMDMEnabled ? (
                                    <TipTapEditor mdm={mdm} updateSection={updateMDM} saveDocument={saveDocument} />
                                ) : (
                                    <TextareaAutosize
                                        className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                        value={mdm || ''}
                                        onChange={(e) => updateMDM('mdm', e.target.value)}
                                        onBlur={() => saveDocument('mdm', mdm)}
                                        rows={10}
                                    />
                                )
                            ) : (
                                <div className="w-full rounded p-2 text-slate-400 italic">
                                    MDM not yet generated. Click 'Generate MDM' to create.
                                </div>
                            )}
                        </div>
                    </>
                );

            case 'PROGRESS_NOTE':
                return (
                    <>
                        {/* Subjective Section */}
                        <div className="flex flex-col">
                            <div className="flex items-center">
                                <div className="font-bold text-slate-700 text-md">Subjective</div>
                                <div className="flex items-center space-x-2 pl-4">
                                    <button
                                        className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => saveDocument('subjective')}
                                    >
                                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                    </button>
                                    {copiedSubjective ? (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(subjective, 'subjective')}
                                        >
                                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                        </button>
                                    ) : (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(subjective, 'subjective')}
                                        >
                                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                        </button>
                                    )}
                                    <RecordingButton section="subjective" label="Subjective" />
                                </div>
                            </div>
                            {sectionLoadingStates.subjective ? (
                                <div className="flex justify-center items-center h-full">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                </div>
                            ) : (
                                <TextareaAutosize
                                    className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                    value={subjective || ''}
                                    onChange={(e) => updateSubjective(e.target.value)}
                                    onBlur={() => saveDocument('subjective', subjective)}
                                    rows={10}
                                    placeholder="Enter Subjective..."
                                />
                            )}
                        </div>

                        {/* Objective Section */}
                        <div className="flex flex-col border-t border-gray-300 mt-2">
                            <div className="flex items-center mt-2">
                                <div className="font-bold text-slate-700 text-md">Objective</div>
                                <div className="flex items-center space-x-2 pl-4">
                                    <button
                                        className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => saveDocument('objective')}
                                    >
                                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                    </button>
                                    {copiedObjective ? (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(objective, 'objective')}
                                        >
                                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                        </button>
                                    ) : (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(objective, 'objective')}
                                        >
                                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                        </button>
                                    )}
                                    <RecordingButton section="objective" label="Objective" />
                                </div>
                            </div>
                            {sectionLoadingStates.objective ? (
                                <div className="flex justify-center items-center h-full">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                </div>
                            ) : (
                                <TextareaAutosize
                                    className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                    value={objective || ''}
                                    onChange={(e) => updateObjective(e.target.value)}
                                    onBlur={() => saveDocument('objective', objective)}
                                    rows={10}
                                    placeholder="Enter Objective..."
                                />
                            )}
                        </div>

                        {/* Assessment and Plan Section */}
                        <div className="flex flex-col border-t border-gray-300 mt-2">
                            <div className="flex items-center mt-2">
                                <div className="font-bold text-slate-700 text-md">Assessment and Plan</div>
                                <div className="flex items-center space-x-2 pl-4">
                                    <button
                                        className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => saveDocument('assessment_and_plan')}
                                    >
                                        Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                    </button>
                                    {copiedAssessmentAndPlan ? (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(assessmentAndPlan, 'assessment_and_plan')}
                                        >
                                            Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                        </button>
                                    ) : (
                                        <button
                                            className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                            onClick={() => copyToClipboard(assessmentAndPlan, 'assessment_and_plan')}
                                        >
                                            Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                        </button>
                                    )}
                                    <RecordingButton section="assessment_and_plan" label="Assessment and Plan" />
                                </div>
                            </div>
                            {sectionLoadingStates.assessment_and_plan ? (
                                <div className="flex justify-center items-center h-full">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                </div>
                            ) : (
                                <TextareaAutosize
                                    className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                    value={assessmentAndPlan || ''}
                                    onChange={(e) => updateAssessmentAndPlan(e.target.value)}
                                    onBlur={() => saveDocument('assessment_and_plan', assessmentAndPlan)}
                                    rows={10}
                                    placeholder="Enter Assessment and Plan..."
                                />
                            )}
                        </div>
                        {/* Medical Decision-Making Documentation (MDM) Section */}
                        <div className="flex flex-col border-t border-gray-300 mt-2">
                            <div className="flex items-center mt-2 justify-start space-x-2">
                                <div className="font-bold text-slate-700 text-md">Medical Decision-Making</div>
                                <div className="flex items-center space-x-2 pl-4">
                                    {mdmStatus !== "IN_PROGRESS" && (
                                        <button
                                            onClick={generateMDM}
                                            className={`flex items-center space-x-2 rounded-lg px-3 py-2 cursor-pointer ${mdm === null || mdm === ''
                                                ? "bg-indigo-900 text-white"
                                                : "border border-[#DB104E] bg-transparent text-[#DB104E] hover:bg-[#DB104E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#DB104E] transition duration-150 ease-in-out"
                                                }`}
                                            title={mdm === null || mdm === '' ? "Generate MDM" : "Regenerate MDM"}
                                        >
                                            <FontAwesomeIcon icon={mdm === null || mdm === '' ? faCalculator : faSync} className="h-5 w-5" />
                                            <span>{mdm === null || mdm === '' ? "Generate MDM" : "Regenerate MDM"}</span>
                                        </button>
                                    )}
                                    {mdmStatus === "IN_PROGRESS" && (
                                        <div className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2">
                                            <FontAwesomeIcon icon={faSpinner} spin className="h-5 w-5" />
                                            <span>Generating MDM...</span>
                                        </div>
                                    )}
                                    {mdm && (
                                        <>
                                            {!isCustomMDMEnabled && (
                                                <button
                                                    className={`rounded-md text-indigo-900 px-3 text-sm hover:bg-gray-200 focus:outline-none ${isRemoved && mdm !== cleanedMDM ? 'cursor-not-allowed opacity-50' : ''}`}
                                                    onClick={toggleBracketedText}
                                                    disabled={isRemoved && mdm !== cleanedMDM}
                                                >
                                                    {isRemoved ? 'Undo Remove' : 'Remove Bracketed Lines'}
                                                </button>
                                            )}
                                            <button
                                                className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                onClick={() => saveDocument('mdm')}
                                            >
                                                Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                            </button>
                                            {copiedMDM ? (
                                                <button
                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                    onClick={() => {
                                                        if (isCustomMDMEnabled) {
                                                            copyMDMToClipboard(mdm, 'mdm');
                                                        } else {
                                                            copyToClipboard(mdm, 'mdm');
                                                        }
                                                    }}
                                                >
                                                    Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                                </button>
                                            ) : (
                                                <button
                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                    onClick={() => {
                                                        if (isCustomMDMEnabled) {
                                                            copyMDMToClipboard(mdm, 'mdm');
                                                        } else {
                                                            copyToClipboard(mdm, 'mdm');
                                                        }
                                                    }}
                                                >
                                                    Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                                </button>
                                            )}

                                            {/* Macro Dropdown Button */}
                                            <div className="relative" ref={mdmMacrosDropdownRef}>
                                                <button
                                                    className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                                    onClick={() =>
                                                        setShowMDMMacrosDropdown(!showMDMMacrosDropdown)
                                                    }
                                                >
                                                    Macros{' '}
                                                    <FontAwesomeIcon
                                                        icon={showMDMMacrosDropdown ? faChevronUp : faChevronDown}
                                                        className="ml-2"
                                                    />
                                                </button>
                                                {showMDMMacrosDropdown && (
                                                    <div className="absolute z-10 bg-white border border-gray-200 rounded shadow-lg mt-2 w-48">
                                                        <div className="py-1">
                                                            {getMacrosForSection('mdm').map(
                                                                (macro) => (
                                                                    <button
                                                                        key={macro.macro_id}
                                                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                                        onClick={() => {
                                                                            insertSelectedMacro(
                                                                                macro,
                                                                                'mdm'
                                                                            );
                                                                            setShowMDMMacrosDropdown(false);
                                                                        }}
                                                                    >
                                                                        {macro.title}
                                                                    </button>
                                                                )
                                                            )}
                                                            {getMacrosForSection('mdm').length ===
                                                                0 && (
                                                                    <div className="text-center">
                                                                        <p className="mt-1 text-sm text-gray-500">No macros created yet for this section.</p>
                                                                        <div className="mt-6">
                                                                            <button
                                                                                type="button"
                                                                                className="inline-flex items-center rounded-md bg-indigo-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-900"
                                                                                onClick={handleCreateMacro}
                                                                            >
                                                                                + New Macro
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <RecordingButton section="mdm" label="MDM" />
                                        </>
                                    )}

                                </div>
                            </div>
                            {sectionLoadingStates.mdm ? (
                                <div className="flex justify-center items-center h-full">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                                </div>
                            ) : mdmStatus === "IN_PROGRESS" ? (
                                <div className="w-full rounded p-2 text-slate-400 italic">
                                    Generating MDM...
                                </div>
                            ) : mdm ? (
                                isCustomMDMEnabled ? (
                                    <TipTapEditor mdm={mdm} updateMDM={updateMDM} saveDocument={saveDocument} />
                                ) : (
                                    <TextareaAutosize
                                        className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                        value={mdm || ''}
                                        onChange={(e) => updateMDM(e.target.value)}
                                        onBlur={() => saveDocument('mdm', mdm)}
                                        rows={10}
                                    />
                                )
                            ) : (
                                <div className="w-full rounded p-2 text-slate-400 italic">
                                    MDM not yet generated. Click 'Generate MDM' to create.
                                </div>
                            )}
                        </div>
                    </>
                );

            case 'DISCHARGE_SUMMARY':
                return (
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <div className="font-bold text-slate-700 text-md">Discharge Summary</div>
                            <div className="flex items-center space-x-2 pl-4">
                                <button
                                    className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                    onClick={() => saveDocument('hospitalist_discharge_summary')}
                                >
                                    Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                </button>
                                {copiedDischargeSummary ? (
                                    <button
                                        className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => copyToClipboard(dischargeSummary, 'hospitalist_discharge_summary')}
                                    >
                                        Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                    </button>
                                ) : (
                                    <button
                                        className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => copyToClipboard(dischargeSummary, 'hospitalist_discharge_summary')}
                                    >
                                        Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                    </button>
                                )}
                                <RecordingButton section="hospitalist_discharge_summary" label="Discharge Summary" />
                            </div>
                        </div>
                        {sectionLoadingStates.hospitalist_discharge_summary ? (
                            <div className="flex justify-center items-center h-full">
                                <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                            </div>
                        ) : (
                            <TextareaAutosize
                                className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                value={dischargeSummary || ''}
                                onChange={(e) => updateDischargeSummary(e.target.value)}
                                onBlur={() => saveDocument('hospitalist_discharge_summary', dischargeSummary)}
                                rows={6}
                                placeholder="Enter Discharge Summary..."
                            />
                        )}
                    </div>
                );

            case 'EXTERNAL_NOTE':
                return (
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <div className="font-bold text-slate-700 text-md">External Note</div>
                            <div className="flex items-center space-x-2 pl-4">
                                <button
                                    className="rounded text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                    onClick={() => saveDocument('hospitalist_external_note')}
                                >
                                    Save <FontAwesomeIcon icon={faSave} className="ml-2" />
                                </button>
                                {copiedExternalNote ? (
                                    <button
                                        className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => copyToClipboard(externalNote, 'hospitalist_external_note')}
                                    >
                                        Copied! <FontAwesomeIcon icon={faCheck} className="ml-2" />
                                    </button>
                                ) : (
                                    <button
                                        className="rounded px-3 text-indigo-900 hover:bg-gray-200 focus:outline-none flex items-center"
                                        onClick={() => copyToClipboard(externalNote, 'hospitalist_external_note')}
                                    >
                                        Copy <FontAwesomeIcon icon={faCopy} className="ml-2" />
                                    </button>
                                )}
                                <RecordingButton section="hospitalist_external_note" label="External Note" />
                            </div>
                        </div>
                        {sectionLoadingStates.hospitalist_external_note ? (
                            <div className="flex justify-center items-center h-full">
                                <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8" />
                            </div>
                        ) : (
                            <TextareaAutosize
                                className="w-full rounded p-2 text-slate-600 focus:outline-none resize-none border-none outline-none"
                                value={externalNote || ''}
                                onChange={(e) => updateExternalNote(e.target.value)}
                                onBlur={() => saveDocument('hospitalist_external_note', externalNote)}
                                rows={6}
                                placeholder="Enter External Note..."
                            />
                        )}
                    </div>
                );

            default:
                return <div>Unsupported document type</div>;
        }
    };


    const formatHospitalistDocumentType = (type) => {
        switch (type) {
            case 'ADMISSION_NOTE':
                return 'Admission Note';
            case 'PROGRESS_NOTE':
                return 'Progress Note';
            case 'DISCHARGE_SUMMARY':
                return 'Discharge Summary';
            case 'EXTERNAL_NOTE':
                return 'External Note';
            default:
                return type;
        }
    };

    const median = (values) => {
        const sorted = [...values].sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2;
        }

        return sorted[middle];
    };



    return (
        <div className="flex flex-1 overflow-hidden min-h-0 pl-6 pr-6 pb-6">
            {/* Document Details Section as a Horizontal Header */}
            <div className="flex flex-1">
                <div className="flex-1 flex flex-col min-h-0">

                    {chart && (
                        <div className="pt-4 pr-4 pl-4 pb-2 border-b border-gray-300 flex items-center justify-between">
                            {/* Left Side: Go Back Button, Patient Name, Procedure Description, and Encounter Date */}
                            <div className="flex items-center space-x-4">
                                {/* Go Back Button */}
                                <button
                                    onClick={goBack}
                                    className="flex items-center justify-center rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-1" />
                                    Back to Dashboard
                                </button>

                                {/* Patient Name */}
                                <div
                                    className="cursor-pointer"
                                    onDoubleClick={() => setIsEditingPatientName(true)}
                                >
                                    {isEditingPatientName ? (
                                        <input
                                            type="text"
                                            className="w-48 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                            value={editedDocument.patient_name}
                                            onChange={(e) => updatePatientName(e.target.value)}
                                            onBlur={() => {
                                                setIsEditingPatientName(false);
                                                saveDocument('patient_name');
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    saveDocument('patient_name');
                                                    setIsEditingPatientName(false);
                                                }
                                            }}
                                            autoFocus
                                            aria-label="Edit Patient Name"
                                        />
                                    ) : (
                                        <p className="text-2xl font-semibold text-gray-800">{patientName}</p>
                                    )}
                                </div>

                                {/* Separator */}
                                <span className="hidden md:inline-block border-l border-gray-300 h-6"></span>

                                {/* Procedure Description */}
                                <div className="text-gray-600">
                                    <p className="text-md">
                                        {formatDate(chart.created_at)} at {formatTime(chart.created_at)}
                                    </p>
                                </div>

                                {/* Separator */}
                                <span className="hidden md:inline-block border-l border-gray-300 h-6"></span>

                                {/* Encounter Date */}
                                <div className="text-gray-600">
                                    <p className="text-md">
                                        {chart && chart.hospitalist_document_type
                                            ? formatHospitalistDocumentType(chart.hospitalist_document_type)
                                            : 'N/A'}
                                    </p>
                                </div>
                            </div>

                            {/* Right Side: Action Buttons */}
                            <div className="flex items-center space-x-2">
                                {/* Copy All Button */}
                                <button
                                    onClick={isCustomMDMEnabled ? copyAllToClipboardHtml : copyAllToClipboardPlain}
                                    className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:shadow-xl transition-shadow duration-200"
                                    title={copiedAll ? "Copied!" : "Copy All"}
                                >
                                    <FontAwesomeIcon icon={copiedAll ? faCheck : faCopy} className="h-5 w-5" />
                                    <span>{copiedAll ? "Copied!" : "Copy All"}</span>
                                </button>

                                {/* Refresh Button */}
                                <button
                                    onClick={fetchDocument}
                                    className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:shadow-xl transition-shadow duration-200"
                                    title="Refresh"
                                >
                                    <FontAwesomeIcon icon={faSync} className="h-5 w-5" />
                                    <span>Refresh</span>
                                </button>

                                {chart && chart.hospitalist_document_type === 'ADMISSION_NOTE' && (
                                    <>
                                        {/* Risk Score Button */}
                                        <div className="relative">
                                            <button
                                                onClick={() => setShowRiskScoreDropdown(!showRiskScoreDropdown)}
                                                className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:shadow-xl transition-shadow duration-200"
                                                title="Calculate Risk Score"
                                            >
                                                <FontAwesomeIcon icon={faChartLine} className="h-5 w-5" />
                                                <span>Risk Score</span>
                                            </button>
                                            {showRiskScoreDropdown && (
                                                <div
                                                    className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                                                    role="menu"
                                                    aria-orientation="vertical"
                                                    aria-labelledby="risk-score-dropdown"
                                                >
                                                    <div className="py-1" role="none">
                                                        {riskScores.map((score) => (
                                                            <button
                                                                key={score.risk_score_id}
                                                                type="button"
                                                                className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100 hover:text-indigo-900"
                                                                role="menuitem"
                                                                onClick={() => {
                                                                    setCurrentRiskScoreId(score.risk_score_id);
                                                                    setMDMCodingNotificationVisible(false);
                                                                    setRiskScoreNotificationVisible(true);
                                                                    setShowRiskScoreDropdown(false);
                                                                }}
                                                            >
                                                                {score.score_name}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}

                                {/* Archive Button */}
                                <button
                                    onClick={toggleArchiveStatus}
                                    className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:shadow-xl transition-shadow duration-200"
                                    title={documentStatus === "FINALIZED" ? "Un-archive" : "Archive"}
                                >
                                    <FontAwesomeIcon
                                        icon={documentStatus === "FINALIZED" ? faUndo : faArchive}
                                        className="h-5 w-5"
                                    />
                                    <span>{documentStatus === "FINALIZED" ? "Un-archive" : "Archive"}</span>
                                </button>
                            </div>
                        </div>
                    )}


                    {/* Clinical Note Section */}
                    {isLoading ? (
                        <div className="loading-overlay">
                            <div className="spinner"></div>
                            <div className="loading-text">{loadingMessage}</div>
                        </div>
                    ) : documentStatus === 'IN_PROGRESS' ? (
                        // Case: IN_PROGRESS but all sections are empty (Whole note is in progress)
                        <div className="flex-1 flex flex-col items-center justify-center space-y-4">
                            <p className="text-xl text-gray-600 text-center">
                                This chart is currently in progress and not available yet.
                            </p>
                            <button
                                onClick={fetchDocument}
                                className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 shadow-lg hover:shadow-xl"
                                title="Refresh"
                            >
                                <FontAwesomeIcon icon={faSync} className="h-5 w-5" />
                                <span>Refresh</span>
                            </button>
                        </div>
                    ) : (
                        <div className="flex-1 overflow-y-auto pr-72">
                            <div className="space-y-2 p-5">
                                {renderDocumentContent()}
                            </div>
                        </div>
                    )
                    }

                    {/* Notification Card for MDM Coding */}
                    {
                        mdmStatus === "GENERATED" && (
                            <div className="fixed top-48 right-4">
                                {mdmCodingNotificationVisible ? (
                                    <div className="h-[70vh] md:h-[350px] w-full md:w-[200px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white transition-all">
                                        <div className="flex justify-between items-center bg-indigo-900 text-white py-2 pl-4 pr-2">
                                            <div className="font-bold text-sm text-center flex-grow cursor-pointer" onClick={() => setMDMCodingNotificationVisible(true)}>
                                                Cleo MDM Assistant
                                            </div>
                                            <button onClick={() => setMDMCodingNotificationVisible(false)} className="p-1 rounded-full bg-white text-indigo-900 hover:text-indigo-900 transition-all">
                                                <XMarkIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="bg-white p-2 pt-2 px-0 flex-grow overflow-auto max-h-[calc(60vh-4rem)]">
                                            <div className="mt-2 p-2 rounded-lg shadow">
                                                <ProgressBar value={copa} title="Complexity of Problems Addressed" denominator={3} />
                                                <ProgressBar value={complexityOfData} title="Complexity of Data" denominator={3} />
                                                <ProgressBar value={riskOfComplications} title="Risk of Complications" denominator={3} />
                                                {billingCode && billingCode !== 'Not available' && billingCode !== '-1' ? (
                                                    <>
                                                        <div className="text-sm font-semibold text-gray-700">CPT Code</div>
                                                        <div className="text-2xl font-bold text-indigo-900">
                                                            {billingCode}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="text-sm font-semibold text-gray-700">Coding Estimate</div>
                                                        <div className="text-2xl font-bold text-indigo-900">
                                                            {Math.floor(median([copa, complexityOfData, riskOfComplications]))}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="h-12 w-12 bg-indigo-900 rounded-full flex items-center justify-center cursor-pointer shadow-lg hover:shadow-xl transition-all" onClick={() => setMDMCodingNotificationVisible(true)}>
                                        <CalculatorIcon className="h-6 w-6 text-white" />
                                    </div>
                                )}
                            </div>
                        )
                    }
                </div>
                {showNotification && (
                    <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                        <div className="rounded-md bg-green-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm font-medium text-green-800">Successfully saved</p>
                                </div>
                                <div className="ml-auto pl-3">
                                    <div className="-mx-1.5 -my-1.5">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                            onClick={() => setShowNotification(false)}
                                        >
                                            <span className="sr-only">Dismiss</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showErrorNotification && (
                    <div className="fixed top-0 left-1/2 transform -translate-x-1/2 mt-4 z-50">
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">Error</h3>
                                    <div className="mt-2 text-sm text-red-700">
                                        <p>{errorMessage}</p>
                                    </div>
                                </div>
                                <div className="ml-auto pl-3">
                                    <div className="-mx-1.5 -my-1.5">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                                            onClick={() => setShowErrorNotification(false)}
                                        >
                                            <span className="sr-only">Dismiss</span>
                                            <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {/* Note Feedback Component */}
                {isFeedbackVisible && (
                    <div className="fixed bottom-48 right-10 z-50">
                        {!hasProvidedRating ? (
                            <div className="w-full md:w-20 border border-gray-300 rounded-xl overflow-hidden flex flex-col items-center bg-white py-2">
                                <div className="text-xs font-bold text-gray-700 mb-2 text-center">
                                    How did Cleo do?
                                </div>

                                <button
                                    onClick={() => handleRatingUpdate('good')}
                                    className="mb-2 focus:outline-none"
                                    aria-label="Rate Good"
                                >
                                    <FontAwesomeIcon icon={faThumbsUp} className="h-6 w-6 text-green-500" />
                                </button>

                                <button
                                    onClick={() => handleRatingUpdate('medium')}
                                    className="mb-2 focus:outline-none"
                                    aria-label="Rate Neutral"
                                >
                                    <FontAwesomeIcon icon={faMeh} className="h-6 w-6 text-yellow-500" />
                                </button>

                                <button
                                    onClick={() => handleRatingUpdate('bad')}
                                    className="focus:outline-none"
                                    aria-label="Rate Poor"
                                >
                                    <FontAwesomeIcon icon={faThumbsDown} className="h-6 w-6 text-red-500" />
                                </button>
                            </div>
                        ) : (
                            !isFeedbackSubmitted ? (
                                <div className="w-full md:w-64 border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white p-4">
                                    <p className="text-base font-semibold text-gray-700">
                                        Would you like to provide additional feedback?
                                    </p>
                                    <textarea
                                        className="w-full border border-gray-300 rounded-md p-2 mt-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        value={noteFeedback}
                                        onChange={(e) => setNoteFeedback(e.target.value)}
                                        rows={4}
                                        placeholder="Optional feedback..."
                                    />
                                    <div className="flex justify-end space-x-2 mt-2">
                                        <button
                                            onClick={() => {
                                                // Reset states  
                                                // setHasProvidedRating(false);

                                                setIsFeedbackSubmitted(true);
                                                setNoteFeedback('');
                                                setTimeout(() => {
                                                    setIsFeedbackVisible(false);
                                                }, 3000);
                                            }}
                                            className="text-gray-600 hover:text-gray-800 focus:outline-none"
                                        >
                                            Skip
                                        </button>
                                        <button
                                            onClick={submitFeedback}
                                            className="bg-indigo-900 text-white py-1 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-200 focus:outline-none"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="w-full md:w-32 border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col items-center bg-white py-4">
                                    <p className="text-sm text-green-600 font-semibold text-center">
                                        Thank you for the feedback!
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                )}


                {
                    riskScoreNotificationVisible && !mdmCodingNotificationVisible && (
                        <RenderRiskScoreNotification riskScoreId={currentRiskScoreId} />
                    )
                }
            </div>
        </div >
    );
}

export default HospitalistChart;
