import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources, callProtectedApi } from '../authConfig';

export const useDataRetentionSetting = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [retentionHours, setRetentionHours] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await callProtectedApi(
                    getAccessTokenSilently,
                    `${protectedResources.apiSettings.endpoint}data_deletion_window`,
                    { method: 'GET' }
                );
                
                if (response.Setting) {
                    setRetentionHours(response.Setting.value);
                }
            } catch (error) {
                console.error('Error fetching data retention setting:', error);
                setError('Failed to load data retention setting');
            } finally {
                setIsLoading(false);
            }
        };

        fetchSetting();
    }, [getAccessTokenSilently]);

    const getExpirationInfo = (documentDate) => {
        if (!retentionHours || !documentDate) return null;

        const docDate = new Date(documentDate);
        const expirationDate = new Date(docDate.getTime() + retentionHours * 60 * 60 * 1000);
        const now = new Date();
        const hoursUntilExpiration = (expirationDate - now) / (1000 * 60 * 60);
        
        return {
            expirationDate,
            hoursUntilExpiration,
            isExpiringSoon: hoursUntilExpiration <= 48, // Consider "soon" as within 48 hours
            isExpired: hoursUntilExpiration <= 0,
            warningMessage: getWarningMessage(hoursUntilExpiration)
        };
    };

    const getWarningMessage = (hoursLeft) => {
        if (hoursLeft <= 0) {
            return 'Expired';
        }
        if (hoursLeft <= 24) {
            return `Expires in ${Math.round(hoursLeft)} hours`;
        }
        const daysLeft = Math.round(hoursLeft / 24);
        return `Expires in ${daysLeft} days`;
    };

    return {
        retentionHours,
        isLoading,
        error,
        getExpirationInfo
    };
}; 