import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../contexts/AuthProvider';
import { useLocation } from 'react-router-dom';
import { getGroupHierarchy } from '../api/groupApi';
import { GroupMacrosPage } from './macros/GroupMacros';

const OrganizationPortalMacroManagement = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo } = useAuth();
    const location = useLocation();
    const [groups, setGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');

    // Get organization ID from location state if provided (for global admin viewing other orgs)
    const isGlobalAdminView = location.state?.isGlobalAdminView || false;
    const orgId = isGlobalAdminView ? location.state?.orgId : actorInfo?.org_id;

    // Fetch groups for the organization
    useEffect(() => {
        const fetchGroups = async () => {
            if (!orgId) return;

            try {
                setIsLoading(true);
                const hierarchy = await getGroupHierarchy(getAccessTokenSilently, orgId);

                // Apply different filtering based on organization ID
                let groupsList;

                if (orgId === 51) {
                    // For Team Health, filter out the top-level group (level 1)
                    groupsList = hierarchy.filter(group => group.level !== 1);
                } else {
                    // For all other orgs, include all groups (including top-level)
                    groupsList = hierarchy;
                }

                setGroups(groupsList);

                // Set the first group as selected by default if available
                if (groupsList.length > 0 && !selectedGroupId) {
                    setSelectedGroupId(groupsList[0].group_id);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchGroups();
    }, [orgId, getAccessTokenSilently, selectedGroupId]);

    const handleGroupChange = (e) => {
        setSelectedGroupId(parseInt(e.target.value, 10));
    };

    const handleSuccessfulSave = (message) => {
        setSuccessMessage(message);
        // Clear the message after 3 seconds
        setTimeout(() => {
            setSuccessMessage('');
        }, 3000);
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                {/* Header with title and group selector */}
                <div className="flex justify-between items-center mb-8">
                    <div>
                        <h1 className="text-3xl font-bold text-gray-900">Macro Management</h1>
                        <p className="mt-2 text-sm text-gray-600">
                            Create and manage macros for groups within your organization to streamline documentation workflows.
                        </p>
                    </div>

                    {/* Group Selector */}
                    {groups.length > 0 && (
                        <div className="w-64">
                            <label htmlFor="group-select" className="block text-sm font-medium text-gray-700 mb-1">
                                Select Group
                            </label>
                            <select
                                id="group-select"
                                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                value={selectedGroupId || ''}
                                onChange={handleGroupChange}
                            >
                                <option value="" disabled>Select a group</option>
                                {groups.map((group) => (
                                    <option key={group.group_id} value={group.group_id}>
                                        {group.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>

                {/* No groups message */}
                {groups.length === 0 && (
                    <div className="mb-6 bg-white rounded-lg shadow p-4">
                        <p className="text-gray-600">No groups available for this organization.</p>
                    </div>
                )}

                {/* Success Message */}
                {successMessage && (
                    <div className="mb-6 bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded relative">
                        {successMessage}
                    </div>
                )}

                {/* Render GroupMacrosPage if a group is selected */}
                {selectedGroupId ? (
                    <GroupMacrosPage
                        groupId={selectedGroupId}
                        onSuccessfulSave={handleSuccessfulSave}
                        isModal={false}
                    />
                ) : groups.length > 0 ? (
                    <div className="bg-white rounded-lg shadow p-8 text-center">
                        <p className="text-gray-600">Please select a group to manage macros.</p>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default OrganizationPortalMacroManagement; 