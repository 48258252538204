import { protectedResources, callProtectedApi } from '../authConfig';

export const customInstructionsService = {
    // Create a new instruction
    async createInstruction(instruction, getToken) {
        return callProtectedApi(
            getToken,
            protectedResources.apiCreateInstruction.endpoint,
            {
                method: 'POST',
                body: JSON.stringify(instruction)
            }
        );
    },

    // Share an instruction
    async shareInstruction(instructionId, shareType, getToken) {
        // Backend expects:
        // - 'private' for private (unshared)
        // - 'org' for organization
        // - 'all' for global
        return callProtectedApi(
            getToken,
            protectedResources.apiShareInstruction.endpoint.replace('{instruction_id}', instructionId),
            {
                method: 'POST',
                body: JSON.stringify({ share_type: shareType })
            }
        );
    },

    // Get user's instructions
    async getMyInstructions(getToken, section = null) {
        const endpoint = section
            ? `${protectedResources.apiGetMyInstructions.endpoint}?section=${section}`
            : protectedResources.apiGetMyInstructions.endpoint;
        return callProtectedApi(
            getToken,
            endpoint,
            { method: 'GET' }
        );
    },

    // Get shared instructions
    async getSharedInstructions(getToken) {
        return callProtectedApi(
            getToken,
            protectedResources.apiGetSharedInstructions.endpoint,
            { method: 'GET' }
        );
    },

    // Add an instruction to user's collection
    async addInstruction(instructionId, getToken) {
        return callProtectedApi(
            getToken,
            protectedResources.apiAddInstruction.endpoint.replace('{instruction_id}', instructionId),
            { method: 'POST' }
        );
    },

    // Remove an instruction from user's collection
    async removeInstruction(instructionId, getToken) {
        return callProtectedApi(
            getToken,
            protectedResources.apiRemoveInstruction.endpoint.replace('{instruction_id}', instructionId),
            { method: 'DELETE' }
        );
    },

    // Add a comment to an instruction
    async addComment(instructionId, content, getToken) {
        return callProtectedApi(
            getToken,
            protectedResources.apiAddInstructionComment.endpoint.replace('{instruction_id}', instructionId),
            {
                method: 'POST',
                body: JSON.stringify({ content })
            }
        );
    },

    // Get comments for an instruction
    async getComments(instructionId, getToken) {
        return callProtectedApi(
            getToken,
            protectedResources.apiAddInstructionComment.endpoint.replace('{instruction_id}', instructionId),
            { method: 'GET' }
        );
    },

    // Add a reaction to an instruction
    async addReaction(instructionId, reactionType, getToken) {
        return callProtectedApi(
            getToken,
            protectedResources.apiAddInstructionReaction.endpoint.replace('{instruction_id}', instructionId),
            {
                method: 'POST',
                body: JSON.stringify({ reaction_type: reactionType })
            }
        );
    },

    // Add a reaction to a comment
    async addCommentReaction(instructionId, commentId, reactionType, getToken) {
        return callProtectedApi(
            getToken,
            protectedResources.apiAddCommentReaction.endpoint
                .replace('{instruction_id}', instructionId)
                .replace('{comment_id}', commentId),
            {
                method: 'POST',
                body: JSON.stringify({ reaction_type: reactionType })
            }
        );
    },

    // Update an instruction
    updateInstruction: async (instructionId, instruction, getToken) => {
        const endpoint = protectedResources.apiUpdateInstruction.endpoint.replace(
            '{instruction_id}',
            instructionId
        );

        return callProtectedApi(getToken, endpoint, {
            method: 'PUT',
            body: JSON.stringify(instruction)
        });
    },

    // Get admin group instructions
    getAdminGroupInstructions: async (getToken, groupId = null) => {
        const endpoint = groupId
            ? `${protectedResources.apiGetAdminGroupInstructions.endpoint}?group_id=${groupId}`
            : protectedResources.apiGetAdminGroupInstructions.endpoint;

        return callProtectedApi(
            getToken,
            endpoint,
            { method: 'GET' }
        );
    },

    // Upsert group instruction
    upsertGroupInstruction: async (getToken, instructionData, groupId = null) => {
        const endpoint = groupId
            ? `${protectedResources.apiUpsertGroupInstruction.endpoint}?group_id=${groupId}`
            : protectedResources.apiUpsertGroupInstruction.endpoint;

        return callProtectedApi(
            getToken,
            endpoint,
            {
                method: 'POST',
                body: JSON.stringify(instructionData)
            }
        );
    },

    // Delete group instruction
    deleteGroupInstruction: async (getToken, instructionId, groupId = null) => {
        const endpoint = groupId
            ? `${protectedResources.apiDeleteGroupInstruction.endpoint.replace('{instruction_id}', instructionId)}?group_id=${groupId}`
            : protectedResources.apiDeleteGroupInstruction.endpoint.replace('{instruction_id}', instructionId);

        return callProtectedApi(
            getToken,
            endpoint,
            { method: 'DELETE' }
        );
    },

    async optOutInstruction(getToken, instructionId) {
        const endpoint = `${protectedResources.apiCreateInstruction.endpoint}/${instructionId}/opt_out`;
        return callProtectedApi(getToken, endpoint, { method: 'POST' });
    },

    async optInInstruction(getToken, instructionId) {
        const endpoint = `${protectedResources.apiCreateInstruction.endpoint}/${instructionId}/opt_in`;
        return callProtectedApi(getToken, endpoint, { method: 'POST' });
    }
};

export default customInstructionsService; 