// GlobalAdminDashboard.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { protectedResources } from '../authConfig';
import { useAuth0 } from '@auth0/auth0-react';
import GlobalUserManagement from '../components/GlobalUserManagement';
import GlobalKPIs from '../components/GlobalKPIs';
import { UserIcon, ChartBarIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import MergeAccountsModal from '../components/MergeAccountsModal';
import { adminApi } from '../api/adminApi';

const GlobalAdminDashboard = () => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedOrgName, setSelectedOrgName] = useState(null);
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);

  const [isUserManagementModalOpen, setIsUserManagementModalOpen] = useState(false);
  const [isKPIModalOpen, setIsKPIModalOpen] = useState(false); // Add state for KPIs modal
  const [notification, setNotification] = useState(null);
  const [isMergeAccountsModalOpen, setIsMergeAccountsModalOpen] = useState(false);

  const handleSelect = (org_id, org_name) => {
    setSelectedOrg(org_id);
    setSelectedOrgName(org_name);
  };

  // Navigate to internal admin dashboard
  const handleViewInternalDashboard = () => {
    if (selectedOrg && selectedOrgName) {
      navigate(`/organization-admin-dashboard`, {
        state: {
          org_id: selectedOrg,
          org_name: selectedOrgName
        }
      });
    }
  };

  // Navigate to organization portal (user-facing)
  const handleViewOrgPortal = () => {
    if (selectedOrg && selectedOrgName) {
      navigate(`/organization-portal/usage-reporting`, {
        state: {
          orgId: selectedOrg,
          orgName: selectedOrgName,
          isGlobalAdminView: true
        }
      });
    }
  };

  const fetchOrgs = async () => {
    if (isAuthenticated) {
      try {
        const data = await adminApi.listOrganizations(getAccessTokenSilently);

        // Sort organizations alphabetically by org_name
        const sortedOrganizations = data.orgs.sort((a, b) =>
          a.org_name.localeCompare(b.org_name)
        );
        setOrganizations(sortedOrganizations);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    }
  };

  useEffect(() => {
    fetchOrgs();
  }, [isAuthenticated]);

  // Notification effect
  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex items-center justify-center mt-24">
        <div className="p-8 rounded-lg shadow-md text-center bg-gray-200">
          <h1 className="text-2xl font-bold mb-6">Global Admin Dashboard</h1>
          <div className="mb-6">
            <select
              onChange={(e) => {
                const selectedOption = e.target.options[e.target.selectedIndex];
                handleSelect(selectedOption.value, selectedOption.text);
              }}
              value={selectedOrg || ''}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="" disabled>Select an organization</option>
              {organizations.map(org => (
                <option key={org.org_id} value={org.org_id}>
                  {org.org_name}
                </option>
              ))}
            </select>
          </div>

          {/* Organization navigation buttons */}
          {selectedOrg && (
            <div className="flex justify-center space-x-4 mb-6">
              <button
                onClick={handleViewInternalDashboard}
                className="flex items-center px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
              >
                <UserIcon className="h-5 w-5 mr-2" />
                Internal Admin Dashboard
              </button>
              <button
                onClick={handleViewOrgPortal}
                className="flex items-center px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
              >
                <ChartBarIcon className="h-5 w-5 mr-2" />
                Organization Portal
              </button>
            </div>
          )}

          <div className="flex justify-center space-x-4">
            <button
              onClick={() => setIsUserManagementModalOpen(true)}
              className="flex items-center px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
            >
              <UserIcon className="h-5 w-5 mr-2" />
              User Management
            </button>
            <button
              onClick={() => setIsKPIModalOpen(true)}
              className="flex items-center px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
            >
              <ChartBarIcon className="h-5 w-5 mr-2" />
              View Global KPIs
            </button>
            <button
              onClick={() => setIsMergeAccountsModalOpen(true)}
              className="flex items-center px-4 py-2 bg-indigo-800 text-white rounded-md hover:bg-indigo-900"
            >
              <UserPlusIcon className="h-5 w-5 mr-2" />
              Merge Accounts
            </button>
          </div>
        </div>
      </div>
      {/* Notification */}
      {notification && (
        <div
          className={`fixed bottom-4 left-4 bg-${notification.type === 'success' ? 'green' : 'red'
            }-500 text-white px-4 py-2 rounded-md`}
        >
          {notification.message}
        </div>
      )}
      {/* Global User Management Modal */}
      <GlobalUserManagement
        isOpen={isUserManagementModalOpen}
        onClose={() => setIsUserManagementModalOpen(false)}
        setNotification={setNotification}
      />

      {/* Global KPIs Modal */}
      <GlobalKPIs
        isOpen={isKPIModalOpen}
        onClose={() => setIsKPIModalOpen(false)}
      />

      {/* Merge Accounts Modal */}
      <MergeAccountsModal
        isOpen={isMergeAccountsModalOpen}
        onClose={() => setIsMergeAccountsModalOpen(false)}
        setNotification={setNotification}
      />
    </div>
  );
};

export default GlobalAdminDashboard;
