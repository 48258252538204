import { protectedResources } from '../authConfig';
import { callApiWithToken } from './apiUtils';

export const replacementsApi = {
    getReplacements: async (getAccessTokenSilently) => {
        try {
            return await callApiWithToken(
                getAccessTokenSilently,
                protectedResources.apiGetReplacements.endpoint,
                'POST'
            );
        } catch (error) {
            console.error('Error fetching replacements:', error);
            throw error;
        }
    },

    upsertReplacement: async (getAccessTokenSilently, replacementData) => {
        try {
            return await callApiWithToken(
                getAccessTokenSilently,
                protectedResources.apiUpsertReplacement.endpoint,
                'POST',
                replacementData
            );
        } catch (error) {
            console.error('Error upserting replacement:', error);
            throw error;
        }
    },

    deleteReplacement: async (getAccessTokenSilently, replacementId) => {
        try {
            return await callApiWithToken(
                getAccessTokenSilently,
                protectedResources.apiDeleteReplacement.endpoint,
                'POST',
                { replacement_id: replacementId }
            );
        } catch (error) {
            console.error('Error deleting replacement:', error);
            throw error;
        }
    }
}; 