import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { callProtectedApi, protectedResources } from '../../authConfig';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * SmartEditButton component for voice recording and smart editing in different sections of the patient note
 * @param {Object} props - Component props
 * @param {string} props.section - The section identifier (e.g., 'hpi', 'ros', 'exam')
 * @param {string} props.label - Display label for the section
 * @param {Object} props.recordingManager - The recording manager instance
 * @param {Object} props.chart - The current chart data with document_id
 * @param {Function} props.updateSection - Function to update a section in the parent component
 * @param {Object} props.isRecording - Object tracking recording state for each section
 * @param {Function} props.setIsRecording - Function to update the isRecording state
 * @param {string|null} props.currentRecordingSection - Currently recording section, if any
 * @param {Function} props.setCurrentRecordingSection - Function to update the currentRecordingSection state
 * @param {Object} props.sectionLoadingStates - Object tracking loading state for each section
 * @param {Function} props.setSectionLoadingStates - Function to update the sectionLoadingStates
 */
const SmartEditButton = ({
    section,
    label,
    recordingManager,
    chart,
    updateSection,
    isRecording,
    setIsRecording,
    currentRecordingSection,
    setCurrentRecordingSection,
    sectionLoadingStates,
    setSectionLoadingStates
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const isDisabled = currentRecordingSection && currentRecordingSection !== section;
    const isSectionRecording = isRecording[section];

    /**
     * Send transcription to the server for smart chart processing
     * @param {string} documentId - The document ID
     * @param {string} section - The section name
     * @param {string} transcriptionToSend - The transcription text
     * @param {number} transcriptionTime - The transcription duration in seconds
     */
    const sendSmartChartTranscription = useCallback(async (documentId, section, transcriptionToSend, transcriptionTime) => {
        if (!transcriptionToSend) {
            console.warn('No transcription to send.');
            setSectionLoadingStates((prevState) => ({ ...prevState, [section]: false }));
            return;
        }

        setSectionLoadingStates((prevState) => ({ ...prevState, [section]: true }));

        const timeZoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const payload = {
            document_id: documentId,
            section_name: section,
            transcript: transcriptionToSend,
            transcription_time: transcriptionTime,
            time_zone: timeZoneIdentifier
        };

        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiSendSmartEditTranscription.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify(payload)
                }
            );

            if (data.updated_section) {
                updateSection(section, data.updated_section);
            } else {
                console.error('No updated section found in the response');
            }
        } catch (error) {
            console.error('Error sending transcription:', error);
        } finally {
            setSectionLoadingStates((prevState) => ({ ...prevState, [section]: false }));
        }
    }, [getAccessTokenSilently, protectedResources, setSectionLoadingStates, updateSection]);

    /**
     * Start recording for a specific section
     * @param {string} section - The section to record for
     */
    const startRecording = useCallback((section) => {
        const startTime = Date.now();

        const onTranscriptionComplete = async (section, transcription) => {
            const endTime = Date.now();
            const transcriptionTime = Math.floor((endTime - startTime) / 1000);
            await sendSmartChartTranscription(chart.document_id, section, transcription, transcriptionTime);
        };

        recordingManager.startRecording(
            section,
            process.env.REACT_APP_AZURE_SPEECH_KEY,
            process.env.REACT_APP_AZURE_SPEECH_REGION,
            onTranscriptionComplete
        );

        // Update local state to reflect recording status
        setIsRecording((prevState) => ({ ...prevState, [section]: true }));
        setCurrentRecordingSection(section);
    }, [chart, recordingManager, sendSmartChartTranscription, setIsRecording, setCurrentRecordingSection]);

    /**
     * Stop recording for a specific section
     * @param {string} section - The section to stop recording for
     */
    const stopRecording = useCallback((section) => {
        recordingManager.stopRecording(section);

        // Update local state to reflect recording status
        setIsRecording((prevState) => ({ ...prevState, [section]: false }));
        setCurrentRecordingSection(null);
    }, [recordingManager, setIsRecording, setCurrentRecordingSection]);

    const toggleRecording = () => {
        if (isSectionRecording) {
            stopRecording(section);
        } else {
            startRecording(section);
        }
    };

    const buttonClasses = `rounded-full p-2 focus:outline-none relative ${isSectionRecording
        ? 'bg-red-500'
        : isDisabled
            ? 'bg-gray-200 cursor-not-allowed'
            : ' hover:bg-gray-200'
        }`;

    const iconClasses = `text-indigo-900 ${isSectionRecording
        ? 'animate-pulse'
        : isDisabled
            ? 'opacity-50'
            : ''
        }`;

    const buttonTitle = isSectionRecording
        ? 'Stop Recording'
        : isDisabled
            ? `Cannot record ${label} while ${currentRecordingSection.replace('_', ' ').toUpperCase()} is recording`
            : 'Start Recording';

    return (
        <button
            className={buttonClasses}
            onClick={() => toggleRecording(section)}
            title={buttonTitle}
            disabled={isDisabled}
        >
            <span className="flex items-center">
                <FontAwesomeIcon
                    icon={faMicrophone}
                    className={iconClasses}
                />
                <span className={`ml-2 ${isDisabled ? 'text-gray-500' : 'text-indigo-900'}`}>
                    Smart Edit
                </span>
            </span>
        </button>
    );
};

export default SmartEditButton; 