import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, CircularProgress, Alert, Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useAuth0 } from '@auth0/auth0-react';
import { callProtectedApi, protectedResources } from '../authConfig';
import { patientsService } from '../services/patientsService';

export default function FollowupButton({ patient, visitContext, compact = false, documentId = null }) {
  const [isOpen, setIsOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const handleOpen = () => {
    setIsOpen(true);
    setError(null);
    setSuccessMessage(null);
  };

  const handleClose = () => {
    setIsOpen(false);
    setPhoneNumber('');
    setError(null);
    setSuccessMessage(null);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleFollowupCall = async () => {
    if (!phoneNumber) {
      setError('Please enter a phone number');
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      // If documentId is provided, use the followup call endpoint
      if (documentId) {
        // 1. Create a patient record first
        await patientsService.createPatient({
          full_name: patient?.name || "Unknown Patient",
          document_id: documentId,
          phone_number: phoneNumber
        }, getAccessTokenSilently);
        
        // 2. Then initiate the follow-up call
        await callProtectedApi(
          getAccessTokenSilently,
          protectedResources.apiElevenLabsFollowupCall.endpoint,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              document_id: documentId,
              phone_number: phoneNumber,
              provider_name: "Cleo Health Main Clinic"
            })
          }
        );
      } else {
        // Use the regular call endpoint with limited context information
        await callProtectedApi(
          getAccessTokenSilently,
          protectedResources.apiElevenLabsInitiateCall.endpoint,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              to_number: phoneNumber,
              conversation_initiation_client_data: {
                dynamic_variables: {
                  provider_name: "Cleo Health Main Clinic",
                  patient_name: patient?.name || "the patient",
                  visit_summary: visitContext?.summary || "a recent visit",
                  follow_up_instructions: visitContext?.followupInstructions || "following up on your care"
                }
              }
            })
          }
        );
      }

      setSuccessMessage('Followup call initiated successfully');
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (error) {
      console.error('Error initiating followup call:', error);
      setError(error.message || 'Failed to initiate followup call');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {compact ? (
        <Button
          variant="outlined"
          size="small"
          onClick={handleOpen}
          startIcon={<PhoneIcon fontSize="small" />}
          sx={{
            minWidth: '30px',
            height: '30px',
            padding: '4px 8px',
            borderRadius: '4px',
            textTransform: 'none',
            fontSize: '0.75rem',
            lineHeight: 1,
            whiteSpace: 'nowrap',
          }}
        >
          Followup
        </Button>
      ) : (
        <Button 
          variant="contained"
          color="primary"
          startIcon={<PhoneIcon />}
          onClick={handleOpen}
          sx={{ 
            borderRadius: 2,
            textTransform: 'none',
            fontWeight: 'medium'
          }}
        >
          Schedule Followup
        </Button>
      )}

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Schedule Patient Followup Call
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, mb: 3 }}>
            <Typography variant="body1" gutterBottom>
              Schedule a followup call for {patient?.name || "the patient"} 
              {visitContext?.summary ? ` regarding ${visitContext.summary}` : ""}
            </Typography>
            
            <TextField
              fullWidth
              label="Patient Phone Number"
              variant="outlined"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="+1 (555) 000-0000"
              sx={{ mt: 2 }}
              autoFocus
              error={!!error}
              helperText={error}
              InputProps={{
                startAdornment: (
                  <PhoneIcon sx={{ color: 'text.secondary', mr: 1 }} />
                ),
              }}
            />
          </Box>
          
          {successMessage && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {successMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button 
            onClick={handleClose} 
            variant="outlined"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleFollowupCall} 
            variant="contained"
            color="primary"
            disabled={isLoading || !phoneNumber.trim()}
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <PhoneIcon />}
          >
            {isLoading ? 'Initiating Call...' : 'Schedule Followup Call'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
} 