import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { replacementsApi } from '../api/replacementsApi';
import { PlusIcon, TrashIcon, PencilIcon, SparklesIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react';
import { useAuth } from '../contexts/AuthProvider';

const defaultSectionOptions = [
    { value: 'ALL', label: 'All Sections' },
    { value: 'hpi', label: 'HPI' },
    { value: 'ros', label: 'Review of Systems' },
    { value: 'physical_exam', label: 'Physical Exam' },
    { value: 'reevaluation', label: 'Evaluation and Plan' },
    { value: 'mdm', label: 'MDM' },
];

const hospitalistSectionOptions = {
    ADMISSION_NOTE: [
        { value: 'hpi', label: 'HPI' },
        { value: 'ros', label: 'Review of Systems' },
        { value: 'physical_exam', label: 'Physical Exam' },
        { value: 'reevaluation', label: 'Assessment and Plan (Admission)' },
    ],
    PROGRESS_NOTE: [
        { value: 'subjective', label: 'Subjective' },
        { value: 'objective', label: 'Objective' },
        { value: 'assessment_and_plan', label: 'Assessment and Plan (Progress)' },
    ],
    DISCHARGE_SUMMARY: [
        { value: 'hospitalist_discharge_summary', label: 'Discharge Summary' },
    ],
};

export default function ReplacementsSettings({ onSuccessfulSave }) {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo } = useAuth();
    const [replacements, setReplacements] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editingReplacement, setEditingReplacement] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    // Form states
    const [target, setTarget] = useState('');
    const [replacement, setReplacement] = useState('');
    const [section, setSection] = useState('ALL');
    const [isFuzzy, setIsFuzzy] = useState(false);
    const [knownVariations, setKnownVariations] = useState('');
    const [contextHint, setContextHint] = useState('');

    useEffect(() => {
        fetchReplacements();
    }, []);

    const fetchReplacements = async () => {
        try {
            const response = await replacementsApi.getReplacements(getAccessTokenSilently);
            const replacementsArray = Object.entries(response.replacements).map(([id, data]) => ({
                replacement_id: parseInt(id),
                ...data
            }));
            setReplacements(replacementsArray);
        } catch (error) {
            console.error('Error fetching replacements:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Helper function to get section label
    const getSectionLabel = (sectionValue) => {
        if (actorInfo?.actor_specialty === 'HOSPITALIST') {
            // Check all hospitalist section options
            for (const noteType in hospitalistSectionOptions) {
                const section = hospitalistSectionOptions[noteType].find(s => s.value === sectionValue);
                if (section) return section.label;
            }
            return sectionValue === 'ALL' ? 'All Sections' : sectionValue;
        } else {
            // Check default section options
            const section = defaultSectionOptions.find(s => s.value === sectionValue);
            return section ? section.label : sectionValue;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const replacementData = {
                replacement_id: editingReplacement?.replacement_id,
                target: isFuzzy ? null : target,
                replacement,
                section: isFuzzy ? 'ALL' : section,
                is_fuzzy: isFuzzy,
                known_variations: isFuzzy ? knownVariations : null,
                context_hint: isFuzzy ? contextHint : null
            };

            await replacementsApi.upsertReplacement(getAccessTokenSilently, replacementData);
            await fetchReplacements();
            resetForm();
            onSuccessfulSave('Replacement saved successfully');
        } catch (error) {
            console.error('Error saving replacement:', error);
        }
    };

    const handleDelete = async (replacementId) => {
        try {
            await replacementsApi.deleteReplacement(getAccessTokenSilently, replacementId);
            await fetchReplacements();
            onSuccessfulSave('Replacement deleted successfully');
        } catch (error) {
            console.error('Error deleting replacement:', error);
        }
    };

    const handleEdit = (replacement) => {
        setEditingReplacement(replacement);
        setTarget(replacement.target || '');
        setReplacement(replacement.replacement);
        setSection(replacement.section);
        setIsFuzzy(replacement.is_fuzzy);
        setKnownVariations((replacement.known_variations || []));
        setContextHint(replacement.context_hint || '');
    };

    const resetForm = () => {
        setEditingReplacement(null);
        setTarget('');
        setReplacement('');
        setSection('ALL');
        setIsFuzzy(false);
        setKnownVariations('');
        setContextHint('');
    };

    const filteredReplacements = replacements.filter(r =>
        r.target?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        r.replacement.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">
                Replacements
            </h1>

            <div className="mb-6 bg-blue-50 p-4 rounded-md">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-blue-800">About Replacements</h3>
                        <div className="mt-2 text-sm text-blue-700">
                            <p className="mb-2">Two types of replacements are available:</p>
                            <ul className="list-disc pl-5 space-y-1">
                                <li><span className="font-medium">Standard Replacement:</span> Exactly replaces specific words or phrases in your documentation.</li>
                                <li><span className="font-medium">Smart Replacement:</span> Uses AI to identify and correct common misspellings of terms (great for frequently misspelled names or facilities).</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2">
                        {/* Form Section */}
                        <div className="space-y-6">
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div className="flex items-center gap-2">
                                    <span className="flex items-center group relative">
                                        <SparklesIcon className={`h-5 w-5 mr-2 ${isFuzzy ? 'text-indigo-600' : 'text-gray-400'}`} />
                                        <span className="text-sm font-medium text-gray-700">Smart Replacement</span>
                                        <span className="hidden group-hover:block absolute bottom-full left-0 mb-2 w-64 bg-gray-900 text-white text-xs rounded p-2">
                                            Smart replacements use AI to identify and fix common misspellings of terms like names and facilities.
                                        </span>
                                    </span>
                                    <Switch
                                        checked={isFuzzy}
                                        onChange={setIsFuzzy}
                                        className={`${isFuzzy ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                                    >
                                        <span className={`${isFuzzy ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                    </Switch>
                                </div>

                                {!isFuzzy && (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Find Text
                                        </label>
                                        <input
                                            type="text"
                                            value={target}
                                            onChange={(e) => setTarget(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            required={!isFuzzy}
                                        />
                                    </div>
                                )}

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {isFuzzy ? 'Correct Spelling' : 'Replace With'}
                                    </label>
                                    <input
                                        type="text"
                                        value={replacement}
                                        onChange={(e) => setReplacement(e.target.value)}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        required
                                    />
                                </div>

                                {isFuzzy ? (
                                    <>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Known Variations
                                            </label>
                                            <textarea
                                                value={knownVariations}
                                                onChange={(e) => setKnownVariations(e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                rows="3"
                                                placeholder="Enter common misspellings, separated by commas..."
                                            />
                                            <p className="mt-1 text-sm text-gray-500">
                                                Example: "Smyth, Smith, Smithe" for "Dr. Smythe"
                                            </p>
                                        </div>
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">
                                                Context Hint
                                            </label>
                                            <input
                                                type="text"
                                                value={contextHint}
                                                onChange={(e) => setContextHint(e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                placeholder="e.g., This is a doctor's name"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Section
                                        </label>
                                        <select
                                            value={section}
                                            onChange={(e) => setSection(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                            {actorInfo?.actor_specialty === 'HOSPITALIST' ? (
                                                <>
                                                    <option value="ALL">All Sections</option>
                                                    <optgroup label="Admission Note">
                                                        <option value="hpi">HPI</option>
                                                        <option value="ros">Review of Systems</option>
                                                        <option value="physical_exam">Physical Exam</option>
                                                        <option value="reevaluation">Assessment and Plan (Admission)</option>
                                                    </optgroup>
                                                    <optgroup label="Progress Note">
                                                        <option value="subjective">Subjective</option>
                                                        <option value="objective">Objective</option>
                                                        <option value="assessment_and_plan">Assessment and Plan (Progress)</option>
                                                    </optgroup>
                                                    <optgroup label="Discharge Summary">
                                                        <option value="hospitalist_discharge_summary">Discharge Summary</option>
                                                    </optgroup>
                                                </>
                                            ) : (
                                                defaultSectionOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                )}

                                <div className="flex justify-end space-x-3">
                                    {editingReplacement && (
                                        <button
                                            type="button"
                                            onClick={resetForm}
                                            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Cancel
                                        </button>
                                    )}
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {editingReplacement ? 'Update' : 'Add'} Replacement
                                    </button>
                                </div>
                            </form>
                        </div>

                        {/* List Section */}
                        <div>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    placeholder="Search replacements..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div className="overflow-y-auto max-h-96">
                                <ul className="divide-y divide-gray-200">
                                    {filteredReplacements.map((r) => (
                                        <li key={r.replacement_id} className="py-4">
                                            <div className="flex justify-between items-start">
                                                <div className="flex-1">
                                                    {r.is_fuzzy ? (
                                                        <div className="flex items-center">
                                                            <SparklesIcon className="h-5 w-5 text-indigo-600 mr-2" />
                                                            <p className="text-sm font-medium text-gray-900">
                                                                Smart Replacement: {r.replacement}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <p className="text-sm font-medium text-gray-900">
                                                            {r.target} → {r.replacement}
                                                        </p>
                                                    )}
                                                    {r.is_fuzzy ? (
                                                        <div className="mt-1">
                                                            {r.known_variations?.length > 0 && (
                                                                <p className="text-sm text-gray-500">
                                                                    Known variations: {r.known_variations}
                                                                </p>
                                                            )}
                                                            {r.context_hint && (
                                                                <p className="text-sm text-gray-500">
                                                                    Context: {r.context_hint}
                                                                </p>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <p className="text-sm text-gray-500">
                                                            Section: {getSectionLabel(r.section)}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="flex space-x-2">
                                                    <button
                                                        onClick={() => handleEdit(r)}
                                                        className="text-indigo-600 hover:text-indigo-900"
                                                    >
                                                        <PencilIcon className="h-5 w-5" />
                                                    </button>
                                                    <button
                                                        onClick={() => handleDelete(r.replacement_id)}
                                                        className="text-red-600 hover:text-red-900"
                                                    >
                                                        <TrashIcon className="h-5 w-5" />
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
} 