import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import RiskScoreCalculator from './RiskScoreCalculator';

/**
 * RiskScoreButton component for displaying a risk score button with dropdown
 * @param {Object} props - Component props
 * @param {Array} props.riskScores - Array of available risk scores
 * @param {string} props.documentStatus - Current document status
 * @param {string} props.reevaluation - Current reevaluation text
 * @param {Function} props.updateSection - Function to update document sections
 * @param {Function} props.setSaveTriggered - Function to trigger saving
 * @param {string|number|null} props.currentRiskScoreId - ID of the current risk score (optional, for external control)
 * @param {Function} props.setCurrentRiskScoreId - Function to update the current risk score ID (optional, for external control)
 * @param {boolean} props.riskScoreCalculatorVisible - Whether the risk score calculator is visible (optional, for external control)
 * @param {Function} props.setRiskScoreCalculatorVisible - Function to update the risk score calculator visibility (optional, for external control)
 * @param {React.RefObject} props.dropdownRef - External ref for the dropdown container (for click-outside detection)
 * @param {boolean} props.showRiskScoreDropdown - Whether the risk score dropdown is visible (optional, for external control)
 * @param {Function} props.setShowRiskScoreDropdown - Function to update the risk score dropdown visibility (optional, for external control)
 */
const RiskScoreButton = ({
    riskScores,
    documentStatus,
    reevaluation,
    updateSection,
    setSaveTriggered,
    currentRiskScoreId: externalRiskScoreId = null,
    setCurrentRiskScoreId: externalSetCurrentRiskScoreId = null,
    riskScoreCalculatorVisible: externalCalculatorVisible = null,
    setRiskScoreCalculatorVisible: externalSetCalculatorVisible = null,
    dropdownRef = null,
    showRiskScoreDropdown: externalShowRiskScoreDropdown = null,
    setShowRiskScoreDropdown: externalSetShowRiskScoreDropdown = null
}) => {
    // Use internal state if external state is not provided
    const [internalShowRiskScoreDropdown, setInternalShowRiskScoreDropdown] = useState(false);
    const [internalRiskScoreCalculatorVisible, setInternalRiskScoreCalculatorVisible] = useState(false);
    const [internalCurrentRiskScoreId, setInternalCurrentRiskScoreId] = useState(null);

    // Create an internal ref if no external ref is provided
    const internalDropdownRef = useRef(null);

    // Use the external ref if provided, otherwise use the internal ref
    const riskScoreDropdownRef = dropdownRef || internalDropdownRef;

    // Determine whether to use internal or external state
    const isExternallyControlled = externalSetCalculatorVisible !== null && externalSetCurrentRiskScoreId !== null;
    const isDropdownExternallyControlled = externalSetShowRiskScoreDropdown !== null;

    // Use either the external or internal state based on what's provided
    const showRiskScoreDropdown = isDropdownExternallyControlled ? externalShowRiskScoreDropdown : internalShowRiskScoreDropdown;
    const setShowRiskScoreDropdown = isDropdownExternallyControlled ? externalSetShowRiskScoreDropdown : setInternalShowRiskScoreDropdown;

    const riskScoreCalculatorVisible = isExternallyControlled ? externalCalculatorVisible : internalRiskScoreCalculatorVisible;
    const setRiskScoreCalculatorVisible = isExternallyControlled ? externalSetCalculatorVisible : setInternalRiskScoreCalculatorVisible;

    const currentRiskScoreId = isExternallyControlled ? externalRiskScoreId : internalCurrentRiskScoreId;
    const setCurrentRiskScoreId = isExternallyControlled ? externalSetCurrentRiskScoreId : setInternalCurrentRiskScoreId;

    // Sync internal state with external state when external state changes
    useEffect(() => {
        if (isExternallyControlled && externalRiskScoreId !== null) {
            setInternalCurrentRiskScoreId(externalRiskScoreId);
        }
    }, [isExternallyControlled, externalRiskScoreId]);

    useEffect(() => {
        if (isExternallyControlled && externalCalculatorVisible !== null) {
            setInternalRiskScoreCalculatorVisible(externalCalculatorVisible);
        }
    }, [isExternallyControlled, externalCalculatorVisible]);

    useEffect(() => {
        if (isDropdownExternallyControlled && externalShowRiskScoreDropdown !== null) {
            setInternalShowRiskScoreDropdown(externalShowRiskScoreDropdown);
        }
    }, [isDropdownExternallyControlled, externalShowRiskScoreDropdown]);

    // Log for debugging
    useEffect(() => {
        console.log('RiskScoreButton: showRiskScoreDropdown =', showRiskScoreDropdown);
        console.log('RiskScoreButton: isDropdownExternallyControlled =', isDropdownExternallyControlled);
    }, [showRiskScoreDropdown, isDropdownExternallyControlled]);

    // Check if the document is in a state where risk scores can be calculated
    const canCalculateRiskScore = !(
        documentStatus === 'PRE_ENCOUNTER' ||
        documentStatus === 'ENCOUNTER_STARTED' ||
        documentStatus === 'IN_PROGRESS'
    );

    // Handle risk score selection
    const handleRiskScoreSelect = (scoreId) => {
        setCurrentRiskScoreId(scoreId);
        setRiskScoreCalculatorVisible(true);
        setShowRiskScoreDropdown(false);
    };

    // Close the risk score calculator
    const handleCloseRiskScoreCalculator = () => {
        setRiskScoreCalculatorVisible(false);
    };

    if (!canCalculateRiskScore) {
        return null;
    }

    return (
        <>
            <div className="relative z-10" ref={riskScoreDropdownRef}>
                <button
                    onClick={() => {
                        console.log('Risk score button clicked, toggling dropdown from', showRiskScoreDropdown, 'to', !showRiskScoreDropdown);
                        setShowRiskScoreDropdown(!showRiskScoreDropdown);
                    }}
                    className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:bg-indigo-800 transition-shadow duration-200 z-10"
                    title="Calculate Risk Score"
                >
                    <FontAwesomeIcon icon={faChartLine} className="h-5 w-5" />
                    <span>Risk Score</span>
                </button>
                {showRiskScoreDropdown && (
                    <div
                        className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="risk-score-dropdown"
                    >
                        <div className="py-1" role="none">
                            {riskScores.map((score) => (
                                <button
                                    key={score.risk_score_id}
                                    type="button"
                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100 hover:text-indigo-900"
                                    role="menuitem"
                                    onClick={() => handleRiskScoreSelect(score.risk_score_id)}
                                >
                                    {score.score_name}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {/* Risk Score Calculator Modal */}
            <RiskScoreCalculator
                isVisible={riskScoreCalculatorVisible}
                onClose={handleCloseRiskScoreCalculator}
                riskScoreId={currentRiskScoreId}
                riskScores={riskScores}
                reevaluation={reevaluation}
                updateSection={updateSection}
                setSaveTriggered={setSaveTriggered}
            />
        </>
    );
};

export default RiskScoreButton; 