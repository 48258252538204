// src/components/TransferPatientModal.jsx

import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes,
    faChevronRight,
    faCheckCircle,
    faExclamationTriangle,
    faSpinner,
    faTrash,
    faExchangeAlt,
    faUsers,
    faLightbulb,
    faSave,
    faLock,
} from '@fortawesome/free-solid-svg-icons';
import { callApiWithToken } from '../api/apiUtils';
import { useAuth } from '../contexts/AuthProvider';
import { useAuth0 } from "@auth0/auth0-react";
import { generateTransferSummary, saveTransferSummary } from '../api/apiUtils';
import { protectedResources, callProtectedApi } from '../authConfig';

function TransferPatientModal({
    isOpen,
    onClose,
    stay,
    groupActors,
    isLoading,
    protectedResources,
    onPatientTransferred,
    onPatientReleased
}) {
    // Core state
    const [transferType, setTransferType] = useState('pool'); // Default to pool
    const [selectedActor, setSelectedActor] = useState(null);
    const [summaryContent, setSummaryContent] = useState('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    // Loading states
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSummaryLoading, setIsSummaryLoading] = useState(false);
    const [isSavingSummary, setIsSavingSummary] = useState(false);

    // Error/success states
    const [transferError, setTransferError] = useState(null);
    const [summaryError, setSummaryError] = useState(null);
    const [summarySuccessMessage, setSummarySuccessMessage] = useState(null);

    const { actorInfo } = useAuth();
    const [isFeatureDisabled, setIsFeatureDisabled] = useState(true);
    const { getAccessTokenSilently } = useAuth0();

    // Computed states for button enabling/disabling
    const canTransfer = useMemo(() => {
        const summaryValid = !hasUnsavedChanges;

        if (transferType === 'pool') {
            return summaryValid;
        }

        return summaryValid && selectedActor !== null;
    }, [transferType, selectedActor, hasUnsavedChanges]);

    // Required actions tracking
    const requiredActions = useMemo(() => {
        const actions = [];

        if (hasUnsavedChanges) {
            actions.push('Save the current summary');
        }

        if (transferType === 'provider' && !selectedActor) {
            actions.push('Select a provider to transfer to');
        }

        return actions;
    }, [transferType, selectedActor, hasUnsavedChanges]);

    // Fetch existing summary when modal opens
    useEffect(() => {
        if (isOpen) {
            resetStates();
            setSummaryContent(stay?.transfer_summary || '');
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            // Check if user is D2C (org_id === 12)
            setIsFeatureDisabled(actorInfo?.org_id === 12);
        }
    }, [isOpen, actorInfo]);

    // Function to reset states
    const resetStates = () => {
        setSelectedActor(null);
        setIsProcessing(false);
        setTransferError(null);
        setSummaryContent('');
        setIsSummaryLoading(false);
        setIsSavingSummary(false);
        setSummaryError(null);
        setSummarySuccessMessage(null);
        setHasUnsavedChanges(false);
        setTransferType('pool');
    };

    // Handle modal close with unsaved changes check
    const handleClose = () => {
        if (hasUnsavedChanges) {
            const confirmClose = window.confirm('You have unsaved changes. Are you sure you want to close?');
            if (!confirmClose) {
                return;
            }
        }
        onClose();
    };

    // Handle AI summary generation
    const handleGenerateAISummary = async () => {
        setIsSummaryLoading(true);
        setSummaryError(null);
        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiGenerateTransferSummary.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    })
                }
            );

            if (data.summary) {
                setSummaryContent(data.summary);
                setHasUnsavedChanges(true);
            } else {
                throw new Error('Failed to generate AI summary.');
            }
        } catch (error) {
            console.error('Error generating AI summary:', error);
            setSummaryError(error.message || 'An error occurred while generating the AI summary.');
        } finally {
            setIsSummaryLoading(false);
        }
    };

    // Handle saving the summary
    const handleSaveSummary = async () => {
        setIsSavingSummary(true);
        setSummaryError(null);
        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiSetTransferSummary.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        transfer_summary: summaryContent,
                    })
                }
            );

            if (data.success) {
                setSummarySuccessMessage('Transfer summary saved successfully.');
                setHasUnsavedChanges(false);
            } else {
                throw new Error(data.detail || 'Failed to save transfer summary.');
            }
        } catch (error) {
            console.error('Error saving transfer summary:', error);
            setSummaryError(error.message || 'An error occurred while saving the transfer summary.');
        } finally {
            setIsSavingSummary(false);
        }
    };

    // Handle deleting the summary
    const handleDeleteSummary = async () => {
        const confirmDelete = window.confirm('Are you sure you want to delete the transfer summary?');
        if (!confirmDelete) {
            return;
        }
        setSummaryContent('');
        setHasUnsavedChanges(false);
        setSummarySuccessMessage('Transfer summary deleted.');

    };

    // Handle transferring the patient
    const handleTransferPatient = async () => {
        if (hasUnsavedChanges) {
            alert('Please save or delete the transfer summary before transferring the patient.');
            return;
        }
        if (!selectedActor) {
            setTransferError('Please select a provider to transfer the patient.');
            return;
        }
        setIsProcessing(true);
        setTransferError(null);
        try {
            await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiTransferPatient.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        new_provider_id: selectedActor.actor_id,
                    })
                }
            );

            onPatientTransferred(stay.inpatient_id, selectedActor.actor_id);
            onClose();
        } catch (error) {
            console.error('Error:', error);
            setTransferError(error.message);
        } finally {
            setIsProcessing(false);
        }
    };

    // Handle summary content change
    const handleSummaryChange = (e) => {
        setSummaryContent(e.target.value);
        setHasUnsavedChanges(true);
    };

    const handleReleaseToPool = async () => {
        setIsProcessing(true);
        setTransferError(null);

        try {
            await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiReleaseToPool.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        inpatient_stay_id: stay.inpatient_id,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        transfer_summary: summaryContent || undefined,
                    })
                }
            );

            onPatientReleased(stay.inpatient_id);
            onClose();
        } catch (error) {
            console.error('Error:', error);
            setTransferError(error.message);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        isOpen && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-4/5 lg:w-3/4 shadow-lg rounded-md bg-white">
                    {/* Header */}
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-semibold text-gray-900">
                            {transferType === 'pool' ? 'Release Patient to Pool' : 'Transfer Patient to Provider'}
                        </h3>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>

                    {/* Enterprise Feature Notice - Show at top */}
                    {isFeatureDisabled && (
                        <div className="mb-6 p-4 bg-amber-50 border border-amber-200 rounded-md">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        className="h-5 w-5 text-amber-400"
                                    />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-amber-800">
                                        Enterprise Feature
                                    </h3>
                                    <div className="mt-2 text-sm text-amber-700">
                                        <p>
                                            Patient transfer and pool features are only available for Enterprise users.
                                            Please contact our sales team to learn more about Enterprise plans.
                                        </p>
                                    </div>
                                    <div className="mt-4">
                                        <a
                                            href="mailto:support@cleo-ai.co"
                                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-amber-700 bg-amber-100 hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                                        >
                                            Contact Sales
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Transfer Type Toggle */}
                    <div className="mb-6">
                        <div className="flex rounded-md shadow-sm" role="group">
                            <button
                                onClick={() => setTransferType('pool')}
                                className={`flex-1 px-4 py-2 text-sm font-medium rounded-l-lg
                                    ${transferType === 'pool'
                                        ? 'bg-indigo-600 text-white'
                                        : 'bg-white text-gray-700 hover:bg-gray-50'
                                    } border border-gray-200`}
                            >
                                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                                Release to Pool
                            </button>
                            <button
                                onClick={() => setTransferType('provider')}
                                className={`flex-1 px-4 py-2 text-sm font-medium rounded-r-lg
                                    ${transferType === 'provider'
                                        ? 'bg-indigo-600 text-white'
                                        : 'bg-white text-gray-700 hover:bg-gray-50'
                                    } border border-gray-200`}
                            >
                                <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" />
                                Transfer to Provider
                            </button>
                        </div>
                    </div>

                    {/* Transfer Summary Section */}
                    <div className="mb-6">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-lg font-medium text-gray-800">Transfer Summary</h3>
                            <div className="flex space-x-2">
                                <button
                                    onClick={handleGenerateAISummary}
                                    className="px-3 py-1 bg-green-600 text-white text-sm rounded-md hover:bg-green-700 transition flex items-center"
                                    disabled={isSummaryLoading}
                                >
                                    {isSummaryLoading ? (
                                        <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                                    ) : (
                                        <FontAwesomeIcon icon={faLightbulb} className="mr-2" />
                                    )}
                                    Generate AI Summary
                                </button>
                                <button
                                    onClick={handleSaveSummary}
                                    className={`px-3 py-1 text-sm rounded-md flex items-center
                                        ${hasUnsavedChanges
                                            ? 'bg-blue-600 text-white hover:bg-blue-700'
                                            : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                        }`}
                                    disabled={!hasUnsavedChanges || isSavingSummary}
                                >
                                    {isSavingSummary ? (
                                        <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                                    ) : (
                                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                                    )}
                                    Save Summary
                                </button>
                            </div>
                        </div>
                        <textarea
                            className={`w-full h-32 p-2 border rounded-md transition-colors
                                ${hasUnsavedChanges ? 'border-yellow-400' : 'border-gray-300'}`}
                            value={summaryContent}
                            onChange={handleSummaryChange}
                            placeholder="Enter transfer summary..."
                        />
                        {summaryError && (
                            <div className="flex items-center text-red-500 mt-2">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                <span>{summaryError}</span>
                            </div>
                        )}
                        {summarySuccessMessage && (
                            <div className="flex items-center text-green-600 mt-2">
                                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                <span>{summarySuccessMessage}</span>
                            </div>
                        )}
                    </div>

                    {/* Provider Selection - Only show if transferType is 'provider' */}
                    {transferType === 'provider' && (
                        <div className="mb-6">
                            <h3 className="text-lg font-medium text-gray-800 mb-4">
                                Select Provider
                            </h3>
                            {isLoading ? (
                                <div className="flex items-center justify-center p-4">
                                    <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                                    <span>Loading providers...</span>
                                </div>
                            ) : groupActors.length === 0 ? (
                                <div className="text-center p-4 bg-gray-50 rounded-md">
                                    <p className="text-gray-500">No other providers available</p>
                                </div>
                            ) : (
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                    {groupActors.map((actor) => (
                                        <button
                                            key={actor.actor_id}
                                            onClick={() => setSelectedActor(actor)}
                                            className={`p-3 rounded-lg border transition-all
                                                ${selectedActor?.actor_id === actor.actor_id
                                                    ? 'border-indigo-500 bg-indigo-50'
                                                    : 'border-gray-200 hover:border-indigo-300'
                                                }`}
                                        >
                                            <div className="flex items-center">
                                                <div className="flex-1 text-left">
                                                    <p className="font-medium">{actor.full_name}</p>
                                                    <p className="text-sm text-gray-500">{actor.email}</p>
                                                </div>
                                                {selectedActor?.actor_id === actor.actor_id && (
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        className="text-indigo-500 ml-2"
                                                    />
                                                )}
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}

                    {/* Action Buttons */}
                    <div className="flex justify-end space-x-3">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition"
                            disabled={isProcessing}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={transferType === 'pool' ? handleReleaseToPool : handleTransferPatient}
                            disabled={!canTransfer || isProcessing || isFeatureDisabled}
                            className={`px-4 py-2 rounded-md transition flex items-center
                                ${canTransfer && !isFeatureDisabled
                                    ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                }`}
                        >
                            {isProcessing ? (
                                <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                            ) : (
                                <FontAwesomeIcon
                                    icon={transferType === 'pool' ? faUsers : faExchangeAlt}
                                    className="mr-2"
                                />
                            )}
                            {transferType === 'pool' ? 'Release to Pool' : 'Transfer Patient'}
                        </button>
                    </div>

                    {/* Required Actions */}
                    {requiredActions.length > 0 && (
                        <div className="mt-6 p-4 bg-yellow-50 border border-yellow-200 rounded-md">
                            <h4 className="text-sm font-medium text-yellow-800 mb-2">
                                Required actions before transfer:
                            </h4>
                            <ul className="list-disc list-inside text-sm text-yellow-700">
                                {requiredActions.map((action, index) => (
                                    <li key={index}>{action}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Error Display */}
                    {transferError && (
                        <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-md">
                            <div className="flex items-center text-red-700">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                <span>{transferError}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    );
}

export default TransferPatientModal;
