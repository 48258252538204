import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../contexts/AuthProvider';
import { useLocation } from 'react-router-dom';
import {
    ChartBarIcon,
    FunnelIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    ArrowsUpDownIcon,
} from '@heroicons/react/24/outline';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar,
} from 'recharts';

// Generate 6 months of historical data for each group
const generateHistoricalData = () => {
    const groups = ['North Fulton', 'Kennestone', 'Onslow', 'Chapel Hill'];
    const months = 6;
    const data = {};

    // Generate a base pattern that will be slightly modified for each group
    const basePattern = Array(months).fill(0).map(() => 60 + Math.floor(Math.random() * 11 - 5));

    groups.forEach(group => {
        const monthlyData = [];
        
        for (let i = months - 1; i >= 0; i--) {
            const date = new Date();
            date.setMonth(date.getMonth() - i);
            
            // Add small variation to base pattern for each group
            const score = basePattern[months - 1 - i] + Math.floor(Math.random() * 3 - 1); // ±1 variation per group
            
            monthlyData.push({
                date: date.toLocaleDateString('en-US', { month: 'short', year: '2-digit' }),
                score: Math.min(Math.max(score, 55), 65) // Ensure score stays between 55-65
            });
        }
        
        data[group] = monthlyData;
    });

    return data;
};

// Generate pentagon (radar) chart data for each group
const generateGroupMetrics = () => {
    const groups = ['North Fulton', 'Kennestone', 'Onslow', 'Chapel Hill'];
    const data = {};

    const baselineScores = {
        'Patient Greeting': 80,
        'Introduction': 77,
        'Duration': 37,
        'Explanation': 88,
        'Manage Up': 21
    };

    groups.forEach(group => {
        data[group] = [
            { criteria: 'Patient Greeting', score: 80 + Math.floor(Math.random() * 11 - 5), target: 82 },
            { criteria: 'Introduction', score: 77 + Math.floor(Math.random() * 11 - 5), target: 79 },
            { criteria: 'Duration', score: 37 + Math.floor(Math.random() * 11 - 5), target: 39 },
            { criteria: 'Explanation', score: 88 + Math.floor(Math.random() * 11 - 5), target: 90 },
            { criteria: 'Manage Up', score: 21 + Math.floor(Math.random() * 11 - 5), target: 23 },
        ];
    });

    return data;
};

const OrganizationPortalPatientExperience = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo } = useAuth();
    const location = useLocation();
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedSpecialty, setSelectedSpecialty] = useState('all');
    const [isLoading, setIsLoading] = useState(false);
    const [expandedGroups, setExpandedGroups] = useState(new Set());
    const [filters, setFilters] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [historicalData] = useState(generateHistoricalData());
    const [groupMetrics] = useState(generateGroupMetrics());

    // Get organization ID from location state if provided (for global admin viewing other orgs)
    const isGlobalAdminView = location.state?.isGlobalAdminView || false;
    const orgId = isGlobalAdminView ? location.state?.orgId : actorInfo?.org_id;

    // Mock hierarchical group data
    const mockGroups = [
        {
            id: 1,
            name: 'ApolloMD',
            level: 1,
            children: [
                {
                    id: 2,
                    name: 'Wellstar',
                    level: 2,
                    children: [
                        { id: 4, name: 'North Fulton', level: 3 },
                        { id: 5, name: 'Kennestone', level: 3 }
                    ]
                },
                {
                    id: 3,
                    name: 'UNC',
                    level: 2,
                    children: [
                        { id: 6, name: 'Onslow', level: 3 },
                        { id: 7, name: 'Chapel Hill', level: 3 }
                    ]
                }
            ]
        }
    ];

    const specialties = [
        { value: 'all', label: 'All Specialties' },
        { value: 'EMERGENCY_PHYSICIAN', label: 'Emergency Physician' },
        { value: 'ADVANCED_PRACTICE_PROVIDER', label: 'Advanced Practice Provider' },
    ];

    const mockData = [
        {
            provider_name: "John Smith",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "North Fulton",
            total_encounters: 150,
            patient_greeting_score: 82,
            intro_score: 75,
            duration_score: 35,
            explanation_score: 90,
            team_management_score: 23,
            overall_score: 63
        },
        {
            provider_name: "Sarah Johnson",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "Kennestone",
            total_encounters: 120,
            patient_greeting_score: 78,
            intro_score: 80,
            duration_score: 40,
            explanation_score: 85,
            team_management_score: 18,
            overall_score: 57
        },
        {
            provider_name: "Michael Chen",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "Chapel Hill",
            total_encounters: 180,
            patient_greeting_score: 83,
            intro_score: 73,
            duration_score: 33,
            explanation_score: 92,
            team_management_score: 25,
            overall_score: 61
        },
        {
            provider_name: "Emily Rodriguez",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "Onslow",
            total_encounters: 135,
            patient_greeting_score: 77,
            intro_score: 79,
            duration_score: 42,
            explanation_score: 86,
            team_management_score: 19,
            overall_score: 59
        },
        {
            provider_name: "James Wilson",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "North Fulton",
            total_encounters: 165,
            patient_greeting_score: 81,
            intro_score: 76,
            duration_score: 39,
            explanation_score: 89,
            team_management_score: 24,
            overall_score: 64
        },
        {
            provider_name: "Lisa Thompson",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "Kennestone",
            total_encounters: 110,
            patient_greeting_score: 84,
            intro_score: 78,
            duration_score: 34,
            explanation_score: 91,
            team_management_score: 17,
            overall_score: 58
        },
        {
            provider_name: "Robert Kim",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "Chapel Hill",
            total_encounters: 142,
            patient_greeting_score: 79,
            intro_score: 74,
            duration_score: 36,
            explanation_score: 87,
            team_management_score: 22,
            overall_score: 56
        },
        {
            provider_name: "Amanda Martinez",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "Onslow",
            total_encounters: 128,
            patient_greeting_score: 76,
            intro_score: 81,
            duration_score: 41,
            explanation_score: 84,
            team_management_score: 20,
            overall_score: 62
        },
        {
            provider_name: "David Park",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "Kennestone",
            total_encounters: 175,
            patient_greeting_score: 85,
            intro_score: 75,
            duration_score: 38,
            explanation_score: 93,
            team_management_score: 16,
            overall_score: 60
        },
        {
            provider_name: "Rachel Foster",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "North Fulton",
            total_encounters: 145,
            patient_greeting_score: 80,
            intro_score: 77,
            duration_score: 35,
            explanation_score: 88,
            team_management_score: 21,
            overall_score: 61
        },
        {
            provider_name: "Thomas Anderson",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "North Fulton",
            total_encounters: 158,
            patient_greeting_score: 83,
            intro_score: 79,
            duration_score: 38,
            explanation_score: 91,
            team_management_score: 22,
            overall_score: 65
        },
        {
            provider_name: "Maria Garcia",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "Chapel Hill",
            total_encounters: 132,
            patient_greeting_score: 81,
            intro_score: 76,
            duration_score: 36,
            explanation_score: 89,
            team_management_score: 20,
            overall_score: 57
        },
        {
            provider_name: "William Taylor",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "Kennestone",
            total_encounters: 170,
            patient_greeting_score: 79,
            intro_score: 78,
            duration_score: 39,
            explanation_score: 87,
            team_management_score: 23,
            overall_score: 59
        },
        {
            provider_name: "Jennifer Lee",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "Onslow",
            total_encounters: 125,
            patient_greeting_score: 82,
            intro_score: 75,
            duration_score: 35,
            explanation_score: 90,
            team_management_score: 19,
            overall_score: 63
        },
        {
            provider_name: "Christopher Brown",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "Chapel Hill",
            total_encounters: 162,
            patient_greeting_score: 84,
            intro_score: 77,
            duration_score: 37,
            explanation_score: 92,
            team_management_score: 24,
            overall_score: 58
        },
        {
            provider_name: "Jessica White",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "North Fulton",
            total_encounters: 140,
            patient_greeting_score: 80,
            intro_score: 79,
            duration_score: 40,
            explanation_score: 88,
            team_management_score: 21,
            overall_score: 61
        },
        {
            provider_name: "Daniel Martinez",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "Kennestone",
            total_encounters: 155,
            patient_greeting_score: 78,
            intro_score: 80,
            duration_score: 38,
            explanation_score: 86,
            team_management_score: 20,
            overall_score: 55
        },
        {
            provider_name: "Ashley Johnson",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "Onslow",
            total_encounters: 130,
            patient_greeting_score: 81,
            intro_score: 76,
            duration_score: 36,
            explanation_score: 89,
            team_management_score: 22,
            overall_score: 60
        },
        {
            provider_name: "Kevin Patel",
            specialty: "EMERGENCY_PHYSICIAN",
            group: "Chapel Hill",
            total_encounters: 168,
            patient_greeting_score: 83,
            intro_score: 78,
            duration_score: 39,
            explanation_score: 91,
            team_management_score: 23,
            overall_score: 64
        },
        {
            provider_name: "Sophia Rodriguez",
            specialty: "ADVANCED_PRACTICE_PROVIDER",
            group: "North Fulton",
            total_encounters: 138,
            patient_greeting_score: 82,
            intro_score: 77,
            duration_score: 37,
            explanation_score: 90,
            team_management_score: 21,
            overall_score: 59
        }
    ];

    const toggleGroup = (groupId) => {
        const newExpanded = new Set(expandedGroups);
        if (newExpanded.has(groupId)) {
            newExpanded.delete(groupId);
        } else {
            newExpanded.add(groupId);
        }
        setExpandedGroups(newExpanded);
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortData = (data) => {
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    // Function to get all descendant groups including the selected group
    const getGroupAndDescendants = (groupId) => {
        const result = new Set();
        
        const addGroupAndChildren = (group) => {
            result.add(group.name);
            if (group.children) {
                group.children.forEach(child => addGroupAndChildren(child));
            }
        };

        const findAndAddGroup = (groups, targetId) => {
            for (const group of groups) {
                if (group.id === targetId) {
                    addGroupAndChildren(group);
                    return true;
                }
                if (group.children && findAndAddGroup(group.children, targetId)) {
                    return true;
                }
            }
            return false;
        };

        findAndAddGroup(mockGroups, groupId);
        return result;
    };

    // Function to get the name of the selected group
    const getSelectedGroupName = (groupId) => {
        const findGroupName = (groups, targetId) => {
            for (const group of groups) {
                if (group.id === targetId) {
                    return group.name;
                }
                if (group.children) {
                    const found = findGroupName(group.children, targetId);
                    if (found) return found;
                }
            }
            return null;
        };

        return findGroupName(mockGroups, groupId);
    };

    // Update filterData to include hierarchical group filtering
    const filterData = (data) => {
        return data.filter(item => {
            // Apply group hierarchy filter
            if (selectedGroup) {
                const validGroups = getGroupAndDescendants(selectedGroup);
                if (!validGroups.has(item.group)) {
                    return false;
                }
            }

            // Apply specialty filter
            if (selectedSpecialty !== 'all' && item.specialty !== selectedSpecialty) {
                return false;
            }

            // Apply text filters
            return Object.entries(filters).every(([key, value]) => {
                if (!value) return true;
                const itemValue = String(item[key]).toLowerCase();
                return itemValue.includes(value.toLowerCase());
            });
        });
    };

    // Get the filtered data for charts
    const getFilteredMetrics = () => {
        const filteredData = filterData(mockData);
        if (filteredData.length === 0) return null;

        // Calculate averages for each metric
        const metrics = {
            patient_greeting_score: 0,
            intro_score: 0,
            duration_score: 0,
            explanation_score: 0,
            team_management_score: 0,
        };

        filteredData.forEach(row => {
            Object.keys(metrics).forEach(key => {
                metrics[key] += row[key];
            });
        });

        Object.keys(metrics).forEach(key => {
            metrics[key] = Math.round(metrics[key] / filteredData.length);
        });

        return [
            { criteria: 'Patient Greeting', score: metrics.patient_greeting_score, target: 82, baseline: 80 },
            { criteria: 'Introduction', score: metrics.intro_score, target: 79, baseline: 77 },
            { criteria: 'Duration', score: metrics.duration_score, target: 39, baseline: 37 },
            { criteria: 'Explanation', score: metrics.explanation_score, target: 90, baseline: 88 },
            { criteria: 'Manage Up', score: metrics.team_management_score, target: 23, baseline: 21 },
        ];
    };

    // Get the filtered historical data
    const getFilteredHistoricalData = () => {
        const selectedGroupName = getSelectedGroupName(selectedGroup);
        
        // If no group is selected, show average across all groups
        if (!selectedGroup) {
            const allData = Object.values(historicalData).reduce((acc, groupData) => {
                groupData.forEach((month, i) => {
                    if (!acc[i]) {
                        acc[i] = { date: month.date, score: 0, count: 0 };
                    }
                    acc[i].score += month.score;
                    acc[i].count += 1;
                });
                return acc;
            }, []);

            return allData.map(month => ({
                date: month.date,
                score: Math.round(month.score / month.count),
            }));
        }

        // Get all valid groups (selected group and its descendants)
        const validGroups = getGroupAndDescendants(selectedGroup);
        
        // If it's a leaf node (individual group), return its data directly
        if (historicalData[selectedGroupName]) {
            return historicalData[selectedGroupName];
        }

        // For parent groups, aggregate data from all descendant groups
        const relevantGroups = Object.keys(historicalData).filter(group => validGroups.has(group));
        
        if (relevantGroups.length === 0) return [];

        // Initialize aggregated data structure using the first group's dates
        const template = historicalData[relevantGroups[0]].map(month => ({
            date: month.date,
            score: 0,
            count: 0
        }));

        // Aggregate scores from all relevant groups
        const aggregatedData = relevantGroups.reduce((acc, groupName) => {
            historicalData[groupName].forEach((month, i) => {
                acc[i].score += month.score;
                acc[i].count += 1;
            });
            return acc;
        }, template);

        // Calculate averages
        return aggregatedData.map(month => ({
            date: month.date,
            score: Math.round(month.score / month.count)
        }));
    };

    const renderGroupOption = (group, depth = 0) => {
        const isExpanded = expandedGroups.has(group.id);
        const hasChildren = group.children && group.children.length > 0;
        const isSelected = selectedGroup === group.id;

        return (
            <div key={group.id} className="group-option">
                <div 
                    className={`flex items-center py-1 px-2 rounded-md hover:bg-gray-50 cursor-pointer
                        ${isSelected ? 'bg-indigo-50' : ''}`}
                    onClick={() => {
                        if (hasChildren) {
                            toggleGroup(group.id);
                        }
                        setSelectedGroup(group.id);
                    }}
                >
                    <div className="flex items-center flex-1">
                        {hasChildren ? (
                            <ChevronRightIcon 
                                className={`h-3 w-3 text-gray-400 transform transition-transform duration-150
                                    ${isExpanded ? 'rotate-90' : ''}`}
                            />
                        ) : (
                            <div className="w-3" />
                        )}
                        <span className={`ml-1 text-xs ${isSelected ? 'font-semibold text-indigo-600' : 'text-gray-700'}`}>
                            {group.name}
                        </span>
                    </div>
                </div>
                {hasChildren && isExpanded && (
                    <div className={`ml-3 border-l border-gray-200 pl-2 mt-1`}>
                        {group.children.map(child => renderGroupOption(child, depth + 1))}
                    </div>
                )}
            </div>
        );
    };

    const filteredAndSortedData = sortData(filterData(mockData));

    return (
        <div className="min-h-screen py-4 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <div className="mb-4">
                    <h1 className="text-2xl font-bold text-gray-900">Patient Experience Analytics</h1>
                    <p className="mt-1 text-sm text-gray-600">
                        Monitor and analyze patient experience metrics across your organization.
                    </p>
                </div>

                {/* Top Section with Filters and Charts */}
                <div className="grid grid-cols-3 gap-4 mb-4">
                    {/* Left Side - Filters */}
                    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                        <div className="px-3 py-3">
                            <h2 className="text-sm font-semibold text-gray-900 mb-2">Filters</h2>
                            <div className="space-y-3">
                                {/* Hierarchical Group Filter */}
                                <div>
                                    <label className="block text-xs font-medium text-gray-700 mb-1">
                                        Group
                                    </label>
                                    <div className="mt-1 bg-white border border-gray-300 rounded-md shadow-sm">
                                        <div className="p-2 max-h-32 overflow-y-auto">
                                            {mockGroups.map(group => renderGroupOption(group))}
                                        </div>
                                    </div>
                                </div>

                                {/* Specialty Filter */}
                                <div>
                                    <label htmlFor="specialty" className="block text-xs font-medium text-gray-700 mb-1">
                                        Specialty
                                    </label>
                                    <select
                                        id="specialty"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs"
                                        value={selectedSpecialty}
                                        onChange={(e) => setSelectedSpecialty(e.target.value)}
                                    >
                                        {specialties.map((specialty) => (
                                            <option key={specialty.value} value={specialty.value}>
                                                {specialty.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Middle and Right - Charts */}
                    <div className="col-span-2 grid grid-cols-2 gap-4">
                        {/* Time Series Chart */}
                        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-3">
                            <h2 className="text-sm font-semibold text-gray-900 mb-2">Average PEX Score Trends</h2>
                            <div className="h-40">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        data={getFilteredHistoricalData()}
                                        margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis 
                                            dataKey="date" 
                                            tick={{ fontSize: 10 }} 
                                            height={20}
                                        />
                                        <YAxis 
                                            domain={[50, 70]} 
                                            tick={{ fontSize: 10 }}
                                            width={25}
                                        />
                                        <Tooltip />
                                        <Legend 
                                            wrapperStyle={{ fontSize: '10px' }}
                                            height={20}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="score"
                                            name="PEX Score"
                                            stroke="#2563eb"
                                            strokeWidth={2}
                                            dot={{ r: 3 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        {/* Pentagon Chart */}
                        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl p-3">
                            <h2 className="text-sm font-semibold text-gray-900 mb-2">Criteria Performance</h2>
                            <div className="h-40">
                                <ResponsiveContainer width="100%" height="100%">
                                    <RadarChart 
                                        data={getFilteredMetrics() || []}
                                        margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                                    >
                                        <PolarGrid gridType="polygon" />
                                        <PolarAngleAxis
                                            dataKey="criteria"
                                            tick={{ fill: '#6b7280', fontSize: 8 }}
                                            tickLine={false}
                                        />
                                        <PolarRadiusAxis
                                            angle={90}
                                            domain={[0, 100]}
                                            tick={{ fill: '#6b7280', fontSize: 8 }}
                                            tickCount={5}
                                        />
                                        <Radar
                                            name="Performance"
                                            dataKey="score"
                                            stroke="#2563eb"
                                            fill="#2563eb"
                                            fillOpacity={0.5}
                                        />
                                        <Radar
                                            name="Target"
                                            dataKey="target"
                                            stroke="#82ca9d"
                                            fill="#82ca9d"
                                            fillOpacity={0.3}
                                        />
                                        <Radar
                                            name="Baseline"
                                            dataKey="baseline"
                                            stroke="#9ca3af"
                                            fill="#9ca3af"
                                            fillOpacity={0.2}
                                        />
                                        <Legend 
                                            wrapperStyle={{ fontSize: '10px' }}
                                            height={20}
                                        />
                                    </RadarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Data Table */}
                <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                    <div className="px-4 py-6 sm:p-8">
                        <div className="overflow-x-auto">
                            <div className="max-h-[600px] overflow-y-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            {[
                                                { key: 'provider_name', label: 'Provider Name' },
                                                { key: 'overall_score', label: 'Overall Score' },
                                                { key: 'specialty', label: 'Specialty' },
                                                { key: 'group', label: 'Group' },
                                                { key: 'total_encounters', label: 'Total Encounters' },
                                                { key: 'patient_greeting_score', label: 'Patient Greeting' },
                                                { key: 'intro_score', label: 'Introduction' },
                                                { key: 'duration_score', label: 'Duration' },
                                                { key: 'explanation_score', label: 'Explanation' },
                                                { key: 'team_management_score', label: 'Manage Up' },
                                            ].map(({ key, label }) => (
                                                <th 
                                                    key={key}
                                                    scope="col" 
                                                    className="px-6 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider group min-w-[120px]"
                                                >
                                                    <div className="flex flex-col items-center">
                                                        <button
                                                            className="flex items-center justify-center gap-1 hover:text-gray-700 w-full"
                                                            onClick={() => handleSort(key)}
                                                        >
                                                            <span className="whitespace-nowrap">{label}</span>
                                                            <ArrowsUpDownIcon className={`h-3 w-3 opacity-0 group-hover:opacity-100 transition-opacity ${
                                                                sortConfig.key === key ? 'opacity-100' : ''
                                                            }`} />
                                                        </button>
                                                        <div className="relative w-full mt-2">
                                                            <input
                                                                type="text"
                                                                className="w-full text-xs bg-transparent border-0 border-b border-gray-200 focus:ring-0 focus:border-indigo-500 placeholder-gray-300 text-center"
                                                                placeholder="Filter..."
                                                                onChange={(e) => setFilters(prev => ({
                                                                    ...prev,
                                                                    [key]: e.target.value
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredAndSortedData.map((row, idx) => (
                                            <tr key={idx}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                                                    {row.provider_name}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <div className="flex justify-center">
                                                        <ScoreCell score={row.overall_score} metric="overall_score" />
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                                    {row.specialty === 'EMERGENCY_PHYSICIAN' ? 'Emergency Physician' : 'Advanced Practice Provider'}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                                    {row.group}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                                    {row.total_encounters}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <div className="flex justify-center">
                                                        <ScoreCell score={row.patient_greeting_score} metric="patient_greeting_score" />
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <div className="flex justify-center">
                                                        <ScoreCell score={row.intro_score} metric="intro_score" />
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <div className="flex justify-center">
                                                        <ScoreCell score={row.duration_score} metric="duration_score" />
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <div className="flex justify-center">
                                                        <ScoreCell score={row.explanation_score} metric="explanation_score" />
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center">
                                                    <div className="flex justify-center">
                                                        <ScoreCell score={row.team_management_score} metric="team_management_score" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Helper component for score cells
const ScoreCell = ({ score, metric }) => {
    const baselineScores = {
        patient_greeting_score: 80,
        intro_score: 77,
        duration_score: 37,
        explanation_score: 88,
        team_management_score: 21,
        overall_score: 60 // baseline for overall score
    };

    const getScoreColor = (score, metricKey) => {
        if (metricKey === 'overall_score') {
            if (score >= 62) return 'text-green-600 bg-green-100';
            if (score >= 58) return 'text-yellow-600 bg-yellow-100';
            return 'text-red-600 bg-red-100';
        }
        
        const baseline = baselineScores[metricKey];
        if (score >= baseline + 2) return 'text-green-600 bg-green-100';
        if (score >= baseline - 2) return 'text-yellow-600 bg-yellow-100';
        return 'text-red-600 bg-red-100';
    };

    return (
        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getScoreColor(score, metric)}`}>
            {score}%
        </span>
    );
};

export default OrganizationPortalPatientExperience; 