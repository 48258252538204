import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import logger from '../utils/logger';

// Get a namespaced logger for this component
const log = logger.getLogger('SSO');

const SSO = () => {
  const { loginWithRedirect, handleRedirectCallback, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [processingCallback, setProcessingCallback] = useState(false);
  const [error, setError] = useState(null);

  // Simplified hash parameter parsing - only used for Implicit Flow
  const parseHashParams = (hash) => {
    if (!hash || hash.length === 0) return {};
    const hashWithoutPrefix = hash.startsWith('#') ? hash.substring(1) : hash;
    const params = {};
    
    hashWithoutPrefix.split('&').forEach(pair => {
      const [key, value] = pair.split('=');
      if (key && value) {
        params[key] = decodeURIComponent(value);
      }
    });
    
    return params;
  };

  useEffect(() => {
    if (processingCallback) return;

    const handleSSO = async () => {
      try {
        log.debug("Current URL:", window.location.href);
        
        // If already authenticated, just navigate to home
        if (isAuthenticated && !isLoading) {
          log.debug("User is already authenticated, navigating to home");
          navigate('/', { replace: true });
          return;
        }

        // Handle Implicit Flow (used by IdP-initiated SAML)
        if (window.location.hash && window.location.hash.includes('access_token=')) {
          log.info("Detected access token in URL hash (Implicit Flow)");
          setProcessingCallback(true);
          
          try {
            // Parse hash to get any appState
            const hashParams = parseHashParams(window.location.hash);
            log.debug("Hash parameters:", hashParams);
            
            
            try {
              await getAccessTokenSilently();
              log.info("Successfully retrieved access token");
            } catch (tokenError) {
              log.warn("Could not retrieve token silently:", tokenError);
            }
            
            navigate('/', { replace: true });
          } catch (error) {
            log.error("Error handling Implicit Flow:", error);
            setError(`Auth0 Implicit Flow error: ${error.message}`);
            navigate('/', { replace: true });
          } finally {
            setProcessingCallback(false);
          }
          return;
        }
        
        // Handle Authorization Code Flow
        if (location.search && (location.search.includes('code=') || location.search.includes('error='))) {
          log.info("Detected code or error in URL query parameters");
          setProcessingCallback(true);
          
          try {
            const result = await handleRedirectCallback();
            log.info("Auth0 callback processed successfully");
            
            const targetUrl = result?.appState?.targetUrl || '/';
            navigate(targetUrl, { replace: true });
          } catch (error) {
            log.error("Error processing Auth0 callback:", error);
            setError(`Auth0 callback error: ${error.message}`);
            navigate('/', { replace: true });
          } finally {
            setProcessingCallback(false);
          }
          return;
        }
        
        // Initiate login if not in a callback process
        if (!isAuthenticated && !isLoading && !processingCallback) {
          log.info("Initiating new login flow");
          
          // Get connection from URL params (for IdP-initiated flow)
          const myURL = new URL(window.location.href);
          let conn = myURL.searchParams.get("connection");
          
          log.debug("Using connection:", conn);
          await login(null, conn);
        }
      } catch (error) {
        log.error("SSO initialization error:", error);
        setError(`SSO initialization error: ${error.message}`);
        navigate('/', { replace: true });
      }
    };

    handleSSO();
  }, [loginWithRedirect, navigate, handleRedirectCallback, location, isAuthenticated, isLoading, processingCallback, getAccessTokenSilently]);

  /**
   * Starts the authentication flow
   */
  const login = async (targetUrl, connection) => {
    try {
      log.info("Initiating login with connection:", connection);
  
      const options = {
        redirect_uri: window.location.origin + '/sso',
        scope: 'openid profile email',
      };
  
      if (connection) {
        options.connection = connection;
      }
  
      if (targetUrl) {
        options.appState = { targetUrl };
      }
  
      log.debug("Login options:", options);
      await loginWithRedirect(options);
    } catch (err) {
      log.error("Login failed:", err);
      setError(`Login failed: ${err.message}`);
      navigate('/', { replace: true });
    }
  };

  // Show error if there is one
  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="text-center p-8 bg-white rounded-lg shadow-md max-w-md">
          <div className="text-red-500 mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-2">Authentication Error</h3>
          <p className="text-gray-600 mb-4">{error}</p>
          <button 
            onClick={() => navigate('/', { replace: true })}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
          >
            Return to Home
          </button>
        </div>
      </div>
    );
  }

  // Return a loading indicator while SSO is processing
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="text-center p-8 bg-white rounded-lg shadow-md max-w-md">
        <div className="spinner mx-auto mb-4"></div>
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Authenticating
        </h2>
        <p className="text-gray-600 mb-1">Please wait while we securely sign you in...</p>
        <p className="text-sm text-gray-500">
          {processingCallback ? 'Processing authentication...' : 'Initializing secure connection...'}
        </p>
      </div>
    </div>
  );
};

export default SSO;