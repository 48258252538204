import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useAuth } from '../contexts/AuthProvider';

// Define all-party consent states
const ALL_PARTY_CONSENT_STATES = [
    'CA',  // California
    'DE',  // Delaware
    'FL',  // Florida
    'IL',  // Illinois
    'MA',  // Massachusetts
    'MD',  // Maryland
    'MT',  // Montana
    'NH',  // New Hampshire
    'PA',  // Pennsylvania
    'WA',  // Washington
];

const ConsentModal = ({ isOpen, onClose, onSelect, attestations }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [showCopied, setShowCopied] = useState(false);
    const { actorInfo } = useAuth();

    // Reset selected option when modal opens
    useEffect(() => {
        if (isOpen) {
            setSelectedOption('');
            setShowCopied(false);
        }
    }, [isOpen]);

    // If no attestations provided, don't show the modal
    useEffect(() => {
        if (isOpen && (!attestations || !attestations.content)) {
            onClose();
            onSelect(''); // Pass empty string to indicate no attestation
        }
    }, [isOpen, attestations, onClose, onSelect]);

    // If no attestations, don't render the modal
    if (!attestations || !attestations.content) {
        return null;
    }

    const modalContent = attestations.content;
    let attestationOptions = modalContent.attestations || {};

    // Check if actor is from organization 51 and from an all-party consent state
    const actorState = actorInfo?.state_for_invoice;
    const isOrgWithStateFiltering = actorInfo?.org_id === 51;

    if (isOrgWithStateFiltering && actorState && ALL_PARTY_CONSENT_STATES.includes(actorState)) {
        // Find the all-party consent option
        const allPartyOption = Object.entries(attestationOptions).find(([_, option]) =>
            option.label.toLowerCase().includes('all party') ||
            option.text.toLowerCase().includes('all parties')
        );

        // If we found the option, only show that one
        if (allPartyOption) {
            const [allPartyKey, allPartyValue] = allPartyOption;
            attestationOptions = { [allPartyKey]: allPartyValue };
        }
    }

    // Get sorted attestation entries based on order
    const sortedAttestations = Object.entries(attestationOptions)
        .sort((a, b) => (a[1].order || 999) - (b[1].order || 999));

    const handleOptionSelect = (key) => {
        setSelectedOption(key);
        onSelect(attestationOptions[key].text);
        setShowCopied(true);
        setTimeout(() => {
            setShowCopied(false);
            onClose();
        }, 1000);
    };

    return (
        <Dialog
            as="div"
            className="fixed inset-0 flex items-center justify-center"
            open={isOpen}
            onClose={onClose}
        >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            <Dialog.Panel className="w-full max-w-md rounded-lg bg-white p-6 text-left shadow-xl relative z-10">
                {!showCopied ? (
                    <>
                        <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900 mb-6">
                            {modalContent.modal_title || "Consent Attestation"}
                        </Dialog.Title>

                        <div className="mb-6">
                            <p className="text-gray-700 mb-4">
                                {modalContent.modal_description || "Please select the most relevant attestation."}
                            </p>

                            <div className="space-y-4">
                                {sortedAttestations.map(([key, attestation]) => (
                                    <label
                                        key={key}
                                        className="flex items-center space-x-3 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer transition-colors"
                                        onClick={() => handleOptionSelect(key)}
                                    >
                                        <input
                                            type="radio"
                                            name="consent"
                                            value={key}
                                            checked={selectedOption === key}
                                            onChange={() => { }}
                                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <span className="text-gray-900">
                                            {attestation.label}
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="flex flex-col items-center justify-center py-4">
                        <CheckCircleIcon className="h-12 w-12 text-green-500 mb-2" />
                        <p className="text-lg font-medium text-gray-900">Copied!</p>
                    </div>
                )}
            </Dialog.Panel>
        </Dialog>
    );
};

export default ConsentModal; 