import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes,
    faSpinner,
    faExclamationTriangle,
    faCalendarAlt,
    faClock,
    faUserPlus,
    faSearch,
    faLock,
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { useAuth0 } from "@auth0/auth0-react";
import { protectedResources, callProtectedApi } from '../authConfig';
import { useAuth } from '../contexts/AuthProvider';

function PatientPoolModal({ isOpen, onClose, navigate, onPatientClaimed }) {
    const { actorInfo } = useAuth();
    const { getAccessTokenSilently } = useAuth0();
    const [poolPatients, setPoolPatients] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isClaiming, setIsClaiming] = useState(false);
    const [selectedPatients, setSelectedPatients] = useState(new Set());
    const [isFeatureDisabled, setIsFeatureDisabled] = useState(true);

    useEffect(() => {
        if (!isOpen) {
            setSelectedPatients(new Set());
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            // Check if user is D2C (org_id === 12)
            setIsFeatureDisabled(actorInfo?.org_id === 12);
            if (actorInfo?.org_id !== 12) {
                fetchPoolPatients();
            }
        }
    }, [isOpen, actorInfo]);

    const fetchPoolPatients = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiGetPoolPatients.endpoint,
                {
                    method: 'GET'
                }
            );
            setPoolPatients(data);
            setIsFeatureDisabled(false);
        } catch (error) {
            console.error('Error:', error);
            if (error.message?.includes('Enterprise users')) {
                setIsFeatureDisabled(true);
            } else {
                setError(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handlePatientSelect = (patientId) => {
        setSelectedPatients(prev => {
            const newSelected = new Set(prev);
            if (newSelected.has(patientId)) {
                newSelected.delete(patientId);
            } else {
                newSelected.add(patientId);
            }
            return newSelected;
        });
    };

    const handleClaimSelected = async () => {
        if (selectedPatients.size === 0) return;

        setIsClaiming(true);
        setError(null);

        const claimedPatients = [];
        const failedClaims = [];

        for (const patientId of selectedPatients) {
            try {
                await handleClaimPatient(patientId);
                claimedPatients.push(patientId);
            } catch (error) {
                failedClaims.push(patientId);
                console.error(`Failed to claim patient ${patientId}:`, error);
            }
        }

        if (failedClaims.length > 0) {
            setError(`Failed to claim ${failedClaims.length} patients`);
        }

        if (claimedPatients.length === selectedPatients.size) {
            onClose();
        }

        setIsClaiming(false);
    };

    const handleClaimPatient = async (patientId) => {
        try {
            await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiClaimPatient.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        inpatient_stay_id: patientId,
                        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    })
                }
            );

            const claimedPatient = poolPatients.find(p => p.inpatient_id === patientId);
            setPoolPatients(prevPatients =>
                prevPatients.filter(p => p.inpatient_id !== patientId)
            );

            if (claimedPatient && onPatientClaimed) {
                onPatientClaimed(claimedPatient);
            }
        } catch (error) {
            throw error;
        }
    };

    const filteredPatients = poolPatients.filter(patient =>
        patient.patient_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleClose = () => {
        setSelectedPatients(new Set());
        onClose();
    };

    return (
        isOpen && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-4/5 lg:w-3/4 shadow-lg rounded-md bg-white">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-semibold text-gray-900">Patient Pool</h3>
                        <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>

                    {isFeatureDisabled && (
                        <div className="mb-6 p-4 bg-amber-50 border border-amber-200 rounded-md">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        className="h-5 w-5 text-amber-400"
                                    />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-amber-800">
                                        Enterprise Feature
                                    </h3>
                                    <div className="mt-2 text-sm text-amber-700">
                                        <p>
                                            The patient pool feature is only available for Enterprise users.
                                            Please contact our sales team to learn more about Enterprise plans.
                                        </p>
                                    </div>
                                    <div className="mt-4">
                                        <a
                                            href="mailto:support@cleo-ai.co"
                                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-amber-700 bg-amber-100 hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                                        >
                                            Contact Sales
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={isFeatureDisabled ? 'opacity-50 pointer-events-none' : ''}>
                        {/* Action Bar */}
                        <div className="flex justify-between items-center mb-4">
                            {/* Search Bar */}
                            <div className="flex-1 mr-4">
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search patients..."
                                        className="w-full px-4 py-2 border rounded-md pr-10"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                                    />
                                </div>
                            </div>

                            {/* Claim Selected Button */}
                            <button
                                onClick={handleClaimSelected}
                                disabled={isClaiming || selectedPatients.size === 0}
                                className={`px-4 py-2 bg-indigo-900 text-white rounded-md hover:bg-indigo-800 
                                        transition flex items-center ${(isClaiming || selectedPatients.size === 0)
                                        ? 'opacity-50 cursor-not-allowed bg-gray-400 hover:bg-gray-400'
                                        : ''}`}
                            >
                                {isClaiming ? (
                                    <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                                ) : (
                                    <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                                )}
                                Claim Selected ({selectedPatients.size})
                            </button>
                        </div>

                        {error && (
                            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
                                <div className="flex items-center text-red-700">
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                    <span>{error}</span>
                                </div>
                            </div>
                        )}

                        {isLoading ? (
                            <div className="flex justify-center items-center py-8">
                                <FontAwesomeIcon icon={faSpinner} className="animate-spin text-2xl text-indigo-600" />
                            </div>
                        ) : error ? (
                            <div className="flex items-center justify-center text-red-500 py-8">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                <span>{error}</span>
                            </div>
                        ) : filteredPatients.length === 0 ? (
                            <div className="text-center text-gray-500 py-8">
                                No patients available in the pool
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 gap-4">
                                {filteredPatients.map((patient) => (
                                    <div
                                        key={patient.inpatient_id}
                                        className={`border rounded-lg p-4 transition-colors cursor-pointer
                                            ${selectedPatients.has(patient.inpatient_id)
                                                ? 'border-indigo-500 bg-indigo-50'
                                                : 'hover:border-indigo-300'}`}
                                        onClick={() => handlePatientSelect(patient.inpatient_id)}
                                    >
                                        <div className="flex justify-between items-start">
                                            <div>
                                                <h4 className="text-lg font-semibold text-gray-900">
                                                    {patient.patient_name}
                                                </h4>
                                                <p className="text-gray-600">
                                                    {patient.original_chief_complaint || 'No chief complaint'}
                                                </p>
                                                <div className="mt-2 space-y-1">
                                                    <div className="flex items-center text-sm text-gray-500">
                                                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                                                        Admitted: {format(new Date(patient.created_at), 'MMM d, yyyy')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {patient.transfer_summary && (
                                            <div className="mt-3 p-3 bg-gray-50 rounded-md">
                                                <p className="text-sm text-gray-600 whitespace-pre-wrap">{patient.transfer_summary}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    );
}

export default PatientPoolModal; 