import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { protectedResources } from "../authConfig";
import { 
  faPills, 
  faThermometer, 
  faQuestion, 
  faExclamationCircle, 
  faCheck, 
  faFilter, 
  faSearch,
  faArrowLeft,
  faCheckCircle,
  faClock,
  faSpinner,
  faSync,
  faFileAlt,
  faUserMd
} from '@fortawesome/free-solid-svg-icons';
import { 
  Chip, 
  TextField, 
  InputAdornment, 
  Switch, 
  FormControlLabel, 
  Tooltip, 
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import { patientsService } from '../services/patientsService';

// Helper function to get icon based on concern type
const getConcernTypeIcon = (concernType) => {
  switch (concernType) {
    case 'SYMPTOMS_CHANGED':
      return <FontAwesomeIcon icon={faThermometer} className="text-red-500" />;
    case 'NEEDS_PRESCRIPTION_REFILL':
      return <FontAwesomeIcon icon={faPills} className="text-blue-500" />;
    case 'UNRESOLVED_ENCOUNTER_QUESTIONS':
      return <FontAwesomeIcon icon={faQuestion} className="text-yellow-500" />;
    case 'OTHER':
    default:
      return <FontAwesomeIcon icon={faExclamationCircle} className="text-gray-500" />;
  }
};

// Helper function to get human-readable concern type label
const getConcernTypeLabel = (concernType) => {
  switch (concernType) {
    case 'SYMPTOMS_CHANGED':
      return 'Symptoms Changed';
    case 'NEEDS_PRESCRIPTION_REFILL':
      return 'Needs Prescription Refill';
    case 'UNRESOLVED_ENCOUNTER_QUESTIONS':
      return 'Unresolved Questions';
    case 'OTHER':
    default:
      return 'Other Concern';
  }
};

// Helper function to format date in a readable way and show "X days ago"
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffTime = Math.abs(now - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  // Format date
  const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedDate = date.toLocaleDateString('en-US', options);
  
  return {
    formatted: formattedDate,
    daysAgo: diffDays === 1 ? 'Yesterday' : `${diffDays} days ago`
  };
};

// Helper function to format phone numbers as (XXX) XXX-XXXX
const formatPhoneNumber = (phoneNumberString) => {
  // Strip all non-numeric characters
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  
  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  
  // If the phone number is not 10 digits, just return the original
  return phoneNumberString;
};

// Helper function to get severity level label and color
const getSeverityInfo = (level) => {
  switch (level) {
    case 3:
      return { label: 'High', color: 'bg-red-100 text-red-800 border-red-300' };
    case 2:
      return { label: 'Medium', color: 'bg-yellow-100 text-yellow-800 border-yellow-300' };
    case 1:
    default:
      return { label: 'Low', color: 'bg-green-100 text-green-800 border-green-300' };
  }
};

const PatientDashboard = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [searchTerm, setSearchTerm] = useState('');
  const [showResolved, setShowResolved] = useState(false);
  const [sortBy, setSortBy] = useState('severity');
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatingPatientId, setUpdatingPatientId] = useState(null);
  
  // Fetch patients from API
  useEffect(() => {
    const fetchPatients = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        console.log("Fetching patients with concerns...");
        const token = await getAccessTokenSilently();
        console.log("Token acquired successfully");
        
        // Log the API endpoint being called
        const endpoint = `${protectedResources.apiPatientConcerns.endpoint}?show_resolved=${showResolved}`;
        console.log(`Calling patient concerns endpoint: ${endpoint}`);
        
        const result = await patientsService.getPatientsWithConcerns(showResolved, getAccessTokenSilently);
        console.log("API response:", result);
        
        if (Array.isArray(result.patients)) {
          setPatients(result.patients);
        } else {
          // Handle case where patients is not an array
          console.warn("API returned patients but it's not an array:", result.patients);
          setPatients([]);
        }
      } catch (err) {
        console.error("Error fetching patients with concerns:", err);
        
        // Get more details about the error
        let errorMessage = "Failed to fetch patient data";
        if (err.message) {
          errorMessage = err.message;
        }
        
        setError(errorMessage);
        setPatients([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPatients();
  }, [getAccessTokenSilently, showResolved]);
  
  // Filter patients based on search term
  const filteredPatients = patients.filter(patient => {
    const matchesSearch = patient.full_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        patient.concerns_description?.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesSearch;
  });
  
  // Sort patients
  const sortedPatients = [...filteredPatients].sort((a, b) => {
    if (sortBy === 'severity') {
      // Handle null values
      const aSeverity = a.concerns_severity || 0;
      const bSeverity = b.concerns_severity || 0;
      return bSeverity - aSeverity;
    } else if (sortBy === 'date') {
      // Handle null values
      const aDate = a.last_contacted_at ? new Date(a.last_contacted_at) : new Date(0);
      const bDate = b.last_contacted_at ? new Date(b.last_contacted_at) : new Date(0);
      return bDate - aDate;
    } else if (sortBy === 'name') {
      return (a.full_name || '').localeCompare(b.full_name || '');
    }
    return 0;
  });

  const handleBackToER = () => {
    navigate('/');
  };

  const handleSortChange = (sortField) => {
    setSortBy(sortField);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleMarkResolved = async (patientId) => {
    setIsUpdating(true);
    setUpdatingPatientId(patientId);
    try {
      await patientsService.resolvePatientConcerns(patientId, getAccessTokenSilently);
      
      // Update local state to reflect the change
      setPatients(prevPatients => 
        prevPatients.map(patient => 
          patient.patient_id === patientId 
            ? { ...patient, concerns_resolved: true } 
            : patient
        )
      );
      
      setSnackbar({
        open: true,
        message: 'Patient concern marked as resolved successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error marking concern as resolved:', err);
      setSnackbar({
        open: true,
        message: 'Failed to update patient concern. Please try again.',
        severity: 'error'
      });
    } finally {
      setIsUpdating(false);
      setUpdatingPatientId(null);
    }
  };

  const refreshPatients = async () => {
    setIsLoading(true);
    try {
      const result = await patientsService.getPatientsWithConcerns(showResolved, getAccessTokenSilently);
      setPatients(result.patients || []);
      setSnackbar({
        open: true,
        message: 'Patient data refreshed',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error refreshing patients:', err);
      setSnackbar({
        open: true,
        message: 'Failed to refresh patient data',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Add new function to handle viewing patient document
  const handleViewDocument = (documentId) => {
    // Create a request to fetch the full document data
    patientsService.getPatientsWithConcerns(showResolved, getAccessTokenSilently)
      .then(result => {
        // Find the current patient to get more info
        const patient = result.patients.find(p => p.initiating_document_id === documentId);
        
        if (patient) {
          // Navigate with full document object similar to ERDashboard
          navigate('/view-er-patient', { 
            state: { 
              document: {
                document_id: documentId,
                patient_name: patient.full_name,
                // Include placeholder created_at to prevent date formatting errors
                created_at: patient.last_contacted_at || new Date().toISOString(),
                // Add other minimal required fields
                document_status: "ENCOUNTER_COMPLETE"
              },
              providedDate: new Date(),
              fromPatientDashboard: true
            } 
          });
        }
      })
      .catch(error => {
        console.error("Error fetching document info:", error);
        // Fallback to basic navigation with minimal info if we can't get full document details
        navigate('/view-er-patient', { 
          state: { 
            document: {
              document_id: documentId,
              created_at: new Date().toISOString(),
              document_status: "ENCOUNTER_COMPLETE"
            },
            providedDate: new Date(),
            fromPatientDashboard: true
          } 
        });
      });
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-7xl">
      {/* Header Section */}
      <div className="mb-6 flex justify-between items-center">
        <div className="flex items-center">
          <button 
            onClick={handleBackToER} 
            className="mr-4 p-2 rounded-full hover:bg-gray-200 transition-colors"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="text-gray-700 text-lg" />
          </button>
          <h1 className="text-2xl font-bold text-indigo-900">Patient Follow-Up Dashboard</h1>
        </div>
        <div className="text-sm text-gray-600">
          <div className="flex items-center">
            <span className="mr-2">Legend:</span>
            <div className="flex space-x-4">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faThermometer} className="text-red-500 mr-1" />
                <span>Symptoms</span>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon icon={faPills} className="text-blue-500 mr-1" />
                <span>Prescriptions</span>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon icon={faQuestion} className="text-yellow-500 mr-1" />
                <span>Questions</span>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon icon={faExclamationCircle} className="text-gray-500 mr-1" />
                <span>Other</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Controls Section */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-6">
        <div className="flex flex-wrap justify-between items-center gap-4">
          <div className="w-full md:w-auto flex-grow">
            <TextField
              fullWidth
              placeholder="Search patients or concerns..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          
          <div className="flex flex-wrap items-center gap-3">
            <FormControlLabel
              control={
                <Switch
                  checked={showResolved}
                  onChange={(e) => setShowResolved(e.target.checked)}
                  color="primary"
                />
              }
              label="Show Resolved"
            />
            
            <div className="flex items-center space-x-2 border-l pl-3">
              <span className="text-sm text-gray-700">Sort by:</span>
              <div className="flex space-x-1">
                <Chip
                  label="Severity"
                  size="small"
                  clickable
                  color={sortBy === 'severity' ? 'primary' : 'default'}
                  onClick={() => handleSortChange('severity')}
                />
                <Chip
                  label="Date"
                  size="small"
                  clickable
                  color={sortBy === 'date' ? 'primary' : 'default'}
                  onClick={() => handleSortChange('date')}
                />
                <Chip
                  label="Name"
                  size="small"
                  clickable
                  color={sortBy === 'name' ? 'primary' : 'default'}
                  onClick={() => handleSortChange('name')}
                />
              </div>
            </div>
            
            <button
              onClick={refreshPatients}
              disabled={isLoading}
              className="ml-2 p-2 rounded-full bg-indigo-100 hover:bg-indigo-200 text-indigo-700 disabled:opacity-50"
              title="Refresh patient data"
            >
              <FontAwesomeIcon icon={isLoading ? faSpinner : faSync} spin={isLoading} />
            </button>
          </div>
        </div>
      </div>

      {/* Loading State */}
      {isLoading && (
        <div className="flex justify-center items-center py-12">
          <CircularProgress size={60} />
        </div>
      )}

      {/* Display error message if API call fails */}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
          <p className="text-sm">Try refreshing the page or contact support if the issue persists.</p>
        </div>
      )}

      {/* Patient Cards */}
      {!isLoading && !error && (
        <div className="grid grid-cols-1 gap-6">
          {sortedPatients.length > 0 ? (
            sortedPatients.map(patient => {
              const severityInfo = getSeverityInfo(patient.concerns_severity);
              const dateInfo = patient.last_contacted_at ? formatDate(patient.last_contacted_at) : { formatted: 'Unknown', daysAgo: '' };
              const isPatientBeingUpdated = updatingPatientId === patient.patient_id;
              
              return (
                <div 
                  key={patient.patient_id} 
                  className={`bg-white rounded-lg shadow-md p-6 border-l-4 ${
                    patient.concerns_resolved ? 'border-indigo-500' : 
                    patient.concerns_severity === 3 ? 'border-red-500' : 
                    patient.concerns_severity === 2 ? 'border-yellow-500' : 
                    'border-blue-500'
                  }`}
                >
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <div className="flex items-center gap-2">
                        <h2 className="text-xl font-semibold">{patient.full_name}</h2>
                        {patient.concerns_resolved && (
                          <Tooltip title="Concern Resolved">
                            <span>
                              <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-500" />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                      <div className="text-gray-600 text-sm">
                        {formatPhoneNumber(patient.phone_number)}
                      </div>
                    </div>
                    
                    <div className="flex items-center">
                      <div className="flex flex-col items-end mr-4">
                        <div className="text-sm text-gray-500">{dateInfo.formatted}</div>
                        {dateInfo.daysAgo && (
                          <div className="text-xs flex items-center">
                            <FontAwesomeIcon icon={faClock} className="text-gray-400 mr-1" />
                            {dateInfo.daysAgo}
                          </div>
                        )}
                      </div>
                      
                      <div className="flex flex-col items-end">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${severityInfo.color}`}>
                          {severityInfo.label} Severity
                        </span>
                        {patient.concern_type && (
                          <div className="mt-1">
                            <Tooltip title={getConcernTypeLabel(patient.concern_type)}>
                              <div className="w-8 h-8 flex items-center justify-center bg-gray-100 rounded-full">
                                {getConcernTypeIcon(patient.concern_type)}
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  
                  <div className="mt-2">
                    <div className="text-gray-700 font-medium mb-1">Concern:</div>
                    <p className="text-gray-800">{patient.concerns_description || 'No specific concerns noted'}</p>
                  </div>
                  
                  <div className="mt-4 flex justify-between items-center">
                    <div className="text-sm">
                      <span className="text-gray-600">Document ID: </span>
                      <span className="font-medium">{patient.initiating_document_id}</span>
                    </div>
                    
                    <div className="flex space-x-3">
                      <button
                        onClick={() => handleViewDocument(patient.initiating_document_id)}
                        className="bg-indigo-900 hover:bg-indigo-800 text-white py-2 px-4 rounded-md flex items-center transition-colors"
                      >
                        <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                        View Document
                      </button>
                      
                      {!patient.concerns_resolved && (
                        <button
                          onClick={() => handleMarkResolved(patient.patient_id)}
                          disabled={isPatientBeingUpdated}
                          className="bg-indigo-900 hover:bg-indigo-800 text-white py-2 px-4 rounded-md flex items-center transition-colors disabled:opacity-70 disabled:hover:bg-indigo-900"
                        >
                          {isPatientBeingUpdated ? (
                            <>
                              <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                              Updating...
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={faCheck} className="mr-2" />
                              Mark as Resolved
                            </>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bg-white rounded-lg shadow-md p-8 text-center">
              <FontAwesomeIcon icon={faFilter} className="text-gray-400 text-4xl mb-3" />
              <h3 className="text-xl font-semibold text-gray-700">No matching patients found</h3>
              <p className="text-gray-600 mt-2">
                {showResolved ? 
                  "No patient records with concerns were found. Try creating patient follow-ups first." :
                  "No unresolved patient concerns found. Try adjusting your search or enabling 'Show Resolved'."
                }
              </p>
            </div>
          )}
        </div>
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PatientDashboard; 