import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { protectedResources, callProtectedApi } from "../authConfig";
import { Button } from "../components/catalyst/button";

const CarepointLocationSurvey = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { actorInfo } = location.state;
    const [locationInput, setLocationInput] = useState("");
    const [locations, setLocations] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const { getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);

    const getToken = async () => {
        return await getAccessTokenSilently();
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setLocationInput(value);
        setFilteredLocations(
            locations.filter(location =>
                location.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const handleLocationClick = (location) => {
        setLocationInput(location);
        setFilteredLocations([]);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const token = await getToken();

            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ location: locationInput }),
            };

            const response = await fetch(protectedResources.apiLocateSignIn.endpoint, requestOptions);
            const result = await response.json();

            if (result.success) {
                console.log("Location logged successfully");
                navigate("/er-dashboard", { state: { actorInfo: actorInfo } });
            } else {
                console.error("Failed to log location");
            }
        } catch (error) {
            console.error("Error submitting location:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLocations = useCallback(async () => {
        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiGetSignInLocations.endpoint,
                {
                    method: "GET"
                }
            );

            const locationNames = data.map(loc => loc.location);
            setLocations(locationNames);
            setFilteredLocations(locationNames);
        } catch (error) {
            console.error("Error fetching locations:", error);
        }
    }, [getAccessTokenSilently]);

    useEffect(() => {
        fetchLocations();
    }, []);

    useEffect(() => {
        setIsLoading(auth0Loading);
    }, [auth0Loading]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="max-w-2xl w-full bg-white shadow-lg rounded-lg p-12">
                <div className="mb-12">
                    <img
                        src="/Carepoint-Logo.jpeg"
                        alt="CarePoint Logo"
                        style={{ maxWidth: "100px", marginBottom: "30px" }}
                    />
                    <h1 className="text-black pb-4 text-2xl">CarePoint Location Survey</h1>
                    <div className="mb-8 relative">
                        <label className="block text-lg font-medium text-gray-700">
                            Where are you working today?
                        </label>
                        <div className="flex mt-2">
                            <input
                                type="text"
                                value={locationInput}
                                onChange={handleInputChange}
                                className="p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-900 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <Button
                                onClick={handleSubmit}
                                color="indigo"
                                className="ml-4 flex items-center justify-center"
                                style={{ fontSize: "18px", padding: "10px 20px" }}
                                disabled={isLoading || locationInput.trim() === ""}
                            >
                                {isLoading ? "Submitting..." : "Submit Location"}
                            </Button>
                        </div>
                        {filteredLocations.length > 0 && (
                            <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1">
                                {filteredLocations.map((location, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleLocationClick(location)}
                                        className="cursor-pointer p-2 hover:bg-gray-200"
                                    >
                                        {location}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CarepointLocationSurvey;
