import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { templatesApi } from '../../api/templatesApi';
import { PlusIcon, PencilIcon, TrashIcon, FilterIcon, UserIcon, UserGroupIcon, BuildingOfficeIcon, StarIcon, ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { Button } from '../catalyst/button';
import { useAuth } from '../../contexts/AuthProvider';
import { Dialog, Transition } from '@headlessui/react';

export default function NoteTemplatesSettings({ onSuccessfulSave }) {
    const [templates, setTemplates] = useState([]);
    const [personalTemplates, setPersonalTemplates] = useState([]);
    const [globalTemplates, setGlobalTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedSpecialty, setSelectedSpecialty] = useState('ALL');
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo, updateActorInfo } = useAuth();
    const [loadingTemplateId, setLoadingTemplateId] = useState(null);
    const [settingPreferredId, setSettingPreferredId] = useState(null);
    const [resettingToDefault, setResettingToDefault] = useState(false);

    // Template editing state
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingTemplate, setEditingTemplate] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    // Function to format specialty enum values to display names
    const formatSpecialtyName = (specialty) => {
        return specialty
            .replace(/_/g, ' ')
            .toLowerCase()
            .replace(/\b\w/g, char => char.toUpperCase());
    };

    // Function to format specialties for display
    const formatSpecialties = (specialties) => {
        if (!specialties || specialties.length === 0) return 'All Specialties';

        return specialties.map(specialty => formatSpecialtyName(specialty)).join(', ');
    };

    // Function to get source icon and label
    const getSourceInfo = (template) => {
        if (template.who_can_view === 'ACTOR') {
            return {
                icon: <UserIcon className="h-5 w-5 text-indigo-600" />,
                label: 'Personal',
                bgColor: 'bg-indigo-100',
                textColor: 'text-indigo-800'
            };
        } else if (template.who_can_view === 'GROUP') {
            return {
                icon: <UserGroupIcon className="h-5 w-5 text-green-600" />,
                label: 'Group',
                bgColor: 'bg-green-100',
                textColor: 'text-green-800'
            };
        } else if (template.who_can_view === 'ORGANIZATION') {
            return {
                icon: <BuildingOfficeIcon className="h-5 w-5 text-purple-600" />,
                label: 'Organization',
                bgColor: 'bg-purple-100',
                textColor: 'text-purple-800'
            };
        } else {
            return {
                icon: <BuildingOfficeIcon className="h-5 w-5 text-gray-600" />,
                label: 'Global',
                bgColor: 'bg-gray-100',
                textColor: 'text-gray-800'
            };
        }
    };

    // Function to fetch templates
    const fetchTemplates = async () => {
        try {
            setIsLoading(true);
            const response = await templatesApi.getTemplates(getAccessTokenSilently, true, true);
            console.log(response);

            // Convert the templates object to an array
            const templatesArray = Object.entries(response.templates).map(([id, template]) => ({
                template_id: parseInt(id),
                ...template
            }));

            // Filter to only show primary note templates
            const primaryTemplates = templatesArray.filter(
                template => template.is_for_primary_note_generation
            );

            setTemplates(primaryTemplates);

            // Separate templates by who_can_view
            setPersonalTemplates(primaryTemplates.filter(template =>
                template.who_can_view === 'ACTOR' ||
                template.who_can_view === 'GROUP' ||
                template.who_can_view === 'ORGANIZATION'
            ));
            setGlobalTemplates(primaryTemplates.filter(template =>
                template.who_can_view === 'GLOBAL'
            ));
        } catch (error) {
            console.error('Error fetching templates:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTemplates();
    }, [getAccessTokenSilently]);

    // Dynamically generate specialty options from global templates
    const specialtyOptions = useMemo(() => {
        // Start with the "All Specialties" option
        const options = [{ value: 'ALL', label: 'All Specialties' }];

        // Create a Set to track unique specialties
        const uniqueSpecialties = new Set();

        // Add all specialties from global templates to the Set
        globalTemplates.forEach(template => {
            if (template.specialties && template.specialties.length > 0) {
                template.specialties.forEach(specialty => {
                    uniqueSpecialties.add(specialty);
                });
            }
        });

        // Convert the Set to an array of option objects
        const specialtyOptionsList = Array.from(uniqueSpecialties).map(specialty => ({
            value: specialty,
            label: formatSpecialtyName(specialty)
        }));

        // Sort options alphabetically by label
        specialtyOptionsList.sort((a, b) => a.label.localeCompare(b.label));

        // Add sorted options to the "All Specialties" option
        return [...options, ...specialtyOptionsList];
    }, [globalTemplates]);

    // Filter global templates by selected specialty
    const filteredGlobalTemplates = globalTemplates.filter(template => {
        if (selectedSpecialty === 'ALL') return true;
        if (!template.specialties || template.specialties.length === 0) return true;
        return template.specialties.includes(selectedSpecialty);
    });

    // Filter out the preferred template from the personal templates list
    const filteredPersonalTemplates = useMemo(() => {
        if (!actorInfo?.preferred_primary_template_id) {
            return personalTemplates;
        }
        return personalTemplates.filter(
            template => template.template_id !== actorInfo.preferred_primary_template_id
        );
    }, [personalTemplates, actorInfo?.preferred_primary_template_id]);

    // Add this function to handle adding a template to the library
    const handleAddToLibrary = async (templateId) => {
        try {
            // Add loading state for the specific button
            setLoadingTemplateId(templateId);

            const result = await templatesApi.addTemplateToLibrary(getAccessTokenSilently, templateId);

            if (result.success) {
                // Show success message
                if (onSuccessfulSave) {
                    onSuccessfulSave(result.message || "Template added to your library");
                }

                // Add the new template to the personal templates array
                if (result.template) {
                    setPersonalTemplates(prev => [...prev, result.template]);

                    // Also add to the all templates array
                    setTemplates(prev => [...prev, result.template]);
                }
            } else {
                // Handle the case where the user already has a copy
                if (result.template_id) {
                    if (onSuccessfulSave) {
                        onSuccessfulSave("You already have this template in your library");
                    }
                }
            }
        } catch (error) {
            console.error('Error adding template to library:', error);
            // Show error message
        } finally {
            // Clear loading state
            setLoadingTemplateId(null);
        }
    };

    // Function to get the preferred template
    const preferredTemplate = useMemo(() => {
        if (!actorInfo?.preferred_primary_template_id) return null;
        return templates.find(t => t.template_id === actorInfo.preferred_primary_template_id);
    }, [templates, actorInfo?.preferred_primary_template_id]);

    // Function to set a template as preferred
    const setPreferredTemplate = async (templateId) => {
        try {
            setSettingPreferredId(templateId);

            // Call API to update preferred template
            const response = await templatesApi.setPreferredTemplate(getAccessTokenSilently, templateId);

            if (response.success) {
                // Update local state
                updateActorInfo('preferred_primary_template_id', templateId);

                // Show success message
                if (onSuccessfulSave) {
                    onSuccessfulSave("Default template updated successfully");
                }
            }
        } catch (error) {
            console.error('Error setting preferred template:', error);
            // Show error message
        } finally {
            setSettingPreferredId(null);
        }
    };

    // Function to reset to Cleo Default Flow
    const resetToDefaultFlow = async () => {
        try {
            setResettingToDefault(true);

            // Call API to set preferred template to null
            const response = await templatesApi.setPreferredTemplate(getAccessTokenSilently, null);

            if (response.success) {
                // Update local state
                updateActorInfo('preferred_primary_template_id', null);

                // Show success message
                if (onSuccessfulSave) {
                    onSuccessfulSave("Reset to Cleo Standard Note successfully");
                }
            }
        } catch (error) {
            console.error('Error resetting to default flow:', error);
            // Show error message
        } finally {
            setResettingToDefault(false);
        }
    };

    // Function to handle template click
    const handleTemplateClick = (template) => {
        // Only allow editing of personal templates
        if (template.who_can_view === 'ACTOR') {
            openEditModal(template);
        } else {
            // For non-personal templates, just show a view-only modal
            openViewModal(template);
        }
    };

    // Function to open a view-only modal for non-personal templates
    const openViewModal = (template) => {
        // Set the template for viewing, but mark it as view-only
        setEditingTemplate({ ...template, isViewOnly: true });
        setIsEditModalOpen(true);
    };

    // Function to open the edit modal for a template
    const openEditModal = (template) => {
        setEditingTemplate({ ...template });
        setFormErrors({});
        setIsEditModalOpen(true);
    };

    // Function to close the edit modal
    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setEditingTemplate(null);
    };

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditingTemplate(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Function to validate the form
    const validateForm = () => {
        const errors = {};
        if (!editingTemplate.template_name.trim()) {
            errors.template_name = 'Template name is required';
        }
        if (!editingTemplate.prompt_template.trim()) {
            errors.prompt_template = 'Template content is required';
        }
        return errors;
    };

    // Function to save the template
    const saveTemplate = async () => {
        // Validate form
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        try {
            setIsSaving(true);

            // Call API to create or update the template
            const response = await templatesApi.upsertTemplate(
                getAccessTokenSilently,
                {
                    template_id: editingTemplate.template_id, // Will be undefined for new templates
                    template_name: editingTemplate.template_name,
                    prompt_template: editingTemplate.prompt_template,
                    specialties: editingTemplate.specialties,
                    who_can_view: editingTemplate.who_can_view,
                    is_for_primary_note_generation: editingTemplate.is_for_primary_note_generation,
                    group_id: editingTemplate.group_id
                }
            );

            if (response && response.success) {
                // Determine if this was a create or update operation
                const isNewTemplate = !editingTemplate.template_id;
                const templateId = response.template_id;

                if (isNewTemplate) {
                    // For new templates, add to the personal templates list
                    const newTemplate = {
                        template_id: templateId,
                        template_name: editingTemplate.template_name,
                        prompt_template: editingTemplate.prompt_template,
                        specialties: editingTemplate.specialties,
                        who_can_view: 'ACTOR',
                        is_for_primary_note_generation: true,
                        created_by: actorInfo.actor_id,
                        created_at: new Date().toISOString()
                    };

                    setPersonalTemplates(prev => [...prev, newTemplate]);
                    setTemplates(prev => [...prev, newTemplate]);

                    // Show success message
                    if (onSuccessfulSave) {
                        onSuccessfulSave("Template created successfully");
                    }
                } else {
                    // For updates, update the existing template in state
                    const updatedTemplate = {
                        ...editingTemplate
                    };

                    // Update templates state
                    setTemplates(prev =>
                        prev.map(t =>
                            t.template_id === updatedTemplate.template_id
                                ? updatedTemplate
                                : t
                        )
                    );

                    // Update personal templates state
                    setPersonalTemplates(prev =>
                        prev.map(t =>
                            t.template_id === updatedTemplate.template_id
                                ? updatedTemplate
                                : t
                        )
                    );

                    // Show success message
                    if (onSuccessfulSave) {
                        onSuccessfulSave("Template updated successfully");
                    }
                }

                // Close the modal
                closeEditModal();
            } else {
                throw new Error(response?.error || "Unknown error updating template");
            }
        } catch (error) {
            console.error('Error saving template:', error);
            // Show error message
            if (onSuccessfulSave) {
                onSuccessfulSave("Error saving template: " + (error.message || "Unknown error"), "error");
            }
        } finally {
            setIsSaving(false);
        }
    };

    // Update the modal to be view-only when needed
    const isViewOnly = editingTemplate?.isViewOnly || false;

    // Function to delete a template
    const deleteTemplate = async (templateId) => {
        if (!window.confirm("Are you sure you want to delete this template? This action cannot be undone.")) {
            return;
        }

        try {
            const response = await templatesApi.deleteTemplate(getAccessTokenSilently, templateId);

            if (response.success) {
                // Remove the template from state
                setTemplates(prev => prev.filter(t => t.template_id !== templateId));
                setPersonalTemplates(prev => prev.filter(t => t.template_id !== templateId));

                // If this was the preferred template, reset to default
                if (actorInfo?.preferred_primary_template_id === templateId) {
                    updateActorInfo('preferred_primary_template_id', null);
                }

                // Show success message
                if (onSuccessfulSave) {
                    onSuccessfulSave("Template deleted successfully");
                }
            }
        } catch (error) {
            console.error('Error deleting template:', error);
            // Show error message
            if (onSuccessfulSave) {
                onSuccessfulSave("Error deleting template: " + (error.message || "Unknown error"), "error");
            }
        }
    };

    // Function to handle global template click for viewing
    const handleGlobalTemplateClick = (template) => {
        // Open the template in view-only mode
        openViewModal(template);
    };

    // Function to create a new template
    const createNewTemplate = () => {
        // Create an empty template with default values
        const newTemplate = {
            template_name: '',
            prompt_template: '',
            specialties: [],
            who_can_view: 'ACTOR',
            is_for_primary_note_generation: true
        };

        // Open the edit modal with the new template
        setEditingTemplate(newTemplate);
        setFormErrors({});
        setIsEditModalOpen(true);
    };

    return (
        <div className="space-y-6">
            {/* Main content - Personal Templates */}
            <div className="flex gap-6">
                <div className="flex-1 space-y-6">
                    <div className="flex items-center justify-between">
                        <h1 className="text-2xl font-semibold text-gray-900">My Note Templates</h1>
                        <Button
                            color="primary"
                            onClick={createNewTemplate}
                            className="flex items-center gap-1"
                            disabled={isLoading}
                        >
                            <PlusIcon className="h-5 w-5" />
                            <span>New Template</span>
                        </Button>
                    </div>

                    <p className="text-sm text-gray-500">
                        Create and manage your personal templates for primary clinical notes.
                    </p>

                    {/* Preferred Template Section - More compact */}
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg border border-indigo-200">
                        <div className="px-4 py-3 sm:px-4 bg-indigo-50 flex items-center justify-between">
                            <div className="flex items-center">
                                <StarIconSolid className="h-4 w-4 text-indigo-600 mr-2" />
                                <h3 className="text-sm font-medium text-gray-900">Default Template</h3>
                            </div>
                            <div className="flex items-center">
                                <p className="text-xs text-gray-500 mr-2">
                                    Used by default when generating new notes
                                </p>
                                {/* Reset to Default button */}
                                {actorInfo?.preferred_primary_template_id && (
                                    <button
                                        type="button"
                                        onClick={resetToDefaultFlow}
                                        disabled={resettingToDefault}
                                        className="inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        title="Return to Cleo Standard Note"
                                    >
                                        {resettingToDefault && (
                                            <div className="animate-spin h-3 w-3 border-b-2 border-indigo-600 rounded-full mr-1"></div>
                                        )}
                                        Return to Cleo Standard Note
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="px-4 py-3 sm:px-4">
                            {isLoading ? (
                                <div className="flex justify-center py-1">
                                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-indigo-600"></div>
                                </div>
                            ) : preferredTemplate ? (
                                <div
                                    className="flex items-center justify-between cursor-pointer hover:bg-indigo-50 -mx-4 -my-3 px-4 py-3 rounded-md"
                                    onClick={() => handleTemplateClick(preferredTemplate)}
                                >
                                    <div className="flex-1">
                                        <h4 className="text-sm font-medium text-gray-900">{preferredTemplate.template_name}</h4>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        {getSourceInfo(preferredTemplate).icon}
                                    </div>
                                </div>
                            ) : (
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h4 className="text-sm font-medium text-gray-900">Cleo Standard Note</h4>
                                        <p className="text-xs text-gray-500">Standard note generation flow</p>
                                    </div>
                                    <BuildingOfficeIcon className="h-4 w-4 text-gray-400" />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Templates Table */}
                    {isLoading ? (
                        <div className="flex justify-center py-8">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                        </div>
                    ) : filteredPersonalTemplates.length === 0 ? (
                        <div className="text-center py-8 border border-dashed rounded-lg">
                            <p className="text-gray-500">You haven't created any personal templates yet.</p>
                        </div>
                    ) : (
                        <div className="overflow-hidden bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-1/2">
                                            Template Name
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/4">
                                            Source
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 w-1/6">
                                            Set Default
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 w-1/12">
                                            <span className="sr-only">Actions</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {filteredPersonalTemplates.map((template) => {
                                        const sourceInfo = getSourceInfo(template);
                                        const isPersonal = template.who_can_view === 'ACTOR';

                                        return (
                                            <tr
                                                key={template.template_id}
                                                className="cursor-pointer hover:bg-gray-50"
                                                onClick={() => handleTemplateClick(template)}
                                            >
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {template.template_name}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                                                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${sourceInfo.bgColor} ${sourceInfo.textColor}`}>
                                                        {sourceInfo.icon}
                                                        <span className="ml-1">{sourceInfo.label}</span>
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-left" onClick={(e) => e.stopPropagation()}>
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setPreferredTemplate(template.template_id);
                                                        }}
                                                        disabled={settingPreferredId === template.template_id}
                                                    >
                                                        {settingPreferredId === template.template_id ? (
                                                            <div className="animate-spin h-4 w-4 border-b-2 border-indigo-600 rounded-full mr-1"></div>
                                                        ) : (
                                                            <StarIcon className="h-4 w-4 mr-1" />
                                                        )}
                                                        Set as Default
                                                    </button>
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6" onClick={(e) => e.stopPropagation()}>
                                                    {isPersonal && (
                                                        <button
                                                            type="button"
                                                            className="text-red-600 hover:text-red-900"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                deleteTemplate(template.template_id);
                                                            }}
                                                            title="Delete template"
                                                        >
                                                            <TrashIcon className="h-5 w-5" />
                                                            <span className="sr-only">Delete</span>
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>

                {/* Sidebar - Global Templates */}
                <div className="w-80 space-y-6">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Global Templates</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Standard templates available to all users
                            </p>

                            {/* Specialty filter */}
                            <div className="mt-3">
                                <label htmlFor="specialty" className="block text-sm font-medium text-gray-700">
                                    Filter by Specialty
                                </label>
                                <select
                                    id="specialty"
                                    name="specialty"
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    value={selectedSpecialty}
                                    onChange={(e) => setSelectedSpecialty(e.target.value)}
                                >
                                    {specialtyOptions.map((specialty) => (
                                        <option key={specialty.value} value={specialty.value}>
                                            {specialty.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {isLoading ? (
                            <div className="flex justify-center py-8">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                            </div>
                        ) : filteredGlobalTemplates.length === 0 ? (
                            <div className="px-4 py-5 sm:p-6 text-center">
                                <p className="text-gray-500">No global templates found for this specialty.</p>
                            </div>
                        ) : (
                            <div className="border-t border-gray-200">
                                <div className="max-h-192 overflow-y-auto">
                                    <ul className="divide-y divide-gray-200">
                                        {filteredGlobalTemplates.map((template) => (
                                            <li
                                                key={template.template_id}
                                                className="px-4 py-4 sm:px-6 hover:bg-gray-50 cursor-pointer"
                                            >
                                                <div
                                                    className="flex items-center justify-between"
                                                    onClick={() => handleGlobalTemplateClick(template)}
                                                >
                                                    <div className="flex-1 min-w-0 pr-2">
                                                        <p className="text-sm font-medium text-indigo-600 break-words">
                                                            {template.template_name}
                                                        </p>
                                                    </div>
                                                    <div className="ml-2 flex-shrink-0" onClick={(e) => e.stopPropagation()}>
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleAddToLibrary(template.template_id);
                                                            }}
                                                            disabled={loadingTemplateId === template.template_id}
                                                        >
                                                            {loadingTemplateId === template.template_id ? (
                                                                <>
                                                                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-indigo-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                    </svg>
                                                                    Adding...
                                                                </>
                                                            ) : (
                                                                "Add to My Templates"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Template Edit Modal */}
            <Transition.Root show={isEditModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeEditModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={closeEditModal}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>

                                    <div>
                                        <div className="mt-3 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                                                {isViewOnly ? "View Template" : "Edit Template"}
                                            </Dialog.Title>

                                            <div className="mt-4 space-y-4">
                                                {/* Template Name */}
                                                <div>
                                                    <label htmlFor="template_name" className="block text-sm font-medium text-gray-700">
                                                        Template Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="template_name"
                                                        id="template_name"
                                                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${formErrors.template_name ? 'border-red-500' : ''}`}
                                                        value={editingTemplate?.template_name || ''}
                                                        onChange={handleInputChange}
                                                        disabled={isViewOnly}
                                                        readOnly={isViewOnly}
                                                    />
                                                    {formErrors.template_name && (
                                                        <p className="mt-1 text-sm text-red-600">{formErrors.template_name}</p>
                                                    )}
                                                </div>

                                                {/* Template Content */}
                                                <div>
                                                    <label htmlFor="prompt_template" className="block text-sm font-medium text-gray-700">
                                                        Template Content
                                                    </label>
                                                    <textarea
                                                        name="prompt_template"
                                                        id="prompt_template"
                                                        rows={10}
                                                        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm font-mono ${formErrors.prompt_template ? 'border-red-500' : ''}`}
                                                        value={editingTemplate?.prompt_template || ''}
                                                        onChange={handleInputChange}
                                                        disabled={isViewOnly}
                                                        readOnly={isViewOnly}
                                                    />
                                                    {formErrors.prompt_template && (
                                                        <p className="mt-1 text-sm text-red-600">{formErrors.prompt_template}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                                        {!isViewOnly ? (
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                                onClick={saveTemplate}
                                                disabled={isSaving}
                                            >
                                                {isSaving ? (
                                                    <>
                                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        Saving...
                                                    </>
                                                ) : (
                                                    "Save Changes"
                                                )}
                                            </button>
                                        ) : null}
                                        <button
                                            type="button"
                                            className={`${!isViewOnly ? 'mt-3 sm:mt-0' : ''} inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto`}
                                            onClick={closeEditModal}
                                        >
                                            {isViewOnly ? "Close" : "Cancel"}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
} 