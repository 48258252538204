import { protectedResources, callProtectedApi } from '../authConfig';

/**
 * Fetch all available MIPS criteria
 * @param {Function} getToken - Function to get the authentication token
 * @returns {Promise<Array>} Array of MIPS criteria
 */
export const fetchAllMipsCriteria = async (getToken) => {
    try {
        const response = await callProtectedApi(
            getToken,
            protectedResources.apiFetchAllMipsCriteria.endpoint,
            { method: 'GET' }
        );
        return response.mips_criteria || [];
    } catch (error) {
        console.error('Error fetching MIPS criteria:', error);
        throw error;
    }
};

/**
 * Fetch a single MIPS criteria by ID
 * @param {Function} getToken - Function to get the authentication token
 * @param {number|string} mipsCriteriaId - The ID of the MIPS criteria to fetch
 * @returns {Promise<Object>} MIPS criteria object with components
 */
export const fetchMipsCriteriaById = async (getToken, mipsCriteriaId) => {
    try {
        const response = await callProtectedApi(
            getToken,
            protectedResources.apiFetchMipsCriteriaById.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({ mips_criteria_id: mipsCriteriaId })
            }
        );
        return response.mips_criteria;
    } catch (error) {
        console.error(`Error fetching MIPS criteria with ID ${mipsCriteriaId}:`, error);
        throw error;
    }
};

/**
 * Generate sample MIPS criteria (admin only)
 * @param {Function} getToken - Function to get the authentication token
 * @returns {Promise<Object>} Response message
 */
export const generateSampleMipsCriteria = async (getToken) => {
    try {
        const response = await callProtectedApi(
            getToken,
            protectedResources.apiGenerateSampleMipsCriteria.endpoint,
            { method: 'GET' }
        );
        return response;
    } catch (error) {
        console.error('Error generating sample MIPS criteria:', error);
        throw error;
    }
}; 