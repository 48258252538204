// api/riskScoreApi.js  
import { protectedResources, callProtectedApi } from '../authConfig';

export const getRiskScores = async (getAccessTokenSilently) => {
    try {
        return await callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiFetchRiskScores.endpoint,
            {
                method: 'GET'
            }
        );
    } catch (error) {
        console.error('Error fetching risk scores:', error);
        throw error;
    }
};  