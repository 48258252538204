import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources } from '../../authConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthProvider';
import { useFeatureFlagEnabled } from 'posthog-js/react';

import { useSettings } from '../../contexts/SettingsProvider';

import { upsertGroupMacro, deleteMacro } from '../../api/macroApi';
import { getAdminGroupMacros } from '../../api/groupApi';



export const GroupMacrosPage = ({ groupId, onSuccessfulSave, isModal }) => {
  // State variables
  const { actorInfo } = useAuth();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [macroId, setMacroId] = useState(null);
  const [macroTitle, setMacroTitle] = useState('');
  const [macroCommand, setMacroCommand] = useState('');
  const [macroContent, setMacroContent] = useState('');
  const [macroSection, setMacroSection] = useState('reevaluation');
  const [isLoading, setIsLoading] = useState(false);
  const [macroList, setMacroList] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMacroId, setSelectedMacroId] = useState(null);
  const [isInherited, setIsInherited] = useState(false);
  const { getSetting, isLoading: isSettingsLoading } = useSettings();
  const isProceduresEnabled = useFeatureFlagEnabled('procedures') || getSetting('procedures_enabled');

  // Check if user has admin privileges (global admin or organization admin)
  const hasAdminPrivileges = actorInfo.admin_status === 'GLOBAL_ADMIN' || actorInfo.admin_status === 'ORGANIZATION_ADMIN';

  const handleTokenExpiration = useCallback((error) => {
    if (error.message.includes('invalid_token')) {
      console.error('Token error:', error);
    } else {
      console.error('Error:', error);
    }
  }, []);

  const upsertMacro = async () => {
    setIsLoading(true);
    try {
      const macroData = {
        macro_id: macroId || null,
        title: macroTitle.trim(),
        command: macroCommand.trim(),
        content: macroContent.trim(),
        section: macroSection
      };

      if (!macroData.title || !macroData.content) {
        console.error('Required fields missing');
        return;
      }

      console.log('Sending macro data:', macroData);

      // Pass groupId if user has admin privileges
      const targetGroupId = hasAdminPrivileges ? groupId : null;
      const data = await upsertGroupMacro(getAccessTokenSilently, macroData, targetGroupId);

      if (data.success) {
        await getMacros();
        setSelectedMacroId(data.macro_id);
        onSuccessfulSave(`Macro "${macroTitle}" saved successfully`);
      } else {
        console.error('Error upserting macro:', data.error);
      }
    } catch (error) {
      handleTokenExpiration(error);
      console.error('Error updating document:', error);
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const data = await deleteMacro(getAccessTokenSilently, macroId);
      if (data.success) {
        resetForm();
        await getMacros();
        onSuccessfulSave('Macro deleted successfully');
      } else {
        console.error('Error deleting macro:', data.error);
      }
    } catch (error) {
      handleTokenExpiration(error);
      console.error('Error deleting document:', error);
    }
    setIsLoading(false);
  };

  const handleMacroClick = (macro) => {
    setMacroId(macro.macro_id);
    setMacroTitle(macro.title);
    setMacroCommand(macro.command);
    setMacroContent(macro.content);
    setMacroSection(macro.section || 'reevaluation');
    setSelectedMacroId(macro.macro_id);

    // Store the inherited status in state
    setIsInherited(macro.is_inherited);
    setIsEditable(!macro.is_inherited);
  };

  const getMacros = useCallback(async () => {
    console.log('Getting macros');
    setIsLoading(true);
    try {
      // Pass groupId if user has admin privileges
      const targetGroupId = hasAdminPrivileges ? groupId : null;
      const data = await getAdminGroupMacros(getAccessTokenSilently, targetGroupId);

      if (data.macros && typeof data.macros === 'object') {
        const macros = Object.values(data.macros);
        setMacroList(macros);
        console.log('Macros fetched:', macros);
      }
    } catch (error) {
      handleTokenExpiration(error);
      console.error('Error fetching macros:', error);
    }
    setIsLoading(false);
  }, [getAccessTokenSilently, hasAdminPrivileges, groupId]);

  const isDuplicateTitle = (title) => {
    return macroList.some(macro => macro.title.toLowerCase() === title.toLowerCase());
  };

  const handleSaveClick = () => {
    if (!areFieldsFilled()) {
      alert("Please fill in all the required fields.");
      return;
    }

    if (selectedMacroId === null && isDuplicateTitle(macroTitle)) {
      alert("A macro with this title already exists. Please use a different title.");
      return;
    }

    upsertMacro();
    setIsEditable(false);
  };

  const areFieldsFilled = () => {
    return macroTitle.trim() !== '' && macroContent.trim() !== '';
  };

  const handleDeleteClick = () => {
    handleDelete();
  };

  const handleNewMacro = () => {
    resetForm();
  };

  const resetForm = () => {
    setMacroId(null);
    setMacroTitle('');
    setMacroCommand('');
    setMacroContent('');
    setMacroSection('reevaluation');
    setSelectedMacroId(null);
    setIsEditable(true);
    setIsInherited(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getMacros();
    }, 250);

    return () => clearTimeout(timeoutId);
  }, [getMacros]);

  // First, let's separate the macros into two lists
  const getMacroLists = useCallback(() => {
    const ownMacros = macroList.filter(m => !m.is_inherited);
    const inheritedMacros = macroList.filter(m => m.is_inherited);
    return { ownMacros, inheritedMacros };
  }, [macroList]);

  return (
    <>
      <div className={isModal ? '' : 'container'}>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Group Macro Settings</h1>
        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-6 sm:p-8">
            <div className="flex">
              {/* Left Column */}
              <div className="w-1/3 pr-4">
                <div className="flex flex-col space-y-4">
                  {/* Always show New Empty Macro button */}
                  <button
                    className="inline-flex items-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleNewMacro}>
                    + New Empty Macro
                  </button>

                  <input
                    type="text"
                    placeholder="Search macros"
                    className="p-2 border border-gray-300 rounded w-full"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>

                {/* Group's Own Macros */}
                <div className="mt-4">
                  <h2 className="text-lg font-semibold mb-2">Group Macros</h2>
                  <div className="overflow-y-auto" style={{ maxHeight: '200px' }}>
                    {isLoading ? (
                      <div className="spinner" />
                    ) : getMacroLists().ownMacros.length > 0 ? (
                      <ul className="space-y-0">
                        {getMacroLists().ownMacros
                          .filter(macro => macro.title.toLowerCase().includes(searchQuery.toLowerCase()))
                          .map((m) => (
                            <li
                              key={m.macro_id}
                              className={`
                                cursor-pointer 
                                text-zinc-700 
                                hover:bg-gray-100 
                                p-2 
                                ${m.macro_id === selectedMacroId ? 'bg-gray-200' : ''} 
                                border-b border-gray-200
                              `}
                              onClick={() => handleMacroClick(m)}
                            >
                              <span>{m.title}</span>
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <p className="text-center text-gray-500 py-4">No group macros found</p>
                    )}
                  </div>
                </div>

                {/* Inherited Macros Section */}
                <div className="mt-8">
                  <h2 className="text-lg font-semibold mb-2 text-gray-600">Inherited Macros</h2>
                  <div className="overflow-y-auto" style={{ maxHeight: '200px' }}>
                    {isLoading ? (
                      <div className="spinner" />
                    ) : getMacroLists().inheritedMacros.length > 0 ? (
                      <ul className="space-y-0">
                        {getMacroLists().inheritedMacros
                          .filter(macro => macro.title.toLowerCase().includes(searchQuery.toLowerCase()))
                          .map((m) => (
                            <li
                              key={m.macro_id}
                              className={`
                                cursor-pointer 
                                text-zinc-700 
                                hover:bg-gray-100 
                                p-2 
                                ${m.macro_id === selectedMacroId ? 'bg-gray-200' : ''} 
                                border-b border-gray-200
                                opacity-75
                              `}
                              onClick={() => handleMacroClick(m)}
                            >
                              <div className="flex justify-between items-center">
                                <span>{m.title}</span>
                                <span className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded">
                                  Inherited
                                </span>
                              </div>
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <p className="text-center text-gray-500 py-4">No inherited macros</p>
                    )}
                  </div>
                </div>
              </div>

              {/* Right Column - Form */}
              <div className="w-2/3 px-4">
                <div className="space-y-6">
                  {/* Form fields */}
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">Title</label>
                    <input
                      type="text"
                      name="macroTitle"
                      className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 
                        ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                      value={macroTitle}
                      onChange={(e) => setMacroTitle(e.target.value)}
                      disabled={!isEditable}
                      required
                    />
                  </div>

                  {/* Field for Command */}
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">When I say this during my patient encounter...</label>
                    <textarea
                      name="macroCommand"
                      className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                        ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                      rows="2"
                      placeholder="Enter command here"
                      value={macroCommand}
                      onChange={(e) => setMacroCommand(e.target.value)}
                      disabled={!isEditable}
                      required
                    />
                  </div>

                  {/* Content */}
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">Insert this content...</label>
                    <textarea
                      name="macroContent"
                      className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                        ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                      rows="8"
                      placeholder="Enter content here"
                      value={macroContent}
                      onChange={(e) => setMacroContent(e.target.value)}
                      disabled={!isEditable}
                      required
                    />
                  </div>

                  {/* Section */}
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900">Into this section of my note...</label>
                    <select
                      name="macroSection"
                      className={`mt-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                        ${!isEditable ? 'bg-gray-100 cursor-not-allowed text-gray-500' : 'text-gray-900'}`}
                      value={macroSection || 'reevaluation'}
                      onChange={(e) => setMacroSection(e.target.value)}
                      disabled={!isEditable}
                    >
                      <option value="hpi">HPI</option>
                      <option value="ros">Review of Systems</option>
                      <option value="physical_exam">Physical Exam</option>
                      <option value="reevaluation">Evaluations</option>
                      {isProceduresEnabled && (
                        <option value="procedures">Procedures</option>
                      )}
                      <option value="mdm">MDM</option>
                    </select>
                  </div>

                  {/* Show inherited macro message */}
                  {isInherited && (
                    <div className="bg-gray-50 border border-gray-200 rounded p-3 text-sm text-gray-600">
                      This is an inherited macro. It cannot be edited or deleted at this level.
                    </div>
                  )}

                  {/* Only show action buttons if the macro is editable */}
                  {isEditable && selectedMacroId && (
                    <div className="flex justify-between mt-4">
                      <button
                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-50 ring-1 ring-inset ring-red-600 focus:outline-none focus:ring-2 focus:ring-red-600"
                        onClick={handleDeleteClick}>
                        Delete
                      </button>
                      <button
                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 ring-1 ring-inset ring-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                        onClick={handleSaveClick}
                        disabled={!areFieldsFilled()}>
                        Save
                      </button>
                    </div>
                  )}

                  {/* Show save button for new macros */}
                  {isEditable && !selectedMacroId && (
                    <div className="flex justify-end mt-4">
                      <button
                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 ring-1 ring-inset ring-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                        onClick={handleSaveClick}
                        disabled={!areFieldsFilled()}>
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupMacrosPage;

