import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faSpinner, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { callProtectedApi } from '../authConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources } from '../authConfig';

const TreatmentPlanModal = ({ isOpen, onClose, documentId }) => {
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [approvedPlans, setApprovedPlans] = useState({});
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isOpen && documentId) {
      generateTreatmentPlan();
    }
  }, [isOpen, documentId]);

  const generateTreatmentPlan = async () => {
    setLoading(true);
    try {
      const data = await callProtectedApi(
        getAccessTokenSilently,
        protectedResources.apiGenerateTreatmentPlan.endpoint,
        {
          method: 'POST',
          body: JSON.stringify({ document_id: documentId })
        }
      );
      
      console.log("Treatment plan data:", data);
      
      // Get the treatment plans from the response
      const plans = data.treatment_plan || [];
      console.log("Treatment plans:", plans);
      
      setTreatmentPlans(plans);
      
      // Initialize approval status for each plan
      const initialApprovals = {};
      plans.forEach((_, index) => {
        initialApprovals[index] = null; // null means not decided yet
      });
      setApprovedPlans(initialApprovals);
      
    } catch (error) {
      console.error('Error generating treatment plan:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = (index) => {
    setApprovedPlans(prev => ({
      ...prev,
      [index]: true
    }));
    
    // Move to next plan if not the last one
    if (index < treatmentPlans.length - 1) {
      setTimeout(() => setCurrentIndex(index + 1), 300);
    }
  };

  const handleDeny = (index) => {
    setApprovedPlans(prev => ({
      ...prev,
      [index]: false
    }));
    
    // Move to next plan if not the last one
    if (index < treatmentPlans.length - 1) {
      setTimeout(() => setCurrentIndex(index + 1), 300);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < treatmentPlans.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleFinish = () => {
    // Here you could add logic to submit the approved plans to your backend
    console.log('Approved plans:', approvedPlans);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-2xl mx-4 overflow-hidden">
        {/* Header */}
        <div className="bg-indigo-900 text-white px-6 py-4 flex justify-between items-center">
          <h2 className="text-xl font-semibold">Treatment Plan</h2>
          <button 
            onClick={onClose}
            className="text-white hover:text-gray-300 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          {loading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-600 text-4xl mb-4" />
              <p className="text-gray-600">Generating treatment plan...</p>
            </div>
          ) : treatmentPlans.length === 0 ? (
            <div className="text-center py-8">
              <p className="text-gray-600">No treatment plans available.</p>
            </div>
          ) : (
            <div className="min-h-[300px]">
              {treatmentPlans.map((plan, index) => (
                <div 
                  key={index} 
                  className={`transition-opacity duration-300 ${currentIndex === index ? 'block opacity-100' : 'hidden opacity-0'}`}
                >
                  <div className="mb-6">
                    <h3 className="text-lg font-semibold text-indigo-900 mb-2">
                      {plan.Subject}
                    </h3>
                    <div className="bg-indigo-50 text-indigo-700 px-3 py-1 rounded-full inline-block text-sm mb-4">
                      Send: {plan.Time}
                    </div>
                    <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-4">
                      <p className="whitespace-pre-line text-gray-700">{plan.Content}</p>
                    </div>
                  </div>

                  {/* Approval status indicator */}
                  {approvedPlans[index] !== null && (
                    <div className={`mb-4 p-2 rounded-md text-center ${approvedPlans[index] ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                      {approvedPlans[index] ? 'Message Approved' : 'Message Denied'}
                    </div>
                  )}

                  {/* Approve/Deny buttons */}
                  <div className="flex justify-center space-x-4 mt-6">
                    <button
                      onClick={() => handleDeny(index)}
                      disabled={approvedPlans[index] !== null}
                      className={`px-4 py-2 rounded-md flex items-center ${
                        approvedPlans[index] === false 
                          ? 'bg-red-100 text-red-700 border border-red-300' 
                          : 'bg-white text-red-600 border border-red-300 hover:bg-red-50'
                      } ${approvedPlans[index] !== null ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      <FontAwesomeIcon icon={faTimes} className="mr-2" />
                      Deny
                    </button>
                    <button
                      onClick={() => handleApprove(index)}
                      disabled={approvedPlans[index] !== null}
                      className={`px-4 py-2 rounded-md flex items-center ${
                        approvedPlans[index] === true 
                          ? 'bg-green-100 text-green-700 border border-green-300' 
                          : 'bg-white text-green-600 border border-green-300 hover:bg-green-50'
                      } ${approvedPlans[index] !== null ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      <FontAwesomeIcon icon={faCheck} className="mr-2" />
                      Approve
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Footer with navigation */}
        {!loading && treatmentPlans.length > 0 && (
          <div className="bg-gray-50 px-6 py-4 flex justify-between items-center border-t border-gray-200">
            <div className="flex items-center">
              <button
                onClick={handlePrevious}
                disabled={currentIndex === 0}
                className={`p-2 rounded-full ${currentIndex === 0 ? 'text-gray-400 cursor-not-allowed' : 'text-indigo-600 hover:bg-indigo-100'}`}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              
              {/* Pagination dots */}
              <div className="flex space-x-2 mx-4">
                {treatmentPlans.map((_, index) => (
                  <div 
                    key={index}
                    className={`h-2 w-2 rounded-full ${
                      currentIndex === index 
                        ? 'bg-indigo-600' 
                        : approvedPlans[index] === true 
                          ? 'bg-green-500'
                          : approvedPlans[index] === false
                            ? 'bg-red-500'
                            : 'bg-gray-300'
                    }`}
                  />
                ))}
              </div>
              
              <button
                onClick={handleNext}
                disabled={currentIndex === treatmentPlans.length - 1}
                className={`p-2 rounded-full ${currentIndex === treatmentPlans.length - 1 ? 'text-gray-400 cursor-not-allowed' : 'text-indigo-600 hover:bg-indigo-100'}`}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
            
            <button
              onClick={handleFinish}
              className="px-4 py-2 bg-indigo-900 text-white rounded-md hover:bg-indigo-800 focus:outline-none"
            >
              Finish
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TreatmentPlanModal; 