import { callProtectedApi } from '../authConfig';
import { protectedResources } from '../authConfig';

export const adminApi = {
    previewMerge: async (getToken, baseEmail, extraEmail) => {
        return callProtectedApi(
            getToken,
            `${protectedResources.apiPreviewMerge.endpoint}?base_email=${baseEmail}&extra_email=${extraEmail}`
        );
    },

    mergeAccounts: async (getToken, mergeData) => {
        return callProtectedApi(
            getToken,
            protectedResources.apiMergeAccounts.endpoint,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(mergeData),
            }
        );
    },

    listOrganizations: async (getToken) => {
        return callProtectedApi(
            getToken,
            protectedResources.apiListOrgs.endpoint
        );
    }
}; 