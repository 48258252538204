import { callProtectedApi, protectedResources } from '../authConfig';

export const organizationPortalApi = {
    getOrgUsers: async (getAccessTokenSilently, orgId) => {
        try {
            const response = await callProtectedApi(
                getAccessTokenSilently,
                `${protectedResources.apiGetUsersForOrgPortal.endpoint}`,
                {
                    method: 'POST',
                    body: JSON.stringify({ org_id: orgId }),
                }
            );
            return response.users.map(user => ({
                actor_id: user.actor_id,
                name: user.full_name,
                email: user.email,
                actor_status: user.actor_status,
                has_signed_in: user.has_signed_in,
                status: !user.has_signed_in && user.actor_status === 'ACTIVE'
                    ? 'PROVISIONED'
                    : user.actor_status,
                group_name: user.group_name,
                specialty: user.specialty,
                is_admin: user.is_admin
            }));
        } catch (error) {
            console.error('Error fetching organization users:', error);
            throw error;
        }
    },

    updateUserStatus: async (getAccessTokenSilently, userId, isActive) => {
        try {
            console.log(`Attempting to update user ${userId} status to ${isActive ? 'ACTIVE' : 'INACTIVE'}`);
            const response = await callProtectedApi(
                getAccessTokenSilently,
                `${protectedResources.apiUpdateUser.endpoint}${userId}`,
                {
                    method: 'PUT',
                    body: JSON.stringify({
                        user_data: {
                            actor_status: isActive ? 'ACTIVE' : 'INACTIVE'
                        }
                    }),
                }
            );
            console.log('Update response:', response);
            if (!response) {
                throw new Error('No response received from server');
            }
            return response;
        } catch (error) {
            console.error('Error details:', {
                userId,
                isActive,
                error: error.message,
                response: error.response,
                stack: error.stack
            });
            throw error;
        }
    },

    uploadUsersCsv: async (getAccessTokenSilently, file, orgId) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const token = await getAccessTokenSilently();
            const response = await fetch(
                `${protectedResources.apiCreateUsersFromCsv.endpoint}?org_id=${orgId}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Error uploading CSV');
            }

            return await response.json();
        } catch (error) {
            console.error('Error uploading users CSV:', error);
            throw error;
        }
    },

    moveUserToGroup: async (getAccessTokenSilently, actorId, groupId) => {
        try {
            const response = await callProtectedApi(
                getAccessTokenSilently,
                `${protectedResources.apiActorsBase.endpoint}/move/`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        actor_id: actorId,
                        group_id: groupId
                    }),
                }
            );

            if (!response) {
                throw new Error('No response received from server');
            }

            return response;
        } catch (error) {
            console.error('Error moving user to group:', error);
            throw error;
        }
    },

    getUsageData: async (getAccessTokenSilently, orgId, startDate, endDate, allTime = false) => {
        try {
            // Get token
            const token = await getAccessTokenSilently();

            // Prepare request body
            const requestBody = {
                org_id: orgId,
                start_date: startDate,
                end_date: endDate,
                all_time: allTime
            };

            // Make API call
            const response = await fetch(
                protectedResources.apiGetUsageDataForOrgPortal.endpoint,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(requestBody)
                }
            );

            // Check if response is ok
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Error fetching usage data');
            }

            // Get blob from response
            const blob = await response.blob();

            // Create download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');

            // Get filename from Content-Disposition header or create a default one
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = 'usage_data.csv';

            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="?([^"]*)"?/);
                if (filenameMatch && filenameMatch[1]) {
                    filename = filenameMatch[1];
                }
            }

            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            return true;
        } catch (error) {
            console.error('Error fetching usage data:', error);
            throw error;
        }
    },

    downloadUsersListReport: async (getAccessTokenSilently, orgId) => {
        try {
            // Get token
            const token = await getAccessTokenSilently();

            // Prepare request body
            const requestBody = {
                org_id: orgId
            };

            // Make API call
            const response = await fetch(
                protectedResources.apiGetUsersListReportCsv.endpoint,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(requestBody)
                }
            );

            // Check if response is ok
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Error fetching users list report');
            }

            // Get blob from response
            const blob = await response.blob();

            // Create download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');

            // Get filename from Content-Disposition header or create a default one
            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = 'users_list_report.csv';

            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="?([^"]*)"?/);
                if (filenameMatch && filenameMatch[1]) {
                    filename = filenameMatch[1];
                }
            }

            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            return true;
        } catch (error) {
            console.error('Error downloading users list report:', error);
            throw error;
        }
    }
};

// Get group hierarchy
export const getGroupHierarchy = async (getAccessTokenSilently, orgId) => {
    try {
        const response = await callProtectedApi(
            getAccessTokenSilently,
            `${protectedResources.apiGetGroupHierarchy.endpoint}${orgId}`,
            { method: 'GET' }
        );

        // The API returns { hierarchy: Array } so we need to extract the hierarchy property
        if (response && response.hierarchy) {
            return response.hierarchy;
        }

        // Return empty array if hierarchy is not found or not an array
        return [];
    } catch (error) {
        console.error('Error fetching group hierarchy:', error);
        // Return empty array on error
        return [];
    }
};

// Get a specific setting for a group
export const getGroupSetting = async (getAccessTokenSilently, groupId, settingName) => {
    try {
        const response = await callProtectedApi(
            getAccessTokenSilently,
            `${protectedResources.apiSettings.endpoint}group/${groupId}/setting/${settingName}`,
            { method: 'GET' }
        );
        return response;
    } catch (error) {
        console.error(`Error fetching setting ${settingName} for group ${groupId}:`, error);
        throw error;
    }
};

// Update a setting for a group
export const updateGroupSetting = async (getAccessTokenSilently, groupId, settingName, value) => {
    try {
        const response = await callProtectedApi(
            getAccessTokenSilently,
            `${protectedResources.apiSettings.endpoint}group/${groupId}/setting/${settingName}`,
            {
                method: 'POST',
                body: JSON.stringify({ value })
            }
        );
        return response;
    } catch (error) {
        console.error(`Error updating setting ${settingName} for group ${groupId}:`, error);
        throw error;
    }
}; 