import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../contexts/AuthProvider';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import {
    UserGroupIcon,
    CubeIcon,
    ChartBarIcon,
    ChevronRightIcon,
    UserIcon,
    CogIcon,
    DocumentTextIcon
} from '@heroicons/react/24/outline';
import OrganizationPortalSSOProvisioning from './OrganizationPortalSSOProvisioning';
import OrganizationPortalMacroManagement from './OrganizationPortalMacroManagement';
import OrganizationPortalUsageReporting from './OrganizationPortalUsageReporting';
import OrganizationPortalPatientExperience from './OrganizationPortalPatientExperience';
import OrganizationPortalSettingsManagement from './OrganizationPortalSettingsManagement';
import OrganizationPortalCustomInstructions from './OrganizationPortalCustomInstructions';
import OrganizationPortalConsentAttestations from './OrganizationPortalConsentAttestations';
import { getGroupHierarchy } from '../api/groupApi';

const OrganizationPortal = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('sso-provisioning');
    const [organizationName, setOrganizationName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const isPexAnalysisEnabled = useFeatureFlagEnabled('org_pex_analysis');
    const isSettingsEnabled = useFeatureFlagEnabled('org_portal_settings');
    const isSSOProvisioningEnabled = useFeatureFlagEnabled('org_portal_sso_provisioning');
    const isMacroManagementEnabled = useFeatureFlagEnabled('org_portal_macro_management');
    const isCustomInstructionsEnabled = useFeatureFlagEnabled('org_portal_custom_instructions');
    const isConsentAttestationsEnabled = useFeatureFlagEnabled('org_portal_consent_attestations');

    // Get organization ID from location state if provided (for global admin viewing other orgs)
    const isGlobalAdminView = location.state?.isGlobalAdminView || false;
    const orgId = isGlobalAdminView ? location.state?.orgId : actorInfo?.org_id;
    const passedOrgName = location.state?.orgName;

    // Check if user has access to this organization portal
    const hasAccess = actorInfo && (
        actorInfo.admin_status === 'GLOBAL_ADMIN' ||
        (actorInfo.admin_status === 'ORGANIZATION_ADMIN' &&
            (!isGlobalAdminView || actorInfo.org_id === orgId))
    );

    useEffect(() => {
        // Set the active tab based on the current path
        const path = location.pathname;
        if (path.includes('macro-management')) {
            setActiveTab('macro-management');
        } else if (path.includes('usage-reporting')) {
            setActiveTab('usage-reporting');
        } else if (path.includes('settings-management')) {
            setActiveTab('settings-management');
        } else if (path.includes('patient-experience')) {
            setActiveTab('patient-experience');
        } else if (path.includes('custom-instructions')) {
            setActiveTab('custom-instructions');
        } else if (path.includes('consent-attestations')) {
            setActiveTab('consent-attestations');
        } else {
            setActiveTab('sso-provisioning');
        }

        // Use passed org name if available, otherwise fetch it
        if (passedOrgName && isGlobalAdminView) {
            setOrganizationName(passedOrgName);
            setIsLoading(false);
        } else {
            // Fetch organization name
            const fetchOrgName = async () => {
                if (!orgId) return;

                try {
                    const hierarchy = await getGroupHierarchy(getAccessTokenSilently, orgId);

                    // Find the top-level group (level 1) to use as organization name
                    const topLevelGroups = hierarchy.filter(group => group.level === 1);
                    if (topLevelGroups.length > 0) {
                        // Use the first top-level group's name as the organization name
                        setOrganizationName(topLevelGroups[0].name);
                    } else if (actorInfo?.org_name) {
                        // Fallback to org_name from actorInfo if available
                        setOrganizationName(actorInfo.org_name);
                    }
                } catch (error) {
                    console.error('Error fetching organization name:', error);
                    // Fallback to org_name from actorInfo if available
                    if (actorInfo?.org_name) {
                        setOrganizationName(actorInfo.org_name);
                    }
                } finally {
                    setIsLoading(false);
                }
            };

            fetchOrgName();
        }
    }, [location.pathname, actorInfo, getAccessTokenSilently, orgId, passedOrgName, isGlobalAdminView]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        // Preserve the organization info in the state when navigating between tabs
        const state = isGlobalAdminView ? {
            orgId,
            orgName: organizationName,
            isGlobalAdminView
        } : undefined;

        if (tab === 'sso-provisioning') {
            navigate('/organization-portal/sso-provisioning', { state });
        } else if (tab === 'macro-management') {
            navigate('/organization-portal/macro-management', { state });
        } else if (tab === 'usage-reporting') {
            navigate('/organization-portal/usage-reporting', { state });
        } else if (tab === 'patient-experience') {
            navigate('/organization-portal/patient-experience', { state });
        } else if (tab === 'settings-management') {
            navigate('/organization-portal/settings-management', { state });
        } else if (tab === 'custom-instructions') {
            navigate('/organization-portal/custom-instructions', { state });
        } else if (tab === 'consent-attestations') {
            navigate('/organization-portal/consent-attestations', { state });
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (!hasAccess) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <div className="text-red-500 text-xl font-semibold mb-2">Access Denied</div>
                <p className="text-gray-600">You do not have permission to access this portal.</p>
            </div>
        );
    }

    return (
        <div className="flex h-screen bg-gray-50">
            {/* Left Sidebar */}
            <div className="w-64 bg-white shadow-md">
                <div className="p-4 border-b border-gray-200">
                    <h2 className="text-lg font-semibold text-gray-800">Organization Portal</h2>
                    <p className="text-sm text-gray-500">{organizationName}</p>
                    {isGlobalAdminView && (
                        <div className="mt-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            Global Admin View
                        </div>
                    )}
                </div>
                <nav className="mt-4">
                    <ul className="space-y-1">
                        {isSSOProvisioningEnabled && (
                            <li>
                                <button
                                    onClick={() => handleTabClick('sso-provisioning')}
                                    className={`flex items-center w-full px-4 py-3 text-left ${activeTab === 'sso-provisioning'
                                        ? 'bg-blue-50 text-blue-600 border-l-4 border-blue-600'
                                        : 'text-gray-700 hover:bg-gray-100'
                                        }`}
                                >
                                    <UserGroupIcon className="h-5 w-5 mr-3" />
                                    <span>SSO Provisioning</span>
                                    <ChevronRightIcon className="h-4 w-4 ml-auto" />
                                </button>
                            </li>
                        )}
                        {isMacroManagementEnabled && (
                            <li>
                                <button
                                    onClick={() => handleTabClick('macro-management')}
                                    className={`flex items-center w-full px-4 py-3 text-left ${activeTab === 'macro-management'
                                        ? 'bg-blue-50 text-blue-600 border-l-4 border-blue-600'
                                        : 'text-gray-700 hover:bg-gray-100'
                                        }`}
                                >
                                    <CubeIcon className="h-5 w-5 mr-3" />
                                    <span>Macro Management</span>
                                    <ChevronRightIcon className="h-4 w-4 ml-auto" />
                                </button>
                            </li>
                        )}
                        <li>
                            <button
                                onClick={() => handleTabClick('usage-reporting')}
                                className={`flex items-center w-full px-4 py-3 text-left ${activeTab === 'usage-reporting'
                                    ? 'bg-blue-50 text-blue-600 border-l-4 border-blue-600'
                                    : 'text-gray-700 hover:bg-gray-100'
                                    }`}
                            >
                                <ChartBarIcon className="h-5 w-5 mr-3" />
                                <span>Usage Reporting</span>
                                <ChevronRightIcon className="h-4 w-4 ml-auto" />
                            </button>
                        </li>
                        {isPexAnalysisEnabled && (
                            <li>
                                <button
                                    onClick={() => handleTabClick('patient-experience')}
                                    className={`flex items-center w-full px-4 py-3 text-left ${activeTab === 'patient-experience'
                                        ? 'bg-blue-50 text-blue-600 border-l-4 border-blue-600'
                                        : 'text-gray-700 hover:bg-gray-100'
                                        }`}
                                >
                                    <UserIcon className="h-5 w-5 mr-3" />
                                    <span>Patient Experience</span>
                                    <ChevronRightIcon className="h-4 w-4 ml-auto" />
                                </button>
                            </li>
                        )}
                        {isSettingsEnabled && (
                            <li>
                                <button
                                    onClick={() => handleTabClick('settings-management')}
                                    className={`flex items-center w-full px-4 py-3 text-left ${activeTab === 'settings-management'
                                        ? 'bg-blue-50 text-blue-600 border-l-4 border-blue-600'
                                        : 'text-gray-700 hover:bg-gray-100'
                                        }`}
                                >
                                    <CogIcon className="h-5 w-5 mr-3" />
                                    <span>Settings Management</span>
                                    <ChevronRightIcon className="h-4 w-4 ml-auto" />
                                </button>
                            </li>
                        )}
                        {isCustomInstructionsEnabled && (
                            <li>
                                <button
                                    onClick={() => handleTabClick('custom-instructions')}
                                    className={`flex items-center w-full px-4 py-3 text-left ${activeTab === 'custom-instructions'
                                        ? 'bg-blue-50 text-blue-600 border-l-4 border-blue-600'
                                        : 'text-gray-700 hover:bg-gray-100'
                                        }`}
                                >
                                    <DocumentTextIcon className="h-5 w-5 mr-3" />
                                    <span>Custom Instructions</span>
                                    <ChevronRightIcon className="h-4 w-4 ml-auto" />
                                </button>
                            </li>
                        )}
                        {isConsentAttestationsEnabled && (
                            <li>
                                <button
                                    onClick={() => handleTabClick('consent-attestations')}
                                    className={`flex items-center w-full px-4 py-3 text-left ${activeTab === 'consent-attestations'
                                        ? 'bg-blue-50 text-blue-600 border-l-4 border-blue-600'
                                        : 'text-gray-700 hover:bg-gray-100'
                                        }`}
                                >
                                    <DocumentTextIcon className="h-5 w-5 mr-3" />
                                    <span>Consent Attestations</span>
                                    <ChevronRightIcon className="h-4 w-4 ml-auto" />
                                </button>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>

            {/* Main Content */}
            <div className="flex-1 overflow-auto">
                {activeTab === 'sso-provisioning' && <OrganizationPortalSSOProvisioning />}
                {activeTab === 'macro-management' && <OrganizationPortalMacroManagement />}
                {activeTab === 'usage-reporting' && <OrganizationPortalUsageReporting />}
                {activeTab === 'patient-experience' && <OrganizationPortalPatientExperience />}
                {activeTab === 'settings-management' && <OrganizationPortalSettingsManagement />}
                {activeTab === 'custom-instructions' && <OrganizationPortalCustomInstructions />}
                {activeTab === 'consent-attestations' && <OrganizationPortalConsentAttestations />}
            </div>
        </div>
    );
};

export default OrganizationPortal; 