import { callApiWithToken, callFileUploadWithToken } from './apiUtils';
import { protectedResources } from '../authConfig';

export const invitationsApi = {
    upsertInvitation: async (getToken, data) => {
        return callApiWithToken(
            getToken,
            protectedResources.apiUpsertInvitation.endpoint,
            'POST',
            data
        );
    },

    getOrgInvitations: async (getToken, orgId) => {
        if (!orgId) {
            throw new Error('Organization ID is required');
        }

        return callApiWithToken(
            getToken,
            `${protectedResources.apiGetOrgInvitations.endpoint}${orgId}`,
            'GET'
        );
    },

    bulkCreateInvitations: async (getToken, formData, orgId) => {
        return callFileUploadWithToken(
            getToken,
            `${protectedResources.apiBulkCreateInvitations.endpoint}?org_id=${orgId}`,
            formData
        );
    }
}; 