import log from 'loglevel';

/**
 * Application logger utility
 * 
 * This module configures loglevel for consistent logging across the application.
 * - In development: All log levels are enabled (DEBUG and above)
 * - In production: Only WARN and ERROR logs are shown
 * 
 * Usage:
 * import logger from 'utils/logger';
 * 
 * // Get a namespaced logger for your component
 * const componentLogger = logger.getLogger('ComponentName');
 * 
 * componentLogger.debug('Debug message');
 * componentLogger.info('Info message');
 * componentLogger.warn('Warning message');
 * componentLogger.error('Error message');
 */

// Set default level for all loggers based on environment
log.setDefaultLevel(process.env.NODE_ENV === 'production' ? log.levels.WARN : log.levels.DEBUG);

/**
 * Get a namespaced logger for a specific component or module
 * @param {string} namespace - The name of the component or module
 * @returns {Object} - A loglevel logger instance with the specified namespace
 */
export const getLogger = (namespace) => {
  const logger = log.getLogger(namespace);
  
  // Wrap the original methods to include the namespace in the log message
  const originalFactory = logger.methodFactory;
  logger.methodFactory = function(methodName, logLevel, loggerName) {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);
    
    return function(...args) {
      rawMethod(`[${namespace}]`, ...args);
    };
  };
  
  // Apply the method factory changes
  logger.setLevel(logger.getLevel());
  return logger;
};

// Export a default logger for general use
const defaultLogger = getLogger('App');

// Export the main functions directly
export default {
  getLogger,
  debug: defaultLogger.debug.bind(defaultLogger),
  info: defaultLogger.info.bind(defaultLogger),
  warn: defaultLogger.warn.bind(defaultLogger),
  error: defaultLogger.error.bind(defaultLogger),
  // Allow changing the log level at runtime if needed
  setLevel: (level) => log.setLevel(level)
}; 