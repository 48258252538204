import { protectedResources, callProtectedApi } from '../authConfig';

// Make sure we're using the correct API URL
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';
console.log('Patient Service using API URL:', API_BASE_URL);

// Add patients API endpoints to protectedResources if not already defined in authConfig.js
if (!protectedResources.apiCreatePatient) {
    console.log('Defining apiCreatePatient endpoint');
    protectedResources.apiCreatePatient = {
        endpoint: `${API_BASE_URL}/api_v1/patients/`,
        scopes: ['openid', 'profile']
    };
}

if (!protectedResources.apiPatientConcerns) {
    console.log('Defining apiPatientConcerns endpoint');
    protectedResources.apiPatientConcerns = {
        endpoint: `${API_BASE_URL}/api_v1/patients/concerns/`,
        scopes: ['openid', 'profile']
    };
}

if (!protectedResources.apiPatientConcernsByGroup) {
    console.log('Defining apiPatientConcernsByGroup endpoint');
    protectedResources.apiPatientConcernsByGroup = {
        endpoint: `${API_BASE_URL}/api_v1/patients/concerns-by-group/`,
        scopes: ['openid', 'profile']
    };
}

export const patientsService = {
    // Create a new patient for follow-up
    async createPatient(patientData, getToken) {
        try {
            console.log('Creating patient with data:', patientData);
            const response = await callProtectedApi(
                getToken,
                protectedResources.apiCreatePatient.endpoint,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(patientData)
                }
            );
            console.log('Create patient response:', response);
            return response;
        } catch (error) {
            console.error('Error in createPatient:', error);
            throw error;
        }
    },

    // Get patients with concerns
    async getPatientsWithConcerns(showResolved = false, getToken) {
        try {
            const endpoint = `${protectedResources.apiPatientConcerns.endpoint}?show_resolved=${showResolved}`;
            console.log('Getting patients with concerns, endpoint:', endpoint);
            const response = await callProtectedApi(
                getToken,
                endpoint,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Get patients with concerns response:', response);
            return response;
        } catch (error) {
            console.error('Error in getPatientsWithConcerns:', error);
            throw error;
        }
    },

    // Get patients with concerns for all providers in a group (for group admins)
    async getPatientsWithConcernsByGroup(showResolved = false, getToken) {
        try {
            const endpoint = `${protectedResources.apiPatientConcernsByGroup.endpoint}?show_resolved=${showResolved}`;
            console.log('Getting patients with concerns by group, endpoint:', endpoint);
            const response = await callProtectedApi(
                getToken,
                endpoint,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Get patients with concerns by group response:', response);
            return response;
        } catch (error) {
            console.error('Error in getPatientsWithConcernsByGroup:', error);
            throw error;
        }
    },

    // Update a patient record (e.g., mark concerns as resolved)
    async updatePatient(patientId, updateData, getToken) {
        try {
            console.log(`Updating patient ${patientId} with data:`, updateData);
            const endpoint = `${protectedResources.apiCreatePatient.endpoint}${patientId}`;
            const response = await callProtectedApi(
                getToken,
                endpoint,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updateData)
                }
            );
            console.log('Update patient response:', response);
            return response;
        } catch (error) {
            console.error(`Error updating patient ${patientId}:`, error);
            throw error;
        }
    },

    // Mark concerns as resolved for a patient
    async resolvePatientConcerns(patientId, getToken) {
        return this.updatePatient(
            patientId,
            { concerns_resolved: true },
            getToken
        );
    },
    
    // Update concern type for a patient
    async updateConcernType(patientId, concernType, getToken) {
        return this.updatePatient(
            patientId,
            { concern_type: concernType },
            getToken
        );
    },
    
    // Update concern severity for a patient
    async updateConcernSeverity(patientId, severityLevel, getToken) {
        return this.updatePatient(
            patientId,
            { concerns_severity: severityLevel },
            getToken
        );
    },
    
    // Update concern description for a patient
    async updateConcernDescription(patientId, description, getToken) {
        return this.updatePatient(
            patientId,
            { concerns_description: description },
            getToken
        );
    },
    
    // Get a single patient by ID
    async getPatientById(patientId, getToken) {
        try {
            console.log(`Getting patient by ID: ${patientId}`);
            const endpoint = `${protectedResources.apiCreatePatient.endpoint}${patientId}`;
            const response = await callProtectedApi(
                getToken,
                endpoint,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log('Get patient by ID response:', response);
            return response;
        } catch (error) {
            console.error(`Error getting patient ${patientId}:`, error);
            throw error;
        }
    }
};

export default patientsService; 