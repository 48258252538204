import { useState, useEffect, useCallback, useMemo } from 'react';
import { UserIcon, BuildingOfficeIcon, MagnifyingGlassIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources, callProtectedApi } from '../../authConfig';

function UserManagementContent({ orgId }) {
  const { getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);

  const getUsersForOrg = useCallback(async () => {
    setIsLoadingUsers(true);
    try {
      const data = await callProtectedApi(
        getAccessTokenSilently,
        protectedResources.apiGetUsersForOrg.endpoint,
        {
          method: 'POST',
          body: JSON.stringify({
            org_id: orgId,
          })
        }
      );

      if (data.users) {
        setUsers(data.users);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoadingUsers(false);
    }
  }, [getAccessTokenSilently, orgId]);

  useEffect(() => {
    if (orgId) {
      getUsersForOrg();
    }
  }, [orgId, getUsersForOrg]);

  // Group users by group_name with enhanced metrics
  const groupedUsers = useMemo(() => {
    const groups = {};
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    users.forEach(user => {
      const groupName = user.group_name || 'Unassigned';
      if (!groups[groupName]) {
        groups[groupName] = {
          name: groupName,
          users: [],
          totalPatients: 0,
          powerUsers: 0, // > 100 patients
          activeUsers: 0, // > 0 patients
          recentUsers: 0, // seen patient in last week
          monthlyActiveUsers: 0, // seen patient in last 30 days
          physicians: 0,
          apps: 0,
          lastActivity: null
        };
      }

      const group = groups[groupName];
      group.users.push(user);
      const patientCount = user.patients_seen || 0;
      group.totalPatients += patientCount;
      
      if (patientCount > 100) group.powerUsers++;
      if (patientCount > 0) group.activeUsers++;
      
      const lastSeen = user.last_patient_seen ? new Date(user.last_patient_seen) : null;
      if (lastSeen && lastSeen > oneWeekAgo) {
        group.recentUsers++;
      }
      if (lastSeen && lastSeen > thirtyDaysAgo) {
        group.monthlyActiveUsers++;
      }
      
      if (user.actor_specialty === 'EMERGENCY_PHYSICIAN') {
        group.physicians++;
      } else {
        group.apps++;
      }

      if (lastSeen && (!group.lastActivity || lastSeen > new Date(group.lastActivity))) {
        group.lastActivity = user.last_patient_seen;
      }
    });
    return groups;
  }, [users]);

  // Calculate global statistics
  const globalStats = useMemo(() => {
    return Object.values(groupedUsers).reduce((stats, group) => {
      return {
        totalPatients: stats.totalPatients + group.totalPatients,
        monthlyActiveUsers: stats.monthlyActiveUsers + group.monthlyActiveUsers,
      };
    }, { totalPatients: 0, monthlyActiveUsers: 0 });
  }, [groupedUsers]);

  // Filter groups based on search and sort by user count
  const filteredGroups = useMemo(() => {
    if (!searchQuery) {
      // If no search, just sort all groups
      return Object.entries(groupedUsers)
        .sort(([, a], [, b]) => b.users.length - a.users.length)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    }
    
    // If searching, filter first then sort
    const searchLower = searchQuery.toLowerCase();
    return Object.entries(groupedUsers)
      .filter(([groupName]) => groupName.toLowerCase().includes(searchLower))
      .sort(([, a], [, b]) => b.users.length - a.users.length)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }, [groupedUsers, searchQuery]);

  if (selectedGroup) {
    return (
      <GroupDetailView 
        group={groupedUsers[selectedGroup]} 
        onBack={() => setSelectedGroup(null)} 
      />
    );
  }

  return (
    <div className="flex flex-col w-full h-screen bg-gray-100">
      {/* Header - Fixed */}
      <div className="bg-white shadow z-10">
        <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Group Management
            </h1>
            <div className="w-64">
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="search"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Search groups..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main content - Scrollable */}
      <div className="flex-1 overflow-auto">
        <div className="py-6">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            {/* Global Stats */}
            <div className="mb-8 flex justify-center gap-8">
              <div className="bg-white rounded-xl shadow-sm overflow-hidden w-64 border border-gray-100">
                <div className="p-5">
                  <div className="flex items-start justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-500">Monthly Active</p>
                      <p className="mt-2 text-3xl font-bold text-gray-900">
                        {globalStats.monthlyActiveUsers}
                        <span className="ml-2 text-sm font-normal text-gray-500">users</span>
                      </p>
                    </div>
                    <div className="p-2 bg-indigo-50 rounded-lg">
                      <UserIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="mt-4 flex items-center text-xs text-gray-500">
                    <span className="flex items-center text-emerald-600 font-medium">
                      Past 30 days
                    </span>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow-sm overflow-hidden w-64 border border-gray-100">
                <div className="p-5">
                  <div className="flex items-start justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-500">Total Patients</p>
                      <p className="mt-2 text-3xl font-bold text-gray-900">
                        {globalStats.totalPatients.toLocaleString()}
                        <span className="ml-2 text-sm font-normal text-gray-500">seen</span>
                      </p>
                    </div>
                    <div className="p-2 bg-emerald-50 rounded-lg">
                      <BuildingOfficeIcon className="h-6 w-6 text-emerald-600" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="mt-4 flex items-center text-xs text-gray-500">
                    <span className="flex items-center text-emerald-600 font-medium">
                      All time
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Groups Grid */}
            {isLoadingUsers ? (
              <div className="flex justify-center items-center h-64">
                <div className="w-12 h-12 rounded-full border-b-2 border-indigo-500 animate-spin"></div>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 auto-rows-max">
                {Object.entries(filteredGroups).map(([groupName, groupData]) => (
                  <div
                    key={groupName}
                    onClick={() => setSelectedGroup(groupName)}
                    className="relative bg-white rounded-lg shadow transition-all duration-200 hover:shadow-lg cursor-pointer overflow-hidden"
                  >
                    {/* Header */}
                    <div className="px-6 py-4 border-b border-gray-100">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <BuildingOfficeIcon className="w-5 h-5 text-gray-400" />
                          <h3 className="ml-2 text-lg font-medium text-gray-900">
                            {groupName}
                          </h3>
                        </div>
                        <div className="flex items-center space-x-1">
                          <span className="text-sm font-medium text-purple-600">{groupData.physicians}</span>
                          <span className="text-sm text-gray-400">/</span>
                          <span className="text-sm font-medium text-blue-600">{groupData.apps}</span>
                        </div>
                      </div>
                    </div>

                    {/* Main Stats */}
                    <div className="px-6 py-4">
                      <div className="flex items-baseline justify-between mb-6">
                        <div>
                          <p className="text-sm font-medium text-gray-500">Total Users</p>
                          <p className="mt-1 text-2xl font-semibold text-gray-900">{groupData.users.length}</p>
                        </div>
                        <div className="text-right">
                          <p className="text-sm font-medium text-gray-500">Total Patients</p>
                          <p className="mt-1 text-2xl font-semibold text-gray-900">{groupData.totalPatients.toLocaleString()}</p>
                        </div>
                      </div>

                      {/* Activity Indicators */}
                      <div className="grid grid-cols-3 gap-4">
                        <div className={`rounded-lg p-3 ${groupData.powerUsers > 0 ? 'bg-indigo-50' : 'bg-gray-50'}`}>
                          <p className="text-xs font-medium text-gray-500">Over 100</p>
                          <p className={`mt-1 text-xl font-semibold ${groupData.powerUsers > 0 ? 'text-indigo-600' : 'text-gray-400'}`}>
                            {groupData.powerUsers}
                          </p>
                        </div>
                        <div className={`rounded-lg p-3 ${groupData.activeUsers > 0 ? 'bg-green-50' : 'bg-gray-50'}`}>
                          <p className="text-xs font-medium text-gray-500">Active</p>
                          <p className={`mt-1 text-xl font-semibold ${groupData.activeUsers > 0 ? 'text-green-600' : 'text-gray-400'}`}>
                            {groupData.activeUsers}
                          </p>
                        </div>
                        <div className={`rounded-lg p-3 ${groupData.recentUsers > 0 ? 'bg-blue-50' : 'bg-gray-50'}`}>
                          <p className="text-xs font-medium text-gray-500">Recent</p>
                          <p className={`mt-1 text-xl font-semibold ${groupData.recentUsers > 0 ? 'text-blue-600' : 'text-gray-400'}`}>
                            {groupData.recentUsers}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Last Activity Footer */}
                    <div className="px-6 py-2 bg-gray-50 text-xs text-gray-500">
                      Last activity: {groupData.lastActivity ? new Date(groupData.lastActivity).toLocaleDateString() : 'No activity'}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function GroupDetailView({ group, onBack }) {
  const [sortConfig, setSortConfig] = useState({ key: 'patients_seen', direction: 'desc' });

  const sortedUsers = useMemo(() => {
    const users = [...group.users];
    return users.sort((a, b) => {
      let aValue, bValue;
      
      switch (sortConfig.key) {
        case 'full_name':
          aValue = a.full_name || '';
          bValue = b.full_name || '';
          break;
        case 'actor_specialty':
          aValue = a.actor_specialty || '';
          bValue = b.actor_specialty || '';
          break;
        case 'patients_seen':
          aValue = a.patients_seen || 0;
          bValue = b.patients_seen || 0;
          break;
        case 'last_patient_seen':
          aValue = a.last_patient_seen ? new Date(a.last_patient_seen).getTime() : 0;
          bValue = b.last_patient_seen ? new Date(b.last_patient_seen).getTime() : 0;
          break;
        case 'mdm_count':
          aValue = a.mdm_count || 0;
          bValue = b.mdm_count || 0;
          break;
        case 'smart_edit_count':
          aValue = a.smart_edit_count || 0;
          bValue = b.smart_edit_count || 0;
          break;
        default:
          return 0;
      }

      if (sortConfig.direction === 'asc') {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      } else {
        return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
      }
    });
  }, [group.users, sortConfig]);

  const requestSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey) {
      return '↕️';
    }
    return sortConfig.direction === 'asc' ? '↑' : '↓';
  };

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  return (
    <div className="flex flex-col w-full bg-gray-100 min-h-screen">
      <div className="bg-white shadow">
        <div className="px-4 py-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex items-center">
            <button
              onClick={onBack}
              className="mr-4 p-2 text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <ArrowLeftIcon className="w-6 h-6" />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">{group.name}</h1>
          </div>
        </div>
      </div>

      <div className="py-6">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mb-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
            <div className="bg-white overflow-hidden rounded-lg shadow">
              <div className="p-5">
                <dt className="text-sm font-medium text-gray-500 truncate">Over 100</dt>
                <dd className="mt-1 text-3xl font-semibold text-indigo-600">{group.powerUsers}</dd>
                <p className="mt-1 text-sm text-gray-600">100+ patients seen</p>
              </div>
            </div>
            <div className="bg-white overflow-hidden rounded-lg shadow">
              <div className="p-5">
                <dt className="text-sm font-medium text-gray-500 truncate">Active Users</dt>
                <dd className="mt-1 text-3xl font-semibold text-green-600">{group.activeUsers}</dd>
                <p className="mt-1 text-sm text-gray-600">Have seen patients</p>
              </div>
            </div>
            <div className="bg-white overflow-hidden rounded-lg shadow">
              <div className="p-5">
                <dt className="text-sm font-medium text-gray-500 truncate">Recent Activity</dt>
                <dd className="mt-1 text-3xl font-semibold text-blue-600">{group.recentUsers}</dd>
                <p className="mt-1 text-sm text-gray-600">Active in past week</p>
              </div>
            </div>
            <div className="bg-white overflow-hidden rounded-lg shadow">
              <div className="p-5">
                <dt className="text-sm font-medium text-gray-500 truncate">Provider Mix</dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {group.physicians}<span className="text-sm text-gray-500"> / </span>{group.apps}
                </dd>
                <p className="mt-1 text-sm text-gray-600">Physicians / APPs</p>
              </div>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="overflow-hidden">
                <div className="max-h-[600px] overflow-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-white sticky top-0 z-10">
                      <tr>
                        <th 
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-white cursor-pointer hover:bg-gray-50"
                          onClick={() => requestSort('full_name')}
                        >
                          Provider {getSortIcon('full_name')}
                        </th>
                        <th 
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-white cursor-pointer hover:bg-gray-50"
                          onClick={() => requestSort('actor_specialty')}
                        >
                          Role {getSortIcon('actor_specialty')}
                        </th>
                        <th 
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-white cursor-pointer hover:bg-gray-50"
                          onClick={() => requestSort('patients_seen')}
                        >
                          Patients {getSortIcon('patients_seen')}
                        </th>
                        <th 
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-white cursor-pointer hover:bg-gray-50"
                          onClick={() => requestSort('last_patient_seen')}
                        >
                          Last Patient {getSortIcon('last_patient_seen')}
                        </th>
                        <th 
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-white cursor-pointer hover:bg-gray-50"
                          onClick={() => requestSort('mdm_count')}
                        >
                          MDMs {getSortIcon('mdm_count')}
                        </th>
                        <th 
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-white cursor-pointer hover:bg-gray-50"
                          onClick={() => requestSort('smart_edit_count')}
                        >
                          Smart Edits {getSortIcon('smart_edit_count')}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {sortedUsers.map((user) => {
                        const lastSeen = user.last_patient_seen ? new Date(user.last_patient_seen) : null;
                        const isRecentlyActive = lastSeen && lastSeen > oneWeekAgo;
                        const isPowerUser = (user.patients_seen || 0) > 100;

                        return (
                          <tr key={user.actor_id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div>
                                  <div className="text-sm font-medium text-gray-900">{user.full_name}</div>
                                  <div className="text-sm text-gray-500">{user.email}</div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                user.actor_specialty === 'EMERGENCY_PHYSICIAN'
                                  ? 'bg-purple-100 text-purple-800'
                                  : 'bg-blue-100 text-blue-800'
                              }`}>
                                {user.actor_specialty === 'EMERGENCY_PHYSICIAN' ? 'Physician' : 'APP'}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className={`text-sm ${isPowerUser ? 'font-semibold text-indigo-600' : 'text-gray-900'}`}>
                                {user.patients_seen || 0}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className={`text-sm ${isRecentlyActive ? 'text-green-600 font-medium' : 'text-gray-900'}`}>
                                {lastSeen ? new Date(lastSeen).toLocaleDateString() : 'Never'}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{user.mdm_count || 0}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{user.smart_edit_count || 0}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  );
}

export default UserManagementContent;
