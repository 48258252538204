import React from "react";
import ReactDOM from "react-dom/client";

import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "./App";
import ErrorPage from "./pages/ErrorPage";

import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import { auth0Config } from './authConfig';

import { PostHogProvider} from 'posthog-js/react'

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  domain : process.env.REACT_APP_AUTH0_DOMAIN,
  clientId : process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience : process.env.REACT_APP_AUTH0_AUDIENCE,
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render( 
  <Auth0Provider
      domain={options.domain}
      clientId={options.clientId}
      authorizationParams={{
        redirect_uri: auth0Config.redirectUri,
        audience: auth0Config.audience,
        scope: auth0Config.scope,
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      useRefreshTokensFallback={true}
    >
      <React.StrictMode>
        <BrowserRouter>
          <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={options}
          >
            <Routes>
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/*" element={<App />} />
            </Routes>
          </PostHogProvider>
        </BrowserRouter>
      </React.StrictMode>
    </Auth0Provider>,
  );