import React from 'react';
import { XMarkIcon, CalculatorIcon } from '@heroicons/react/20/solid';
import ProgressBar from '../PatientViewer/ProgressBars';

/**
 * MDMCodingButton component for displaying MDM coding information
 * @param {Object} props - Component props
 * @param {string} props.mdmStatus - Current MDM status
 * @param {boolean} props.mdmCodingNotificationVisible - Whether MDM coding notification is visible
 * @param {Function} props.setMDMCodingNotificationVisible - Function to update MDM coding notification visibility
 * @param {number} props.copa - Complexity of Problems Addressed value
 * @param {number} props.complexityOfData - Complexity of Data value
 * @param {number} props.riskOfComplications - Risk of Complications value
 * @param {Function} props.handleModalVisibility - Function to handle modal visibility
 */
const MDMCodingButton = ({
    mdmStatus,
    mdmCodingNotificationVisible,
    setMDMCodingNotificationVisible,
    copa,
    complexityOfData,
    riskOfComplications,
    handleModalVisibility
}) => {

    const median = (values) => {
        const sorted = [...values].sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2;
        }

        return sorted[middle];
    };

    if (mdmStatus !== "GENERATED") {
        return null;
    }

    return (
        <div className="fixed top-72 right-4 z-[70]">
            {mdmCodingNotificationVisible ? (
                <div className="h-[60vh] md:h-[375px] w-full md:w-[200px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white">
                    <div className="flex justify-between items-center bg-indigo-900 text-white py-2 pl-4 pr-2">
                        <div className="font-bold text-sm text-center flex-grow cursor-pointer" onClick={() => setMDMCodingNotificationVisible(true)}>
                            Cleo MDM Assistant
                        </div>
                        <button onClick={() => setMDMCodingNotificationVisible(false)} className="p-1 rounded-full bg-white text-indigo-900 hover:text-indigo-900">
                            <XMarkIcon className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="bg-white p-2 pt-2 px-0 flex-grow overflow-auto max-h-[calc(60vh-4rem)]">
                        <div className="mt-2 p-2 rounded-lg shadow">
                            <ProgressBar value={copa} title="Complexity of Problems Addressed" />
                            <ProgressBar value={complexityOfData} title="Complexity of Data" />
                            <ProgressBar value={riskOfComplications} title="Risk of Complications" />
                        </div>
                        <div className="mt-4 p-2 rounded-lg shadow">
                            <div className="text-sm font-semibold text-gray-700">Coding Estimate</div>
                            <div className="text-2xl font-bold text-indigo-900">
                                {Math.floor(median([copa, complexityOfData, riskOfComplications]))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <button
                    className="group flex flex-col items-center justify-center h-16 w-16 bg-indigo-900 rounded-full cursor-pointer shadow-lg hover:shadow-xl hover:bg-indigo-800 transition-all duration-200"
                    onClick={() => { handleModalVisibility('mdm') }}
                    title="MDM Assistant"
                >
                    <CalculatorIcon className="h-6 w-6 text-white group-hover:scale-110 transition-transform duration-200" />
                    <span className="text-white text-[8px] mt-1">MDM Score</span>
                </button>
            )}
        </div>
    );
};

export default MDMCodingButton; 