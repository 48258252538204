import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { useSettings } from '../../contexts/SettingsProvider';

const AutoGenerateMDMToggle = () => {
    const { getSetting, updateSetting, isLoading } = useSettings();
    const [autoGenerateMDM, setAutoGenerateMDM] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // Load the current setting value
    useEffect(() => {
        if (!isLoading) {
            const mdmSetting = getSetting('auto_generate_mdm');
            setAutoGenerateMDM(mdmSetting === true);
        }
    }, [getSetting, isLoading]);

    const handleToggleChange = async () => {
        const newValue = !autoGenerateMDM;
        setIsSaving(true);

        // Optimistic update
        setAutoGenerateMDM(newValue);

        try {
            const success = await updateSetting('auto_generate_mdm', newValue);
            if (!success) {
                // Revert if failed
                setAutoGenerateMDM(!newValue);
            }
        } catch (error) {
            console.error('Error updating auto generate MDM setting:', error);
            // Revert if failed
            setAutoGenerateMDM(!newValue);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Switch
            checked={autoGenerateMDM}
            onChange={handleToggleChange}
            disabled={isSaving}
            className={`${autoGenerateMDM ? 'bg-indigo-600' : 'bg-gray-200'}
                relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
        >
            <span className="sr-only">Auto Generate MDM</span>
            <span
                aria-hidden="true"
                className={`${autoGenerateMDM ? 'translate-x-5' : 'translate-x-0'}
                    pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
            />
        </Switch>
    );
};

export default AutoGenerateMDMToggle; 