import { protectedResources } from '../authConfig';
import { callApiWithToken } from './apiUtils';

// Utility function to safely convert any value to a string
const safeString = (value) => {
  return value ? String(value) : '';
};

export const getAvailableGroups = async (getAccessTokenSilently) => {
  try {
    return await callApiWithToken(
      getAccessTokenSilently,
      protectedResources.apiGetAvailableGroups.endpoint,
      'GET'
    );
  } catch (error) {
    console.error('getAvailableGroups error:', error);
    throw error;
  }
};

export const getGroupMdmTemplate = async (getAccessTokenSilently, selectedGroup) => {
  try {
    if (!selectedGroup) {
      return '';
    }

    // Ensure groupId is always an integer
    const groupId = parseInt(
      typeof selectedGroup === 'object' ? safeString(selectedGroup.group_id) : safeString(selectedGroup),
      10
    );

    if (!Number.isInteger(groupId)) {
      throw new Error('Invalid group ID: must be an integer');
    }

    // Ensure proper URL formatting with a forward slash
    const endpoint = `${protectedResources.apiGetGroupMdmTemplate.endpoint}${groupId}`;

    return await callApiWithToken(
      getAccessTokenSilently,
      endpoint,
      'GET'
    );
  } catch (error) {
    console.error('getGroupMdmTemplate error:', error);
    throw error;
  }
};

export const getActorsInGroup = async (getAccessTokenSilently, groupId) => {
  try {
    // Ensure groupId is provided and is a valid integer  
    if (!groupId || !Number.isInteger(groupId)) {
      throw new Error('Invalid or missing group ID.');
    }

    // Define the endpoint, adding the groupId to the URL  
    const endpoint = `${protectedResources.apiGetGroupBase.endpoint}/${groupId}/actors`;

    return await callApiWithToken(
      getAccessTokenSilently,
      endpoint,
      'GET'
    );
  } catch (error) {
    console.error('getActorsInGroup error:', error);
    throw error;
  }
};

export const getAdminGroupMacros = async (getAccessTokenSilently, targetGroupId = null) => {
  try {
    // Build the endpoint URL with optional group_id parameter
    let endpoint = protectedResources.apiGetAdminGroupMacros.endpoint;
    if (targetGroupId) {
      endpoint += `?group_id=${targetGroupId}`;
    }

    return await callApiWithToken(
      getAccessTokenSilently,
      endpoint,
      'GET'
    );
  } catch (error) {
    console.error('getAdminGroupMacros error:', error);
    throw error;
  }
};

export const getGroupHierarchy = async (getAccessTokenSilently, orgId) => {
  try {
    if (!orgId) {
      throw new Error('Organization ID is required');
    }

    const endpoint = `${protectedResources.apiGetGroupHierarchy.endpoint}${orgId}`;
    const response = await callApiWithToken(
      getAccessTokenSilently,
      endpoint,
      'GET'
    );
    return response.hierarchy;
  } catch (error) {
    console.error('getGroupHierarchy error:', error);
    throw error;
  }
}; 