import React from 'react';
import { useAuth } from '../contexts/AuthProvider';
import GenericSettings from './GenericSettings';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function GeneralSettings({ onSuccessfulSave }) {
    const { actorInfo, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const validateDataRetention = (value) => {
        const days = Number(value);
        if (isNaN(days)) {
            return { isValid: false, message: 'Must be a number' };
        }
        if (days < 1) {
            return { isValid: false, message: 'Must be at least 1 day' };
        }
        if (days > 30) {
            return { isValid: false, message: 'Cannot exceed 30 days' };
        }
        return { isValid: true, message: '' };
    };

    // Convert hours to days for display
    const hoursToDisplayDays = (hours) => {
        return Math.round(hours / 24);
    };

    // Convert days back to hours for API
    const displayDaysToHours = (days) => {
        return days * 24;
    };

    return (
        <>
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Settings</h1>
            
            {/* Data Retention Section */}
            <div className="mb-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Data Retention</h2>
                <GenericSettings
                    settingName="data_deletion_window"
                    title="Data Deletion Window"
                    description="Clinical notes will be automatically deleted after this many days. Value must be between 1 and 30 days. If no value is set, notes will be retained indefinitely."
                    type="number"
                    validate={validateDataRetention}
                    onSuccessfulSave={onSuccessfulSave}
                    convertFrom={(value) => value === null ? '' : hoursToDisplayDays(value)}
                    convertTo={(value) => value === '' ? null : displayDaysToHours(value)}
                    inputProps={{
                        min: 1,
                        max: 30,
                        className: 'w-32',
                        placeholder: 'No limit set'
                    }}
                />
            </div>

            {/* Add more settings sections here */}
        </>
    );
} 