import React from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';

export default function ExpirationWarning({ expirationInfo }) {
    if (!expirationInfo || (!expirationInfo.isExpiringSoon && !expirationInfo.isExpired)) {
        return null;
    }

    return (
        <div className={`flex items-center text-sm ${expirationInfo.isExpired ? 'text-red-600' : 'text-amber-600'}`}>
            <ClockIcon className="h-4 w-4 mr-1 flex-shrink-0" />
            <span>{expirationInfo.warningMessage}</span>
        </div>
    );
} 