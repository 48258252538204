import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
    Alert,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useTheme
} from '@mui/material';

const ZENDESK_DOMAIN = process.env.REACT_APP_ZENDESK_DOMAIN;
const ZENDESK_API_TOKEN = process.env.REACT_APP_ZENDESK_API_TOKEN;
const ZENDESK_EMAIL = process.env.REACT_APP_ZENDESK_EMAIL;

const REQUEST_TYPES = [
    { value: 'bug_report', label: 'Bug Report' },
    { value: 'feature_request', label: 'Feature Request' },
    { value: 'sales_request', label: 'Sales' },
    { value: 'billing', label: 'Billing' },
    { value: 'other', label: 'Other' }
];

export const ContactUsForm = ({ open, onClose }) => {
    const { user, isAuthenticated } = useAuth0();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        requestType: '',
        description: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);
    const theme = useTheme();

    useEffect(() => {
        if (isAuthenticated && user) {
            setFormData(prev => ({
                ...prev,
                name: user.name || '',
                email: user.email || ''
            }));
        }
    }, [isAuthenticated, user, open]);

    const resetForm = () => {
        setFormData({
            name: isAuthenticated && user ? user.name || '' : '',
            email: isAuthenticated && user ? user.email || '' : '',
            phoneNumber: '',
            requestType: '',
            description: ''
        });
        setSubmitStatus(null);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSubmitStatus(null);

        try {
            const requestTypeLabel = REQUEST_TYPES.find(type => type.value === formData.requestType)?.label || formData.requestType;
            
            const response = await fetch(`https://${ZENDESK_DOMAIN}.zendesk.com/api/v2/requests.json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(`${ZENDESK_EMAIL}/token:${ZENDESK_API_TOKEN}`)
                },
                body: JSON.stringify({
                    request: {
                        requester: {
                            name: formData.name,
                            email: formData.email,
                            phone: formData.phoneNumber
                        },
                        subject: requestTypeLabel,
                        comment: {
                            body: `Request Type: ${formData.requestType}\n${formData.phoneNumber ? `Phone: ${formData.phoneNumber}\n\n` : '\n'}${formData.description}`
                        }
                    }
                })
            });

            if (!response.ok) throw new Error('Failed to submit ticket');

            setSubmitStatus('success');
        } catch (error) {
            console.error('Error submitting ticket:', error);
            setSubmitStatus('error');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            {submitStatus === 'success' ? (
                <>
                    <DialogContent sx={{ textAlign: 'center', py: 4 }}>
                        <Typography variant="h5" sx={{ color: theme.palette.primary.main, mb: 2 }}>
                            Thank You!
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            We have received your message and will get back to you as soon as possible.
                        </Typography>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                transition: 'background-color 0.2s ease-in-out',
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.light
                                }
                            }}
                        >
                            Close
                        </Button>
                    </DialogContent>
                </>
            ) : (
                <>
                    <DialogTitle>
                        <Typography variant="h5" component="div" sx={{ color: theme.palette.primary.main }}>
                            Contact Us
                        </Typography>
                    </DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            {submitStatus === 'error' && (
                                <Alert
                                    severity="error"
                                    sx={{
                                        mb: 2,
                                        '& .MuiAlert-icon': {
                                            color: '#DC2626'
                                        },
                                        backgroundColor: '#FEE2E2',
                                        color: '#DC2626'
                                    }}
                                >
                                    There was an error sending your message. Please try again.
                                </Alert>
                            )}
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                                <TextField
                                    required
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <TextField
                                    required
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <TextField
                                    label="Phone Number (optional)"
                                    name="phoneNumber"
                                    type="tel"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <FormControl required fullWidth>
                                    <InputLabel>Request Type</InputLabel>
                                    <Select
                                        name="requestType"
                                        value={formData.requestType}
                                        onChange={handleChange}
                                        label="Request Type"
                                    >
                                        {REQUEST_TYPES.map((type) => (
                                            <MenuItem key={type.value} value={type.value}>
                                                {type.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    required
                                    label="Description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                disabled={isSubmitting}
                                sx={{
                                    color: theme.palette.primary.main,
                                    transition: 'background-color 0.2s ease-in-out',
                                    '&:hover': {
                                        backgroundColor: `${theme.palette.primary.main}0a`
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    transition: 'background-color 0.2s ease-in-out',
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.light
                                    },
                                    '&:disabled': {
                                        backgroundColor: '#C7D2FE'
                                    }
                                }}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                </>
            )}
        </Dialog>
    );
}; 