import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlay, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "@auth0/auth0-react";
import { useFeatureFlagEnabled } from 'posthog-js/react';
import '../styles/App.css';
import { useAuth } from '../contexts/AuthProvider';

export const NavigationBar = () => {
    const { isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0();
    const { actorInfo } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const isLoginScreen = location.pathname === "/";
    const isERDashboard = location.pathname === "/er-dashboard";
    const isCleoOutboundEnabled = useFeatureFlagEnabled("cleo_outbound");
    const isCarepoint = useFeatureFlagEnabled('carepoint');
    const isMacrosEnabled = useFeatureFlagEnabled("macros");

    // Check if user is on admin pages
    const isOnAdminPage = [
        '/global-admin-dashboard',
        '/organization-admin-dashboard',
        '/organization-portal'
    ].includes(location.pathname);

    // Check if user is admin
    const isAdmin = actorInfo && (
        actorInfo.admin_status === 'GLOBAL_ADMIN' ||
        actorInfo.admin_status === 'ORGANIZATION_ADMIN'
    );

    const getButtonConfig = () => {
        if (!isAdmin) return null;

        if (isOnAdminPage) {
            // On admin page, show "Note Dashboard" button
            const isHospitalist = actorInfo.actor_specialty === 'HOSPITALIST';
            return {
                text: 'Note Dashboard',
                to: isHospitalist ? '/hospitalist-inpatient-dashboard' : '/er-dashboard'
            };
        } else {
            // Not on admin page, show "Admin View" button
            return {
                text: 'Admin View',
                to: actorInfo.admin_status === 'GLOBAL_ADMIN'
                    ? '/global-admin-dashboard'
                    : '/organization-portal'
            };
        }
    };

    const buttonConfig = getButtonConfig();

    const handleAppcuesFlow = () => {
        if (window.Appcues) {
            window.Appcues.show('c08456cb-f522-4fba-ab1c-0ca40ac656a2');
        } else {
            console.error('Appcues is not available.');
        }
    };

    const handleLogin = () => {
        loginWithRedirect({
            authorizationParams: {
                prompt: 'select_account'
            }
        });
    };

    const handleLogout = () => {
        console.log('Starting logout process');

        logout({
            logoutParams: {
                returnTo: window.location.origin,
                localOnly: true  // This ensures we only logout of this application
            }
        });
    };

    const loadingMessage = isLoading ? "Securely Logging In..." : "";

    return (
        <header className="transparent">
            <nav className="flex items-center justify-between pt-6 pr-6 pl-6 pb-1" aria-label="Global">
                <Link to="/" className="-m-1.5 p-1.5">
                    <img className="h-10 w-24" src="/cleo-logo-expanded.png" alt="Cleo Logo" />
                </Link>

                <div className="flex flex-1 justify-end gap-3">
                    {isAuthenticated && (
                        <>
                            {buttonConfig && (
                                <Link
                                    to={buttonConfig.to}
                                    className="inline-flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium text-indigo-900 bg-white border-2 border-indigo-900 hover:bg-indigo-50 hover:shadow-md transition-colors"
                                >
                                    {buttonConfig.text}
                                </Link>
                            )}

                            {isERDashboard && window.Appcues && (
                                <button
                                    onClick={handleAppcuesFlow}
                                    className="inline-flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium text-indigo-900 bg-white border-2 border-indigo-900 hover:bg-indigo-50 hover:shadow-md transition-colors"
                                >
                                    <FontAwesomeIcon icon={faPlay} className="h-4 w-4 mr-2" />
                                    Pro Tips
                                </button>
                            )}

                            {isCleoOutboundEnabled && (
                                <Link
                                    to="/phone-calls"
                                className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${
                                    location.pathname === '/phone-calls'
                                        ? 'border-b-2 border-indigo-500 text-gray-900'
                                        : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'
                                }`}
                            >
                                    <FontAwesomeIcon icon={faPhone} className="mr-2" />
                                    Phone Calls
                                </Link>
                            )}

                            <a href="/settings" className="inline-flex items-center justify-center rounded-full transition-shadow hover:shadow-md p-2">
                                <FontAwesomeIcon icon={faCog} className="h-6 w-6" />
                                <span className="ml-2">Settings</span>
                            </a>

                            <button
                                onClick={handleLogout}
                                className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                            >
                                Sign out
                            </button>
                        </>
                    )}

                    {!isAuthenticated && !isLoginScreen && (
                        <button
                            onClick={handleLogin}
                            className="justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-900 text-sm font-medium text-white hover:bg-indigo-900 focus:outline-none focus:ring-offset-2 focus:ring-indigo-900"
                        >
                            Sign In
                        </button>
                    )}
                </div>
            </nav>
            {isLoading && (
                <div className="loadingOverlay">
                    <div className="spinner"></div>
                    <div className="loadingMessage">{loadingMessage}</div>
                </div>
            )}
        </header>
    );
};