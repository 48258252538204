import { protectedResources, callProtectedApi } from '../authConfig';

export const getMacros = async (getAccessTokenSilently) => {
  try {
    return await callProtectedApi(
      getAccessTokenSilently,
      protectedResources.apiGetMacros.endpoint,
      {
        method: 'POST'
      }
    );
  } catch (error) {
    console.error('getMacros error:', error);
    throw error;
  }
};

export const getGroupMacros = async (getAccessTokenSilently, groupId) => {
  try {
    return await callProtectedApi(
      getAccessTokenSilently,
      protectedResources.apiGetGroupMacros.endpoint,
      {
        method: 'POST',
        body: JSON.stringify({ group_id: groupId })
      }
    );
  } catch (error) {
    console.error('getGroupMacros error:', error);
    throw error;
  }
};

export const upsertMacro = async (getAccessTokenSilently, macroData) => {
  try {
    return await callProtectedApi(
      getAccessTokenSilently,
      protectedResources.apiUpsertMacro.endpoint,
      {
        method: 'POST',
        body: JSON.stringify(macroData)
      }
    );
  } catch (error) {
    console.error('upsertMacro error:', error);
    throw error;
  }
};

export const upsertGroupMacro = async (getAccessTokenSilently, macroData, groupId = null) => {
  try {
    // Build the URL with optional group_id query parameter
    let endpoint = protectedResources.apiUpsertGroupMacro.endpoint;
    if (groupId) {
      endpoint += `?group_id=${groupId}`;
    }

    return await callProtectedApi(
      getAccessTokenSilently,
      endpoint,
      {
        method: 'POST',
        body: JSON.stringify({
          macro_id: macroData.macro_id,
          group_id: macroData.group_id,
          title: macroData.title,
          command: macroData.command,
          content: macroData.content,
          section: macroData.section
        })
      }
    );
  } catch (error) {
    console.error('upsertGroupMacro error:', error);
    throw error;
  }
};

export const deleteMacro = async (getAccessTokenSilently, macroId) => {
  try {
    return await callProtectedApi(
      getAccessTokenSilently,
      protectedResources.apiDeleteMacro.endpoint,
      {
        method: 'POST',
        body: JSON.stringify({ macro_id: macroId })
      }
    );
  } catch (error) {
    console.error('deleteMacro error:', error);
    throw error;
  }
};

export const toggleMacroOptOut = async (getAccessTokenSilently, macroId) => {
  try {
    return await callProtectedApi(
      getAccessTokenSilently,
      `${protectedResources.apiToggleMacroOptOut.endpoint}${macroId}`,
      {
        method: 'PUT'
      }
    );
  } catch (error) {
    console.error('toggleMacroOptOut error:', error);
    throw error;
  }
};
