import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

/**
 * PatientExperienceButton component displays a circular progress button for patient experience feedback
 * 
 * @param {Object} props - Component props
 * @param {Function} props.onClick - Function to call when the button is clicked
 * @param {boolean} props.hasNullCriteria - Whether any criteria are null/undefined
 * @param {number} props.completedCriteria - Number of completed criteria
 * @param {number} props.totalCriteria - Total number of criteria
 * @param {number} props.completionPercentage - Percentage of completed criteria
 * @returns {React.ReactElement|null} The rendered component or null if disabled
 */
const PatientExperienceButton = ({
    onClick,
    hasNullCriteria,
    completedCriteria,
    totalCriteria,
    completionPercentage
}) => {

    return (
        <div className="fixed bottom-96 right-12 z-40 group">
            <div className="relative">
                {/* Label */}
                <div className="absolute -top-10 left-1/2 transform -translate-x-1/2 text-center">
                    <div className="text-xs font-medium text-gray-600 whitespace-nowrap">
                        Patient Experience
                    </div>
                    <div className="text-xs font-medium text-gray-600 whitespace-nowrap">
                        Feedback
                    </div>
                </div>

                {/* Progress Circle */}
                <div
                    className="h-20 w-20 p-1 bg-white rounded-full shadow-lg cursor-pointer hover:shadow-xl transition-shadow duration-200"
                    onClick={onClick}
                >
                    {hasNullCriteria ? (
                        <CircularProgressbar
                            value={100}
                            text="..."
                            styles={buildStyles({
                                textSize: '28px',
                                pathColor: '#6b7280',
                                textColor: '#6b7280',
                                trailColor: '#e5e7eb',
                                strokeLinecap: 'round',
                                pathTransitionDuration: 0.5,
                                strokeWidth: 12,
                            })}
                        />
                    ) : (
                        <CircularProgressbar
                            value={completionPercentage}
                            text={`${completedCriteria}/${totalCriteria}`}
                            styles={buildStyles({
                                textSize: '28px',
                                pathColor: completedCriteria <= 1 ? '#ef4444' :
                                    completedCriteria <= 3 ? '#f97316' :
                                        completedCriteria <= 5 ? '#22c55e' :
                                            '#6b7280',
                                textColor: completedCriteria <= 1 ? '#ef4444' :
                                    completedCriteria <= 3 ? '#f97316' :
                                        completedCriteria <= 5 ? '#22c55e' :
                                            '#6b7280',
                                trailColor: '#e5e7eb',
                                strokeLinecap: 'round',
                                pathTransitionDuration: 0.5,
                                strokeWidth: 12,
                            })}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PatientExperienceButton; 