import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../contexts/AuthProvider';
import { useLocation } from 'react-router-dom';
import { ArrowPathIcon, ChevronDownIcon, ChevronRightIcon, PencilIcon, CheckCircleIcon, XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { organizationPortalApi } from '../api/organizationPortalApi';
import { getGroupHierarchy } from '../api/groupApi';

const OrganizationPortalSSOProvisioning = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { actorInfo } = useAuth();
    const location = useLocation();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [csvFile, setCsvFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [filePreview, setFilePreview] = useState([]);
    const [userToDisable, setUserToDisable] = useState(null);
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [groups, setGroups] = useState([]);
    const [groupsError, setGroupsError] = useState(null);
    const [isGroupsExpanded, setIsGroupsExpanded] = useState(false);
    const [organizationName, setOrganizationName] = useState('');
    const [showGroupChangeModal, setShowGroupChangeModal] = useState(false);
    const [userToChangeGroup, setUserToChangeGroup] = useState(null);
    const [selectedNewGroup, setSelectedNewGroup] = useState('');
    const [groupChangeSuccess, setGroupChangeSuccess] = useState(null);
    const [isGroupChangeLoading, setIsGroupChangeLoading] = useState(false);
    const [changingGroupUserId, setChangingGroupUserId] = useState(null);
    const [groupChangeError, setGroupChangeError] = useState(null);

    // Get organization ID from location state if provided (for global admin viewing other orgs)
    const isGlobalAdminView = location.state?.isGlobalAdminView || false;
    const orgId = isGlobalAdminView ? location.state?.orgId : actorInfo?.org_id;

    // Check if user has access to this organization portal
    const hasAccess = actorInfo && (
        actorInfo.admin_status === 'GLOBAL_ADMIN' || actorInfo.admin_status === 'ORGANIZATION_ADMIN'
    );

    useEffect(() => {
        // Don't try to fetch if actorInfo isn't loaded yet
        if (!actorInfo) {
            setIsLoading(true);
            return;
        }

        if (!hasAccess) {
            setError('You do not have access to this portal');
            setIsLoading(false);
            console.log(actorInfo.admin_status);
            return;
        }

        fetchUsers();
    }, [hasAccess, getAccessTokenSilently, actorInfo]);

    useEffect(() => {
        const fetchGroups = async () => {
            if (!orgId) return;

            try {
                const hierarchy = await getGroupHierarchy(getAccessTokenSilently, orgId);
                setGroups(hierarchy);

                // Find the top-level group (level 1) to use as organization name
                const topLevelGroups = hierarchy.filter(group => group.level === 1);
                if (topLevelGroups.length > 0) {
                    // Use the first top-level group's name as the organization name
                    setOrganizationName(topLevelGroups[0].name);
                }
            } catch (error) {
                console.error('Error fetching groups:', error);
                setGroupsError('Failed to load groups');
            }
        };

        fetchGroups();
    }, [orgId, getAccessTokenSilently]);

    const fetchUsers = async () => {
        if (!orgId) {
            console.log("No org_id available");
            return;
        }
        setError(null);

        try {
            const response = await organizationPortalApi.getOrgUsers(
                getAccessTokenSilently,
                orgId
            );

            setUsers(response.map(user => {
                if (!user.name || !user.email) {
                    console.warn('User missing required fields:', user);
                }
                return {
                    id: user.actor_id,
                    name: user.name,
                    email: user.email,
                    group_name: user.group_name,
                    specialty: user.specialty,
                    is_admin: user.is_admin,
                    status: !user.has_signed_in && user.actor_status === 'ACTIVE'
                        ? 'PROVISIONED'
                        : user.actor_status
                };
            }));
        } catch (err) {
            setError('Failed to fetch users');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileSelect = async (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        if (!file.name.toLowerCase().endsWith('.csv')) {
            setError('Please upload a CSV file');
            return;
        }

        setSelectedFile(file);
        setUploadStatus(null);
        setError(null);

        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                const csv = event.target.result.toString();
                const lines = csv.split('\n');
                const headers = lines[0].split(',');

                // Validate required columns
                const requiredColumns = ['first_name', 'last_name', 'email', 'state_for_invoice', 'group_name', 'specialty', 'is_admin'];
                const missingColumns = requiredColumns.filter(col =>
                    !headers.map(h => h.trim().toLowerCase()).includes(col)
                );

                if (missingColumns.length > 0) {
                    setError(`Missing required columns: ${missingColumns.join(', ')}`);
                    return;
                }

                // Preview data
                const previewData = lines.slice(1, 6).map(line => {
                    const values = line.split(',');
                    return headers.reduce((obj, header, index) => {
                        obj[header.trim()] = values[index]?.trim() || '';
                        return obj;
                    }, {});
                }).filter(row => Object.values(row).some(value => value));

                setFilePreview(previewData);
                setShowPreview(true);
            } catch (error) {
                console.error("Error processing file:", error);
                setError('Error processing file');
            }
        };

        reader.readAsText(file);
    };

    const handleSubmitCsv = async () => {
        if (!selectedFile) {
            setError('Please select a file first');
            return;
        }

        if (!selectedFile.name.toLowerCase().endsWith('.csv')) {
            setError('Please upload a CSV file');
            return;
        }

        setUploadStatus('uploading');
        setError(null);
        try {
            const response = await organizationPortalApi.uploadUsersCsv(
                getAccessTokenSilently,
                selectedFile,
                orgId
            );

            setUploadStatus('success');
            setCsvFile(null);
            setFilePreview([]);
            setShowPreview(false);
            setSelectedFile(null);

            // Refresh the users list
            await fetchUsers();
        } catch (err) {
            console.error('Error uploading CSV:', err);
            setUploadStatus('error');
            setError(err.response?.data?.detail || err.message || 'Error uploading CSV');
        }
    };

    const handleDisableUser = async (user) => {
        setUserToDisable(user);
        setShowDisableModal(true);
    };

    const confirmDisable = async () => {
        try {
            setError(null);
            const updatedUser = await organizationPortalApi.updateUserStatus(
                getAccessTokenSilently,
                userToDisable.id,
                false
            );

            // Update the user in the local state
            setUsers(currentUsers =>
                currentUsers.map(u =>
                    u.id === userToDisable.id ? {
                        ...u,
                        status: 'INACTIVE'
                    } : u
                )
            );

            setShowDisableModal(false);
            setUserToDisable(null);
        } catch (error) {
            console.error('Error disabling user:', error);
            setError('Failed to disable user: ' + (error.message || 'Unknown error'));
        }
    };

    const handleEnableUser = async (user) => {
        try {
            setError(null);
            const updatedUser = await organizationPortalApi.updateUserStatus(
                getAccessTokenSilently,
                user.id,
                true
            );

            // Update the user in the local state
            setUsers(currentUsers =>
                currentUsers.map(u =>
                    u.id === user.id ? {
                        ...u,
                        status: !user.has_signed_in ? 'PROVISIONED' : 'ACTIVE'
                    } : u
                )
            );
        } catch (error) {
            console.error('Error enabling user:', error);
            setError('Failed to enable user: ' + (error.message || 'Unknown error'));
        }
    };

    const filteredUsers = users.filter(user => {
        if (!user.name || !user.email) return false;

        const searchLower = searchQuery.toLowerCase();
        return (
            user.name.toLowerCase().includes(searchLower) ||
            user.email.toLowerCase().includes(searchLower) ||
            (user.specialty && user.specialty.toLowerCase().includes(searchLower))
        );
    });

    const handleDownloadTemplate = () => {
        // Make sure headers exactly match what backend expects
        const headers = 'first_name,last_name,email,state_for_invoice,group_name,specialty,is_admin,npi\n';
        const example = 'John,Doe,john.doe@example.com,NY,Baptist,EMERGENCY_MEDICINE,N,1234567890\n';
        const blob = new Blob([headers, example], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'user_template.csv';
        a.click();
    };

    const refreshUsers = async () => {
        setIsLoading(true);
        try {
            await fetchUsers();
        } catch (error) {
            console.error('Error refreshing users:', error);
            setError('Failed to refresh users');
        } finally {
            setIsLoading(false);
        }
    };

    const processedGroups = groups.filter(group => {
        // For org_id 51, only show specific groups
        if (orgId === 51) {
            // Only include groups that are not top-level (level !== 1)
            return group.level !== 1;
        }

        // For other organizations, show all groups
        return true;
    });

    const handleGroupClick = (user) => {
        setUserToChangeGroup(user);
        setSelectedNewGroup('');
        setShowGroupChangeModal(true);
    };

    const handleChangeGroup = async () => {
        if (!selectedNewGroup) {
            return;
        }

        try {
            setIsGroupChangeLoading(true);
            setChangingGroupUserId(userToChangeGroup.id);
            setGroupChangeError(null);

            await organizationPortalApi.moveUserToGroup(
                getAccessTokenSilently,
                userToChangeGroup.id,
                parseInt(selectedNewGroup, 10)
            );

            // Update the user in the local state
            setUsers(currentUsers =>
                currentUsers.map(u =>
                    u.id === userToChangeGroup.id ? {
                        ...u,
                        group_name: processedGroups.find(g => g.group_id === parseInt(selectedNewGroup, 10))?.name || u.group_name
                    } : u
                )
            );

            setGroupChangeSuccess(`Successfully moved ${userToChangeGroup.name} to ${processedGroups.find(g => g.group_id === parseInt(selectedNewGroup, 10))?.name}`);
            setTimeout(() => setGroupChangeSuccess(null), 5000);

            setShowGroupChangeModal(false);
            setUserToChangeGroup(null);
            setSelectedNewGroup('');
        } catch (error) {
            console.error('Error changing user group:', error);
            setGroupChangeError('Failed to change user group: ' + (error.message || 'Unknown error'));
            setTimeout(() => setGroupChangeError(null), 5000);
        } finally {
            setIsGroupChangeLoading(false);
            setChangingGroupUserId(null);
        }
    };

    // Show loading state while actorInfo is being loaded
    if (!actorInfo) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
            </div>
        );
    }

    if (!hasAccess) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100">
                <div className="p-6 bg-white rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold text-red-600">Access Denied</h2>
                    <p className="mt-2 text-gray-600">You do not have permission to access this portal.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold text-gray-900">SSO Provisioning & Management</h1>
                    <p className="mt-2 text-sm text-gray-600">
                        Upload users via CSV to provision them for SSO login. When users log in via SSO with matching email addresses, their accounts will be automatically linked.
                    </p>
                </div>

                {/* User Upload Section */}
                <div className="mb-8 bg-white rounded-lg shadow">
                    <div className="px-4 sm:p-6">
                        <div className="mb-4">
                            <h2 className="text-xl font-semibold">Upload Users</h2>
                        </div>

                        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-4">
                            <h3 className="font-medium text-gray-900 mb-2">Required CSV Columns:</h3>
                            <div className="flex flex-wrap gap-4 text-sm text-gray-600">
                                <span className="inline-flex items-center">
                                    <span className="font-medium">first_name*</span>
                                    <span className="ml-1 text-gray-500">(John)</span>
                                </span>
                                <span className="inline-flex items-center">
                                    <span className="font-medium">last_name*</span>
                                    <span className="ml-1 text-gray-500">(Doe)</span>
                                </span>
                                <span className="inline-flex items-center">
                                    <span className="font-medium">email*</span>
                                    <span className="ml-1 text-gray-500">(john.doe@example.com)</span>
                                </span>
                                <span className="inline-flex items-center">
                                    <span className="font-medium">state_for_invoice*</span>
                                    <span className="ml-1 text-gray-500">(NY)</span>
                                </span>
                                <span className="inline-flex items-center">
                                    <span className="font-medium">group_name*</span>
                                    <span className="ml-1 text-gray-500">(Baptist)</span>
                                </span>
                                <span className="inline-flex items-center">
                                    <span className="font-medium">specialty*</span>
                                    <span className="ml-1 text-gray-500">(EMERGENCY_MEDICINE)</span>
                                </span>
                                <span className="inline-flex items-center">
                                    <span className="font-medium">is_admin*</span>
                                    <span className="ml-1 text-gray-500">(Y/N)</span>
                                </span>
                                <span className="inline-flex items-center">
                                    <span className="font-medium">npi</span>
                                    <span className="ml-1 text-gray-500">(1234567890)</span>
                                </span>
                            </div>
                        </div>

                        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-4">
                            <button
                                onClick={() => setIsGroupsExpanded(!isGroupsExpanded)}
                                className="w-full flex items-center justify-between text-left"
                            >
                                <div className="flex items-center">
                                    <h3 className="text-lg font-medium">Available Values</h3>
                                    <span className="ml-2 text-sm text-gray-500">
                                        (Reference for CSV upload)
                                    </span>
                                </div>
                                {isGroupsExpanded ? (
                                    <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                                ) : (
                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                                )}
                            </button>

                            {isGroupsExpanded && (
                                <div className="mt-4 space-y-4">
                                    {/* Groups Section */}
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">Available Groups:</h4>
                                        {groupsError ? (
                                            <p className="text-sm text-red-600">{groupsError}</p>
                                        ) : processedGroups.length > 0 ? (
                                            <div className="bg-white p-3 rounded border border-gray-300">
                                                <p className="text-sm text-gray-700">
                                                    {processedGroups.map(group => group.name).join(', ')}
                                                </p>
                                            </div>
                                        ) : (
                                            <p className="text-sm text-gray-500">No groups available</p>
                                        )}
                                    </div>

                                    {/* Specialty Section */}
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">Available Specialties:</h4>
                                        <div className="bg-white p-3 rounded border border-gray-300">
                                            <p className="text-sm text-gray-700">
                                                EMERGENCY_MEDICINE, HOSPITALIST_MEDICINE
                                            </p>
                                        </div>
                                    </div>

                                    {/* Is Admin Section */}
                                    <div>
                                        <h4 className="font-medium text-gray-900 mb-2">
                                            Admin Status Values:
                                            <span className="ml-2 font-normal text-xsm text-gray-500">
                                                (Admins will have access to the organization portal)
                                            </span>
                                        </h4>
                                        <div className="bg-white p-3 rounded border border-gray-300">
                                            <p className="text-sm text-gray-700">
                                                Y, N
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="flex flex-col space-y-4">
                            {!showPreview ? (
                                <div className="flex justify-between">
                                    <button
                                        onClick={handleDownloadTemplate}
                                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Download Template
                                    </button>

                                    <label className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer">
                                        Select CSV File
                                        <input
                                            type="file"
                                            accept=".csv"
                                            onChange={handleFileSelect}
                                            className="hidden"
                                        />
                                    </label>
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    <div className="flex items-center justify-between">
                                        <span className="text-sm font-medium text-gray-700">
                                            Selected file: {selectedFile?.name}
                                        </span>
                                        <button
                                            onClick={() => {
                                                setSelectedFile(null);
                                                setFilePreview([]);
                                                setShowPreview(false);
                                            }}
                                            className="text-sm text-red-600 hover:text-red-800"
                                        >
                                            Remove
                                        </button>
                                    </div>

                                    <div className="border rounded-lg">
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        {Object.keys(filePreview[0] || {}).map((header) => (
                                                            <th
                                                                key={header}
                                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                            >
                                                                {header}
                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {filePreview.map((row, idx) => (
                                                        <tr key={idx}>
                                                            {Object.values(row).map((value, valueIdx) => (
                                                                <td
                                                                    key={valueIdx}
                                                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                                >
                                                                    {value}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="flex justify-end space-x-4">
                                        <button
                                            onClick={() => {
                                                setSelectedFile(null);
                                                setFilePreview([]);
                                                setShowPreview(false);
                                            }}
                                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={handleSubmitCsv}
                                            disabled={isLoading}
                                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                                        >
                                            {isLoading ? 'Processing...' : 'Upload Users'}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        {uploadStatus && (
                            <p className={`mt-2 text-sm ${uploadStatus === 'success' ? 'text-green-600' :
                                uploadStatus === 'error' ? 'text-red-600' :
                                    'text-gray-600'
                                }`}>
                                {uploadStatus === 'uploading' ? 'Uploading...' :
                                    uploadStatus === 'success' ? 'Upload successful!' :
                                        error || 'Upload failed. Please try again.'}
                            </p>
                        )}
                    </div>
                </div>

                {/* Users Table */}
                <div className="bg-white rounded-lg shadow">
                    <div className="px-4 sm:p-6">
                        <div className="flex justify-between items-center mb-4">
                            <div className="flex items-center space-x-4">
                                <h2 className="text-xl font-semibold">Users</h2>
                                <div className="flex items-center space-x-4">
                                    <span className="inline-flex items-center space-x-1">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">PROVISIONED</span>
                                        <span className="text-gray-400">=</span>
                                        <span className="ml-1 text-sm text-gray-500">Not signed in</span>
                                    </span>
                                    <span className="inline-flex items-center space-x-1">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">ACTIVE</span>
                                        <span className="text-gray-400">=</span>
                                        <span className="ml-1 text-sm text-gray-500">Signed in</span>
                                    </span>
                                    <span className="inline-flex items-center space-x-1">
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">INACTIVE</span>
                                        <span className="text-gray-400">=</span>
                                        <span className="ml-1 text-sm text-gray-500">Disabled</span>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4">
                                <button
                                    onClick={refreshUsers}
                                    className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <ArrowPathIcon
                                        className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`}
                                    />
                                </button>
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder="Search by name or email..."
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="w-64 px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                    <svg
                                        className="absolute right-3 top-2.5 h-5 w-5 text-gray-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="flex justify-center items-center h-32">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
                            </div>
                        ) : groupsError ? (
                            <div className="text-red-600">{groupsError}</div>
                        ) : (
                            <div className="overflow-x-auto">
                                <div className="max-h-[600px] overflow-y-auto">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50 sticky top-0">
                                            <tr>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    <div className="flex items-center">
                                                        <span>Group</span>
                                                        <span className="ml-1 text-xs font-normal text-gray-400 lowercase">(click to change)</span>
                                                    </div>
                                                </th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Specialty</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Admin</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-24">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {filteredUsers.map((user) => (
                                                <tr key={user.id}>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 cursor-pointer hover:text-indigo-600 hover:underline group" onClick={() => handleGroupClick(user)}>
                                                        <div className="flex items-center">
                                                            {changingGroupUserId === user.id ? (
                                                                <div className="flex items-center">
                                                                    <div className="animate-spin h-4 w-4 mr-2 border-b-2 border-indigo-600 rounded-full"></div>
                                                                    <span className="text-indigo-600">Updating...</span>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <span>{user.group_name}</span>
                                                                    <PencilIcon className="h-4 w-4 ml-2 text-gray-400 group-hover:text-indigo-600 opacity-0 group-hover:opacity-100 transition-opacity" />
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {user.specialty ? user.specialty.split('_').map(word =>
                                                            word.charAt(0) + word.slice(1).toLowerCase()
                                                        ).join(' ') : ''}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {user.is_admin ? (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                                                Yes
                                                            </span>
                                                        ) : (
                                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                                                No
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.status === 'ACTIVE' ? 'bg-green-100 text-green-800' :
                                                            user.status === 'INACTIVE' ? 'bg-red-100 text-red-800' :
                                                                'bg-yellow-100 text-yellow-800'
                                                            }`}>
                                                            {user.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-24">
                                                        {user.status === 'INACTIVE' ? (
                                                            <button
                                                                onClick={() => handleEnableUser(user)}
                                                                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                            >
                                                                Enable
                                                            </button>
                                                        ) : (
                                                            <button
                                                                onClick={() => handleDisableUser(user)}
                                                                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                            >
                                                                Disable
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Dialog
                open={showDisableModal}
                onClose={() => setShowDisableModal(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-6">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <Dialog.Title className="text-lg font-medium text-gray-900">
                                    Disable User Access
                                </Dialog.Title>
                            </div>
                        </div>

                        <div className="mt-4">
                            <p className="text-sm text-gray-500">
                                Are you sure you want to disable {userToDisable?.name}'s access to Cleo?
                                They will no longer be able to log in.
                            </p>
                        </div>

                        <div className="mt-6 flex justify-end space-x-3">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setShowDisableModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                onClick={confirmDisable}
                            >
                                Disable Access
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>

            {/* Group Change Modal */}
            <Dialog
                open={showGroupChangeModal}
                onClose={() => setShowGroupChangeModal(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-6">
                        <div className="flex items-center">
                            <div className="ml-3">
                                <Dialog.Title className="text-lg font-medium text-gray-900">
                                    Change User Group
                                </Dialog.Title>
                            </div>
                        </div>

                        <div className="mt-4">
                            <p className="text-sm text-gray-500 mb-4">
                                Select a new group for {userToChangeGroup?.name}:
                            </p>

                            <div className="mt-2">
                                <label htmlFor="group-select" className="block text-sm font-medium text-gray-700">
                                    Group
                                </label>
                                <select
                                    id="group-select"
                                    value={selectedNewGroup}
                                    onChange={(e) => setSelectedNewGroup(e.target.value)}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                >
                                    <option value="">Select a group</option>
                                    {processedGroups.map((group) => (
                                        <option key={group.group_id} value={group.group_id}>
                                            {group.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="mt-6 flex justify-end space-x-3">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setShowGroupChangeModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                disabled={!selectedNewGroup || isGroupChangeLoading}
                                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                                onClick={handleChangeGroup}
                            >
                                {isGroupChangeLoading ? 'Processing...' : 'Change Group'}
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>

            {/* Success Message */}
            {groupChangeSuccess && (
                <div className="fixed bottom-4 left-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded shadow-md max-w-md flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-600 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="block sm:inline flex-grow">{groupChangeSuccess}</span>
                    <button
                        onClick={() => setGroupChangeSuccess(null)}
                        className="ml-2 text-green-700 hover:text-green-900"
                    >
                        <XMarkIcon className="h-5 w-5" />
                    </button>
                </div>
            )}

            {/* Error Message */}
            {groupChangeError && (
                <div className="fixed bottom-4 left-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded shadow-md max-w-md flex items-start">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-600 mr-2 flex-shrink-0 mt-0.5" />
                    <span className="block sm:inline flex-grow">{groupChangeError}</span>
                    <button
                        onClick={() => setGroupChangeError(null)}
                        className="ml-2 text-red-700 hover:text-red-900"
                    >
                        <XMarkIcon className="h-5 w-5" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default OrganizationPortalSSOProvisioning; 