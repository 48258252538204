import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { adminApi } from '../api/adminApi';

const MergeAccountsModal = ({ isOpen, onClose, setNotification }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [baseAccount, setBaseAccount] = useState('');
    const [extraAccount, setExtraAccount] = useState('');
    const [mergeFields, setMergeFields] = useState({
        email: false,
        auth0_id: false,
        full_name: false,
        first_name: false,
        last_name: false
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [previewData, setPreviewData] = useState(null);

    const handlePreviewMerge = async () => {
        try {
            setLoading(true);
            setError(null);

            const data = await adminApi.previewMerge(
                getAccessTokenSilently,
                baseAccount,
                extraAccount
            );

            setPreviewData(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleMerge = async () => {
        try {
            setLoading(true);
            setError(null);

            await adminApi.mergeAccounts(
                getAccessTokenSilently,
                {
                    base_email: baseAccount,
                    extra_email: extraAccount,
                    merge_fields: mergeFields,
                }
            );

            setNotification({
                type: 'success',
                message: 'Accounts merged successfully',
            });
            onClose();
        } catch (err) {
            setError(err.message);
            setNotification({
                type: 'error',
                message: `Failed to merge accounts: ${err.message}`,
            });
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                <h2 className="text-2xl font-bold mb-4">Merge User Accounts</h2>

                <div className="space-y-4 mb-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Base Account Email</label>
                        <input
                            type="email"
                            value={baseAccount}
                            onChange={(e) => setBaseAccount(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="Enter base account email"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Extra Account Email</label>
                        <input
                            type="email"
                            value={extraAccount}
                            onChange={(e) => setExtraAccount(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="Enter extra account email"
                        />
                    </div>

                    {previewData && (
                        <div className="mt-4">
                            <div className="mb-4">
                                <h3 className="text-lg font-medium mb-2">Merging Actors</h3>
                                <div className="overflow-x-auto">
                                    <table className="min-w-full border-collapse border border-gray-300 text-sm">
                                        <thead>
                                            <tr className="bg-gray-50">
                                                <th className="border border-gray-300 px-4 py-2">Field</th>
                                                <th className="border border-gray-300 px-4 py-2">
                                                    Base Account (ID: {previewData.base_actor_id})
                                                </th>
                                                <th className="border border-gray-300 px-4 py-2">
                                                    Extra Account (ID: {previewData.extra_actor_id})
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(previewData.base_account).map(([key, value]) => (
                                                <tr key={key} className="hover:bg-gray-50">
                                                    <td className="border border-gray-300 px-4 py-2 font-medium">
                                                        {key}
                                                    </td>
                                                    <td className="border border-gray-300 px-4 py-2">
                                                        {value?.toString() || 'null'}
                                                    </td>
                                                    <td className="border border-gray-300 px-4 py-2">
                                                        {previewData.extra_account[key]?.toString() || 'null'}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="mt-6">
                                <h3 className="text-lg font-medium mb-2">Fields to Merge</h3>
                                <div className="space-y-2">
                                    {Object.keys(mergeFields).map((field) => (
                                        <div key={field} className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id={field}
                                                checked={mergeFields[field]}
                                                onChange={(e) => setMergeFields(prev => ({
                                                    ...prev,
                                                    [field]: e.target.checked
                                                }))}
                                                className="mr-2"
                                            />
                                            <label htmlFor={field} className="text-sm">
                                                {field.replace(/_/g, ' ').toUpperCase()}: {previewData.extra_account[field]}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="mt-4">
                                <h4 className="font-medium">Documents to Merge: {previewData.document_count}</h4>
                                <p className="text-sm text-gray-600 mt-2">
                                    All documents from the extra account will be transferred to the base account.
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                {error && (
                    <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
                        {error}
                    </div>
                )}

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                        Cancel
                    </button>

                    <button
                        onClick={handlePreviewMerge}
                        disabled={!baseAccount || !extraAccount || loading}
                        className="px-4 py-2 bg-blue-600 text-white rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 disabled:opacity-50"
                    >
                        Preview Merge
                    </button>

                    {previewData && (
                        <button
                            onClick={handleMerge}
                            disabled={loading}
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm text-sm font-medium hover:bg-indigo-700 disabled:opacity-50"
                        >
                            {loading ? 'Merging...' : 'Confirm Merge'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MergeAccountsModal; 