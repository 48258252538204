import { protectedResources, callProtectedApi } from '../authConfig';

export const supplementalDocumentsApi = {
    getSupplementalDocuments: async (getAccessTokenSilently, documentId) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiGetSupplementalDocuments.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({ document_id: documentId })
            }
        );
    },

    generateSupplementalDocument: async (getAccessTokenSilently, documentId, templateId) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiGenerateSupplementalDocument.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({
                    document_id: documentId,
                    template_id: templateId,
                    timezone: timezone
                })
            }
        );
    },

    updateSupplementalDocument: async (getAccessTokenSilently, supplementalDocumentId, content) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiUpdateSupplementalDocument.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({
                    supplemental_document_id: supplementalDocumentId,
                    content: content
                })
            }
        );
    },

    deleteSupplementalDocument: async (getAccessTokenSilently, supplementalDocumentId) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiDeleteSupplementalDocument.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({ supplemental_document_id: supplementalDocumentId })
            }
        );
    }
}; 