import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck, faRotate, faXmark, faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { v4 as uuidv4 } from 'uuid';
import { callProtectedApi, protectedResources } from '../../authConfig';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * CPTCodesButton component for displaying and managing CPT codes
 * @param {Object} props - Component props
 * @param {boolean} props.isCPTCodesVisible - Whether CPT codes panel is visible
 * @param {Function} props.setIsCPTCodesVisible - Function to update CPT codes panel visibility
 * @param {Array} props.parsedCPTProceduralCodes - Array of procedural CPT codes
 * @param {Function} props.setParsedCPTProceduralCodes - Function to update procedural CPT codes
 * @param {Array} props.parsedCPTEMCodes - Array of E/M CPT codes
 * @param {Function} props.setParsedCPTEMCodes - Function to update E/M CPT codes
 * @param {Object} props.documentID   - The document ID of the current chart
 * @param {Function} props.updateDocument - Function to update the document
 */
const CPTCodesButton = ({
    isCPTCodesVisible,
    setIsCPTCodesVisible,
    parsedCPTProceduralCodes,
    setParsedCPTProceduralCodes,
    parsedCPTEMCodes,
    setParsedCPTEMCodes,
    documentID,
    updateDocument
}) => {
    /**
     * Generate CPT codes from the document
     */
    const { getAccessTokenSilently } = useAuth0();
    const [isCPTFeedbackVisible, setIsCPTFeedbackVisible] = useState(true);
    const [CPTFeedback, setCPTFeedback] = useState('');
    const [isCPTFeedbackSubmitted, setIsCPTFeedbackSubmitted] = useState(false);
    const [isGeneratingCPTCodes, setIsGeneratingCPTCodes] = useState(false);

    const generateCPTCodes = async () => {
        setIsGeneratingCPTCodes(true);
        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiGenerateCPTCodes.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({ "document_id": documentID })
                }
            );

            if (data.cpt_codes) {
                setParsedCPTProceduralCodes(
                    data.cpt_codes.procedural_codes.map(code => ({
                        id: uuidv4(),
                        text: `${code.code}: ${code.description}`
                    }))
                );

                setParsedCPTEMCodes(
                    data.cpt_codes.em_codes.map(code => ({
                        id: uuidv4(),
                        text: `${code.code}: ${code.description}`
                    }))
                );

                setIsCPTCodesVisible(true);
                setIsCPTFeedbackVisible(true);
                setIsCPTFeedbackSubmitted(false);
                setCPTFeedback('');
            } else {
                console.error('CPT codes not found or invalid:', data.cpt_codes);
            }
        } catch (error) {
            console.error('Error generating CPT codes:', error);
        }
        setIsGeneratingCPTCodes(false);
    };

    /**
     * Remove a procedural CPT code
     * @param {string} codeId - ID of the code to remove
     */
    const removeCPTProceduralCode = (codeId) => {
        setParsedCPTProceduralCodes(prev => prev.filter(code => code.id !== codeId));
    };

    /**
     * Remove an E/M CPT code
     * @param {string} codeId - ID of the code to remove
     */
    const removeCPTEMCode = (codeId) => {
        setParsedCPTEMCodes(prev => prev.filter(code => code.id !== codeId));
    };

    /**
     * Submit CPT feedback
     */

    const submitCPTFeedback = async () => {
        try {

            setIsCPTFeedbackSubmitted(true);
            // Update document with note feedback  
            await updateDocument(documentID, 'note_feedback', CPTFeedback);

            setTimeout(() => {
                setIsCPTFeedbackVisible(false);
            }, 2000);

        } catch (error) {
            console.error('Error submitting CPT feedback:', error);
            // Optionally handle error state  
        }
    };

    /**
     * Handle modal visibility
     */
    const handleModalVisibility = () => {
        if (parsedCPTProceduralCodes.length > 0 || parsedCPTEMCodes.length > 0) {
            // If we already have codes, just show the modal
            setIsCPTCodesVisible(true);
        } else {
            // If no codes exist, generate them
            generateCPTCodes();
        }
    };

    return (
        <div className="fixed top-96 right-4 z-[60]">
            {isCPTCodesVisible ? (
                <div className="absolute top-0 right-0 z-60 w-full md:w-[600px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white">
                    <div className="flex justify-between items-center bg-gradient-to-r from-indigo-700 to-indigo-900 text-white py-1 px-4">
                        <div className="font-bold text-xl flex items-center">
                            <FontAwesomeIcon icon={faListCheck} className="mr-2" />
                            CPT Codes
                            {!isGeneratingCPTCodes && (
                                <button
                                    onClick={generateCPTCodes}
                                    className="ml-4 text-sm px-2 py-1 bg-white/20 hover:bg-white/30 rounded-md flex items-center transition-all duration-200 text-white"
                                    title="Regenerate CPT Codes"
                                >
                                    <FontAwesomeIcon
                                        icon={faRotate}
                                        className="mr-1 text-white"
                                    />
                                    <span className="text-white">Regenerate</span>
                                </button>
                            )}
                        </div>
                        <button
                            onClick={() => setIsCPTCodesVisible(false)}
                            className="p-1 rounded-full hover:bg-indigo-700 transition-colors duration-200"
                        >
                            <FontAwesomeIcon icon={faXmark} className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="flex-grow overflow-y-auto p-2">
                        {isGeneratingCPTCodes ? (
                            <div className="flex justify-center items-center h-full">
                                <div className="text-center">
                                    <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-900 h-8 w-8 mb-2" />
                                    <p className="text-gray-600">Analyzing documentation...</p>
                                </div>
                            </div>
                        ) : (
                            <div className="space-y-2">
                                {/* E/M Codes Section - Always render */}
                                <h3 className="font-bold text-lg text-gray-800 flex justify-between items-center">
                                    E/M Codes:
                                </h3>
                                {parsedCPTEMCodes.length > 0 ? (
                                    parsedCPTEMCodes.map(code => (
                                        <div
                                            key={code.id}
                                            className="flex items-center bg-gray-50 rounded-lg p-2 shadow-sm hover:shadow-md transition-shadow duration-200"
                                        >
                                            <div className="flex-grow">
                                                <div className="flex items-center space-x-2">
                                                    <span className="bg-indigo-100 text-indigo-800 px-2 py-1 rounded-md text-sm font-medium">
                                                        {code.text.split(':')[0]}
                                                    </span>
                                                    <span className="text-gray-700">
                                                        {code.text.split(':')[1]}
                                                    </span>
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => removeCPTEMCode(code.id)}
                                                className="ml-2 text-gray-400 hover:text-red-500 transition-colors duration-200"
                                                title="Remove code"
                                            >
                                                <XMarkIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-center text-gray-500 py-4">
                                        Generate MDM to extract E/M codes.
                                    </div>
                                )}

                                {/* Divider for CPT Codes */}
                                <div className="border-t border-gray-300"></div>

                                {/* CPT Codes Section */}
                                <h3 className="font-bold text-lg text-gray-800 flex justify-between items-center">
                                    Procedural Codes:
                                </h3>
                                {parsedCPTProceduralCodes.length > 0 ? (
                                    parsedCPTProceduralCodes.map(code => (
                                        <div
                                            key={code.id}
                                            className="flex items-center bg-gray-50 rounded-lg p-2 shadow-sm hover:shadow-md transition-shadow duration-200"
                                        >
                                            <div className="flex-grow">
                                                <div className="flex items-center space-x-2">
                                                    <span className="bg-indigo-100 text-indigo-800 px-2 py-1 rounded-md text-sm font-medium">
                                                        {code.text.split(':')[0]}
                                                    </span>
                                                    <span className="text-gray-700">
                                                        {code.text.split(':')[1]}
                                                    </span>
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => removeCPTProceduralCode(code.id)}
                                                className="ml-2 text-gray-400 hover:text-red-500 transition-colors duration-200"
                                                title="Remove code"
                                            >
                                                <XMarkIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-center text-gray-500 py-4">
                                        No procedural codes generated.
                                    </div>
                                )}

                                {/* Feedback Section */}
                                {isCPTFeedbackVisible && (
                                    <div className="mt-6 border-t border-gray-300 pt-4">
                                        <h3 className="font-bold text-lg text-gray-800 mb-2">
                                            Feedback
                                        </h3>
                                        {isCPTFeedbackSubmitted ? (
                                            <div className="flex items-center justify-center space-x-2 py-4 bg-green-50 rounded-lg">
                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                    className="h-5 w-5 text-green-500"
                                                />
                                                <span className="text-green-700 font-medium">
                                                    Thank you for your feedback!
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="space-y-3">
                                                <textarea
                                                    className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                    value={CPTFeedback}
                                                    onChange={(e) => setCPTFeedback(e.target.value)}
                                                    rows={3}
                                                    placeholder="How can we improve our CPT code suggestions?"
                                                />
                                                <div className="flex justify-end space-x-2">
                                                    <button
                                                        onClick={submitCPTFeedback}
                                                        className="bg-indigo-900 text-white py-1 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-200 focus:outline-none"
                                                    >
                                                        Submit Feedback
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <button
                    onClick={handleModalVisibility}
                    className="group flex flex-col items-center justify-center h-16 w-16 bg-indigo-900 rounded-full cursor-pointer shadow-lg hover:shadow-xl hover:bg-indigo-800 transition-all duration-200"
                    title="View CPT Codes"
                    disabled={isGeneratingCPTCodes}
                >
                    {isGeneratingCPTCodes ? (
                        <FontAwesomeIcon icon={faSpinner} spin className="h-6 w-6 text-white" />
                    ) : (
                        <>
                            <FontAwesomeIcon icon={faListCheck} className="h-6 w-6 text-white group-hover:scale-110 transition-transform duration-200" />
                            <span className="text-white text-[8px] mt-1">CPT Codes</span>
                        </>
                    )}
                </button>
            )}
        </div>
    );
};

export default CPTCodesButton; 