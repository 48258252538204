'use client'

import { useState, useCallback, React, useEffect, useRef } from 'react'
import { Link, Route, Routes, useLocation, Navigate, useNavigate, NavLink } from 'react-router-dom'

import { Dialog, DialogPanel, Field, Label, Switch } from '@headlessui/react'
import { Bars3Icon, CheckCircleIcon, ArrowLeftIcon, TrashIcon, CheckIcon, ChartBarIcon, CogIcon, PlusIcon, XMarkIcon, ShareIcon, ChevronDownIcon, ChevronUpIcon, GlobeAltIcon, HeartIcon, MagnifyingGlassIcon, ChatBubbleLeftIcon, HandThumbUpIcon } from '@heroicons/react/20/solid'
import { useAuth } from '../contexts/AuthProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources, callProtectedApi } from '../authConfig';
import {
    CreditCardIcon,
    CubeIcon,
    UserCircleIcon,
    UsersIcon,
    PencilSquareIcon,
    BuildingOfficeIcon,
    CommandLineIcon,
    UserGroupIcon, UserIcon, ExclamationTriangleIcon, DocumentTextIcon, ArrowPathRoundedSquareIcon, ClipboardIcon, PencilIcon
} from '@heroicons/react/24/outline'
import { useSettings } from '../contexts/SettingsProvider';

import DifferentialDiagnosisIcon from '../components/DdxIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../components/catalyst/button';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

import DdxSettings from '../components/DdxSettings';
import GroupMacrosPage from './macros/GroupMacros';
import MacroSettings from './macros/MacroSettings';
import GroupPatientDashboard from './GroupPatientDashboard';

import MdmSettings from '../components/MdmSettings';
// import RcmAnalyst from '../components/RcmAnalyst';
import CriticalCareSettings from '../components/CriticalCareSettings';
import { useFeatureFlagEnabled } from 'posthog-js/react'
import SupplementalDocumentTemplatesSettings from '../components/SettingsPage/SupplementalDocumentTemplatesSettings';
import QualityDashboard from '../components/QualityDashboard';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import ReplacementsSettings from '../components/ReplacementsSettings';
import GeneralSettings from '../components/GeneralSettings';
import CustomInstructions from '../components/SettingsPage/CustomInstructions';
import NoteTemplatesSettings from '../components/SettingsPage/NoteTemplatesSettings';



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function SuccessMessage({ message, onDismiss }) {
    return (
        <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400" />
                </div>
                <div className="ml-3">
                    {/* <p className="text-sm font-medium text-green-800">{message}</p> */}
                    <p className="text-sm font-medium text-green-800">Succcessfully saved!</p>
                </div>
                <div className="ml-auto pl-3">
                    <div className="-mx-1.5 -my-1.5">
                        <button
                            type="button"
                            onClick={onDismiss}
                            className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                        >
                            <span className="sr-only">Dismiss</span>
                            <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function Settings() {
    const [successMessage, setSuccessMessage] = useState(null)
    const { getSetting } = useSettings();
    const location = useLocation()
    const navigate = useNavigate();
    const { actorInfo, isLoading } = useAuth();
    const isCustomDdxEnabled = useFeatureFlagEnabled('custom_ddx');
    const isQualityEnabled = useFeatureFlagEnabled('quality_dashboard');
    const isReplacementsEnabled = useFeatureFlagEnabled('replacements');
    const isExtraSettingsEnabled = useFeatureFlagEnabled('show_extra_settings');
    const isTemplateCleoEnabled = useFeatureFlagEnabled('template_cleo');
    const isGroupPatientManagementEnabled = useFeatureFlagEnabled('group_patient_management');
    const isHideSupplementalDocumentCreationEnabled = getSetting("hide_supplemental_document_creation");


    const [navigationItems, setNavigationItems] = useState([]);

    useEffect(() => {
        const setupNavigation = async () => {
            if (!isLoading && actorInfo) {
                const baseNavigation = [
                    { name: 'Profile', href: '/settings/profile', icon: UserCircleIcon, component: ProfileSettings },
                ];

                if (isExtraSettingsEnabled) {
                    baseNavigation.push({ name: 'Settings', href: '/settings/general', icon: CogIcon, component: GeneralSettings });
                }

                baseNavigation.push({ name: 'Macros', href: '/settings/macros', icon: CubeIcon, component: MacroSettings });

                baseNavigation.push({
                    name: 'Custom Instructions',
                    href: '/settings/custom-instructions',
                    icon: PencilSquareIcon,
                    component: CustomInstructions
                });

                if (actorInfo.admin_status === 'GLOBAL_ADMIN' || actorInfo.admin_status === 'GROUP_ADMIN') {
                    baseNavigation.push({ name: 'Group Macros', href: '/settings/group-macros', icon: UserGroupIcon, component: GroupMacrosPage });
                }

                // Add Group Patient Management option for group admins
                if (isGroupPatientManagementEnabled && actorInfo.admin_status === 'GROUP_ADMIN') {
                    baseNavigation.push({
                        name: 'Group Patient Management',
                        href: '/settings/group-patient-management',
                        icon: UsersIcon,
                        component: GroupPatientDashboard
                    });
                }

                if (isReplacementsEnabled) {
                    baseNavigation.push({
                        name: 'Replacements',
                        href: '/settings/replacements',
                        icon: ArrowPathRoundedSquareIcon,
                        component: ReplacementsSettings
                    });
                }

                if (isHideSupplementalDocumentCreationEnabled !== null && !isHideSupplementalDocumentCreationEnabled) {
                    baseNavigation.push({
                        name: 'Supplemental Document Templates',
                        href: '/settings/supplemental-document-templates',
                        icon: DocumentTextIcon,
                        component: SupplementalDocumentTemplatesSettings
                    });
                }

                if (isTemplateCleoEnabled) {
                    baseNavigation.push({
                        name: 'Note Templates',
                        href: '/settings/note-templates',
                        icon: ClipboardIcon,
                        component: NoteTemplatesSettings
                    });
                }

                if (isQualityEnabled) {
                    baseNavigation.push({
                        name: 'Quality & Risk',
                        href: '/settings/quality',
                        icon: ChartBarIcon,
                        component: QualityDashboard
                    });
                }

                setNavigationItems(baseNavigation);
            }
        };

        setupNavigation();
    }, [isLoading, actorInfo, isQualityEnabled, isTemplateCleoEnabled, isHideSupplementalDocumentCreationEnabled]);

    const handleSuccessfulSave = (message) => {
        setSuccessMessage(message)
        setTimeout(() => setSuccessMessage(null), 5000)
    }

    const handleBackToDashboard = () => {
        if (isLoading) {
            return; // Don't navigate if still loading
        }

        if (actorInfo?.actor_specialty === 'EMERGENCY_PHYSICIAN') {
            navigate('/er-dashboard');
        } else if (actorInfo?.actor_specialty === 'HOSPITALIST') {
            navigate('/hospitalist-inpatient-dashboard');
        } else {
            // Default fallback, or handle unknown roles
            navigate('/');
        }
    };

    return (
        <div className="flex min-h-screen">
            {successMessage && (
                <div className="fixed inset-x-0 top-4 flex justify-center z-50">
                    <div className="max-w-sm w-full">
                        <SuccessMessage
                            message={successMessage}
                            onDismiss={() => setSuccessMessage(null)}
                        />
                    </div>
                </div>
            )}

            <aside className="w-64 flex-shrink-0 bg-white border-r border-gray-200">
                <nav className="h-full overflow-y-auto py-20 px-4">
                    <ul role="list" className="space-y-1">
                        <li>
                            <button
                                onClick={handleBackToDashboard}
                                className="group flex w-full items-center gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-800"
                            >
                                <ArrowLeftIcon
                                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-800"
                                    aria-hidden="true"
                                />
                                Back to Dashboard
                            </button>
                        </li>
                        {navigationItems.map((item) => (
                            <li key={item.name}>
                                <NavLink
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(
                                        location.pathname === item.href
                                            ? 'bg-gray-50 text-indigo-800'
                                            : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-800',
                                        'group flex w-full gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6',
                                    )}
                                >
                                    <item.icon
                                        aria-hidden="true"
                                        className={classNames(
                                            location.pathname === item.href ? 'text-indigo-800' : 'text-gray-400 group-hover:text-indigo-800',
                                            'h-6 w-6 shrink-0',
                                        )}
                                    />
                                    {item.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>
            </aside>

            <main className="flex-grow flex justify-center">
                <div className="w-full max-w-6xl px-4 py-16 sm:px-6 lg:px-8">
                    <Routes>
                        {navigationItems.map((item) => (
                            <Route
                                key={item.name}
                                path={item.href.replace('/settings/', '')}
                                element={<item.component onSuccessfulSave={handleSuccessfulSave} />}
                            />
                        ))}
                        <Route
                            path="/general"
                            element={
                                isExtraSettingsEnabled ? (
                                    <GeneralSettings onSuccessfulSave={handleSuccessfulSave} />
                                ) : (
                                    <Navigate to="/settings/profile" replace />
                                )
                            }
                        />
                        {isGroupPatientManagementEnabled && (
                            <Route
                                path="/group-patient-management"
                                element={
                                    actorInfo?.admin_status === 'GROUP_ADMIN' ? (
                                        <GroupPatientDashboard />
                                    ) : (
                                        <Navigate to="/settings/profile" replace />
                                    )
                                }
                            />
                        )}
                        <Route path="/" element={<Navigate to="/settings/profile" replace />} />
                    </Routes>
                </div>
            </main>
        </div>
    );
}


function ProfileSettings() {
    const { actorInfo, isLoading } = useAuth();

    const [data, setData] = useState([]);
    const [groupBy, setGroupBy] = useState('day'); // 'day' or 'month'
    const [isLoadingPatientLog, setIsLoadingPatientLog] = useState(true);

    const { getAccessTokenSilently } = useAuth0();

    const formatSpecialty = (specialty) => {
        if (!specialty) return 'N/A';
        return specialty
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const fetchPatientLog = async () => {
        if (!protectedResources.apiFetchPatientLog) {
            throw new Error('Authentication instance or API configuration is missing.');
        }

        try {
            // Acquire access token silently
            const token = await getAccessTokenSilently();

            // Define the API endpoint
            const endpoint = protectedResources.apiFetchPatientLog.endpoint;

            // Detect the user's time zone
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // Make the POST request
            const fetchResponse = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ time_zone: userTimeZone }),
            });

            if (!fetchResponse.ok) {
                const errorData = await fetchResponse.json();
                throw new Error(errorData.detail || 'Failed to fetch patient log data.');
            }

            const data = await fetchResponse.json();
            return data;

        } catch (error) {
            console.error('Error fetching patient log data:', error);
            throw error;
        }
    };

    function generateDateRange(startDate, endDate) {
        const dateArray = [];
        let currentDate = new Date(startDate);
        const theEndDate = new Date(endDate);
        while (currentDate <= theEndDate) {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dateArray;
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingPatientLog(true);
            try {
                const response = await fetchPatientLog();

                // Check if the response structure matches expectations
                // Assuming response is an array of {date, document_count}
                if (!Array.isArray(response.patient_log)) {
                    throw new Error('Invalid response format: Expected an array.');
                }

                // Map 'document_count' to 'patientCount' for consistency
                let patientLogData = response.patient_log.map(item => ({
                    date: item.date,
                    patientCount: item.document_count,
                }));

                // Determine the date range
                const dates = patientLogData.map(item => new Date(item.date));
                const startDate = dates.length > 0 ? new Date(Math.min(...dates)) : new Date();
                const endDate = dates.length > 0 ? new Date(Math.max(...dates)) : new Date();

                // Generate a complete date range
                const completeDateRange = generateDateRange(startDate, endDate);

                // Create a map for existing data for quick lookup
                const dataMap = {};
                patientLogData.forEach(item => {
                    dataMap[item.date] = item.patientCount;
                });

                // Fill in missing dates with patientCount = 0
                const completeData = completeDateRange.map(dateObj => {
                    const dateStr = dateObj.toISOString().slice(0, 10); // "YYYY-MM-DD"
                    return {
                        date: dateStr,
                        patientCount: dataMap[dateStr] || 0,
                    };
                });

                if (groupBy === 'month') {
                    patientLogData = aggregateDataByMonth(completeData);
                } else {
                    patientLogData = completeData;
                }

                setData(patientLogData);
            } catch (err) {
                console.error('Error fetching patient log data:', err);
            }
            setIsLoadingPatientLog(false);
        };

        fetchData();
    }, [groupBy]);

    const aggregateDataByMonth = (data) => {
        const monthlyData = {};

        data.forEach(item => {
            const date = new Date(item.date);
            const month = date.toISOString().slice(0, 7); // "YYYY-MM"
            if (!monthlyData[month]) {
                monthlyData[month] = 0;
            }
            monthlyData[month] += item.patientCount;
        });

        return Object.keys(monthlyData).map(month => ({
            date: month,
            patientCount: monthlyData[month],
        })).sort((a, b) => new Date(a.date) - new Date(b.date));
    };

    const handleToggle = () => {
        setGroupBy((prev) => (prev === 'day' ? 'month' : 'day'));
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 mt-8 mb-6">Profile</h1>
            <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                        <p className="text-sm leading-6 text-gray-500 mb-6">
                            Contact support@cleo-ai.co to update your profile.
                        </p>

                        <dl className="space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                            <div className="pt-6 sm:flex sm:justify-between">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
                                <dd className="mt-1 sm:mt-0 text-gray-900 sm:text-right">
                                    {actorInfo?.full_name || 'N/A'}
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex sm:justify-between">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                                <dd className="mt-1 sm:mt-0 text-gray-900 sm:text-left">
                                    {actorInfo?.email || 'N/A'}
                                </dd>
                            </div>
                            <div className="pt-6 sm:flex sm:justify-between">
                                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Title</dt>
                                <dd className="mt-1 sm:mt-0 text-gray-900 sm:text-left">
                                    {formatSpecialty(actorInfo?.actor_specialty)}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
            <div className="mt-8">
                <h2 className="text-2xl font-semibold mb-4">Patient Log</h2>

                {/* Group By Toggle */}
                <div className="flex items-center mb-4">
                    <span className="mr-2">Group by Day</span>
                    <Switch
                        checked={groupBy === 'month'}
                        onChange={handleToggle}
                        className={`${groupBy === 'month' ? 'bg-indigo-600' : 'bg-gray-200'}
            relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500`}
                    >
                        <span
                            className={`${groupBy === 'month' ? 'translate-x-6' : 'translate-x-1'}
              inline-block h-4 w-4 transform bg-white rounded-full transition-transform`}
                        />
                    </Switch>
                    <span className="ml-2">Group by Month</span>
                </div>

                {/* Loading State */}
                {isLoadingPatientLog ? (
                    <div>Loading...</div>
                ) : (
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                            data={data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis allowDecimals={false} />
                            <Tooltip />
                            <Legend />
                            <Line
                                type="monotone" // Try 'linear', 'basis', or 'step' as alternatives
                                dataKey="patientCount"
                                name="Patients Seen"
                                stroke="#8884d8"
                                strokeWidth={2} // Adjust stroke width for prominence
                                activeDot={{ r: 8 }}
                            />

                        </LineChart>
                    </ResponsiveContainer>
                )}
            </div>
        </>
    );
}

