import { protectedResources, callProtectedApi } from '../authConfig';

export const templatesApi = {
    getTemplates: async (getAccessTokenSilently, isPrimary = null, includeGlobal = true) => {
        const requestBody = {
            ...(isPrimary !== null && { is_primary_note: isPrimary }),
            include_global: includeGlobal
        };

        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiGetTemplates.endpoint,
            {
                method: 'POST',
                body: JSON.stringify(requestBody)
            }
        );
    },

    upsertTemplate: async (getAccessTokenSilently, templateData) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiUpsertTemplate.endpoint,
            {
                method: 'POST',
                body: JSON.stringify(templateData)
            }
        );
    },

    deleteTemplate: async (getAccessTokenSilently, templateId) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiDeleteTemplate.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({ template_id: templateId })
            }
        );
    },

    generateTemplate: async (getAccessTokenSilently, description) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiGenerateTemplate.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({ description })
            }
        );
    },

    addTemplateToLibrary: async (getAccessTokenSilently, templateId) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiAddTemplateToLibrary.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({ template_id: templateId })
            }
        );
    },

    setPreferredTemplate: async (getAccessTokenSilently, templateId) => {
        return callProtectedApi(
            getAccessTokenSilently,
            protectedResources.apiSetPreferredTemplate.endpoint,
            {
                method: 'POST',
                body: JSON.stringify({ template_id: templateId })
            }
        );
    },
}; 