import React, { useState, useEffect, useRef } from 'react';
import { XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { Fieldset } from '../../components/catalyst/fieldset';

/**
 * RiskScoreCalculator component for calculating and displaying risk scores
 * @param {Object} props - Component props
 * @param {boolean} props.isVisible - Whether the risk score calculator is visible
 * @param {Function} props.onClose - Function to close the risk score calculator
 * @param {string|number} props.riskScoreId - ID of the selected risk score
 * @param {Array} props.riskScores - Array of available risk scores
 * @param {string} props.reevaluation - Current reevaluation text
 * @param {Function} props.updateSection - Function to update document sections
 * @param {Function} props.setSaveTriggered - Function to trigger saving
 */
const RiskScoreCalculator = ({
    isVisible,
    onClose,
    riskScoreId,
    riskScores,
    reevaluation,
    updateSection,
    setSaveTriggered
}) => {
    const [currentRiskScoreState, setCurrentRiskScoreState] = useState([]);
    const componentRefs = useRef([]);

    // Get the selected risk score based on the ID
    const currentRiskScore = riskScores.find(score => score.risk_score_id === riskScoreId);

    useEffect(() => {
        if (currentRiskScore) {
            // Initialize the risk score state with component names and initial null values
            const initialState = currentRiskScore.components.map(component => ({
                component_name: component.component_name,
                component_value: null // Set to null initially to indicate no selection
            }));
            setCurrentRiskScoreState(initialState);
        }
    }, [riskScoreId, currentRiskScore]);

    // Handle changes in risk score options
    const handleRiskScoreChange = (componentName, value) => {
        setCurrentRiskScoreState(prevState =>
            prevState.map(item =>
                item.component_name === componentName ? { ...item, component_value: value } : item
            )
        );

        // Auto-scroll to the next component
        const currentIndex = currentRiskScore.components.findIndex(
            component => component.component_name === componentName
        );

        if (currentIndex !== -1 && currentIndex + 1 < currentRiskScore.components.length) {
            const nextRef = componentRefs.current[currentIndex + 1];
            if (nextRef && nextRef.current) {
                nextRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    // Evaluate whether the score is complete and calculate the total score
    const evaluateRiskScore = () => {
        const scoreValues = currentRiskScoreState.map(item => item.component_value);
        const totalScore = scoreValues.reduce((total, current) => (current !== null ? total + current : total), 0);
        const isComplete = currentRiskScoreState.every(item => item.component_value !== null);

        return { totalScore, isComplete };
    };

    // Update the reevaluation summary and close the notification
    const updateReevaluationWithSummary = () => {
        // Calculate total score and completion status
        let totalScore = 0;

        // Generate the inputs summary
        const inputsSummary = currentRiskScore.components.map(component => {
            const selectedValue = currentRiskScoreState.find(
                item => item.component_name === component.component_name
            )?.component_value;

            const selectedOption = component.options.find(
                option => option.risk_score_component_option_id === selectedValue
            );
            const optionValue = selectedOption ? selectedOption.option_value : 'Not selected';
            totalScore += optionValue;

            return `${component.component_label} (${optionValue}): ${selectedOption ? selectedOption.option_name : 'Not selected'}`;
        }).join('\n');

        // Include the total score at the top of the summary
        const summary = `${currentRiskScore.score_name} Calculation (Total Score = ${totalScore})\n\n${inputsSummary}`;

        // Update the reevaluation with the new summary
        const newReevaluation = `${reevaluation}\n\n${summary}`;

        updateSection('reevaluation', newReevaluation);
        setSaveTriggered(true);
        onClose();
    };

    if (!isVisible || !currentRiskScore) {
        return null;
    }

    return (
        <Fieldset className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="border border-gray-300 shadow-lg rounded-lg overflow-hidden h-[85vh] w-[95vw] md:h-[75vh] md:w-[900px] flex flex-col bg-white">
                <div className="flex justify-between items-center bg-indigo-900 text-white py-4 px-6">
                    <div className="font-bold text-xl text-center flex-grow">
                        {currentRiskScore.score_name}
                    </div>
                    <button onClick={onClose} className="ml-auto">
                        <XMarkIcon className="h-6 w-6" />
                    </button>
                </div>
                <div className="p-4 flex-grow overflow-auto max-h-[calc(100%-8rem)] space-y-4">
                    {currentRiskScore.components.map((component, index) => {
                        // Initialize ref for this component if it doesn't exist
                        if (!componentRefs.current[index]) {
                            componentRefs.current[index] = React.createRef();
                        }

                        return (
                            <div
                                key={component.component_name}
                                ref={componentRefs.current[index]}
                                className="w-full bg-gray-100 p-4 rounded-lg flex flex-col"
                            >
                                <RiskScoreOptions
                                    label={component.component_label}
                                    options={component.options}
                                    selectedValue={
                                        currentRiskScoreState.find(
                                            item => item.component_name === component.component_name
                                        )?.component_value
                                    }
                                    onChange={value => handleRiskScoreChange(component.component_name, value)}
                                    className="break-words whitespace-normal w-full"
                                />
                            </div>
                        );
                    })}
                    <div className="bg-white p-4 flex flex-col">
                        {evaluateRiskScore().isComplete ? (
                            <p className="mt-2">Score complete.</p>
                        ) : (
                            <p className="flex items-center text-yellow-500 mt-2">
                                <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                                Complete items above to calculate {currentRiskScore.score_name} score
                            </p>
                        )}
                        <div className="flex justify-center items-center mt-4">
                            <button
                                onClick={updateReevaluationWithSummary}
                                className={`bg-indigo-900 hover:bg-indigo-900 text-white font-bold py-3 px-4 rounded ${!evaluateRiskScore().isComplete ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                disabled={!evaluateRiskScore().isComplete}
                            >
                                Copy and Save to Evaluations
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fieldset>
    );
};

/**
 * RiskScoreOptions component for displaying risk score options
 * @param {Object} props - Component props
 * @param {string} props.label - Label for the risk score option
 * @param {Array} props.options - Array of available options
 * @param {string|number} props.selectedValue - Currently selected value
 * @param {Function} props.onChange - Function to handle option change
 */
const RiskScoreOptions = ({ label, options, selectedValue, onChange }) => {
    return (
        <div className="flex justify-between items-center w-full my-4 border-b border-gray-200 pb-3 px-3">
            <div className="text-sm font-semibold flex items-center">
                {selectedValue === null && (
                    <span className="mr-2 h-3 w-3 bg-red-500 rounded-full inline-block"></span>
                )}
                {label}:
            </div>
            <div className="flex flex-col items-end w-[75%] space-y-2">
                {options.map((option, index) => {
                    let borderRadiusClass = '';
                    if (index === 0) {
                        borderRadiusClass = 'rounded-t-lg';
                    } else if (index === options.length - 1) {
                        borderRadiusClass = 'rounded-b-lg';
                    }

                    return (
                        <button
                            key={option.risk_score_component_option_id}
                            type="button"
                            className={`relative inline-flex justify-between items-center w-full px-5 py-3 text-sm font-medium ${selectedValue === option.risk_score_component_option_id ? 'bg-indigo-600 text-white' : 'bg-white text-gray-800 hover:bg-indigo-50'} shadow-md focus:ring-2 focus:ring-indigo-500 focus:outline-none transition-colors duration-150 ease-in-out ${borderRadiusClass}`}
                            onClick={() => onChange(option.risk_score_component_option_id)}
                        >
                            <span>{option.option_name}</span>
                            <span>{option.option_value}</span>
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default RiskScoreCalculator; 