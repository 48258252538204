import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import MipsCriteriaCalculator from './MipsCriteriaCalculator';

/**
 * MipsCriteriaButton component for displaying a MIPS criteria button with dropdown
 * @param {Object} props - Component props
 * @param {Array} props.mipsCriteria - Array of available MIPS criteria
 * @param {string} props.documentStatus - Current document status
 * @param {Function} props.updateSection - Function to update document sections
 * @param {Function} props.setSaveTriggered - Function to trigger saving
 * @param {string|number|null} props.currentMipsCriteriaId - ID of the current MIPS criteria (optional, for external control)
 * @param {Function} props.setCurrentMipsCriteriaId - Function to update the current MIPS criteria ID (optional, for external control)
 * @param {boolean} props.mipsCriteriaCalculatorVisible - Whether the MIPS criteria calculator is visible (optional, for external control)
 * @param {Function} props.setMipsCriteriaCalculatorVisible - Function to update the MIPS criteria calculator visibility (optional, for external control)
 * @param {React.RefObject} props.dropdownRef - External ref for the dropdown container (for click-outside detection)
 * @param {boolean} props.showMipsCriteriaDropdown - Whether the MIPS criteria dropdown is visible (optional, for external control)
 * @param {Function} props.setShowMipsCriteriaDropdown - Function to update the MIPS criteria dropdown visibility (optional, for external control)
 */
const MipsCriteriaButton = ({
    mipsCriteria,
    documentStatus,
    updateSection,
    setSaveTriggered,
    currentMipsCriteriaId: externalMipsCriteriaId = null,
    setCurrentMipsCriteriaId: externalSetCurrentMipsCriteriaId = null,
    mipsCriteriaCalculatorVisible: externalCalculatorVisible = null,
    setMipsCriteriaCalculatorVisible: externalSetCalculatorVisible = null,
    dropdownRef = null,
    showMipsCriteriaDropdown: externalShowMipsCriteriaDropdown = null,
    setShowMipsCriteriaDropdown: externalSetShowMipsCriteriaDropdown = null
}) => {
    // Use internal state if external state is not provided
    const [internalShowMipsCriteriaDropdown, setInternalShowMipsCriteriaDropdown] = useState(false);
    const [internalMipsCriteriaCalculatorVisible, setInternalMipsCriteriaCalculatorVisible] = useState(false);
    const [internalCurrentMipsCriteriaId, setInternalCurrentMipsCriteriaId] = useState(null);

    // Create an internal ref if no external ref is provided
    const internalDropdownRef = useRef(null);

    // Use the external ref if provided, otherwise use the internal ref
    const mipsCriteriaDropdownRef = dropdownRef || internalDropdownRef;

    // Determine whether to use internal or external state
    const isExternallyControlled = externalSetCalculatorVisible !== null && externalSetCurrentMipsCriteriaId !== null;
    const isDropdownExternallyControlled = externalSetShowMipsCriteriaDropdown !== null;

    // Use either the external or internal state based on what's provided
    const showMipsCriteriaDropdown = isDropdownExternallyControlled 
        ? externalShowMipsCriteriaDropdown 
        : internalShowMipsCriteriaDropdown;
        
    const setShowMipsCriteriaDropdown = isDropdownExternallyControlled 
        ? externalSetShowMipsCriteriaDropdown 
        : setInternalShowMipsCriteriaDropdown;

    const mipsCriteriaCalculatorVisible = isExternallyControlled 
        ? externalCalculatorVisible 
        : internalMipsCriteriaCalculatorVisible;
        
    const setMipsCriteriaCalculatorVisible = isExternallyControlled 
        ? externalSetCalculatorVisible 
        : setInternalMipsCriteriaCalculatorVisible;

    const currentMipsCriteriaId = isExternallyControlled 
        ? externalMipsCriteriaId 
        : internalCurrentMipsCriteriaId;
        
    const setCurrentMipsCriteriaId = isExternallyControlled 
        ? externalSetCurrentMipsCriteriaId 
        : setInternalCurrentMipsCriteriaId;

    // Sync internal state with external state when external state changes
    useEffect(() => {
        if (isExternallyControlled && externalMipsCriteriaId !== null) {
            setInternalCurrentMipsCriteriaId(externalMipsCriteriaId);
        }
    }, [isExternallyControlled, externalMipsCriteriaId]);

    useEffect(() => {
        if (isExternallyControlled && externalCalculatorVisible !== null) {
            setInternalMipsCriteriaCalculatorVisible(externalCalculatorVisible);
        }
    }, [isExternallyControlled, externalCalculatorVisible]);

    useEffect(() => {
        if (isDropdownExternallyControlled && externalShowMipsCriteriaDropdown !== null) {
            setInternalShowMipsCriteriaDropdown(externalShowMipsCriteriaDropdown);
        }
    }, [isDropdownExternallyControlled, externalShowMipsCriteriaDropdown]);

    // Check if the document is in a state where MIPS criteria can be used
    const canUseMipsCriteria = !(
        documentStatus === 'PRE_ENCOUNTER' ||
        documentStatus === 'ENCOUNTER_STARTED' ||
        documentStatus === 'IN_PROGRESS'
    );

    // Handle MIPS criteria selection
    const handleMipsCriteriaSelect = (criteriaId) => {
        setCurrentMipsCriteriaId(criteriaId);
        setMipsCriteriaCalculatorVisible(true);
        setShowMipsCriteriaDropdown(false);
    };

    // Close the MIPS criteria calculator
    const handleCloseMipsCriteriaCalculator = () => {
        setMipsCriteriaCalculatorVisible(false);
    };

    if (!canUseMipsCriteria) {
        return null;
    }

    return (
        <>
            <div className="relative z-10" ref={mipsCriteriaDropdownRef}>
                <button
                    onClick={() => {
                        console.log('MIPS criteria button clicked, toggling dropdown from', showMipsCriteriaDropdown, 'to', !showMipsCriteriaDropdown);
                        setShowMipsCriteriaDropdown(!showMipsCriteriaDropdown);
                    }}
                    className="flex items-center space-x-2 bg-indigo-900 text-white rounded-lg px-3 py-2 hover:bg-indigo-800 transition-shadow duration-200 z-10"
                    title="MIPS Criteria"
                >
                    <FontAwesomeIcon icon={faCheckSquare} className="h-5 w-5" />
                    <span>MIPS Criteria</span>
                </button>
                {showMipsCriteriaDropdown && (
                    <div
                        className="absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="mips-criteria-dropdown"
                    >
                        <div className="py-1" role="none">
                            {mipsCriteria && mipsCriteria.length > 0 ? (
                                mipsCriteria.map((criteria) => (
                                    <button
                                        key={criteria.mips_criteria_id}
                                        type="button"
                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100 hover:text-indigo-900"
                                        role="menuitem"
                                        onClick={() => handleMipsCriteriaSelect(criteria.mips_criteria_id)}
                                    >
                                        #{criteria.criteria_number} - {criteria.criteria_title}
                                    </button>
                                ))
                            ) : (
                                <div className="px-4 py-2 text-sm text-gray-500">No MIPS criteria available</div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* MIPS Criteria Calculator Modal */}
            <MipsCriteriaCalculator
                isVisible={mipsCriteriaCalculatorVisible}
                onClose={handleCloseMipsCriteriaCalculator}
                mipsCriteriaId={currentMipsCriteriaId}
                mipsCriteria={mipsCriteria}
                updateSection={updateSection}
                setSaveTriggered={setSaveTriggered}
            />
        </>
    );
};

export default MipsCriteriaButton; 