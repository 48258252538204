// interventionApi.js  

import { protectedResources, callProtectedApi } from '../authConfig';

// Get interventions for a group  
export const getGroupInterventions = async (getAccessTokenSilently, groupId) => {
    if (!groupId) {
        throw new Error('Group ID is required');
    }

    return callProtectedApi(
        getAccessTokenSilently,
        protectedResources.apiGetGroupInterventions.endpoint,
        {
            method: 'POST',
            body: JSON.stringify({ group_id: groupId })
        }
    );
};

// Upsert an intervention  
export const upsertGroupIntervention = async (getAccessTokenSilently, interventionData) => {
    return callProtectedApi(
        getAccessTokenSilently,
        protectedResources.apiUpsertGroupIntervention.endpoint,
        {
            method: 'POST',
            body: JSON.stringify(interventionData)
        }
    );
};

// Delete an intervention  
export const deleteIntervention = async (getAccessTokenSilently, interventionId) => {
    if (!interventionId) {
        throw new Error('Intervention ID is required');
    }
    
    return callProtectedApi(
        getAccessTokenSilently,
        protectedResources.apiDeleteIntervention.endpoint,
        {
            method: 'POST',
            body: JSON.stringify({ intervention_id: interventionId })
        }
    );
};  