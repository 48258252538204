import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources, callProtectedApi } from '../authConfig';
import {
  Box,
  Button,
  TextField,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Chip,
  Container,
  Divider,
  IconButton,
  Tooltip,
  useTheme,
  InputAdornment,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import RefreshIcon from '@mui/icons-material/Refresh';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import HistoryIcon from '@mui/icons-material/History';
import { styled } from '@mui/material/styles';

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'medium',
  color: theme.palette.text.primary,
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  padding: theme.spacing(2),
  borderBottom: `2px solid ${theme.palette.divider}`,
}));

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
}));

const PageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const StyledPaper = styled(Paper)(({ theme, variant }) => ({
  backgroundColor: '#fff',
  borderRadius: 16,
  padding: theme.spacing(4),
  marginBottom: theme.spacing(3),
  transition: 'box-shadow 0.3s ease-in-out',
  ...(variant === 'primary' ? {
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.12)',
    },
  } : {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    '&:hover': {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
    },
  }),
}));

const SectionTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  marginBottom: theme.spacing(3),
  '& .icon': {
    color: theme.palette.text.primary,
  },
  '& .text': {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    backgroundColor: '#F8F9FC',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
  },
}));

const CallButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  borderRadius: 12,
  padding: '12px 24px',
  fontSize: '1rem',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
  minWidth: 140,
  height: 56,
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
  },
  '&:active': {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
}));

const TableHeader = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  fontWeight: 500,
  borderBottom: 'none',
  padding: '16px 24px',
  backgroundColor: '#fff',
}));

const TableContent = styled(TableCell)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '16px 24px',
  borderBottom: '1px solid #F0F0F0',
  color: theme.palette.text.primary,
}));

const StatusChip = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: theme.palette.success.main,
  '& .icon': {
    fontSize: '1.25rem',
  },
  '& .text': {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
}));

const MessageCount = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  '& .icon': {
    fontSize: '1.25rem',
  },
  '& .count': {
    fontSize: '0.875rem',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'background-color 0.2s ease',
  '&:hover': {
    backgroundColor: '#F8F9FC',
    cursor: 'default',
  },
}));

export default function PhoneCalls() {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [callerOffice, setCallerOffice] = useState('Cleo Health Main Clinic');
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [nextCursor, setNextCursor] = useState(null);

  // Fetch call history on component mount
  useEffect(() => {
    fetchCallHistory();
  }, []);

  const fetchCallHistory = async (cursor = null) => {
    try {
      setIsRefreshing(true);
      const endpoint = `${protectedResources.apiElevenLabsConversations.endpoint}${cursor ? `?cursor=${cursor}` : ''}`;
      const response = await callProtectedApi(
        getAccessTokenSilently,
        endpoint,
        { method: 'GET' }
      );
      
      if (cursor) {
        setConversations(prev => [...prev, ...response.conversations]);
      } else {
        setConversations(response.conversations || []);
      }
      
      setHasMore(response.has_more || false);
      setNextCursor(response.next_cursor || null);
      setError(null);
    } catch (error) {
      console.error('Error fetching call history:', error);
      setError('Failed to fetch call history');
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleLoadMore = () => {
    if (nextCursor) {
      fetchCallHistory(nextCursor);
    }
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleCallerOfficeChange = (event) => {
    setCallerOffice(event.target.value);
  };

  const handleInitiateCall = async () => {
    if (!phoneNumber) {
      setError('Please enter a phone number');
      return;
    }

    if (!callerOffice.trim()) {
      setError('Please enter a caller office');
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      await callProtectedApi(
        getAccessTokenSilently,
        protectedResources.apiElevenLabsInitiateCall.endpoint,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            to_number: phoneNumber,
            calling_behalf_of: callerOffice.trim()
          })
        }
      );

      setSuccessMessage('Call initiated successfully');
      setPhoneNumber('');
      // Refresh call history
      await fetchCallHistory();
    } catch (error) {
      console.error('Error initiating call:', error);
      setError(error.message || 'Failed to initiate call');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDuration = (seconds) => {
    if (!seconds) return '-';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const formatDate = (unixSecs) => {
    if (!unixSecs) return '-';
    return new Date(unixSecs * 1000).toLocaleString();
  };

  const getStatusColor = (status, callSuccessful) => {
    if (callSuccessful === 'success') return 'success';
    if (callSuccessful === 'failure') return 'error';
    if (status === 'processing') return 'warning';
    return 'default';
  };

  const getStatusLabel = (status, callSuccessful) => {
    if (callSuccessful === 'success') return 'Completed';
    if (callSuccessful === 'failure') return 'Failed';
    if (status === 'processing') return 'In Progress';
    return status;
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <PageHeader>
        <Box>
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              fontWeight: 'bold',
              color: theme.palette.text.primary,
              mb: 1,
            }}
          >
            Phone Calls
          </Typography>
          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ maxWidth: 600 }}
          >
            Make outbound calls to patients and manage your call history
          </Typography>
        </Box>
      </PageHeader>

      <StyledPaper variant="primary">
        <SectionTitle>
          <PhoneIcon className="icon" />
          <Typography className="text">Make a Call</Typography>
        </SectionTitle>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography sx={{ mb: 1, color: 'text.secondary' }}>Caller Office</Typography>
            <StyledTextField
              fullWidth
              value={callerOffice}
              onChange={handleCallerOfficeChange}
              placeholder="Enter office name"
              InputProps={{
                startAdornment: (
                  <Box sx={{ mr: 1, color: 'text.secondary' }}>
                    <BusinessIcon />
                  </Box>
                ),
              }}
              disabled={isLoading}
            />
          </Box>

          <Box>
            <Typography sx={{ mb: 1, color: 'text.secondary' }}>Phone Number</Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
              <StyledTextField
                fullWidth
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="+1 (555) 000-0000"
                InputProps={{
                  startAdornment: (
                    <Box sx={{ mr: 1, color: 'text.secondary' }}>
                      <PhoneIcon />
                    </Box>
                  ),
                }}
                disabled={isLoading}
                error={!!error}
              />
              <CallButton
                onClick={handleInitiateCall}
                disabled={isLoading || !phoneNumber.trim()}
                variant="contained"
                type="button"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress size={20} color="inherit" />
                    <span>Calling...</span>
                  </>
                ) : (
                  <>
                    <PhoneIcon />
                    <span>Make Call</span>
                  </>
                )}
              </CallButton>
            </Box>
          </Box>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {successMessage}
          </Alert>
        )}
      </StyledPaper>

      <StyledPaper variant="primary">
        <SectionTitle>
          <HistoryIcon className="icon" />
          <Typography className="text">Call History</Typography>
        </SectionTitle>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>DATE & TIME</TableHeader>
                <TableHeader>PATIENT</TableHeader>
                <TableHeader>AGENT</TableHeader>
                <TableHeader>STATUS</TableHeader>
                <TableHeader>DURATION</TableHeader>
                <TableHeader>MESSAGES</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {conversations.map((conv) => (
                <StyledTableRow key={conv.conversation_id}>
                  <TableContent>{formatDate(conv.start_time_unix_secs)}</TableContent>
                  <TableContent>{conv.patient_name || 'Unknown'}</TableContent>
                  <TableContent>{conv.agent_name}</TableContent>
                  <TableContent>
                    <StatusChip>
                      <CheckCircleIcon className="icon" />
                      <span className="text">Completed</span>
                    </StatusChip>
                  </TableContent>
                  <TableContent>{formatDuration(conv.call_duration_secs)}</TableContent>
                  <TableContent>
                    <MessageCount>
                      <ChatBubbleOutlineIcon className="icon" />
                      <span className="count">{conv.message_count}</span>
                    </MessageCount>
                  </TableContent>
                </StyledTableRow>
              ))}
              {conversations.length === 0 && (
                <TableRow>
                  <TableContent colSpan={6} align="center" sx={{ py: 6 }}>
                    {isLoading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                        <CircularProgress size={20} />
                        <Typography>Loading calls...</Typography>
                      </Box>
                    ) : (
                      <Typography color="text.secondary">No calls found</Typography>
                    )}
                  </TableContent>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>
    </Container>
  );
} 