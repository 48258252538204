import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShield, faChevronLeft, faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { XMarkIcon, ShieldCheckIcon } from '@heroicons/react/20/solid';
import { callProtectedApi, protectedResources } from '../../authConfig';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * RiskMessagesButton component for displaying risk messages and their actions
 * @param {Object} props - Component props
 * @param {Array} props.riskMessages - Array of risk messages
 * @param {boolean} props.hasRiskMessages - Whether there are risk messages
 * @param {boolean} props.isShowingRiskMessages - Whether risk messages are being shown
 * @param {Function} props.setIsShowingRiskMessages - Function to update risk messages visibility
 * @param {Function} props.setCurrentRiskScoreId - Function to set the current risk score ID
 * @param {Function} props.setRiskScoreCalculatorVisible - Function to show the risk score calculator
 * @param {Object} props.documentID - The document ID of the current chart
 * @param {Function} props.updateSection - Function to update document sections
 * @param {Function} props.onRiskItemResolved - Optional callback when a risk item is resolved
 * @param {Function} props.setSaveTriggered - Function to trigger saving the document
 */
const RiskMessagesButton = ({
    riskMessages,
    hasRiskMessages,
    isShowingRiskMessages,
    setIsShowingRiskMessages,
    setCurrentRiskScoreId,
    setRiskScoreCalculatorVisible,
    documentID,
    updateSection,
    onRiskItemResolved,
    setSaveTriggered
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const [currentRiskMessageIndex, setCurrentRiskMessageIndex] = useState(0);
    const [resolvingRiskItem, setResolvingRiskItem] = useState(null);
    const [resolvedRiskItem, setResolvedRiskItem] = useState(null);

    // Reset the current message index when risk messages change
    useEffect(() => {
        setCurrentRiskMessageIndex(0);
    }, [riskMessages]);

    // Function to resolve a risk item
    const resolveRiskItem = async (riskItemId) => {
        setResolvingRiskItem(riskItemId);
        try {
            await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiResolveRiskItem.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({ risk_item_id: riskItemId })
                }
            );
            setResolvedRiskItem(riskItemId);
            setResolvingRiskItem(null);

            // Notify parent component if callback is provided
            if (onRiskItemResolved) {
                onRiskItemResolved(riskItemId);
            }
        } catch (error) {
            console.error('Error resolving risk item:', error);
            setResolvingRiskItem(null);
        }
    };

    // Function to apply a macro to the document
    const applyMacroToDocument = async (riskItemId, macroId) => {
        setResolvingRiskItem(riskItemId);
        try {
            const data = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiApplyMacroToDocument.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        document_id: documentID,
                        macro_id: macroId,
                        risk_item_id: riskItemId
                    })
                }
            );

            // Handle the new response format with updated_section_name and updated_section_content
            if (data.updated_section_name && data.updated_section_content) {
                // Update the appropriate section using the consolidated updateSection function
                updateSection(data.updated_section_name, data.updated_section_content);

                // Trigger save to persist changes
                if (setSaveTriggered) {
                    setSaveTriggered(true);
                }
            }

            // Set resolved state and clear resolving state
            setResolvedRiskItem(riskItemId);
            setResolvingRiskItem(null);

            // Notify parent component if callback is provided
            if (onRiskItemResolved) {
                onRiskItemResolved(riskItemId);
            }
        } catch (error) {
            console.error('Error applying macro:', error);
            // Clear resolving state on error
            setResolvingRiskItem(null);
        }
    };

    const handleModalVisibility = () => {
        setIsShowingRiskMessages(true);
    };

    const renderRiskMessagesContent = () => {
        if (!Array.isArray(riskMessages) || riskMessages.length === 0) return null;

        // Filter out resolved messages
        const filteredMessages = riskMessages.filter(message => !message.resolved);

        if (filteredMessages.length === 0) {
            return null;
        }

        // Define priority order based on the specified criteria
        const getPriority = (message) => {
            if (message.force_resolve === true) {
                return 1;
            } else if (message.risk_category === "CRITICAL_CARE" || message.risk_category === "SEPSIS") {
                return 2;
            } else if (message.auto_show === true) {
                return 3;
            } else {
                return 4;
            }
        };

        // Sort messages based on priority
        const filteredAndSortedMessages = filteredMessages.sort((a, b) => {
            const aPriority = getPriority(a);
            const bPriority = getPriority(b);

            if (aPriority !== bPriority) {
                return aPriority - bPriority;
            }

            // Secondary sorting criteria (optional)
            // For example, sort by risk_name alphabetically
            return a.risk_name.localeCompare(b.risk_name);
        });

        // Ensure currentRiskMessageIndex is valid
        const validIndex = Math.max(0, Math.min(currentRiskMessageIndex, filteredAndSortedMessages.length - 1));
        if (validIndex !== currentRiskMessageIndex) {
            setCurrentRiskMessageIndex(validIndex);
        }

        // Safety check if no messages left after filtering
        if (filteredAndSortedMessages.length === 0) {
            return null;
        }

        const currentMessage = filteredAndSortedMessages[validIndex];

        // Safety check to make sure currentMessage is defined
        if (!currentMessage) {
            return null;
        }

        const nextMessage = () => {
            setCurrentRiskMessageIndex(prevIndex =>
                prevIndex === filteredAndSortedMessages.length - 1 ? 0 : prevIndex + 1
            );
        };

        const prevMessage = () => {
            setCurrentRiskMessageIndex(prevIndex =>
                prevIndex === 0 ? filteredAndSortedMessages.length - 1 : prevIndex - 1
            );
        };

        const getHeaderTitle = (riskCategory) => {
            switch (riskCategory) {
                case "CRITICAL_CARE":
                    return "Critical Care Alert";
                case "SEPSIS":
                    return "Sepsis Alert";
                default:
                    return null;
            }
        };

        const handleActionClick = () => {
            if (currentMessage.linked_macro_id) {
                applyMacroToDocument(currentMessage.risk_item_id, currentMessage.linked_macro_id);
            } else if (currentMessage.linked_risk_score_id) {
                // Set the current risk score ID
                setCurrentRiskScoreId(currentMessage.linked_risk_score_id);

                // Show the risk score calculator
                setRiskScoreCalculatorVisible(true);

                // Hide the risk messages panel
                setIsShowingRiskMessages(false);

                // Resolve the risk item
                resolveRiskItem(currentMessage.risk_item_id);
            } else {
                // Resolve the message if there's no action to perform
                resolveRiskItem(currentMessage.risk_item_id);
            }
        };

        const renderActionButton = () => {
            if (resolvingRiskItem === currentMessage.risk_item_id) {
                return (
                    <svg className="animate-spin h-5 w-5 text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                );
            }
            if (resolvedRiskItem === currentMessage.risk_item_id) {
                return (
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-green-500">
                        <FontAwesomeIcon icon={faCheck} className="h-6 w-6 text-white" />
                    </div>
                );
            }

            let buttonText;
            if (currentMessage.linked_macro_id) {
                buttonText = currentMessage.risk_category === "CRITICAL_CARE"
                    ? "Apply Critical Care Macro"
                    : currentMessage.risk_category === "SEPSIS"
                        ? "Apply Sepsis Macro"
                        : "Apply Macro";
            } else if (currentMessage.linked_risk_score_id) {
                buttonText = currentMessage.risk_name.toUpperCase() === "HEART"
                    ? "Calculate HEART Score"
                    : currentMessage.risk_name.toUpperCase() === "NIHSS"
                        ? "Calculate NIHSS"
                        : "Calculate Risk Score";
            } else {
                buttonText = "Resolve";
            }

            return (
                <div className="bg-indigo-900 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors duration-200">
                    {buttonText}
                </div>
            );
        };

        return (
            <div className="relative w-full h-full max-w-md mx-auto flex flex-col justify-center items-center p-4">
                {filteredAndSortedMessages.length > 1 && (
                    <div className="flex items-center justify-between w-full absolute inset-x-0 top-1/2 transform -translate-y-1/2">
                        <button onClick={prevMessage} className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none absolute left-0">
                            <FontAwesomeIcon icon={faChevronLeft} className="h-8 w-8" />
                        </button>
                        <button onClick={nextMessage} className="text-indigo-900 hover:text-indigo-700 transition-colors duration-200 focus:outline-none absolute right-0">
                            <FontAwesomeIcon icon={faChevronRight} className="h-8 w-8" />
                        </button>
                    </div>
                )}

                {/* Header for Force Resolve Messages - with safety check */}
                {currentMessage && currentMessage.force_resolve && (
                    <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded">
                        Required
                    </div>
                )}

                {/* Risk Category Title - with safety check */}
                {currentMessage && getHeaderTitle(currentMessage.risk_category) && (
                    <h2 className="text-xl font-bold text-red-500 mb-2">
                        {getHeaderTitle(currentMessage.risk_category)}
                    </h2>
                )}

                {/* Message Content - with safety check */}
                {currentMessage && (
                    <p className="text-lg text-center mb-4 px-4">{currentMessage.message}</p>
                )}

                {/* Action Button - with safety check */}
                {currentMessage && (
                    <button
                        onClick={handleActionClick}
                        className="mb-2 px-4 py-2 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 relative"
                        disabled={resolvingRiskItem === currentMessage.risk_item_id || resolvedRiskItem === currentMessage.risk_item_id}
                    >
                        {renderActionButton()}
                    </button>
                )}
            </div>
        );
    };

    if (!hasRiskMessages) {
        return null;
    }

    return (
        <div className="fixed top-48 right-4 z-[80]">
            {isShowingRiskMessages ? (
                <div className="h-[40vh] md:h-[350px] w-full md:w-[600px] border border-gray-300 shadow-2xl rounded-xl overflow-hidden flex flex-col bg-white">
                    <div className="flex justify-between items-center bg-gradient-to-r from-indigo-700 to-indigo-900 text-white py-3 px-4">
                        <div className="font-bold text-xl flex items-center">
                            <FontAwesomeIcon icon={faShield} className="mr-2" />
                            Cleo Risk Analyst
                        </div>
                        <button
                            onClick={() => setIsShowingRiskMessages(false)}
                            className="p-1 rounded-full hover:bg-indigo-700 transition-colors duration-200"
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="flex-grow overflow-y-auto p-4 space-y-4 text-xl leading-relaxed">
                        {renderRiskMessagesContent()}
                    </div>
                </div>
            ) : (
                <button
                    className="group flex flex-col items-center justify-center h-16 w-16 bg-indigo-900 rounded-full cursor-pointer shadow-lg hover:shadow-xl hover:bg-indigo-800 transition-all duration-200"
                    onClick={handleModalVisibility}
                    title="Risk Messages"
                >
                    <ShieldCheckIcon className="h-6 w-6 text-white group-hover:scale-110 transition-transform duration-200" />
                    <span className="text-white text-[8px] mt-1">Risk</span>
                </button>
            )}
        </div>
    );
};

export default RiskMessagesButton; 