import React, { useState, useEffect, useCallback } from 'react';
import { Switch } from '@headlessui/react';
import { useSettings } from '../../contexts/SettingsProvider';
import { useAuth } from '../../contexts/AuthProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources, callProtectedApi } from '../../authConfig';

const HideROSToggle = () => {
    const { getSetting, updateSetting, isLoading: settingsLoading } = useSettings();
    const { actorInfo, updateActorInfo } = useAuth();
    const { getAccessTokenSilently } = useAuth0();
    const [hideROS, setHideROS] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // Use a more controlled load method with useCallback to prevent unnecessary re-renders
    const loadCurrentValue = useCallback(() => {
        if (!settingsLoading && actorInfo) {
            // Check both sources and use either if available
            const settingValue = getSetting('hide_ros');
            const actorValue = actorInfo.transfer_ros_to_hpi;

            // If either is true, we consider the feature enabled
            const newValue = settingValue === true || actorValue === true;

            // Only update state if value has changed to prevent re-render loops
            setHideROS(prevValue => {
                if (prevValue !== newValue) return newValue;
                return prevValue;
            });
        }
    }, [getSetting, settingsLoading, actorInfo]);

    // Only run this effect when the dependencies actually change
    useEffect(() => {
        loadCurrentValue();
    }, [loadCurrentValue]);

    const handleToggleChange = async () => {
        if (isSaving) return; // Prevent multiple clicks while saving

        const newValue = !hideROS;
        setIsSaving(true);

        try {
            // Create an array of promises to execute all updates in parallel
            const updatePromises = [
                // 1. Update the settings table
                updateSetting('hide_ros', newValue),

                // 2. Update the actor property for backward compatibility
                callProtectedApi(
                    getAccessTokenSilently,
                    protectedResources.apiUpdateActorSettings.endpoint,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            field: 'transfer_ros_to_hpi',
                            value: String(newValue)
                        })
                    }
                )
            ];

            // Wait for all updates to complete
            const [settingsSuccess] = await Promise.all(updatePromises);

            // 3. Update local actor info after API updates are successful
            updateActorInfo('transfer_ros_to_hpi', newValue);

            // 4. Only after all updates are done, update our local UI state
            setHideROS(newValue);

            if (!settingsSuccess) {
                console.warn("Settings update was not successful");
            }
        } catch (error) {
            console.error('Error updating hide ROS setting:', error);
            // Don't update local state since the server updates failed
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Switch
            checked={hideROS}
            onChange={handleToggleChange}
            disabled={isSaving}
            className={`${hideROS ? 'bg-indigo-600' : 'bg-gray-200'}
                relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
        >
            <span className="sr-only">Hide ROS</span>
            <span
                aria-hidden="true"
                className={`${hideROS ? 'translate-x-5' : 'translate-x-0'}
                    pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
            />
        </Switch>
    );
};

export default HideROSToggle; 