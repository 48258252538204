import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTimes, 
    faArrowLeft, 
    faArrowRight, 
    faThumbsDown,
    faSpinner,
    faCheckCircle,
    faUsers,
    faUserPlus,
    faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';

function BatchSummaryModal({
    isOpen,
    onClose,
    summaries,
    currentIndex,
    onPrevious,
    onNext,
    onApprove,
    onReject,
    isLoading,
    approvedSummaries,
    onReleaseToPool,
    onReleaseToProvider,
    onSummaryChange,
    groupActors,
    onFetchProviders
}) {
    const [showProviderModal, setShowProviderModal] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [showPoolNotification, setShowPoolNotification] = useState(false);
    
    // Check if all summaries have been processed (approved or rejected)
    const allSummariesProcessed = summaries.length > 0 && 
        summaries.every(summary => 
            approvedSummaries[summary.inpatientId] === true || 
            approvedSummaries[summary.inpatientId] === false
        );
    
    if (!isOpen || summaries.length === 0) return null;
    
    const currentSummary = summaries[currentIndex];
    const isApproved = approvedSummaries[currentSummary.inpatientId];
    const isRejected = approvedSummaries[currentSummary.inpatientId] === false;
    
    const handleReleaseToPoolClick = () => {
        onReleaseToPool(currentSummary);
        setShowPoolNotification(true);
        setTimeout(() => {
            setShowPoolNotification(false);
        }, 3000);
    };
    
    const handleReleaseToProviderClick = () => {
        setShowProviderModal(true);
    };
    
    const handleProviderSelect = (provider) => {
        setSelectedProvider(provider);
    };
    
    const handleConfirmProviderTransfer = () => {
        if (selectedProvider) {
            onReleaseToProvider(currentSummary, selectedProvider);
            setShowProviderModal(false);
            setSelectedProvider(null);
        }
    };
    
    const handleCancelProviderModal = () => {
        setShowProviderModal(false);
        setSelectedProvider(null);
    };
    
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
            <div className="relative bg-white rounded-lg shadow-xl w-3/4 max-w-4xl p-6 max-h-[90vh] flex flex-col">
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold text-indigo-900">
                        Transfer Summary Review ({currentIndex + 1}/{summaries.length})
                    </h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                
                {/* Patient info */}
                <div className="bg-gray-50 p-3 mb-4 rounded-md">
                    <h3 className="font-semibold text-lg">{currentSummary.patientName}</h3>
                    <p className="text-sm text-gray-600">
                        {currentSummary.complaint || 'No chief complaint recorded'}
                    </p>
                </div>
                
                {/* Summary content */}
                <div className="flex-grow overflow-y-auto mb-4 border border-gray-200 rounded-md p-4 bg-white">
                    {isLoading ? (
                        <div className="flex justify-center items-center h-full">
                            <FontAwesomeIcon icon={faSpinner} spin className="text-indigo-600 text-2xl mr-2" />
                            <span>Generating summary...</span>
                        </div>
                    ) : (
                        <textarea
                            className="w-full h-full min-h-[200px] p-2 focus:ring-indigo-500 focus:border-indigo-500 rounded-md resize-vertical"
                            value={currentSummary.summary}
                            onChange={(e) => {
                                const updatedSummaries = [...summaries];
                                updatedSummaries[currentIndex] = {
                                    ...updatedSummaries[currentIndex],
                                    summary: e.target.value,
                                    isEdited: true
                                };
                                onSummaryChange(updatedSummaries);
                            }}
                        />
                    )}
                </div>
                
                {/* Navigation and action buttons */}
                <div className="flex justify-between items-center">
                    <div>
                        <button
                            onClick={onPrevious}
                            disabled={currentIndex === 0}
                            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                            Previous
                        </button>
                        <button
                            onClick={onNext}
                            disabled={currentIndex === summaries.length - 1}
                            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Next
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                        </button>
                    </div>
                    
                    <div className="flex items-center">
                        {isApproved ? (
                            <span className="text-green-600 flex items-center">
                                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                Approved
                            </span>
                        ) : isRejected ? (
                            <span className="text-red-600 flex items-center">
                                <FontAwesomeIcon icon={faThumbsDown} className="mr-2" />
                                Rejected
                            </span>
                        ) : (
                            <>
                                <button
                                    onClick={() => onReject(currentSummary)}
                                    className={`px-4 py-2 rounded-md mr-2 ${
                                        isLoading 
                                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                                        : 'bg-red-500 text-white hover:bg-red-600'
                                    }`}
                                    disabled={isLoading}
                                >
                                    <FontAwesomeIcon icon={faThumbsDown} className="mr-2" />
                                    Reject
                                </button>
                                
                                <button
                                    onClick={handleReleaseToPoolClick}
                                    className={`px-4 py-2 rounded-md mr-2 ${
                                        isLoading 
                                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                                        : 'bg-blue-500 text-white hover:bg-blue-600'
                                    }`}
                                    disabled={isLoading}
                                >
                                    <FontAwesomeIcon icon={faUsers} className="mr-2" />
                                    Release to Pool
                                </button>
                                <button
                                    onClick={handleReleaseToProviderClick}
                                    className={`px-4 py-2 rounded-md ${
                                        isLoading 
                                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                                        : 'bg-green-600 text-white hover:bg-green-700'
                                    }`}
                                    disabled={isLoading}
                                >
                                    <FontAwesomeIcon icon={faExchangeAlt} className="mr-2" />
                                    Transfer to Provider
                                </button>
                            </>
                        )}
                    </div>
                </div>
                
                {/* Progress indicator */}
                <div className="mt-4 w-full bg-gray-200 rounded-full h-2.5">
                    <div 
                        className="bg-indigo-600 h-2.5 rounded-full" 
                        style={{ width: `${(currentIndex + 1) / summaries.length * 100}%` }}
                    ></div>
                </div>
                
                {/* Done button (appears when all summaries are processed) */}
                {allSummariesProcessed && (
                    <div className="mt-4 flex justify-center">
                        <button
                            onClick={onClose}
                            className="px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 font-medium"
                        >
                            Finish
                        </button>
                    </div>
                )}
            </div>
            
            {/* Provider Selection Modal */}
            {showProviderModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
                        <h3 className="text-lg font-semibold mb-4">Select Provider</h3>
                        
                        <div className="max-h-60 overflow-y-auto mb-4">
                            {groupActors && groupActors.length > 0 ? (
                                <div className="space-y-2">
                                    {groupActors.map(actor => (
                                        <div 
                                            key={actor.actor_id}
                                            className={`p-3 border rounded-md cursor-pointer flex items-center ${
                                                selectedProvider?.actor_id === actor.actor_id 
                                                ? 'border-indigo-500 bg-indigo-50' 
                                                : 'border-gray-200 hover:bg-gray-50'
                                            }`}
                                            onClick={() => handleProviderSelect(actor)}
                                        >
                                            <div className="flex-1">
                                                <div className="font-medium">{actor.display_name || actor.email}</div>
                                                {actor.email && <div className="text-sm text-gray-500">{actor.email}</div>}
                                            </div>
                                            {selectedProvider?.actor_id === actor.actor_id && (
                                                <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-500 ml-2" />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="text-center text-gray-500">No providers available</p>
                            )}
                        </div>
                        
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={handleCancelProviderModal}
                                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleConfirmProviderTransfer}
                                disabled={!selectedProvider}
                                className={`px-4 py-2 rounded-md ${
                                    selectedProvider 
                                    ? 'bg-indigo-600 text-white hover:bg-indigo-700' 
                                    : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                }`}
                            >
                                Confirm Transfer
                            </button>
                        </div>
                    </div>
                </div>
            )}
            
            {/* Pool notification popup */}
            {showPoolNotification && (
                <div className="fixed inset-0 flex items-center justify-center pointer-events-none z-50">
                    <div className="bg-green-100 border border-green-400 text-green-700 px-6 py-3 rounded-md shadow-lg">
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faUsers} className="mr-3 text-green-500" />
                            <span className="font-medium">Patient added to pool successfully</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default BatchSummaryModal;