import React, { useState } from 'react';
import { format } from 'date-fns';
import {
    ArrowPathIcon,
    CheckCircleIcon,
    ExclamationCircleIcon
} from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import { invitationsApi } from '../api/invitationsApi';

const statusColors = {
    PROCESSING: 'bg-gray-100 text-gray-800',
    INVITED: 'bg-yellow-100 text-yellow-800',
    FAILED: 'bg-red-100 text-red-800'
};

const statusIcons = {
    PROCESSING: <ArrowPathIcon className="h-4 w-4 animate-spin" />,
    INVITED: <CheckCircleIcon className="h-4 w-4 text-yellow-500" />,
    FAILED: <ExclamationCircleIcon className="h-4 w-4 text-red-500" />
};

const getStatusColor = (status) => {
    switch (status) {
        case 'INVITED':
            return 'bg-yellow-100 text-yellow-800'; // Yellow for pending invites
        case 'ACCEPTED':
            return 'bg-green-100 text-green-800';   // Green for accepted
        case 'FAILED':
            return 'bg-red-100 text-red-800';       // Red for failed
        case 'PROCESSING':
            return 'bg-blue-100 text-blue-800';     // Blue for processing
        default:
            return 'bg-gray-100 text-gray-800';     // Gray for unknown status
    }
};

export const InvitationsTable = ({ invitations, onInvitationUpdated }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [resending, setResending] = useState({}); // Track resending state per invitation

    const handleResend = async (invitation) => {
        setResending(prev => ({ ...prev, [invitation.invitation_id]: true }));
        try {
            // Reuse the upsert endpoint
            await invitationsApi.upsertInvitation(getAccessTokenSilently, {
                email: invitation.email,
                first_name: invitation.first_name,
                last_name: invitation.last_name,
                actor_specialty: invitation.actor_specialty,
                org_id: invitation.org_id,
                group_id: invitation.group_id
            });

            if (onInvitationUpdated) {
                await onInvitationUpdated();
            }
        } catch (error) {
            console.error('Error resending invitation:', error);
        } finally {
            setResending(prev => ({ ...prev, [invitation.invitation_id]: false }));
        }
    };

    return (
        <div className="mt-8 mb-32">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h2 className="text-xl font-semibold text-gray-900">Invitations</h2>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all pending and sent invitations
                    </p>
                </div>
            </div>

            <div className="mt-6 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Name</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Specialty</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Invited</th>
                            <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {invitations.map((invitation) => (
                            <tr key={invitation.invitation_id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                                    {invitation.first_name} {invitation.last_name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {invitation.email}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {invitation.actor_specialty}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <span className={`inline-flex items-center gap-1 rounded-full px-2 py-1 text-xs font-semibold ${getStatusColor(invitation.invitation_status)}`}>
                                        {statusIcons[invitation.invitation_status]}
                                        {invitation.invitation_status}
                                    </span>
                                    {invitation.error_message && (
                                        <span className="block mt-1 text-xs text-red-600">
                                            {invitation.error_message}
                                        </span>
                                    )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {format(new Date(invitation.created_at), 'MMM d, yyyy')}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {invitation.last_invited_at
                                        ? format(new Date(invitation.last_invited_at), 'MMM d, yyyy')
                                        : '-'
                                    }
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    {invitation.invitation_status !== 'ACCEPTED' && (
                                        <button
                                            onClick={() => handleResend(invitation)}
                                            disabled={resending[invitation.invitation_id]}
                                            className={`inline-flex items-center gap-1 rounded-md px-2.5 py-1.5 text-sm font-semibold 
                                                ${resending[invitation.invitation_id]
                                                    ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                                                    : 'bg-indigo-600 text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'}`}
                                        >
                                            {resending[invitation.invitation_id] ? (
                                                <>
                                                    <ArrowPathIcon className="h-4 w-4 animate-spin" />
                                                    Resending...
                                                </>
                                            ) : (
                                                'Resend'
                                            )}
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}; 