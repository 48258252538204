import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { protectedResources, callProtectedApi } from '../authConfig';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../styles/OrganizationStructure.css';
import GroupMacrosPage from '../pages/macros/GroupMacros';
import MdmSettings from './MdmSettings';
import GroupInterventionsPage from './GroupInterventionsPage';
import GroupSignupCodes from './GroupSignupCodes';
import { Dialog } from '@headlessui/react';
import { CubeIcon, CreditCardIcon, PlusIcon } from '@heroicons/react/24/outline';
import Tree from 'react-d3-tree';
import { Tab } from '@headlessui/react';

const OrganizationStructure = ({ orgId }) => {
    // **React Hooks must be called at the top level of the component**  

    // State Hooks  
    const { getAccessTokenSilently, user } = useAuth0();
    const [organizationData, setOrganizationData] = useState(null);
    const [treeData, setTreeData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selection, setSelection] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [mdmTemplate, setMdmTemplate] = useState(null);
    const [inheritanceSource, setInheritanceSource] = useState(null);
    const [groupDetails, setGroupDetails] = useState({
        group_name: '',
        group_description: '',
    });
    const [isMacrosModalOpen, setIsMacrosModalOpen] = useState(false);
    const [isMdmModalOpen, setIsMdmModalOpen] = useState(false);
    const [isInterventionsModalOpen, setIsInterventionsModalOpen] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(null);

    // **New state variables for Add Child Node Modal**  
    const [isAddChildModalOpen, setIsAddChildModalOpen] = useState(false);
    const [newChildData, setNewChildData] = useState({
        name: '',
        description: '',
        is_group_paying: true
    });

    const [groupActors, setGroupActors] = useState([]);
    const [isFetchingActors, setIsFetchingActors] = useState(false);
    const [errorFetchingActors, setErrorFetchingActors] = useState(null);

    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({
        key: null,
        direction: 'ascending',
    });

    const [users, setUsers] = useState([]);
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const [usersError, setUsersError] = useState(null);

    const [usersSearchQuery, setUsersSearchQuery] = useState('');
    const [usersSortConfig, setUsersSortConfig] = useState({
        key: null,
        direction: 'ascending',
    });

    const [selectedUser, setSelectedUser] = useState(null);
    const [isMoveUserModalOpen, setIsMoveUserModalOpen] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroupIdForMove, setSelectedGroupIdForMove] = useState(null);

    const [isSignupCodesModalOpen, setIsSignupCodesModalOpen] = useState(false);


    const [notification, setNotification] = useState(null);

    const handleConfirmMoveUser = async () => {
        try {
            const response = await callProtectedApi(
                getAccessTokenSilently,
                `${protectedResources.apiActorsBase.endpoint}/move/`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        actor_id: selectedUser.actor_id,
                        group_id: parseInt(selectedGroupIdForMove, 10)
                    })
                }
            );

            console.log('User moved successfully:', response);
            getUsersForOrg();
            setSelectedUser(null);
            setIsMoveUserModalOpen(false);
            setNotification({ type: 'success', message: 'User moved successfully.' });
        } catch (error) {
            console.error('Error moving user:', error);
            setNotification({ type: 'error', message: error.message || 'Failed to move user. Please try again later.' });
        }
    };

    useEffect(() => {
        if (notification) {
            const timer = setTimeout(() => {
                setNotification(null);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [notification]);

    const handleUserSelection = (user) => {
        if (selectedUser && selectedUser.actor_id === user.actor_id) {
            setSelectedUser(null); // Deselect if same user is clicked  
        } else {
            setSelectedUser(user);
        }
    };
    const handleMoveUserClick = () => {
        setSelectedGroupIdForMove(null); // Reset any previous selection  
        setIsMoveUserModalOpen(true);
        //fetchGroups(); // Fetch the list of groups  
    };

    const getAllGroupsFromHierarchy = (hierarchy) => {
        const groups = [];

        const traverse = (node) => {
            console.log('Node:', node);
            groups.push({
                group_id: node.attributes.group_id,
                name: node.name,
                description: node.attributes.description,
                // Include other attributes as needed  
            });

            if (node.children && node.children.length > 0) {
                node.children.forEach((child) => traverse(child));
            }
        };

        traverse(hierarchy);

        return groups;
    };

    const getUsersForOrg = useCallback(async () => {
        setIsLoadingUsers(true);
        setUsersError(null);

        if (user && orgId) {
            try {
                const response = await callProtectedApi(
                    getAccessTokenSilently,
                    protectedResources.apiGetUsersForOrg.endpoint,
                    {
                        method: 'POST',
                        body: JSON.stringify({ org_id: orgId })
                    }
                );

                setUsers(response.users);
            } catch (error) {
                console.error('Error fetching users:', error);
                setUsersError(error.message);
            } finally {
                setIsLoadingUsers(false);
            }
        } else {
            console.log('user or orgId not available');
            setIsLoadingUsers(false);
        }
    }, [user, orgId, getAccessTokenSilently]);

    useEffect(() => {
        getUsersForOrg();
    }, [getUsersForOrg]);

    const formatSpecialty = (specialty) => {
        if (!specialty) return '';
        return specialty
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    // Callback Hooks  
    const fetchOrganizationData = useCallback(async () => {
        setIsLoading(true);
        if (user && orgId) {
            try {
                const response = await callProtectedApi(
                    getAccessTokenSilently,
                    `${protectedResources.apiGetGroupHierarchy.endpoint}${orgId}`,
                    {
                        method: 'GET'
                    }
                );

                setOrganizationData(response.hierarchy);
            } catch (error) {
                console.error('Error fetching organization data:', error);
            } finally {
                setIsLoading(false);
            }
        } else {
            console.log('user or orgId not available');
            setIsLoading(false);
        }
    }, [user, orgId, getAccessTokenSilently]);

    // Effect Hooks  
    useEffect(() => {
        fetchOrganizationData();
    }, [fetchOrganizationData]);

    const transformDataForTree = (data) => {
        const nodesById = {};
        const rootNodes = [];
        console.log(data);

        // First, create a map of all nodes  
        data.forEach((item) => {
            nodesById[item.group_id] = {
                name: item.name,
                attributes: {
                    description: item.description,
                    group_type_id: item.group_type_id,
                    org_id: item.org_id,
                    level: item.level,
                    group_id: item.group_id, // Include group_id here  
                    path: item.path, // Include path here  
                    is_group_paying: item.is_group_paying,
                },
                children: [],
            };
        });

        // Then, loop again to assign children to their parents  
        data.forEach((item) => {
            const node = nodesById[item.group_id];
            if (item.parent_group_id === null) {
                // Root node  
                rootNodes.push(node);
            } else {
                const parentNode = nodesById[item.parent_group_id];
                if (parentNode) {
                    parentNode.children.push(node);
                } else {
                    console.warn(
                        `Parent with ID ${item.parent_group_id} not found for node ${item.group_id}`
                    );
                }
            }
        });

        // If there's only one root node, return it directly  
        if (rootNodes.length === 1) {
            return rootNodes[0];
        }

        // If there are multiple root nodes, return them as children of a virtual root  
        return {
            name: 'Root',
            children: rootNodes,
            attributes: {
                group_id: 0,
            },
        };
    };

    useEffect(() => {
        const fetchActors = async () => {
            if (selection.length > 0) {
                const selectedGroup = selection[0];
                const groupId = selectedGroup.attributes.group_id;

                try {
                    setIsFetchingActors(true);
                    setErrorFetchingActors(null);
                    const endpoint = `${protectedResources.apiGetGroupBase.endpoint}/${groupId}/actors`;

                    const data = await callProtectedApi(
                        getAccessTokenSilently,
                        endpoint,
                        {
                            method: 'GET'
                        }
                    );

                    setGroupActors(data);
                } catch (error) {
                    console.error('Error fetching actors in group:', error);
                    setErrorFetchingActors(error.message || 'Failed to fetch actors');
                } finally {
                    setIsFetchingActors(false);
                }
            } else {
                setGroupActors([]);
            }
        };

        fetchActors();
    }, [selection, getAccessTokenSilently]);

    // Transform data when organizationData changes  
    useEffect(() => {
        if (organizationData) {
            const transformedData = transformDataForTree(organizationData);
            setTreeData(transformedData);

            // Extract all groups from the hierarchy  
            const allGroups = getAllGroupsFromHierarchy(transformedData);
            setGroups(allGroups);
        }
    }, [organizationData]);

    useEffect(() => {
        if (selection.length > 0) {
            setIsAdmin(true); // Assuming all selected groups are admin for demonstration 
        } else {
            setIsAdmin(false);
            setMdmTemplate(null);
            setInheritanceSource(null);
            setGroupDetails({
                group_name: '',
                group_description: '',
            });
        }
    }, [selection]);

    // **Function to add child node to tree data**  
    const addChildNodeToTree = (parentGroupId, newChild) => {
        const newTreeData = { ...treeData };

        const recursiveAddChild = (node) => {
            if (node.attributes.group_id === parentGroupId) {
                if (!node.children) {
                    node.children = [];
                }
                node.children.push(newChild);
                return true; // Found and added  
            } else if (node.children) {
                for (let child of node.children) {
                    if (recursiveAddChild(child)) {
                        return true; // Propagate up the success  
                    }
                }
            }
            return false; // Not found in this branch  
        };

        recursiveAddChild(newTreeData);
        setTreeData(newTreeData);
    };

    // **Handler to open Add Child Node Modal**  
    const handleAddChildNode = () => {
        setNewChildData({ name: '', description: '', is_group_paying: true });
        setIsAddChildModalOpen(true);
    };

    // **Handler to save new child node**  
    const handleSaveNewChild = async () => {
        const parentNode = selection[0];
        const newGroupData = {
            name: newChildData.name,
            description: newChildData.description,
            group_type_id: 1,
            org_id: orgId,
            parent_group_id: parentNode.attributes.group_id,
            is_group_paying: newChildData.is_group_paying
        };

        try {
            const createdGroup = await callProtectedApi(
                getAccessTokenSilently,
                protectedResources.apiCreateOrUpdateGroup.endpoint,
                {
                    method: 'POST',
                    body: JSON.stringify(newGroupData)
                }
            );

            // Update the tree data with the new group
            const newChildNode = {
                name: createdGroup.name,
                attributes: {
                    description: createdGroup.description,
                    group_type_id: createdGroup.group_type_id,
                    org_id: createdGroup.org_id,
                    level: (parentNode.attributes.level || 0) + 1,
                    group_id: createdGroup.group_id,
                    path: `${parentNode.attributes.path}/${createdGroup.name}`,
                },
                children: [],
            };

            addChildNodeToTree(parentNode.attributes.group_id, newChildNode);

            setIsAddChildModalOpen(false);
            setNotification({ type: 'success', message: 'Child group added successfully.' });
        } catch (error) {
            console.error('Error creating new child group:', error);
            setNotification({ type: 'error', message: error.message || 'Failed to add child group. Please try again later.' });
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Filter actors based on the search query  
    const filteredActors = groupActors.filter((actor) => {
        const query = searchQuery.toLowerCase();
        return (
            actor.full_name.toLowerCase().includes(query) ||
            (actor.email && actor.email.toLowerCase().includes(query))
        );
    });

    // Sort actors based on the sort configuration  
    const sortedActors = React.useMemo(() => {
        if (sortConfig.key !== null) {
            return [...filteredActors].sort((a, b) => {
                let aValue = a[sortConfig.key] || '';
                let bValue = b[sortConfig.key] || '';

                // For specialties, compare formatted values  
                if (sortConfig.key === 'actor_specialty') {
                    aValue = formatSpecialty(aValue);
                    bValue = formatSpecialty(bValue);
                } else {
                    aValue = aValue.toString().toLowerCase();
                    bValue = bValue.toString().toLowerCase();
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        } else {
            return filteredActors;
        }
    }, [filteredActors, sortConfig]);

    const handleUsersSort = (key) => {
        let direction = 'ascending';
        if (usersSortConfig.key === key && usersSortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setUsersSortConfig({ key, direction });
    };

    // Filter users based on the search query  
    const filteredUsers = users.filter((user) => {
        const query = usersSearchQuery.toLowerCase();
        return (
            user.full_name.toLowerCase().includes(query) ||
            (user.email && user.email.toLowerCase().includes(query))
        );
    });

    // Sort users based on the sort configuration  
    const sortedUsers = React.useMemo(() => {
        if (usersSortConfig.key !== null) {
            return [...filteredUsers].sort((a, b) => {
                let aValue = a[usersSortConfig.key] || '';
                let bValue = b[usersSortConfig.key] || '';

                aValue = aValue.toString().toLowerCase();
                bValue = bValue.toString().toLowerCase();

                if (aValue < bValue) {
                    return usersSortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return usersSortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        } else {
            return filteredUsers;
        }
    }, [filteredUsers, usersSortConfig]);

    // **Now it's safe to have early returns**  

    if (isLoading) {
        return (
            <div className="flex justify-center items-center w-full h-full">
                <div className="text-xl text-gray-700">Loading...</div>
            </div>
        );
    }

    if (!treeData) {
        return (
            <div className="flex justify-center items-center w-full h-full">
                <div className="text-xl text-red-500">Error: Organization data not available.</div>
            </div>
        );
    }

    // Rest of your component code  



    const renderNodeWithCustomContent = ({ nodeDatum, toggleNode }) => (
        <g
            onClick={(e) => {
                e.stopPropagation();
                setSelection([nodeDatum]);
            }}
        >
            {/* Main Node Card */}
            <foreignObject x="-120" y="-40" width="240" height="80">
                <div
                    xmlns="http://www.w3.org/1999/xhtml"
                    style={{
                        backgroundColor: selection.length > 0 && selection[0].attributes.group_id === nodeDatum.attributes.group_id
                            ? '#f5f3ff' // Light indigo background when selected
                            : 'white',
                        padding: '12px 16px',
                        borderRadius: '12px',
                        border: selection.length > 0 && selection[0].attributes.group_id === nodeDatum.attributes.group_id
                            ? '2px solid #818cf8' // Indigo border when selected
                            : '2px solid #e5e7eb',
                        fontSize: '14px',
                        lineHeight: '1.4',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        textAlign: 'center',
                        boxShadow: selection.length > 0 && selection[0].attributes.group_id === nodeDatum.attributes.group_id
                            ? '0 4px 12px rgba(129, 140, 248, 0.2)' // Indigo shadow when selected
                            : '0 4px 6px rgba(0, 0, 0, 0.05)',
                        cursor: 'pointer',
                        transition: 'all 0.2s ease-in-out',
                        transform: selection.length > 0 && selection[0].attributes.group_id === nodeDatum.attributes.group_id
                            ? 'scale(1.05)'
                            : 'scale(1)',
                    }}
                    className="hover:shadow-lg hover:border-indigo-300 hover:bg-gray-50"
                >
                    <div className="font-medium text-gray-900">{nodeDatum.name}</div>
                    {/* Add subscription type indicator */}
                    <div className="mt-1 flex justify-center">
                        {nodeDatum.attributes.is_group_paying !== undefined && (
                            <span className={`px-2 py-0.5 text-xs rounded-full ${nodeDatum.attributes.is_group_paying
                                ? 'bg-green-100 text-green-800'
                                : 'bg-blue-100 text-blue-800'
                                }`}>
                                {nodeDatum.attributes.is_group_paying ? 'Group' : 'Individual'}
                            </span>
                        )}
                    </div>
                    {nodeDatum.attributes.description && nodeDatum.attributes.description.trim() !== '' && (
                        <div className="mt-1 text-xs text-gray-500 truncate">
                            {nodeDatum.attributes.description}
                        </div>
                    )}
                </div>
            </foreignObject>
        </g>
    );
    const handleNodeClick = (nodeDatum, evt) => {
        setSelection([nodeDatum]);
        console.log(nodeDatum);
    };

    return (
        <div className="flex flex-col w-full h-full organization-structure">
            <header className="bg-white shadow">
                <div className="flex justify-between items-center px-4 py-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight leading-tight text-gray-900">
                        Organization Structure
                    </h1>
                </div>
            </header>
            <div className="overflow-auto flex-1 p-4 bg-gray-200 rounded-md">
                <Tab.Group>
                    <Tab.List className="flex p-1 space-x-1 rounded-xl bg-blue-900/20">
                        <Tab
                            className={({ selected }) =>
                                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg  
              ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
                            }
                        >
                            Organization Tree
                        </Tab>
                        <Tab
                            className={({ selected }) =>
                                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-lg  
              ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
                            }
                        >
                            Users List
                        </Tab>
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                        {/* Panel for Organization Tree */}
                        <Tab.Panel className="pb-2 bg-gray-100 rounded-md">
                            <main className="overflow-auto flex-1">
                                {/* Outer Scrollable Container */}
                                <div className="overflow-auto w-full h-full">
                                    <div className="w-full h-full">
                                        <div id="treeWrapper" style={{ width: '100%', height: '80vh' }}>
                                            <Tree
                                                data={treeData}
                                                zoomable={true}
                                                translate={{ x: window.innerWidth / 2, y: 100 }}
                                                orientation="vertical"
                                                pathFunc="step"
                                                collapsible={false}
                                                nodeSize={{ x: 250, y: 200 }}
                                                renderCustomNodeElement={renderNodeWithCustomContent}
                                                enableLegacyTransitions={true}
                                                onNodeClick={handleNodeClick}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {selection.length > 0 && (
                                    <>
                                        <div
                                            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                                            onClick={() => setSelection([])}
                                        />
                                        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-xl border border-gray-200 w-[90%] max-w-2xl animate-fade-in z-50 overflow-y-auto max-h-[90vh]">
                                            <div className="flex justify-between items-center mb-6">
                                                <h3 className="text-xl font-semibold text-gray-800">
                                                    Selected Group Details
                                                </h3>
                                                <button
                                                    onClick={() => setSelection([])}
                                                    className="text-gray-500 transition-colors hover:text-gray-700"
                                                >
                                                    <i className="pi pi-times" />
                                                </button>
                                            </div>
                                            <div className="space-y-4">
                                                <p className="flex flex-col">
                                                    <span className="mb-1 text-sm font-medium text-gray-600">
                                                        Name
                                                    </span>
                                                    <span className="text-gray-800">{selection[0].name}</span>
                                                </p>
                                                <p className="flex flex-col">
                                                    <span className="mb-1 text-sm font-medium text-gray-600">
                                                        Description
                                                    </span>
                                                    <span className="text-gray-800">
                                                        {selection[0].attributes.description?.trim()
                                                            ? selection[0].attributes.description
                                                            : <span className="italic text-gray-400">No description provided</span>
                                                        }
                                                    </span>
                                                </p>
                                                <p className="flex flex-col">
                                                    <span className="mb-1 text-sm font-medium text-gray-600">
                                                        Subscription Type
                                                    </span>
                                                    <span className="text-gray-800">
                                                        {selection[0].attributes.is_group_paying
                                                            ? "Group Subscription"
                                                            : "Individual Subscriptions"}
                                                    </span>
                                                </p>
                                                <p className="flex flex-col">
                                                    <span className="mb-1 text-sm font-medium text-gray-600">
                                                        Path
                                                    </span>
                                                    <span className="text-gray-800">
                                                        {selection[0].attributes.path}
                                                    </span>
                                                </p>
                                                {isFetchingActors ? (
                                                    <div>Loading actors...</div>
                                                ) : errorFetchingActors ? (
                                                    <div>Error: {errorFetchingActors}</div>
                                                ) : groupActors.length > 0 ? (
                                                    <div className="mt-4">
                                                        <h3 className="mb-2 text-lg font-semibold">Providers in Group</h3>
                                                        {/* Search Input */}
                                                        <div className="mb-2">
                                                            <input
                                                                type="text"
                                                                placeholder="Search by name or email..."
                                                                value={searchQuery}
                                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                                className="p-2 w-full rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                            />
                                                        </div>
                                                        <div className="overflow-y-auto max-h-64 rounded-md border border-gray-200">
                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                <thead className="sticky top-0 bg-gray-50">
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            className="px-4 py-2 text-sm font-medium text-left text-gray-500 cursor-pointer select-none"
                                                                            onClick={() => handleSort('full_name')}
                                                                        >
                                                                            Name
                                                                            {sortConfig.key === 'full_name' && (
                                                                                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                                            )}
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="px-4 py-2 text-sm font-medium text-left text-gray-500 cursor-pointer select-none"
                                                                            onClick={() => handleSort('email')}
                                                                        >
                                                                            Email
                                                                            {sortConfig.key === 'email' && (
                                                                                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                                            )}
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="px-4 py-2 text-sm font-medium text-left text-gray-500 cursor-pointer select-none"
                                                                            onClick={() => handleSort('actor_specialty')}
                                                                        >
                                                                            Specialty
                                                                            {sortConfig.key === 'actor_specialty' && (
                                                                                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                                            )}
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="bg-white divide-y divide-gray-200">
                                                                    {sortedActors.map((actor) => (
                                                                        <tr key={actor.actor_id}>
                                                                            <td className="px-4 py-2 text-sm text-gray-700">{actor.full_name}</td>
                                                                            <td className="px-4 py-2 text-sm text-gray-700">{actor.email}</td>
                                                                            <td className="px-4 py-2 text-sm text-gray-700">
                                                                                {formatSpecialty(actor.actor_specialty)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>No actors found in this group.</div>
                                                )}

                                                {isAdmin && (
                                                    <div className="pt-4 space-y-2 border-t">
                                                        <button
                                                            onClick={() => {
                                                                setSelectedGroupId(selection[0].attributes.group_id);
                                                                setIsSignupCodesModalOpen(true);
                                                            }}
                                                            className="flex gap-2 items-center px-4 py-2 w-full text-white bg-indigo-800 rounded-md transition-colors hover:bg-indigo-900"
                                                        >
                                                            <PlusIcon className="w-5 h-5" />
                                                            Manage Signup Codes
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setSelectedGroupId(
                                                                    selection[0].attributes.group_id
                                                                );
                                                                setIsMacrosModalOpen(true);
                                                            }}
                                                            className="flex gap-2 items-center px-4 py-2 w-full text-white bg-indigo-800 rounded-md transition-colors hover:bg-indigo-900"
                                                        >
                                                            <CubeIcon className="w-5 h-5" />
                                                            View Macros
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setSelectedGroupId(
                                                                    selection[0].attributes.group_id
                                                                );
                                                                setIsMdmModalOpen(true);
                                                            }}
                                                            className="flex gap-2 items-center px-4 py-2 w-full text-white bg-indigo-800 rounded-md transition-colors hover:bg-indigo-900"
                                                        >
                                                            <CreditCardIcon className="w-5 h-5" />
                                                            View MDM Template
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setSelectedGroupId(
                                                                    selection[0].attributes.group_id
                                                                );
                                                                setIsInterventionsModalOpen(true);
                                                            }}
                                                            className="flex gap-2 items-center px-4 py-2 w-full text-white bg-indigo-800 rounded-md transition-colors hover:bg-indigo-900"
                                                        >
                                                            {/* Icon for Interventions */}
                                                            <i className="pi pi-exclamation-triangle" />
                                                            View Interventions
                                                        </button>
                                                        <button
                                                            onClick={handleAddChildNode}
                                                            className="flex gap-2 items-center px-4 py-2 w-full text-white bg-green-600 rounded-md transition-colors hover:bg-green-700"
                                                        >
                                                            <i className="pi pi-plus-circle" />
                                                            Add Child Group
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </main>
                        </Tab.Panel>
                        {/* Panel for Users List */}
                        <Tab.Panel className="p-4 bg-white rounded-md">
                            {/* Users List */}
                            <div className="mt-4">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-lg font-semibold">Users in Organization</h3>
                                    <button
                                        onClick={handleMoveUserClick}
                                        disabled={!selectedUser}
                                        className={`px-4 py-2 rounded-md text-white ${selectedUser ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-gray-400 cursor-not-allowed'
                                            } transition-colors`}
                                    >
                                        Move User to Group
                                    </button>
                                </div>

                                {/* Search Input */}
                                <div className="mb-2">
                                    <input
                                        type="text"
                                        placeholder="Search by name or email..."
                                        value={usersSearchQuery}
                                        onChange={(e) => setUsersSearchQuery(e.target.value)}
                                        className="p-2 w-full rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    />
                                </div>

                                {isLoadingUsers ? (
                                    <div>Loading users...</div>
                                ) : usersError ? (
                                    <div>Error: {usersError}</div>
                                ) : users.length > 0 ? (
                                    <div className="overflow-y-auto max-h-96 rounded-md border border-gray-200">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="sticky top-0 bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-2 text-sm font-medium text-left text-gray-500 cursor-pointer select-none"
                                                        onClick={() => handleUsersSort('full_name')}
                                                    >
                                                        Name
                                                        {usersSortConfig.key === 'full_name' && (
                                                            <span>{usersSortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                        )}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-2 text-sm font-medium text-left text-gray-500 cursor-pointer select-none"
                                                        onClick={() => handleUsersSort('email')}
                                                    >
                                                        Email
                                                        {usersSortConfig.key === 'email' && (
                                                            <span>{usersSortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                        )}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-4 py-2 text-sm font-medium text-left text-gray-500 cursor-pointer select-none"
                                                        onClick={() => handleUsersSort('group_name')}
                                                    >
                                                        Group
                                                        {usersSortConfig.key === 'group_name' && (
                                                            <span>{usersSortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                                                        )}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {sortedUsers.map((user, index) => (
                                                    <tr
                                                        key={user.actor_id}
                                                        className={`
                                                            cursor-pointer
                                                            hover:bg-indigo-50
                                                            ${selectedUser && selectedUser.actor_id === user.actor_id ? 'bg-indigo-100' : 'bg-gray-50'}
                                                        `}
                                                        onClick={() => handleUserSelection(user)}
                                                    >
                                                        <td className="px-4 py-2 text-sm text-gray-700">{user.full_name}</td>
                                                        <td className="px-4 py-2 text-sm text-gray-700">{user.email}</td>
                                                        <td className="px-4 py-2 text-sm text-gray-700">{user.group_name || 'Unassigned'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div>No users found in this organization.</div>
                                )}
                            </div>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>

            {/* **Add Child Node Modal** */}
            {isAddChildModalOpen && (
                <>
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                        onClick={() => setIsAddChildModalOpen(false)}
                    />
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-xl border border-gray-200 w-[90%] max-w-md animate-fade-in z-50 overflow-y-auto max-h-[90vh]">
                        <div className="flex justify-between items-center mb-6">
                            <h3 className="text-xl font-semibold text-gray-800">
                                Add Child Group
                            </h3>
                            <button
                                onClick={() => setIsAddChildModalOpen(false)}
                                className="text-gray-500 transition-colors hover:text-gray-700"
                            >
                                <i className="pi pi-times" />
                            </button>
                        </div>
                        <div className="space-y-4">
                            <div className="flex flex-col">
                                <label className="text-sm font-medium text-gray-600">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    value={newChildData.name}
                                    onChange={(e) =>
                                        setNewChildData({
                                            ...newChildData,
                                            name: e.target.value,
                                        })
                                    }
                                    className="p-2 mt-1 rounded-md border"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="text-sm font-medium text-gray-600">
                                    Description
                                </label>
                                <textarea
                                    value={newChildData.description}
                                    onChange={(e) =>
                                        setNewChildData({
                                            ...newChildData,
                                            description: e.target.value,
                                        })
                                    }
                                    className="p-2 mt-1 rounded-md border"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="text-sm font-medium text-gray-600">
                                    Subscription Type
                                </label>
                                <div className="mt-2">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            checked={newChildData.is_group_paying}
                                            onChange={() => setNewChildData({
                                                ...newChildData,
                                                is_group_paying: true
                                            })}
                                            className="form-radio h-4 w-4 text-indigo-600"
                                        />
                                        <span className="ml-2">Group Subscription</span>
                                    </label>
                                    <label className="inline-flex items-center ml-6">
                                        <input
                                            type="radio"
                                            checked={!newChildData.is_group_paying}
                                            onChange={() => setNewChildData({
                                                ...newChildData,
                                                is_group_paying: false
                                            })}
                                            className="form-radio h-4 w-4 text-indigo-600"
                                        />
                                        <span className="ml-2">Individual Subscriptions</span>
                                    </label>
                                </div>
                            </div>
                            <div className="flex justify-end space-x-2">
                                <button
                                    onClick={() => setIsAddChildModalOpen(false)}
                                    className="px-4 py-2 text-gray-700 bg-gray-300 rounded-md transition-colors hover:bg-gray-400"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSaveNewChild}
                                    className="px-4 py-2 text-white bg-green-600 rounded-md transition-colors hover:bg-green-700"
                                >
                                    Add Child
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {isMoveUserModalOpen && (
                <>
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                        onClick={() => setIsMoveUserModalOpen(false)}
                    />
                    <div className="fixed top-1/2 left-1/2 z-50 p-8 w-full max-w-md bg-white rounded-lg border border-gray-200 shadow-xl transform -translate-x-1/2 -translate-y-1/2">
                        <div className="flex justify-between items-center mb-6">
                            <h3 className="text-xl font-semibold text-gray-800">
                                Move User to Group
                            </h3>
                            <button
                                onClick={() => setIsMoveUserModalOpen(false)}
                                className="text-gray-500 transition-colors hover:text-gray-700"
                            >
                                <i className="pi pi-times" />
                            </button>
                        </div>
                        <div className="space-y-4">
                            <p>
                                Moving <strong>{selectedUser.full_name}</strong> to a new group.
                            </p>
                            <div className="flex flex-col">
                                <label className="mb-1 text-sm font-medium text-gray-600">
                                    Select New Group
                                </label>
                                <select
                                    value={selectedGroupIdForMove || ''}
                                    onChange={(e) => setSelectedGroupIdForMove(e.target.value)}
                                    className="p-2 rounded-md border"
                                >
                                    <option value="" disabled>
                                        Choose a group
                                    </option>
                                    {groups.map((group) => (
                                        <option key={group.group_id} value={group.group_id}>
                                            {group.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-end space-x-2">
                                <button
                                    onClick={() => setIsMoveUserModalOpen(false)}
                                    className="px-4 py-2 text-gray-700 bg-gray-300 rounded-md transition-colors hover:bg-gray-400"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleConfirmMoveUser}
                                    className={`
                                        px-4 py-2 rounded-md transition-colors
                                        ${!selectedGroupIdForMove
                                            ? 'text-gray-500 bg-gray-300 cursor-not-allowed'
                                            : 'text-white bg-indigo-600 hover:bg-indigo-700'
                                        }
                                    `}
                                    disabled={!selectedGroupIdForMove}
                                >
                                    Move User
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* Group Macros Modal */}
            <Dialog
                open={isMacrosModalOpen}
                onClose={() => setIsMacrosModalOpen(false)}
                className="relative z-50"
            >
                <div
                    className="fixed inset-0 bg-black bg-opacity-50"
                    aria-hidden="true"
                />
                <div className="flex fixed inset-0 justify-center items-center p-4">
                    <Dialog.Panel className="p-6 mx-auto w-full max-w-6xl bg-white rounded shadow-lg">
                        <Dialog.Title className="mb-4 text-lg font-bold">
                            Group Macros
                        </Dialog.Title>
                        <GroupMacrosPage
                            groupId={selectedGroupId}
                            onSuccessfulSave={() => {
                                // Optionally handle success  
                            }}
                            isModal={true} // Pass a prop to adjust styling for modal  
                        />
                        <div className="mt-4">
                            <button
                                onClick={() => setIsMacrosModalOpen(false)}
                                className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700"
                            >
                                Close
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>

            {/* MDM Settings Modal */}
            <Dialog
                open={isMdmModalOpen}
                onClose={() => setIsMdmModalOpen(false)}
                className="relative z-50"
            >
                <div
                    className="fixed inset-0 bg-black bg-opacity-50"
                    aria-hidden="true"
                />
                <div className="flex fixed inset-0 justify-center items-center p-4">
                    <Dialog.Panel className="mx-auto w-full max-w-6xl h-[90vh] bg-white rounded shadow-lg p-6 overflow-y-auto">
                        <MdmSettings
                            groupId={selectedGroupId}
                            onSuccessfulSave={() => {
                                // Optionally handle success (e.g., show a success message)  
                            }}
                            isModal={true} // Pass a prop to adjust styling for modal  
                        />
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setIsMdmModalOpen(false)}
                                className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700"
                            >
                                Close
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>

            {/* Interventions Modal */}
            <Dialog
                open={isInterventionsModalOpen}
                onClose={() => setIsInterventionsModalOpen(false)}
                className="relative z-50"
            >
                <div
                    className="fixed inset-0 bg-black bg-opacity-50"
                    aria-hidden="true"
                />
                <div className="flex fixed inset-0 justify-center items-center p-4">
                    <Dialog.Panel className="p-6 mx-auto w-full max-w-6xl bg-white rounded shadow-lg">
                        <Dialog.Title className="mb-4 text-lg font-bold">
                            Group Interventions
                        </Dialog.Title>
                        <GroupInterventionsPage
                            groupId={selectedGroupId}
                            onSuccessfulSave={() => {
                                // Optionally handle success  
                            }}
                            isModal={true}
                        />
                        <div className="mt-4">
                            <button
                                onClick={() => setIsInterventionsModalOpen(false)}
                                className="px-4 py-2 text-white bg-gray-600 rounded hover:bg-gray-700"
                            >
                                Close
                            </button>
                        </div>
                    </Dialog.Panel>
                </div>
            </Dialog>

            <GroupSignupCodes
                groupId={selectedGroupId}
                groupName={selection[0]?.name}
                isOpen={isSignupCodesModalOpen}
                onClose={() => setIsSignupCodesModalOpen(false)}
                setNotification={setNotification}
            />



            {notification && (
                <div className={`fixed bottom-4 left-4 bg-${notification.type === 'success' ? 'green' : 'red'}-500 text-white px-4 py-2 rounded-md`}>
                    {notification.message}
                </div>
            )}
        </div>


    );
};

export default OrganizationStructure;  